<template>
    <div
        class="flex flex-col"
    >
        <label
            v-if="label"
            :for="id"
            class="mb-2"
        >{{label}}</label>
        <div class="relative">
            <div
            @click="show = !show"
            :ref="ev=>{input=ev}"
            class="bg-white dark:bg-gray-300  border-b-2 py-2 pl-4 pr-2 cursor-pointer flex justify-between items-center group"
            :class="[
                errorMessage ? 'border-red-500 focus:border-red-600 focus:ring-offset-red-200 is-invalid' : 'dark:border-gray-600 border-gray-400 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600'
            ]"
            >
                <div>{{text}}: <strong>{{selectedItems.length}}</strong></div>
                <SvgIcon
                    name="selector"
                    class="text-gray-400 group-hover:text-gray-600"
                    :size="20"
                />
            </div>

            <div
            v-if="show"
            class="absolute -bottom-2 bg-white dark:bg-gray-300 transform translate-y-full w-full z-50 shadow-lg overflow-y-auto max-h-64"
            v-click-outside="() => {show = false}"
            >
                <div
                v-for="(item, key) in data"
                :key="type == 'objectkey' ? key : (type == 'slug' ? item.slug : item.id)"
                class="py-1 px-4 border-b border-gray-400 last:border-0 cursor-pointer flex items-center"
                :class="selectedItems.includes(type == 'objectkey' ? key : (type == 'slug' ? item.slug : item.id)) ? 'bg-green-50 text-green-900':''"
                @click="changeItems(type == 'objectkey' ? key : (type == 'slug' ? item.slug : item.id))"
                >
                    <!-- <input type="checkbox" :checked="selectedItems.includes(item.id)"> -->
                    <SvgIcon
                        name="check"
                        class="text-green-900"
                        :class="selectedItems.includes(type == 'objectkey' ? key : (type == 'slug' ? item.slug : item.id)) ? 'opacity-100':'opacity-0'"
                        :size="20"
                    />
                    <div class="ml-2" v-html="type == 'objectkey' ? item : item.name"></div>
                </div>
            </div>
        </div>
        <div class="text-red-500 text-xs font-bold" v-if="errorMessage">{{ Array.isArray(errorMessage) ? errorMessage[0] : errorMessage }}</div>
    </div>
</template>

<script>
import SvgIcon from '@/Components/SvgIcon'
import vClickOutside from 'click-outside-vue3'
import { ref } from '@vue/reactivity';
    import { computed, nextTick, onMounted, watch } from '@vue/runtime-core';
    import { usePage } from '@inertiajs/inertia-vue3';

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        SvgIcon
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    props: {
        data: {
            type: Object,
            default: null
        },
        modelValue: [Array, Object],
        selected: {
            type: Array,
            default: []
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: 'Wybrane'
        },
        type: {
            type: String,
            default: 'array'
        },
        error: [String, Array, Boolean],
    },

    emits: ['update:modelValue', 'update:unfocus', 'update', 'change'],

    setup(props, { emit }) {
        const options = props.data;
        const selectedItems = ref(props.selected);
        const show = ref(false);

        watch(selectedItems.value, (val, prevVal) => {
            emit('update:modelValue', val);
        })


        const changeItems = (id) => {
            if (!selectedItems.value.includes(id)) selectedItems.value.push(id)
            else selectedItems.value.splice(selectedItems.value.indexOf(id), 1);
        }

        const input = ref(null);

        const errorMessage = ref(props.error);
        watch( () => usePage().props.value.errors, (error, oldError) => {
            if(props.name || props.id) errorMessage.value = error[props.name ? props.name : props.id];

            nextTick(() => {
                if(document.getElementsByClassName('is-invalid').length && document.getElementsByClassName('is-invalid')[0] == input.value) {

                    window.scrollTo({
                        top: input.value.offsetTop - 120,
                        behavior: 'smooth'
                    });
                }
            })
        })

        return {
            options,
            selectedItems,
            changeItems,
            show,
            errorMessage,
            input
        }
    },

    watch: {
        modelValue(val) {
            this.selectedItems = val;
        }
    }
}
</script>
