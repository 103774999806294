<template>
    <AdminLayout>
        <Modal v-if="confirmModal" @update="confirmModal = $event">
            <div class="lg:px-48  py-2">
                <h2 class="text-xl font-bold text-center pt-4">Czy na pewno chcesz pobrać wszystkie dane?</h2>
                <h1 class="text-sm text-center">Upewnij się że <b>wszystkie</b> drużyny są dodane oraz zmapowane.
                <br>Drużyny które nie będą odpowiednio skonfigurowane mogą zostać pominięte przy pobieraniu danych z kolejek.</h1>

                <div class="text-red-500 text-lg" v-if="downloadTables.values.length">
                    <p>Znaleziono więcej niż jedną tabelę z wynikami. Proszę wybrać właściwą i ponownie pobrać dane lub anulować operację!</p>

                    <div class="flex items-center" v-for="(item, key) in downloadTables.values" :key="key">
                        <input
                            type="radio"
                            name="table"
                            v-model="downloadTables.selected"
                            :value="key"
                            :id="'table-' + key"
                        />
                        <Label
                            class="ml-2 dark:text-white"
                            :for="'table-' + key"
                        >{{ item }}</Label>
                    </div>
                </div>
                <div v-if="downloadTables.loading" class="absolute flex items-center justify-center inset-0 bg-gray-900 bg-opacity-50">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
            <div class="w-full">
                    <div class="lg:w-2/5 w-5/5  grid grid-cols-2 mx-auto py-6 text-center">
                        <Link
                            class="ml-2 text-center px-6"
                            color="gray"
                            @click="confirmModal = false"
                            >
                                <SvgIcon name="chevron-left" width="18" height="18"/>
                                <span class="ml-2  ">Wróć</span>
                            </Link>
                            <Link
                                class="ml-2 bg-yellow-500 hover:bg-yellow-600"
                                color="gray"
                                @click="ev => {if(queue.league.url) downloadQueue(queue.id) }"
                                @update="confirmModal = false"
                                :disabled="queue.league.url ? false : true"
                            >
                                <SvgIcon name="download" width="18" height="18"/>
                                <span class="ml-2  ">Pobierz dane</span>
                            </Link>
                    </div>
            </div>
        </Modal>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div>
                <Link :href="route('admin.events.leagues.gueues', queue.league_id)" class="mr-2">
                    <SvgIcon name="chevron-left" width="18" height="18" />
                    <span class="ml-2">Wróć</span>
                </Link>
                <Link
                    class="ml-2 bg-yellow-500"
                    @click="confirmModal = true"
                    :disabled="queue.league.url ? false : true"
                >
                    <SvgIcon name="download" width="18" height="18" />
                    <span class="ml-2">Pobierz dane</span>
                </Link>
            </div>

            <div class="py-2 my-5 px-4 inline-flex sm:flex-row flex-col w-full justify-between rounded border-dashed border-2 border-green-400">
                <div>
                    <h1 class="text-xl font-thin mb-3 mt-2 pt-2 dark:text-white" >
                        <span>Edycja kolejki #{{queue.number}}</span>
                        <div class="dark:text-gray-300 font-normal mt-1 text-xs">{{ queue.league.name  }}</div>
                    </h1>
                </div>
                <div class="ml-4 pt-2 inline-flex flex-wrap items-center justify-end gap-2">
                    <InputLabel
                        placeholder="Number kolejki"
                        id="number"
                        name="number"
                        class=" w-24"
                        type="number"
                        v-model="editQueue.number"
                    />
                    <div>

                        <InputLabel
                            placeholder=""
                            id="date"
                            name="date"
                            type="date"
                            v-model="editQueue.start_date"
                        />
                    </div>
                    <div>
                        <Link
                            class="w-full text-center h-10  rounded-none justify-center bg-green-600 hover:bg-green-800"
                            @click="updateQueue()"
                            >
                                <SvgIcon name="check" width="14" height="14" />
                                <span class="ml-2">Zapisz</span>
                        </Link>
                    </div>
                </div>
            </div>

            <form>
                <div class="grid lg:grid-cols-9 grid-cols-4 gap-2 font-bold">
                    <BigListbox
                        label="Gospodarze"
                        :data="teams"
                        v-model="queue_form.host"
                        class="col-span-4 w-full lg:col-span-2"
                        type="teams"
                    />
                    <BigListbox
                        label="Goście"
                        :data="teams"
                        v-model="queue_form.guest"
                        class="col-span-4 lg:col-span-2"
                        type="teams"
                    />
                    <div class="grid grid-cols-2 gap-2 col-span-4 lg:col-span-1">
                        <InputLabel
                            label="A"
                            placeholder="-"
                            id="host_goals"
                            name="host_goals"
                            type="text"
                            class="w-full"
                            v-model="queue_form.host_goals"
                        />
                        <InputLabel
                            label="B"
                            placeholder="-"
                            id="guest_goals"
                            name="guest_goals"
                            type="text"
                            class="w-full"
                            v-model="queue_form.guest_goals"
                        />
                    </div>
                    <Listbox
                        label="Walkowery"
                        :data="walkovers"
                        v-model="queue_form.walkover"
                        class="col-span-4 lg:col-span-1"
                    />
                    <div class="w-full col-span-2 lg:col-span-1">
                        <InputLabel
                            label="Data"
                            placeholder=""
                            id="date"
                            name="date"
                            type="date"
                            v-model="queue_form.date"
                        />
                    </div>
                    <div class="w-full col-span-2 lg:col-span-1">
                        <Timepicker
                            class="mt-8"
                            v-model="queue_form.time"
                        />

                    </div>


                    <div class="mt-2 lg:mt-0 flex flex-col lg:col-span-1 col-span-4 align-center justify-end">
                        <Link class="flex  justify-center items-center h-auto bg-green-600 hover:bg-green-800" padding="py-3" @click="addQueueItem()">
                            <svg v-if="queue_form.processing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <SvgIcon name="plus" width="18" height="18" />
                            <span class="ml-2">Dodaj</span>
                        </Link>
                    </div>
                </div>
                <div class="border-b-4 border-gray-400 border-dashed mt-4"></div>
            </form>

            <div class="mt-6 space-y-3">
                <div
                    class="grid lg:grid-cols-8 grid-cols-4 gap-2 mb-2 border-b-2 border-gray-400 pb-3 last:border-0"
                    v-for="(i) in editItemsForm.items"
                    :key="i.id"
                >
                    <div class="text-left col-span-8 inline-flex justify-between items-center">
                        <div class="flex gap-1">
                            <h3 class="font-bold my-2 text-lg dark:text-white">
                                <span>Element kolejki (# {{ i.id }})</span>
                                <div class="dark:text-gray-300 font-normal mt-1 text-xs">{{ queue.league.name  }}</div>
                            </h3>
                            <div
                                v-if="i.users && i.users.length"
                                class="text-gray-700 dark:text-gray-200 group relative flex items-center"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>
                                <div class=" dark:text-gray-700 group-hover:opacity-100 group-hover:visible invisible transition duration-300 absolute left-1/2 transform -translate-x-1/2 bottom-full border p-2 bg-white w-max text-sm max-w-xs shadow">
                                    <ul class="space-y-1 text-xs max-h-48 overflow-auto">
                                        <li
                                            v-for="(user, key) in i.users"
                                            :key="user.id"
                                        >
                                            {{ key == 0 ? 'dodał wynik' : 'modyfikował' }}: <b>{{ user ? user.name : '' }}, {{ user ? getDate(user.pivot.created_at) : '' }}</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-2">
                            <Link
                                class="text-center justify-center bg-green-600 hover:bg-green-800"
                                @click="editValue(i, true)"
                            >
                                <SvgIcon name="check" width="18" height="18" />
                            </Link>
                            <Link
                                class="text-center justify-center bg-red-600 hover:bg-red-800"
                                @click="showRemove.modal = true; showRemove.item = i;"
                            >
                                <SvgIcon name="x" width="18" height="18" />
                                <!-- <span class="ml-2">Usuń</span> -->
                            </Link>
                        </div>
                    </div>
                    <div class="col-span-8 grid gap-2 grid-cols-4">
                        <div class="col-span-full lg:col-span-2 grid grid-cols-2 gap-2">
                            <div class="flex flex-col gap-2 col-span-full sm:col-span-1">
                                <BigListbox
                                    :data="teams"
                                    v-model="i.host_id"
                                    :selected="i.host_id"
                                />
                            </div>
                            <div  class="flex flex-col gap-2 col-span-full sm:col-span-1">
                                <BigListbox
                                    :data="teams"
                                    v-model="i.guest_id"
                                    :selected="i.guest_id"
                                />
                            </div>
                            <div class="flex flex-col gap-2">
                                <InputLabel
                                    placeholder="-"
                                    :id="'host_goals_'+i.id"
                                    name="host_goals"
                                    type="text"
                                    class="w-full"
                                    v-model="i.host_goals"
                                />
                            </div>
                            <div  class="flex flex-col gap-2">
                                <InputLabel
                                    placeholder="-"
                                    :id="'guest_goals_'+i.id"
                                    name="guest_goals"
                                    type="text"
                                    class="w-full"
                                    v-model="i.guest_goals"
                                />
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-2 col-span-4 lg:col-span-2">
                            <div class="col-span-full grid grid-cols-2 gap-2">
                                <InputLabel
                                    placeholder=""
                                    :id="'date_'+i.id"
                                    name="date"
                                    type="date"
                                    class="w-full"
                                    v-model="i.match_dates"
                                />
                                <Timepicker
                                    v-model="i.match_time"
                                />
                            </div>
                            <Listbox
                                :data="walkovers"
                                v-model="i.walkover"
                                class="col-span-full"
                                type="object"
                            />
                        </div>

                        <InputLabel
                            placeholder="Komentarz"
                            height="auto"
                            type="textarea"
                            rows="1"
                            class="col-span-full"
                            v-model="i.comment"
                        />
                    </div>
                </div>
            </div>
            <div class="flex justify-end sticky bottom-0 py-2 dark:bg-gray-800 z-20">
                <Link
                    class="text-center justify-center h-12 bg-green-600 hover:bg-green-800" style="min-width: 150px;"
                    @click="editValue()"
                >
                    <svg v-if="editItemsForm.processing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <SvgIcon name="check" width="18" height="18" />
                    <span class="ml-2">Zapisz wszystko</span>
                </Link>
            </div>
        </div>
        <Modal v-if="showRemove.modal" @update="showRemove.modal = $event">
            <div class="rounded p-5 text-white">
                <h2 class="mb-5 font-bold">Napewno chcesz usunąć element kolejki?</h2>
                <div class="flex">
                    <Link @click="deleteItem(showRemove.item.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                        <SvgIcon name="x" width="18" height="18" />
                        <span class="ml-2">Tak, Usuń</span>
                    </Link>
                    <Link @click="showRemove.modal = false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                        <SvgIcon name="x" width="18" height="18" />
                        <span class="ml-2">Nie, anuluj operację</span>
                    </Link>
                </div>
            </div>
        </Modal>
    </AdminLayout>
</template>

<script>
import SvgIcon from '@/Components/SvgIcon'
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Checkbox from '@/Components/Checkbox'
    import Listbox from '@/Components/Listbox'
    import BigListbox from '@/Components/BigListbox'
    import Label from '@/Components/Label'
    import Link from '@/Components/Link'
    import { useForm } from '@inertiajs/inertia-vue3'
    import { ref, watch, onMounted } from 'vue'
    import moment from 'moment'
    import { Inertia } from '@inertiajs/inertia'
    import Dropdown from '@/Components/Dropdown'
    import Timepicker from '@/Components/Timepicker';
    import Modal from '@/Components/Modal';
    // import TinyEditor from '@/Components/TinyEditor';

    export default {
        components: {
            AdminLayout,
            InputLabel,
            Link,
            Checkbox,
            Label,
            Listbox,
            SvgIcon,
            BigListbox,
            Dropdown,
            Timepicker,
            Modal,
            // TinyEditor,
        },
        props: {
            queue: Object,
            teams: Object,
            items: Array,
        },
        setup(props) {
            const showTimer = ref(false);
            const showRemove = ref({
                modal: false,
                item: null,
            });

            const walkovers = ref([
                {id: 'none', name:'Brak'},
                {id: 'for_host', name:'Na korzyść gospodarzy'},
                {id: 'for_guest', name:'Na korzyść gości'},
                {id: 'equal', name:'Obustronny'},
            ]);
            const queue_form = useForm({
                queue: route().params.queue,
                host: props.teams[0] ? props.teams[0].id : null,
                guest: props.teams[1] ? props.teams[1].id : null,
                league: props.queue.league_id,
                host_goals: null,
                guest_goals: null,
                comment: null,
                date: null,
                time: null,
                walkover: 'none',
            });

            const confirmModal = ref(false);
            const downloadTables = ref({
                selected: null,
                values: [],
                loading: false,
            });

            const downloadQueue = (id) => {
                if(props.queue.league.url) {
                    downloadTables.value.loading = true;
                    Inertia.post(route('admin.clubs.queue.download', id), {
                        table: downloadTables.value.selected
                    }, {
                        preserveScroll: true,
                        onSuccess: (e) => {
                            if(e.props.custom && e.props.custom.length){
                                downloadTables.value.values = e.props.custom;
                            }
                            else {
                                confirmModal.value = false;
                                downloadTables.value.values = [];

                                editItemsForm.items = props.items;
                            }

                            downloadTables.value.loading = false;
                        },
                        onError: () => {
                            downloadTables.value.loading = false;
                        }
                    })
                }
            }

            const editQueue = useForm({
                start_date: props.queue.start_date,
                number: props.queue.number
            })

            const editItemsForm = useForm({
                items: props.items,
            });

            const updateQueue = () => {
                editQueue.post(route('admin.clubs.queue.edit', props.queue.id))
            }

            const deleteItem = (id) => {
                Inertia.post(route('admin.clubs.queue.item.remove', id), null, {
                    preserveScroll: true,
                    onSuccess: () => {
                        editItemsForm.items = props.items;

                        showRemove.value.modal = false;
                        showRemove.value.item = null;
                    }
                })
            }

            const addQueueItem = () => {
                queue_form.post(route('admin.clubs.queue.item.add'), {
                    preserveScroll: true,
                    onSuccess: () => {
                        editItemsForm.items = props.items;
                    }
                });
            }
            const editValue = (el, single = false) => {
                if(single && el){
                    Inertia.post(route('admin.clubs.queue.item.edit', el.id), el, {
                        preserveScroll: true,
                        preserveState: true,
                        onSuccess: () => editItemsForm.items = props.items,
                    })
                }
                else {
                    editItemsForm.post(route('admin.clubs.queue.items.edit'), {
                        preserveScroll: true,
                        preserveState: true,
                        onSuccess: (e) => {
                            editItemsForm.items = props.items;
                        }
                    })
                }
                // Inertia.post(route('admin.clubs.queue.item.edit', props.items[key].id), props.items[key]);
            }

            onMounted(() => {
                editValues();
                props.items.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0))
            })

            Inertia.on('success', (event) => {
                editValues();

                editQueue.start_date = props.queue.start_date;
                editQueue.number = props.queue.number;
            })

            const editValues = () => {
                props.items.forEach(function(el){
                    // el.match_time = el.match_time ?
                    //     el.match_time : (
                    //         moment(el.match_date).format('HH:mm') != '00:00'
                    //         ) ? moment(el.match_date).format('HH:mm') : null;

                    el.match_dates = el.match_date ? moment(el.match_date).format('YYYY-MM-DD') : null;
                })
            }

            function getDate(date){
                if(date) return moment(date).format('YYYY-MM-DD H:mm');
                else return '-';
            }

            return {
                queue_form,
                walkovers,
                addQueueItem,
                editValue,
                downloadQueue,
                deleteItem,
                editQueue,
                updateQueue,
                showTimer,

                getDate,

                confirmModal,
                downloadTables,
                showRemove,
                editItemsForm
            }
        }
    }
</script>
<style>
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #74A9D8;
  border-radius: 1px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000031;
  border: 1px solid #00001E;
  height: 16px;
  width: 16px;
  border-radius: 22px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #74A9D8;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #74A9D8;
  border-radius: 1px;
  border: 0px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000031;
  border: 1px solid #00001E;
  height: 16px;
  width: 16px;
  border-radius: 22px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #74A9D8;
  border: 0px solid #010101;
  border-radius: 2px;
  box-shadow: 1px 1px 1px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #74A9D8;
  border: 0px solid #010101;
  border-radius: 2px;
  box-shadow: 1px 1px 1px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000031;
  border: 1px solid #00001E;
  height: 16px;
  width: 16px;
  border-radius: 22px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #74A9D8;
}
input[type=range]:focus::-ms-fill-upper {
  background: #74A9D8;
}

.flip-list-move {
  transition: transform .5s;
}
</style>
