<template>
<inertia-link href="/" class="flex">
    <img v-lazy="$page.props.logo ? $page.props.logo : '/img/cms/logo.jpg'">
</inertia-link>
</template>

<script>
    export default {
        props: {
            dark: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>
