<template>
    <inertia-head>
        <title>{{news.title.replace(/(<([^>]+)>)/gi, "")}} - {{ $page.props.app_name }}</title>
    </inertia-head>
    <MainLayout :adsLogo="adsLogo">
        <ImageModal
            v-if="imageModal !== null"
            :image="'/storage/galleries/' + news.galleries[0].name + '/' + news.galleries[0].images[imageModal].name"
            @closeModal="imageModal = null"
            @prev="ev => {
                if(news.galleries[0].images[imageModal - 1]){
                    imageModal--;
                }
            }"
            @next="ev => {
                if(news.galleries[0].images[imageModal + 1]){
                    imageModal++;
                }
            }"
        />
        <div class="xl:w-10/12 gap-4 mx-auto sm:p-5 p-3 m-auto grid grid-cols-12">

            <div class="col-span-12 lg:col-span-9">
                <div class="sm:p-5 p-3 shadow-md w-full bg-white dark:bg-gray-900 mb-4">
                    <div class="dark:text-white overflow-hidden border-b-2 mb-4 border-green-600  w-full">
                        <div class="col-span-full grid grid-cols-2 gap-4 w-full lg:w-full mx-auto mb-4">
                            <div v-if="newsAds[0] && newsAds[0].adds.length <= 1" class="col-span-full w-full mx-auto lg:w-140">
                                <AddBar
                                :addImg="'/storage/adds/'+newsAds[0].adds[0].images[0].name"
                                :addHref="newsAds[0].adds[0].url"
                                v-if="newsAds[0].adds[0]"
                                />
                            </div>
                            <div v-if="newsAds[0] && newsAds[0].adds.length > 1" class=" col-span-2 md:col-span-2 lg:col-span-1 flex items-center w-full">
                                <AddBar
                                :addImg="'/storage/adds/'+newsAds[0].adds[0].images[0].name"
                                :addHref="newsAds[0].adds[0].url"
                                v-if="newsAds[0].adds[0]"
                                />
                            </div>
                            <div  v-if="newsAds[0] && newsAds[0].adds.length > 1" class="col-span-2 md:col-span-2 lg:col-span-1 flex items-center w-full">
                                <AddBar
                                :addImg="'/storage/adds/'+newsAds[0].adds[1].images[0].name"
                                :addHref="newsAds[0].adds[1].url"
                                v-if="newsAds[0].adds[1]"
                                />
                            </div>
                        </div>
                        <img v-if="news.images.length"
                            v-lazy="{
                                src: '/storage/news/'+news.images[0].name,
                                loading: '/img/cms/loading.gif',
                                error: '/img/cms/img-error.png'
                            }"
                            class="w-full mt-4 object-cover h-48 md:h-full">

                        <div class="py-4">
                            <div class="flex flex-wrap gap-2" v-if="news.leagues && news.leagues.length">
                                <inertia-link
                                    v-for="l in news.leagues"
                                    :key="l.id"
                                    :href="route('league', l.slug)"
                                    class="text-white bg-green-800 hover:bg-opacity-80 font-bold text-sm leading-none px-3 py-2 shadow-md rounded-full">
                                    {{l.name}}
                                </inertia-link>
                            </div>

                            <h1 class="font-semibold mb-2 text-2xl lg:text-xl md:text-xl leading-tight sm:leading py-3" v-html="news.title"></h1>
                            <TinyContent class="mt-4" :value="news.description"></TinyContent>

                            <div v-if="news.galleries.length" class="clear-both grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 my-4">
                                <div
                                    v-for="(image, key) in news.galleries[0].images"
                                    :key="image"
                                    class="rounded-md overflow-hidden border-2 border-white shadow-sm"
                                >
                                <img
                                class="h-full w-full cursor-pointer"
                                v-lazy="{
                                    src: '/storage/galleries/'+news.galleries[0].name+'/'+image.name,
                                    loading: '/img/cms/loading.gif',
                                    error: '/img/cms/img-error.png'
                                }"
                                @click="imageModal = key"
                                >
                                </div>
                            </div>

                            <div class="clear-both text-sm pt-4 flex items-center dark:text-white">
                                <svg class="mr-2" fill="currentColor"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="14" height="14" viewBox="0 0 97.16 97.16" style="enable-background:new 0 0 97.16 97.16;" xml:space="preserve">
                                    <path d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823    c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z"/>
                                    <path d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832    c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z"/>
                                </svg>
                                <div class="divide-x  divide-green-600 flex items-center gap-3">
                                    <p class="leading-none py-1">{{ currentDateTime(news.created_at) }}</p>
                                    <p class="leading-none py-1 pl-3 text-gray-300" v-if="news.source"><i>{{ news.source }}</i></p>
                                </div>
                            </div>

                            <div class="mt-4 flex gap-2 w-full">
                               <div
                                    class="inline-flex items-center rounded font-semibold text-xs dark:text-white uppercase tracking-widest transition ease-in-out duration-150 dark:bg-gray-800 bg-gray-200 text-gray-700 px-2 py-2"
                                    color="gray"
                                >
                                    <SvgIcon name="share" width="14" height="14"/>
                                </div>

                                <ShareNetwork
                                    class="bg-blue-600 hover:bg-blue-700 px-2 py-1 rounded text-white flex items-center gap-2"
                                    network="facebook"
                                    :url="url"
                                    :title="title"
                                > <SvgIcon name="facebook" width="18" height="18" viewBox="0 0 24 24" /> <span>Facebook</span>
                                </ShareNetwork>
                                 <ShareNetwork
                                    class="bg-blue-400  hover:bg-blue-500 px-2 py-1 rounded text-white flex items-center gap-2"
                                    network="twitter"
                                    :url="url"
                                    :title="title"
                                > <SvgIcon name="twitter" width="18" height="18" viewBox="0 0 24 24"/> <span>Twitter</span>
                                </ShareNetwork>
                            </div>
                        </div>
                    </div>
                    <div v-if="news.results.length">
                        <div
                            class="grid grid-cols-12 bg-gray-100 dark:bg-gray-800 dark:text-white px-2 py-2 hover:bg-green-100 dark:hover:bg-green-800 hover:border-green-100 border-gray-200 dark:border-gray-600 dark:hover:border-green-600 border-b-2"
                            v-for="r in news.results"
                            :key="r"
                        >

                            <div class="col-span-12 lg:col-span-3 text-center">
                                {{r.match_date}}
                            </div>
                            <div class="col-span-12 lg:col-span-2 text-center">
                                {{r.league.name }}, <span class="text-gray-500 dark:text-gray-400 text-xs">Kolejka:&nbsp;{{r.number}}</span>
                            </div>
                            <div class="col-span-12 lg:col-span-3 font-bold text-center">
                                <inertia-link  v-if="r.host"
                                    :href="route('singleclub', {
                                        club: r.host.club_id,
                                        team: r.host.id,
                                        league: r.league_id
                                    })"
                                >{{r.host.club_name}}</inertia-link>
                                <p v-else>--</p>
                            </div>
                            <div class="col-span-12 lg:col-span-3 font-bold text-center" >
                                <inertia-link v-if="r.guest"
                                    :href="route('singleclub', {
                                        club: r.guest.club_id,
                                        team: r.guest.id,
                                        league: r.league_id
                                    })"
                                >{{r.guest.club_name}}</inertia-link>
                                <p v-else>--</p>
                            </div>

                            <div class="col-span-12 lg:col-span-1 text-center">
                                {{ getGoals(r) }}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap gap-2 mt-4" v-if="news.categories && news.categories.length">
                        <inertia-link
                            v-for="category in news.categories"
                            :key="category.id"
                            :href="route('page.display', category.slug)"
                        >
                            <p class="text-white md:text-sm text-xs  hover:bg-green-700 bg-green-600 font-semibold leading-none md:px-2 md:py-1 px-3 py-1 shadow-md rounded-full">{{category.name}}</p>
                        </inertia-link>
                    </div>
                    <div class="flex flex-wrap gap-2 mt-2" v-if="news.clubs && news.clubs.length">
                        <inertia-link
                            v-for="n in news.clubs"
                            :key="n.id"
                            :href="route('singleclub', n.id)"
                            class="text-white bg-green-800 hover:bg-opacity-80 font-bold text-sm leading-none px-3 py-1 shadow-md rounded-full">
                            {{n.name}}
                        </inertia-link>
                    </div>
                    <div class="flex gap-2 flex-wrap mt-2">
                        <span v-for="tag in news.tags" :key="tag.id">
                            <p class="dark:text-white text-xs  bg-gray-100 dark:bg-gray-800 font-semibold leading-none md:px-2 md:py-1 px-3 py-1 shadow rounded-full">{{tag.name}}</p>
                        </span>
                    </div>
                    <div ref="FBblock" class="mt-4">
                        <!-- <button
                            class="w-full py-3 lg:py-5 border-2 text-lg font-bold transition-all cursor-pointer border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
                            @click="FBparse"
                            v-if="!showComments"
                        >Pokaż komentarze</button> -->

                        <div class="bg-white">
                            <div class="pt-2">
                                <div class="fb-like"
                                    :data-href="url"
                                    data-width=""
                                    data-layout="button_count"
                                    data-action="like"
                                    data-size="large"
                                    data-share="true"
                                ></div>
                            </div>
                            <Comments :url="thisPage"/>
                        </div>
                    </div>

                    <div class="flex justify-between md:flex-row flex-col gap-3 mt-5">
                        <div class="col-span-2 lg:col-span-1">
                            <div class="text-left dark:text-white">
                                <p class="text-xs">Aby wyświetlić komentarze musisz być zalogowany na Facebooku w aktualnej przeglądarce.<br>Upewnij się również, że twoja przeglądarka nie blokuje skryptów śledzących takich jak Facebook SDK.</p>
                            </div>
                        </div>
                        <div class="flex">
                            <a
                                href="https://pl-pl.facebook.com/"
                                target="_blank"
                                class="w-full text-center p-2 flex items-center justify-center border-2 text-base font-bold transition-all cursor-pointer border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white"
                            >Przejdź do Facebooka</a>
                        </div>
                        <!-- <div
                            class="fb-login-button"
                            data-width="" data-size="large"
                            data-button-type="login_with"
                            data-layout="rounded"
                            data-auto-logout-link="false"
                            data-use-continue-as="true"
                        ></div> -->
                    </div>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-3 px-5 lg:px-0 mb-8">

                <p class="text-xl font-bold mb-3 dark:text-gray-200 text-gray-600 shadow p-4 bg-gray-100 dark:bg-gray-900">Pozostałe wiadomości</p>
                <div class="flex flex-col text-sm text-left ">
                    <inertia-link
                        :href="route('news.id', news.slug)"
                        v-for="news in newses" :key="news.id"
                        class="flex flex-col justify-between items-start px-4 py-2 odd:bg-gray-100 dark:text-gray-200 text-gray-600 dark:odd:bg-gray-900 dark:odd:bg-opacity-50 hover:bg-gray-200 dark:hover:bg-opacity-100 dark:hover:bg-gray-900"
                    >
                    <span class="text-xs mb-0.5 text-gray-400 whitespace-nowrap">{{getDate(news.created_at)}}</span>
                    <span>{{ news.title }}</span>
                    </inertia-link>
                </div>
            </div>
        </div>
    </MainLayout>
</template>


<script>
    import LeaguesMini from '@/Components/LeaguesMini'
    import MainLayout from '@/Layouts/Main'
    import ImageModal from '@/Components/ImageModal'
    import CardImage from '@/Components/CardImage'
    import MiniNews from '@/Components/miniNews'
    import NewsList from '@/Components/newsList'
    import CardImageLess from '@/Components/cardImageLess'
    import AddBar from '@/Components/Adds/addBar'
    import Roll from '@/Components/rollUp'
    import Comments from '@/Components/FBComments'
    import moment from 'moment'
    import {nextTick, onMounted} from 'vue'
    import { ref, computed } from '@vue/reactivity';
    import Link from '@/Components/Link';
    import SvgIcon from '@/Components/SvgIcon'
    import Button from '@/Components/Button';
    import TinyContent from '@/Components/TinyContent';


    export default {
        components: {
            MainLayout,
            CardImage,
            ImageModal,
            LeaguesMini,
            MiniNews,
            CardImageLess,
            AddBar,
            NewsList,
            Roll,
            Comments,
            Link,
            SvgIcon,
            Button,
            TinyContent,
        },
        props: {
            news: [Object, Array],
            add: [Object, Array],
            newses: [Object, Array],
            // adds
            news1: [Object, Array],
            news2: [Object, Array],

            adsLogo: [Object, Array],
            newsAds: [Object, Array],

        },
        data(props){
            return {
                url: 'https://futmal.pl/news/'+ props.news.slug,
                title: props.news.title,
                des: props.news.description.replace( /(<([^>]+)>)/ig, '').substring(0, 150),
            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).format('DD.MM.YYYY, HH:mm')
            },
        },
        setup(props){
            const thisPage = 'https://futmal.pl/news/'+ props.news.slug;
            const imageModal = ref(null);

            const showComments = ref(false);
            const showFB = ref(false);
            const FBblock = ref(null);

            nextTick(() => FBparse());

            function FBparse(){
                showComments.value = !showComments.value;
                // window.FB.XFBML.parse();
                setTimeout(() => {
                    if(window.FB) window.FB.XFBML.parse();
                }, 10);
            }

            window.fbAsyncInit = function() {
                window.FB.XFBML.parse();
            };

            window.onload = function() {
                // console.log(FB)
                FB.Event.subscribe('xfbml.render', function(response) {
                    console.log(response);
                });
            };

            // (function(d, s, id) {
            // var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            // js = d.createElement(s); js.id = id;
            // js.src = 'https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v12.0';
            // fjs.parentNode.insertBefore(js, fjs);
            // }(document, 'script', 'facebook-jssdk'));

            const getGoals = (item) => {
            let walkover = '';
            let host_goals = item.host_goals !== null ? item.host_goals : '-';
            let guest_goals = item.guest_goals !== null ? item.guest_goals : '-';

            if(item.walkover != 'none'){
                walkover = ' v.o';

                if(item.walkover == 'for_host'){
                    host_goals = 3;
                    guest_goals = 0;
                }
                else if(item.walkover == 'for_guest'){
                    host_goals = 0;
                    guest_goals = 3;
                }
                else if(item.walkover == 'equal') {
                    host_goals = 3;
                    guest_goals = 3;
                }

            }
            else {
                if(item.host_id === 0 || item.guest_id === 0){
                    host_goals = '-';
                    guest_goals = '-';
                }
            }

            return host_goals + ":" + guest_goals + walkover;
        }

        function getDate(date){
                return moment(date).format('DD/MM/YYYY HH:mm')
            }

            onMounted(() => {

            })
            return {
                imageModal,
                showComments,
                showFB,
                FBparse,
                FBblock,
                thisPage,

                getGoals,

                getDate
            }
        }
    }
</script>

<style>

.opened .wrapper{
    max-height: 300vh;
    overflow: hidden;
}
.closed .wrapper{
    max-height: 0;
    overflow: hidden;
}
</style>
