<template>

<div class="flex flex-col border-b-2 border-gray-600 py-3 mb-1">
    <!-- Label -->
    
    <div class="mini-news-label-wrapper mb-3 pl-3">
        <span 
        v-if="label"
        class="z-20 w-auto shadow-sm bg-gray-800 bg-opacity-80 p-1 uppercase text-xs text-white rounded-md"
        v-html="label"
        ></span>
    </div>

    <!-- Link -->

    <a :href="route(href)" v-if='text'>
        <span class="text-xl">• </span>
        <span>{{text}}</span>
    </a>
</div>

</template>

<script>

export default ({

    props:{
        href: {
                type: String,
                default: 'home'
            },
        text: {
                type: String,
                default: 'unset'
            },
        label: {
                type: String,
                default: 'unset'
            },
    },


    setup(props) {
        
    },
})
</script>
