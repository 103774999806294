<template>

    <inertia-head>
        <title v-if="box">{{ box.name }} - {{ $page.props.app_name }}</title>
        <title v-else>Wiadomosci - {{ $page.props.app_name }}</title>
    </inertia-head>

    <MainLayout>
        <div class="sm:p-5 p-3 xl:w-10/12 m-auto">
        <!-- {{ box }} -->
            <!-- adds banners -->
            <div class="col-span-full grid grid-cols-2 gap-4 w-full lg:w-full mx-auto mb-4">
                <div v-if="box.locations.length && box.locations[0].adds.length <= 1" class="col-span-full w-full mx-auto lg:w-140">
                    <AddBar
                    :addImg="'/storage/adds/' + box.locations[0].adds[0].images[0].name"
                    :addHref="box.locations[0].adds[0].url"
                    v-if="box.locations.length > 0  && box.locations[0].adds[0]"
                    />
                </div>
                <div v-if="box.locations.length && box.locations[0].adds.length > 1" class=" col-span-2 md:col-span-2 lg:col-span-1 flex items-center w-full">
                    <AddBar
                    :addImg="'/storage/adds/'+box.locations[0].adds[0].images[0].name"
                    :addHref="box.locations[0].adds[0].url"
                    v-if="box.locations.length > 0  && box.locations[0].adds[0]"
                    />
                </div>
                <div  v-if="box.locations.length && box.locations[0].adds.length > 1" class="col-span-2 md:col-span-2 lg:col-span-1 flex items-center w-full">
                    <AddBar
                    :addImg="'/storage/adds/'+box.locations[0].adds[1].images[0].name"
                    :addHref="box.locations[0].adds[1].url"
                    v-if="box.locations.length > 0 && box.locations[0].adds[1]"
                    />
                </div>
            </div>

            <div class="text-xl dark:text-white" v-if="box">{{box.name}}</div>
            <div class="grid grid-cols-4 gap-4">
                <div class="col-span-4 lg:col-span-3  mt-4" >
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">

                        <CardImage v-for="n in news.data" :key='n'
                            :image="n.images.length ? '/storage/news/' + n.images[0].name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')"
                            :title="n.title"
                            :date="n.created_at"
                            :href="route('news.id', n.slug)"
                            class="h-52"
                            color="white"
                        />
                    </div>
                    <div class="flex justify-center">
                        <pagination class="mt-6" :links="news.links" />
                    </div>
                </div>
                <div class="col-span-4 lg:col-span-1 flex flex-col">

                    <!-- Banner Box 1 -->
                    <div class="mb-3" v-if="add">
                        <AddBar
                        :addImg="'/storage/adds/'+add.images[0].name"
                        :addHref="add.url"
                        v-if="add"
                        class="col-span-12"
                        />
                    </div>
                    <div
                        class="text-xl shadow-md bg-white text-center px-3 py-4 mb-3 dark:bg-gray-900 dark:text-gray-200"
                    >⚽ Ligi ⚽</div>
                    <Roll
                        custom-class="py-3 px-4 h-16 mb-3 bg-gray-100 dark:bg-gray-900 dark:bg-opacity-50"
                        v-for="(c, ckey) in leagues" :key="ckey"
                        :title="ckey"
                        class="mb-3"
                        :opened='true'
                    >
                    <div
                        class="col-span-full flex  text-gray-500 dark:text-gray-300"
                        v-for="l in c" :key="l"
                    >
                        <inertia-link
                            :href="route('league', l.slug)"
                            class="p-2 w-full dark:hover:bg-gray-900 hover:bg-gray-200"
                        >
                        {{l.name}}
                        </inertia-link>
                    </div>
                    </Roll>
                </div>
            </div>

        </div>
    </MainLayout>
</template>


<script>
    import LeaguesMini from '@/Components/LeaguesMini'
    import MainLayout from '@/Layouts/Main'
    import CardImage from '@/Components/CardImage'
    import MiniNews from '@/Components/miniNews'
    import NewsList from '@/Components/newsList'
    import CardImageLess from '@/Components/cardImageLess'
    import AddBar from '@/Components/Adds/addBar'
    import Posts from '@/Components/Post/gridOnMain'
    import Pagination from '@/Components/Pagination'
    import Roll from '@/Components/rollUp'
    import {ref} from 'vue'
    import moment from 'moment'

    export default {
        components: {
            MainLayout,
            CardImage,
            LeaguesMini,
            MiniNews,
            CardImageLess,
            AddBar,
            NewsList,
            Posts,
            Pagination,
            Roll,
        },
        props: {
            canLogin: Boolean,
            canRegister: Boolean,
            laravelVersion: String,
            phpVersion: String,
            leagues: [Object, Array],
            news: [Object, Array],
            main_news: [Object, Array],
            box: [Object, Array],
            add: [Object, Array],
            // adds
            cat1a: [Object, Array],
            cat1b: [Object, Array],
        },
        setup(props) {

            return {

            }
        }
    }
</script>

<style scoped>

/* Придумать решение по-лучше  */

.opened .wrapper{
    max-height: 300vh;
    overflow: hidden;
}
.closed .wrapper{
    max-height: 0;
    overflow: hidden;
}
</style>
