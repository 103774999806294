<template>
<div class="w-full">
    <a :href="addHref" target="_blank">
        <!-- max-h-20 2xl:max-h-32 -->
        <div class="w-full flex items-center justify-center overflow-hidden">
            <img v-if="addImg" :src="addImg" class="max-h-full object-cover w-full lg:w-full mx-auto 2xl:w-full" readonly>
        </div>
    </a>

<div v-if="addTitle">{{addTitle}}</div>
</div>
</template>

<script>
export default{
    setup(props) {

    },
    props:{
        addTitle: {
            type: String,
            default: null
        },
        addHref: {
            type: String,
            default: '#'
        },
        addText: {
            type: String,
            default: "Jestem Reklamką"
        },
        addImg: {
            type: String,
            default: "/img/cms/bg1.webp"
        }
    }
}
</script>
