<template>
    <inertia-link :href="href" :class="[classes, hiddenn]">
        <slot />
    </inertia-link>
</template>

<script>
    export default {
        props:{
            active:{
                type: Boolean,
            },
            href:{
                type: String,
            },
            hidden:{
                type: Boolean,
                default: false
            }

        },

        computed: {
            classes() {
                return this.active
                            ? 'inline-flex items-center px-1 pt-1 border-b-2 border-green-500 leading-5 text-gray-900 dark:text-gray-100 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out'
                            : 'inline-flex items-center px-1 pt-1 border-b-2 border-transparent leading-5 text-gray-600 dark:text-gray-300 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out'
            },
            hiddenn(){
                return this.hidden
                            ? 'lg:hidden'
                            : ''
            }
        }
    }
</script>
