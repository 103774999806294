<template>
    <AdminLayout>
        <!-- <div class="fixed inset-0 z-20 flex justify-center items-center bg-gray-900 bg-opacity-20"
            v-if="loading"
        >
             <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div> -->
        <div class="pb-12 px-3 pt-6 sm:px-6 lg:px-8 ">
            <div class="flex justify-between flex-wrap gap-3">
                <div class="flex items-center pb-3 gap-3">
                    <inertia-link
                        preserve-scroll
                        :href="route('admin.dashboard.users.stats')"
                        :data="{
                            year: route().params.year ? (Number(route().params.year) - 1) : (Number(new Date().getFullYear()) - 1),
                        }"
                        class="bg-green-600 hover:bg-green-500 rounded-full flex items-center justify-center w-8 h-8"
                    >
                    <SvgIcon name="arrow-left" />
                    </inertia-link>
                    <div class="font-bold text-lg bg-gray-600 rounded-md text-white px-3 py-2">
                        {{ route().params.year ? route().params.year : new Date().getFullYear() }}
                    </div>
                    <inertia-link
                        preserve-scroll
                        :href="route('admin.dashboard.users.stats')"
                        :data="{
                            year: route().params.year ? (Number(route().params.year) + 1) : (Number(new Date().getFullYear()) + 1),
                        }"
                        class="bg-green-600 hover:bg-green-500 rounded-full flex items-center justify-center w-8 h-8"
                    >
                    <SvgIcon name="arrow-right" />
                    </inertia-link>
                </div>
                <Link :href="route('admin.users.stats.download', { year: route().params.year ? Number(route().params.year) : new Date().getFullYear() })" color="green">Pobierz dane na nowo</Link>
            </div>
            <div class="mt-2 space-y-6">

                <!-- Queue items -->
                <div class="relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words w-full rounded">
                    <h1 class="text-lg font-bold mb-1 dark:text-white ">
                       Informacje o wynikach
                    </h1>
                    <div class="block w-full overflow-x-auto bg-white">
                        <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                                <th
                                    class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left"
                                >Użytkownik</th>
                                <th
                                    v-for="month in $props.months"
                                    :key="month"
                                    class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left"
                                >{{ month }}</th>
                                <th class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left">
                                    SUMA
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="$props.stats">
                            <tr
                                v-for="s in $props.stats"
                                :key="s.user.id"
                                class="bg-white hover:bg-gray-100 dark:hover:bg-gray-700 border-0 dark:border-gray-700 dark:bg-gray-600 text-gray-800 dark:text-white"
                            >
                                <th class="p-2 align-middle border-t dark:border-gray-700  bg-gray-100  dark:bg-gray-700 dark:text-gray-100  text-xs whitespace-nowrap  text-left">
                                    <inertia-link :href="route('admin.dashboard.user.stats', s.user.id)" preserve-scroll>
                                        {{s.user.name}}
                                    </inertia-link>
                                </th>
                                <td
                                    v-for="(item, key) in s.queue_items"
                                    :key="key"
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ item }}

                                </td>
                                <td
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getSum(s.user, 'queue_items') }}
                                </td>
                            </tr>
                            <tr
                                class="bg-gray-300 dark:bg-gray-900 text-gray-800 dark:text-white"
                            >
                                <th class="p-2 font-bold align-middle border-t bg-gray-300  dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 text-xs whitespace-nowrap  text-left">
                                    RAZEM
                                </th>
                                <td
                                    v-for="(month, key) in $props.months"
                                    :key="month"
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getCount(key, 'queue_items') }}
                                </td>
                                <td
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getSum('all', 'queue_items') }}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>

                <!-- News -->
                <div class="relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words w-full rounded">
                    <h1 class="text-lg font-bold mb-1 dark:text-white">
                       Informacje o newsach
                    </h1>
                    <div class="block w-full overflow-x-auto bg-white">
                        <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                                <th
                                    class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left"
                                >Użytkownik</th>
                                <th
                                    v-for="month in $props.months"
                                    :key="month"
                                    class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left"
                                >{{ month }}</th>
                                <th class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left">
                                    SUMA
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="$props.stats">
                            <tr
                                v-for="s in $props.stats"
                                :key="s.user.id"
                                class="bg-white hover:bg-gray-100 dark:hover:bg-gray-700 border-0 dark:border-gray-700 dark:bg-gray-600 text-gray-800 dark:text-white"
                            >
                                <th class="p-2 align-middle border-t dark:border-gray-700  bg-gray-100  dark:bg-gray-700 dark:text-gray-100  text-xs whitespace-nowrap  text-left">
                                    <inertia-link :href="route('admin.dashboard.user.stats', s.user.id)" preserve-scroll>
                                        {{s.user.name}}
                                    </inertia-link>
                                </th>
                                <td
                                    v-for="(item, key) in s.news"
                                    :key="key"
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ item }}
                                    <template v-if="s.percent_news[key]">({{ s.percent_news[key] }}%)</template>
                                </td>
                                <td
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getSum(s.user, 'news') }}
                                </td>
                            </tr>
                            <tr
                                class="bg-gray-300 dark:bg-gray-900 text-gray-800 dark:text-white"
                            >
                                <th class="p-2 font-bold align-middle border-t bg-gray-300  dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 text-xs whitespace-nowrap  text-left">
                                    RAZEM
                                </th>
                                <td
                                    v-for="(month, key) in $props.months"
                                    :key="month"
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getCount(key, 'news') }}
                                </td>
                                <td
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getSum('all', 'news') }}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
                <!-- News views -->
                <div class="relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words w-full rounded">
                    <h1 class="text-lg font-bold mb-1 dark:text-white">
                       Informacje o oglądalności newsów
                    </h1>
                    <div class="block w-full overflow-x-auto bg-white">
                        <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                                <th
                                    class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left"
                                >Użytkownik</th>
                                <th
                                    v-for="month in $props.months"
                                    :key="month"
                                    class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left"
                                >{{ month }}</th>
                                <th class="p-2 align-middle py-2 text-xs uppercase border-0 whitespace-nowrap font-semibold text-left">
                                    SUMA
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="$props.stats">
                            <tr
                                v-for="s in $props.stats"
                                :key="s.user.id"
                                class="bg-white hover:bg-gray-100 dark:hover:bg-gray-700 border-0 dark:border-gray-700 dark:bg-gray-600 text-gray-800 dark:text-white"
                            >
                                <th class="p-2 align-middle border-t dark:border-gray-700  bg-gray-100  dark:bg-gray-700 dark:text-gray-100  text-xs whitespace-nowrap  text-left">
                                    <inertia-link :href="route('admin.dashboard.user.stats', s.user.id)" preserve-scroll>
                                        {{s.user.name}}
                                    </inertia-link>
                                </th>
                                <td
                                    v-for="(item, key) in s.news_views"
                                    :key="key"
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ item }}{{ getPercent(item, getCount(key, 'news_views')) }}
                                </td>
                                <td
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getSum(s.user, 'news_views') }}
                                </td>
                            </tr>
                            <tr
                                class="bg-gray-300 dark:bg-gray-900 text-gray-800 dark:text-white"
                            >
                                <th class="p-2 font-bold align-middle border-t bg-gray-300  dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 text-xs whitespace-nowrap  text-left">
                                    RAZEM
                                </th>
                                <td
                                    v-for="(month, key) in $props.months"
                                    :key="month"
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getCount(key, 'news_views') }}
                                </td>
                                <td
                                    class="p-2 font-bold align-middle border-t dark:border-gray-700 text-xs whitespace-nowrap "
                                >
                                    {{ getSum('all', 'news_views') }}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import Progress from '@/Components/Progress'
    import { computed, ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted, onUnmounted, watch } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
    import moment from 'moment';
import { Inertia } from '@inertiajs/inertia'

import Pagination from '@/Components/Pagination'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox,
            Progress,
            Pagination
        },
        props: {
            months: [Array, Object],
            stats: [Object, Array],
            news: [Array, Object],
        },
        setup(props) {
            moment.locale('pl')
            const sorted_views = ref([]);
            const loading = ref(false);

            onUnmounted(
                Inertia.on('start', function(e){
                    loading.value = true;
                })
            );

            function getDate(value){
                moment.locale('pl');
                return moment(value);
            };

            function getCount(value, slug){
                let count = 0;
                props.stats.forEach(el => {
                    count += el[slug][value];
                });

                return count;
            }

            function getPercent(value, sum){
                if(value && sum) return ` (${Math.round((value * 100) / sum)}%)`
            }

            function getSum(value, slug){
                let count = 0;

                if(value == 'all'){
                    for (let index = 0; index < props.stats.length; index++) {
                        const element = props.stats[index];

                        element[slug].forEach(e => count += e);
                    }
                }
                else {
                    for (let index = 0; index < props.stats.find(e => e.user.id == value.id)[slug].length; index++) {
                        const element = props.stats.find(e => e.user.id == value.id)[slug][index];
                        count += element;
                    }
                }
                return count;
            }
            return {
                sorted_views,
                getDate,
                getCount,
                getSum,
                getPercent,

                loading,
            }
        }
    }
</script>
<style>
.dark input::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
</style>
