<template>

     <inertia-head>
        <title>Wyniki - {{ $page.props.app_name }}</title>
    </inertia-head>

    <MainLayout>
        <div
            class="h-36 lg:h-52 bg-cover relative"
            style="background-image: url(/img/cms/bg1.webp)"
        >
            <div class="absolute top-0 left-0 h-full w-full bg-black bg-opacity-20"></div>
            <div class="relative page-title md:w-10/12 mx-auto h-full w-full flex items-center">
                <h1 class="text-white uppercase text-2xl lg:text-5xl font-bold px-5 lg:px-0">Wyniki</h1>
            </div>
        </div>
        <!-- <div class="h-12 bg-white"></div> -->

        <!-- taby -->

        <div class="grid grid-cols-6 mx-auto text-gray-700 bg-gray-100 dark:bg-gray-900 dark:text-gray-200">
                <div class="overflow-x-scroll lg:overflow-x-auto col-span-6 dark:bg-gray-900">
                    <div class="lg:flex md:flex">
                        <LinkDefault
                            v-for="(category, key) in [
                                {name: 'Wyniki', slug: 'regio'},
                                {name: 'Seniorzy', slug: 'senior'},
                                {name: 'Młodzież', slug: 'junior'},
                                {name: 'Kobiety', slug: 'female'},
                                {name: 'Inne', slug: 'other'},
                                {name: 'Patronat', slug: 'patronat'}
                            ]"
                            :key="key"

                            class="px-5 w-full flex items-center justify-center col-span-1 text-center lg:py-5 py-2 hover:bg-green-600 hover:text-white cursor-pointer text-lg font-bold"
                            :href="category.slug != currentCategory ? route(route().current(), { category: category.slug }) : null"
                            @click="ev => { if(category.slug != currentCategory) loading = true; }"

                            @success="currentCategory = category.slug; loading = false;"
                        :class="{ 'bg-gray-300 dark:bg-black active-tab': currentCategory == category.slug }">
                            <p>{{category.name}}</p>
                        </LinkDefault>
                    </div>
                </div>

            <div class="tab-content col-span-6 text-white relative" id="myTabContent">
                <div v-if="loading" class="absolute flex items-start justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
                <div
                    class="tab-pane text-gray-900 dark:text-white bg-white dark:bg-gray-800 sm:p-5 p-3 grid grid-cols-5 gap-4"
                    :class="{ 'show': currentCategory == 'regio'  }" id="regio"
                    v-if="currentCategory == 'regio' || !refCategories[currentCategory]"
                >
                        <div class="lg:col-span-4 col-span-full">
                            <MainResults
                                :results="results"
                            />
                        </div>
                        <div class="lg:col-span-1 col-span-full">
                            <div
                                class="text-xl shadow-md bg-white text-center px-3 py-4 mb-3 dark:bg-gray-900 dark:text-gray-200"
                            >⚽ Ligi ⚽</div>
                            <Roll
                                custom-class="py-3 px-4 h-16 mb-3 bg-gray-100 dark:bg-gray-900 dark:bg-opacity-50"
                                v-for="(c, ckey) in lcategories" :key="ckey"
                                :title="ckey"
                                class="mb-3"
                                :opened='true'
                            >
                            <div
                                class="col-span-full flex  text-gray-500 dark:text-gray-300"
                                v-for="l in c" :key="l"
                            >
                                <inertia-link
                                    :href="route('league', l.slug)"
                                    class="p-2 w-full dark:hover:bg-gray-900 hover:bg-gray-200"
                                >
                                {{l.name}}
                                </inertia-link>
                            </div>
                            </Roll>
                        </div>

                    <!-- </div> -->
                </div>
                <div
                    class="tab-pane text-gray-900 dark:text-white bg-white dark:bg-gray-800 sm:p-5 p-3 grid grid-cols-5 gap-4"
                    v-else-if="refCategories[currentCategory].length"
                >
                <div class="lg:col-span-4 col-span-full">
                    <Roll
                        :secondary="key % 2 == 1 ? true : false"
                        :small="true"
                        :title="league.name"
                        class="lg:w-12/12 lg:m-auto"
                        v-for="(league, key) in refCategories[currentCategory]"
                        :key="key"
                        :opened="openRoll"
                        @clicked="getData($event, key)"

                    >
                    <!-- @clicked="getData($event, key)" -->
                        <div class="px-4 bg-gray-100 dark:bg-gray-900 col-span-12 shadow">
                            <Roll class="w-full  m-0" title="Konfiguruj tabelę" :opened='false'>
                                <div class="col-span-12 gap-4 text-gray-700 justify-between w-full pb-4 grid grid-cols-4">
                                    <InputLabel
                                        class="col-span-4 sm:col-span-2 lg:col-span-1"
                                        placeholder="Min date"
                                        id="minDate"
                                        name="minDate"
                                        type="date"
                                        v-model="league.minDate"
                                        @update:modelValue="getData($event, key, true)"
                                        label="Data OD"
                                    />
                                    <InputLabel
                                        class="col-span-4 sm:col-span-2 lg:col-span-1"
                                        placeholder="Max date"
                                        id="maxDate"
                                        name="maxDate"
                                        type="date"
                                        v-model="league.maxDate"
                                        @update:modelValue="getData($event, key, true)"
                                        label="Data DO"
                                    />
                                    <InputLabel
                                        class="col-span-4 sm:col-span-2 lg:col-span-1"
                                        placeholder="Min queue"
                                        id="minQueue"
                                        name="minQueue"
                                        type="number"
                                        v-model="league.minQueue"
                                        @update:modelValue="getData($event, key, true)"
                                        label="Kolejka OD"
                                    />
                                    <InputLabel
                                        class="col-span-4 sm:col-span-2 lg:col-span-1"
                                        placeholder="Max queue"
                                        id="maxQueue"
                                        name="maxQueue"
                                        type="number"
                                        v-model="league.maxQueue"
                                        @update:modelValue="getData($event, key, true)"
                                        label="Kolejka DO"
                                    />
                                </div>
                            </Roll>
                        </div>

                        <Table
                            :league="league"
                            :scores="league.scores"
                            :current_queue="league.current_queue_index"
                            :table1="table1"
                            :table2="table2"
                            :queue="queue"
                            class="col-span-12"
                        />
                        <!-- ads tabela 2 -->
                        <inertia-link
                            :href="route('league', league.slug)"
                            class="col-span-12 w-full md: py-3 lg:py-5 border-2 text-lg font-bold my-5 transition-all cursor-pointer text-center border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
                        >Sprawdź pełny terminarz</inertia-link>
                    </Roll>
                </div>
                <div class="lg:col-span-1 col-span-full">
                    <div
                            class="text-xl shadow-md bg-white text-center px-3 py-4 mb-3 dark:bg-gray-900 dark:text-gray-200"
                        >⚽ Ligi ⚽</div>
                        <Roll
                            custom-class="py-3 px-4 h-16 mb-3 bg-gray-100 dark:bg-gray-900 dark:bg-opacity-50"
                            v-for="(c, ckey) in lcategories" :key="ckey"
                            :title="ckey"
                            class="mb-3"
                            :opened='true'
                        >
                        <div
                            class="col-span-full flex  text-gray-500 dark:text-gray-300"
                            v-for="l in c" :key="l"
                        >
                            <inertia-link
                                :href="route('league', l.slug)"
                                class="p-2 w-full dark:hover:bg-gray-900 hover:bg-gray-200"
                            >
                            {{l.name}}
                            </inertia-link>
                        </div>
                        </Roll>
                </div>
                </div>
                <div class="bg-white dark:bg-gray-800 tab-pane text-gray-700 dark:text-gray-200 py-5" v-else>
                    <div class="flex justify-center items-center py-5 text-xl text-gray-600 text-center">BRAK DANYCH</div>
                </div>

            </div>
        </div>
    </MainLayout>
</template>


<script>

    import MainLayout from '@/Layouts/Main'
    import Regio from '@/Components/Results/RegioResults'
    import MainResults from '@/Components/Results/MainResults'
    import Listbox from '@/Components/Listbox'
    import Table from '@/Components/Results/tableResults'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link';
    import LinkDefault from '@/Components/LinkDefault';
    import { ref, watch, onMounted, nextTick } from 'vue'
    import moment from 'moment'
    import Roll from '@/Components/rollUp'
    import AddBar from '@/Components/Adds/addBar'
    import axios from 'axios'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { usePage } from '@inertiajs/inertia-vue3'

    export default {
        components: {
            MainLayout,
            Link,
            SvgIcon,
            Regio,
            Table,
            Roll,
            MainResults,
            Listbox,
            InputLabel,
            AddBar,
            PulseLoader,
            LinkDefault,
        },
        props: {
            canLogin: Boolean,
            canRegister: Boolean,
            news: Object,
            categories: [Array, Object],
            results: [Object, Array],
            lcategories: [Object, Array],
            // adds
            table1: [Object, Array],
            table2: [Object, Array],
            queue: [Object, Array],
            adsLogo: [Object, Array],
            test:  [Array, Object],
        },
        setup(props) {
            const activeTab = ref('sen');
            const cats = ref({});
            const refCategories = ref(props.categories);
            const currentCategory = ref(route().params.category ? (!refCategories.value[route().params.category] ? 'regio' : route().params.category) : 'regio');
            const loading = ref(false);
            const openRoll = ref(false);
            const loadingData = ref(false);

            watch(() => currentCategory.value, (val, old) => {
                openRoll.value = false;
            })

            onMounted(() => {
                if(currentCategory.value != 'regio') {
                    // nextTick(() => getAllScoresResults());
                }
            })
            const getAllScoresResults = async () => {
                try {
                    const resp = await axios.post(route('get.scores.category'), {
                        category: currentCategory.value,
                    })
                    resp.data[currentCategory.value].forEach(element => {
                        refCategories.value[currentCategory.value].find(e => e.id == element.id).scores = element.results.scores;
                        if(element.results.queues){
                            refCategories.value[currentCategory.value].find(e => e.id == element.id).queues = element.results.queues;
                        }
                    });

                } catch (err) {
                    console.error(err);
                }
            };
            var timer = null;
            const getData = (e, key, check = false) => {
                openRoll.value = true;
                var category = JSON.parse(JSON.stringify(refCategories.value[currentCategory.value]));
                var curCat = JSON.parse(JSON.stringify(currentCategory.value));
                let currentLeague = refCategories.value[curCat].find(e => e.id == category.id);

                // if(!loadingData.value){
                //     nextTick(() => {
                //         if(!document.getElementById('table-load-' + category.id).getElementsByClassName('loading-table').length){
                //         document.getElementById('table-load-' + category.id).insertAdjacentHTML('afterbegin', `
                //         <div class="loading-table absolute flex items-center justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50">
                //             <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                //         </div>`)
                //         }
                //     })
                // }

                loadingData.value = true;

                if ((e && category && category[key] && !category[key].scores) || check) {
                    category = category[key];
                    nextTick(() => {
                        if(timer) clearTimeout(timer);

                        timer = setTimeout(() => {
                            axios.get(route('get.league.scores', category.id), {
                                'minQueue': category.minQueue,
                                'maxQueue': category.maxQueue,
                                'minDate': category.minDate,
                                'maxDate': category.maxDate,
                            })
                            .then((res) => {
                                refCategories.value[curCat].find(e => e.id == category.id).scores = res.data.scores;

                                if(res.data.queues){
                                    refCategories.value[currentCategory.value].find(e => e.id == category.id).queues = res.data.queues;
                                }
                                loadingData.value = false;
                            })
                        }, 1000)
                    })
                }
            }
            const isActive = (tabItem) => {
                return activeTab.value === tabItem
            }
            const setActive = (tabitem) =>{
                activeTab.value = tabitem
            }

            onMounted(() => {
                for (const [key, value] of Object.entries(refCategories.value)) {
                    if(refCategories.value[key]){
                        refCategories.value[key].forEach((l) => {
                            l.minQueue = 1;
                            l.maxQueue = l.queues.length;
                            // l.queues.forEach((q) => {
                            //     q.items.forEach((i) => {
                            //     })
                            //     if (l.minQueue > q.number || l.minQueue == 0) l.minQueue = parseInt(q.number);
                            //     if (l.maxQueue < q.number) l.maxQueue = parseInt(q.number);
                            // })
                        })
                    }
                }
                for (const [ckey, cat] of Object.entries(refCategories.value)) {
                    cat.forEach((league, lkey) => {
                        league.queues.forEach((queue, key) => {
                            if (queue.id == league.current_queue) refCategories.value[ckey][lkey].current_queue_index = key;
                        })
                    })
                }
            })

            // setTimeout(()=>{
            //     loading.value = false;
            // },10000);

            return {
                activeTab,
                cats,
                currentCategory,
                isActive,
                setActive,
                getData,
                refCategories,
                loading,
                openRoll,

                loadingData,
            }
        },

    }
</script>

<style scoped>
.active-tab{
    position: relative;
}

.active-tab:hover::after{
    background: #059669;
}
</style>
