<template>

    <div class="grid grid-cols-12 pb-3 gap-5 mb-4 dark:text-white">
        <div class="col-span-12">
             <a href="javascript:history.go(-1)" class="flex items-center text-green-600 font-semibold text-underline" v-if="linkBack=='true'">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                </svg>
                Wróć
            </a>
        </div>
        <div class="col-span-12 flex items-end" v-if="leagueHasTitle=='true'">
            <div class="cat-name text-2xl mr-3">Liga:</div>
            <div class="selector">
                <div class="wrapper">
                    <Dropdown class="w-60">
                        <template #trigger>
                                <button class="text-xl font-bold flex">
                                    <p>{{league.name}}</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-6 w-6 pointer-events-none transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                        </template>
                        <template #content class="w-full">
                                <ul class="">
                                    <li class="hover:bg-green-700 hover:text-white px-3 flex" v-for="l in leagues" :key="l">
                                        <inertia-link :href="route('league', l.slug)" class="py-2 w-full hover:cursor-pointer">{{l.name}}</inertia-link>
                                    </li>
                                </ul>
                        </template>
                    </Dropdown>
                </div>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-8 p-4 bg-gray-100 dark:bg-gray-900 shadow-md">
            <!-- ads tabela 1 -->
             <div class="mb-2" v-if="table1 && table1.length > 0">
                <AddBar
                :addImg="'/storage/adds/'+table1[0].images[0].name"
                :addHref="table1[0].url"
                class="col-span-12"
                />
             </div>
            <!-- Wielka kolorowa tabelka -->
            <Table
                :scores="scores"
                :loadingData="loadingData"
                :queues="league.queues"
                :leagues="leagues"
                :league="league"
                :divide="league.divide ? true:false"
             />
             <!-- ads tabela 2 -->
             <div class="mb-2" v-if="table2 && table2.length > 0">
                <AddBar
                :addImg="'/storage/adds/'+table2[0].images[0].name"
                :addHref="table2[0].url"
                class="col-span-12"
                />
             </div>
        </div>
        <div class="col-span-12 lg:col-span-4">
            <!-- ads kolejka -->
             <div class="mb-2 col-span-full" v-if="queue && queue.length > 0">
                <AddBar
                    :addImg="'/storage/adds/'+queue[0].images[0].name"
                    :addHref="queue[0].url"
                    class="col-span-12"
                />
             </div>
            <div class="grid grid-cols-2 gap-5 sticky top-20">
                <div class="shadow-md col-span-full lg:col-span-2 relative" :id="`content-load-${$props.league.id}`">
                    <div class="grid grid-cols-3 bg-gray-100 dark:bg-gray-800">
                        <div class="flex items-center justify-center text-sm lg:text-normal col-span-1 text-center py-5 hover:bg-green-600 hover:text-white cursor-pointer font-bold" @click.prevent="setActiveQ('prev')" :class="{ 'bg-white shadow-md active-tab dark:bg-gray-900': isActiveQ('prev') }">
                            <p>Poprzednia</p>
                        </div>
                        <div class="flex items-center justify-center text-sm lg:text-normal col-span-1 text-center py-5 hover:bg-green-600 hover:text-white cursor-pointer font-bold" @click.prevent="setActiveQ('cur')" :class="{ 'bg-white shadow-md active-tab dark:bg-gray-900': isActiveQ('cur') }">
                            <p>Bieżąca</p>
                        </div>
                        <div class="flex items-center justify-center text-sm lg:text-normal col-span-1 text-center py-5 hover:bg-green-600 hover:text-white cursor-pointer font-bold" @click.prevent="setActiveQ('next')" :class="{ 'bg-white shadow-md active-tab dark:bg-gray-900': isActiveQ('next') }">
                            <p>Następna</p>
                        </div>
                    </div>

                    <div class="tab-pane px-4 py-4 dark:bg-gray-900" :class="{ 'show': isActiveQ('prev') }" id="prev" v-if="isActiveQ('prev')">
                        <!-- Poprzednia kolejka -->
                        <MiniQ
                            :queue="league.queues[league.current_queue_index ? (league.current_queue_index - 1) : (league.queues.length - 1)]"
                        />
                    </div>

                    <div class="tab-pane px-4 py-4 dark:bg-gray-900" :class="{ 'show': isActiveQ('cur') }" id="cur" v-if="isActiveQ('cur')">
                        <!-- Bieżąca kolejka -->

                        <!-- {{ league }} -->
                        
                        <MiniQ
                            v-if="league.queues.filter(el => el.start_date == new Date().toISOString().slice(0,10)).length"
                            :queue="league.queues.filter(el => el.start_date == new Date().toISOString().slice(0,10))[0]"
                        />
                        <MiniQ
                            v-else
                            :queue="league.queues[league.current_queue_index + 1]"
                        />
                        <!-- 2022-09-06 -->
                        <!-- {{ new Date().toISOString().slice(0,10) }} -->
                        <!-- {{ league.queues }} -->
                        <!-- {{ filter(el => league.queue }} -->
                        <!-- {{  }} -->
                        <!-- {{ league.queues.filter(el => el.start_date == '2021-07-23')[0] }} -->
                    </div>

                    <div class="tab-pane px-4 py-4 dark:bg-gray-900" :class="{ 'show': isActiveQ('next') }" id="next" v-if="isActiveQ('next')">
                        <!-- Następna kolejka -->

                        <!-- {{ league.current_queue_index }} -->

                        <MiniQ
                            v-if="league.queues.filter(el => el.start_date == new Date().toISOString().slice(0,10)).length"
                            :queue="league.queues[league.current_queue_index + 1]"
                        />

                        <!-- {{ league.queues[3] }} -->
                        
                        <MiniQ
                            v-else
                            :queue="league.queues[league.current_queue_index + 2]"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-full">
            <div class="grid grid-cols-12 gap-5">
                <div class="col-span-full lg:col-span-8 lg:order-1 order-2" v-if="full">
                    <ListQ v-for="(queue, index) in league.queues" class="mb-4 last:mb-0" :key="index"
                        :queue="queue"
                        :league_name="league.name"
                        :closed='openedAll ? false : !queue.current'
                    />
                </div>
                <div class="col-span-full lg:col-span-4 lg:order-2 order-1 flex flex-col" v-if="full">
                    <div class="flex flex-col sticky bottom-5 mt-auto" v-if="league.news && league.news.length">
                        <p class="text-xl font-bold mb-3 dark:text-gray-200 text-gray-600 shadow p-4 bg-gray-100 dark:bg-gray-900">Wiadomości ligowe</p>
                        <div class="flex flex-col text-sm">
                            <inertia-link
                                :href="route('news.id', n.slug)"
                                v-for="n in league.news" :key="n"
                                class="flex flex-col justify-between items-start px-4 py-2 odd:bg-gray-100 dark:text-gray-200 text-gray-600 dark:odd:bg-gray-900 dark:odd:bg-opacity-50 hover:bg-gray-200 dark:hover:bg-opacity-100 dark:hover:bg-gray-900"
                            >
                            <span class="text-xs mb-0.5 text-gray-400 whitespace-nowrap">{{getDate(n.created_at)}}</span>
                            <span>{{ n.title }}</span>
                            </inertia-link>
                        </div>
                        <!-- <a
                            href='#'
                            class="col-span-12 w-full md: py-3 lg:py-5 border-2 text-lg font-bold mt-5 transition-all cursor-pointer text-center border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
                        >Czytaj więcej</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Table from '@/Components/Results/showTable'
import MiniQ from '@/Components/Results/miniQ'
import ListQ from '@/Components/Results/qList'
import Dropdown from '@/Components/Dropdown'
import { ref, watch, onMounted, nextTick } from 'vue'
import AddBar from '@/Components/Adds/addBar'
import moment from 'moment'
export default {
    components: {
        Table,
        MiniQ,
        ListQ,
        Dropdown,
        AddBar
    },
    props: {
        league: [Object, Array],
        leagues: [Object, Array],
        scores: [Object, Array],
        // adds
        loadingData: [Boolean],
        table1: [Object, Array],
        table2: [Object, Array],
        queue: [Object, Array],
        current_queue: Number,
        leagueHasTitle: {
            type: Boolean,
            default: false
        },
        linkBack: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },
        openedAll: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const activeQ = ref("cur");

        const isActiveQ = (tabItem) => {
            return activeQ.value === tabItem
        }
        const setActiveQ = (tabitem) => {
            activeQ.value = tabitem
        }

        const contentId = `content-load-${props.league.id}`;

        nextTick(() => {
            if(document.getElementById(contentId)){
            document.getElementById(contentId).insertAdjacentHTML('afterbegin', `
            <div class="loading-table absolute flex items-center justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50">
                <div class="font-bold text-sm flex items-center justify-center flex-col">
                    <p>Ładowanie</p>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>`)
            }
        })

        function getDate(date){
                return moment(date).format('DD/MM/YYYY HH:mm')
            }

        return {
            isActiveQ,
            setActiveQ,
            activeQ,

            getDate
        }
    }
}
</script>

