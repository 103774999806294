<template>
    <div
        class="flex flex-col relative"
        data-input="true"
    >
        <label
            v-if="label"
            :for="id"
            class="mb-2 dark:text-white text-gray-500"
        >{{label}}</label>

        <textarea
            :ref='ev=>{input=ev}'
            v-if="type == 'textarea'"
            :id="id"
            class="dark:border-gray-300 border-gray-400 dark:hover:border-gray-200 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0"
            :class="{
                [ 'h-'+height ]:true,
                [ 'border-b-2' ]:border,
                [inputClass] : inputClass,
                ['bg-white dark:bg-gray-300 dark:text-gray-700'] : !transparant,
                ['bg-transparent'] : transparant
            }"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            v-on:blur="$emit('update:unfocus')"
            :placeholder="placeholder"
            :name="name"
            :rows="rows"
        ></textarea>

        <input
            :ref='ev=>{ input = ev }'
            v-else
            :type="type"
            :id="id"
            class="dark:border-gray-300 border-gray-400 dark:hover:border-gray-200 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0"
            :class="{
                ['border-b-2']:border,
                ['border-red-500 focus:border-red-600 focus:ring-offset-red-200 is-invalid'] : errorMessage,
                ['border-gray-300 focus:border-gray-400 focus:ring-offset-gray-300'] : !errorMessage,
                [inputClass] : inputClass,
                ['bg-white dark:bg-gray-300 dark:text-gray-700'] : !transparant,
                ['bg-transparent'] : transparant
            }"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :placeholder="placeholder"
            :autofocus="autofocus"
            :name="name"
            :autocomplete="autocomplete ? 'on':'off'"
            v-on:blur="$emit('update:unfocus')"
            @click="showList.show = !showList.show"
        >
        <div class="text-red-500 text-xs font-bold" v-if="errorMessage">{{ Array.isArray(errorMessage) ? errorMessage[0] : errorMessage }}</div>
        <div v-if="$slots.searchList && showList.show" :ref="el => search = el"
                class="absolute w-full shadow border"
                :class="[
                    showList.position == 'bottom' ? 'top-full' : 'bottom-full'
                ]"
            >
            <slot name="searchList" />
        </div>
    </div>
</template>

<script>
    import { ref } from '@vue/reactivity';
    import { computed, nextTick, onMounted, watch } from '@vue/runtime-core';
    import { usePage } from '@inertiajs/inertia-vue3';

    export default {
        props: {
            type: {
                type: String,
                default: 'text'
            },
            modelValue: [String, Number],
            id: {
                type: String,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            placeholder: {
                type: String,
                default: null
            },
            name: {
                type: String,
                default: null
            },
            inputClass: String,
            height: {
                type: String,
                default: '56'
            },
            autofocus: {
                type: String,
                default: null
            },
            border: {
                type: Boolean,
                default: true
            },
            transparant: Boolean,
            rows: {
                type: [String, Number],
                default: 2,
            },
            autocomplete: {
                type: Boolean,
                default: true
            },
            error: [String, Array, Boolean],
        },

        emits: ['update:modelValue', 'update:unfocus', 'update', 'change'],
        setup(props){
            const showList = ref({
                show: false,
                position: 'bottom',
            });
            const input = ref(null);
            const search = ref(null);
            const errorMessage = ref(props.error);
            watch( () => usePage().props.value.errors, (error, oldError) => {
                if(props.name || props.id) errorMessage.value = error[props.name ? props.name : props.id];

                nextTick(() => {
                    if(document.getElementsByClassName('is-invalid').length && document.getElementsByClassName('is-invalid')[0] == input.value) {

                        window.scrollTo({
                            top: input.value.offsetTop - 120,
                            behavior: 'smooth'
                        });
                    }
                })
            })

            watch(() => search.value, (val, oldVal) => {
                if(val){
                    let bottom = val.getBoundingClientRect().bottom;
                    if(window.innerHeight + window.scrollY < bottom){
                        showList.value.position = 'top';
                    }
                    else showList.value.position = 'bottom';

                    window.addEventListener('click', clickEvent)
                }
                else window.removeEventListener('click', clickEvent)
            })

            function clickEvent(ev){
                let exit = true;
                ev.path.forEach((el) => {
                    if(el == input.value || ( el.dataset && el.dataset.input == 'true') ){
                        exit = false;
                    }
                })

                if(exit) showList.value.show = false;
            }

            return {
                errorMessage,
                input,
                showList,
                search
            }
        }
    }
</script>

