<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <!-- {{ teams }} -->
            <div  class="flex ">
                <button
                class="flex items-center py-3 px-5"
                :class="tab==1 ? 'dark:bg-gray-700 dark:text-white bg-gray-200 shadow' :  'dark:bg-gray-800 dark:text-white bg-gray-300'"
                @click="tab=1"
                >
                    Usuwanie drużyn z ligi
                </button>

                <button
                class="flex items-center py-3 px-5"
                :class="tab==2 ? 'dark:bg-gray-700 dark:text-white bg-gray-200 shadow' :  'dark:bg-gray-800 dark:text-white bg-gray-300'"
                @click="tab=2"
                >
                    Wartości startowe w tabeli
                </button>

                <button
                class="flex items-center py-3 px-5"
                :class="tab==3 ? 'dark:bg-gray-700 dark:text-white bg-gray-200 shadow' :  'dark:bg-gray-800 dark:text-white bg-gray-300'"
                @click="tab=3"
                >
                    Predefiniowanie miejsc
                </button>

                <button
                v-if="league[0].url !== null"
                class="flex items-center py-3 px-5"
                :class="tab==4 ? 'dark:bg-gray-700 dark:text-white bg-gray-200 shadow' :  'dark:bg-gray-800 dark:text-white bg-gray-300'"
                @click="tab=4"
                >
                    Mapowanie drużyn
                </button>

            </div>
            <!-- tab1 -->
            <div class="my-4  dark:bg-gray-600 bg-white rounded-xl" v-if="tab==1">
                 <table class="rounded-md overflow-hidden shadow-sm w-full">
                    <thead class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                        <tr >
                            <th class="py-3 pl-5 text-left"></th>
                            <th class="py-3 pl-5 text-left">Drużyna</th>
                            <th class="p-2 text-right"></th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 text-sm  font-light">
                        <tr
                            v-for="(t, key) in start_values"
                            :key="t"
                            class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white"
                        >
                            <td class="pl-5">{{ key + 1 }}</td>
                            <td class="pl-5">
                                <div class="flex flex-col font-bold">
                                    <span>{{ t.club_name }} - {{ t.category_name }}</span>
                                    <span v-if="$props.laczynaspilka.find(e => e.team_id == t.id)" class="text-xs dark:text-gray-900 text-gray-400">{{ $props.laczynaspilka.find(e => e.team_id == t.id).name }}</span>
                                </div>
                            </td>
                            <td class="text-right pr-5">
                                <Link @click="showRemove = t.id" class="my-2 bg-red-600 hover:bg-red-800">
                                    <SvgIcon name="x" width="18" height="18"/>
                                    <span class="ml-2 hidden lg:block">Usuń</span>
                                </Link>

                                <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                                    v-if="showRemove == t.id"
                                >
                                    <div class="w-10/12 md:w-6/12 bg-white text-center rounded p-5 shadow">
                                        <h2 class="mb-5 font-bold text-gray-900">Czy napewno chcesz usunąć "{{ t.club_name }} - {{ t.name }}" z ligi?</h2>
                                        <div class="flex justify-center">
                                            <Link @click="deleteTeam(t.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                                                <SvgIcon name="check" width="18" height="18" />
                                                <span class="ml-2">Tak, Usuń</span>
                                            </Link>
                                            <Link @click="showRemove=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                                <SvgIcon name="x" width="18" height="18" />
                                                <span class="ml-2">Nie, anuluj operację</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                 </table>
            </div>
            <div class="my-4  dark:bg-gray-600 bg-white rounded-xl " v-if="tab==2">
                <table class="rounded-md overflow-hidden shadow-sm w-full">
                    <thead class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                        <tr >
                            <th class="py-3 pl-5 text-left"></th>
                            <th class="py-3 pl-5 text-left">Drużyna</th>
                            <th class="py-3 text-right pr-3">Punkty</th>
                            <th class="py-3 text-right pr-3">Bramki strzelone</th>
                            <th class="py-3 text-right pr-3">Bramki stracone</th>
                            <th class="py-3 text-right pr-3">Mecze rozegrane</th>
                            <th class="p-2 text-right">
                                <Link
                                    color="green"
                                    @click="updateStartValue()"
                                >
                                    <SvgIcon name="check" width="18" height="18" class="mr-2"/>
                                    Zapisz
                                </Link>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 text-sm  font-light">
                        <tr
                            v-for="(t, key) in $props.start_values"
                            :key="key"
                            class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white"
                        >
                            <td class="pl-5">{{ key + 1 }}</td>
                            <td class="pl-5">
                                <div class="flex flex-col font-bold">
                                    <span>{{ t.club_name }} - {{ t.category_name }}</span>
                                    <span v-if="$props.laczynaspilka.find(e => e.team_id == t.id)" class="text-xs dark:text-gray-900 text-gray-400">{{ $props.laczynaspilka.find(e => e.team_id == t.id).name }}</span>
                                </div>
                            </td>
                            <td class="p-2">
                                <InputLabel type="number" class="ml-auto w-32" :id="'points_'+key" :key="key"
                                v-model="formData[key].points"
                                />
                            </td>
                            <td class="p-2">
                                <InputLabel type="number" class="ml-auto w-32" :name="'goal_scored_'+key" :id="'goal_scored_'+t.id"
                                v-model="formData[key].goal_scored"
                                />
                            </td>
                            <td class="p-2">
                                <InputLabel type="number" class="ml-auto w-32" :name="'goal_conceded_'+key" :id="'goal_conceded_'+t.id"
                                v-model="formData[key].goal_conceded"
                                />
                            </td>
                            <td class="p-2">
                                <InputLabel type="number" class="ml-auto w-32" :name="'match_played_'+key" :id="'match_played_'+t.id"
                                v-model="formData[key].match_played"
                                />
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- tab3 -->
            <div class="my-4 " v-if="tab==3">
                <div class="overflow-auto" v-if="tab==3">
                <table class="dark:bg-gray-600 bg-white rounded-md overflow-hidden shadow-sm w-full">
                    <thead class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                        <tr >
                            <th></th>
                            <th class="py-3 pl-5 text-left">Drużyna</th>
                            <th class="p-2 text-right">
                                <Link
                                    color="green"
                                    @click="updateStartValue()"
                                >
                                    <SvgIcon name="check" width="18" height="18" class="mr-2"/>
                                    Zapisz
                                </Link>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 text-sm  font-light">
                        <tr
                            v-for="(t, key) in start_values"
                            :key="key"
                            class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white"
                        >
                            <td class="pl-5">{{ key + 1 }}</td>
                            <td class="pl-5">
                                <div class="flex flex-col font-bold">
                                    <span>{{ t.club_name }} - {{ t.category_name }}</span>
                                    <span v-if="$props.laczynaspilka.find(e => e.team_id == t.id)" class="text-xs dark:text-gray-900 text-gray-400">{{ $props.laczynaspilka.find(e => e.team_id == t.id).name }}</span>
                                </div>
                            </td>

                            <td class="p-2 text-right">
                                 <InputLabel
                                    type="number"
                                    class="w-44 ml-auto"
                                    :id="'goal_conceded_'+t.id"
                                    v-model="formData[key].predifined_place"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <!-- tab4 -->
            <div class="my-4" v-if="tab==4">
                <div v-if="$props.laczynaspilka && $props.laczynaspilka.filter(e => !e.team_id).length " class="flex flex-wrap gap-3  dark:text-white text-sm mb-3">
                    <p class="w-full font-bold">Drużyny niezmapowane (ŁNP):</p>
                    <div
                        class=" dark:text-white text-xs border border-gray-500 px-2 py-1 flex"
                        v-for="item in $props.laczynaspilka.filter(e => !e.team_id)"
                        :key="item.id"
                    >
                        <span class="mt-0.5">{{ item.name }}</span>
                    </div>
                </div>
                <div class="overflow-auto w-full">
                <table class="rounded-md dark:bg-gray-600 bg-white table-auto overflow-hidden shadow-sm w-full">
                    <thead class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                        <tr>
                            <th></th>
                            <th class="py-3 pl-5 text-left">Drużyna</th>
                            <th class="p-2 text-right">
                                <div class="flex gap-2 items-center justify-end">
                                    <select
                                        v-if="!$props.teams.length && $props.laczynaspilka && $props.laczynaspilka.length"
                                        class="w-96 bg-white text-gray-500 dark:text-gray-900 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0 border-b-2"
                                    >
                                        <option
                                            v-for="(l, key) in laczynaspilka"
                                            :key="l.id"
                                            :value="l.id"
                                        >
                                        {{ key + 1}}. {{ l.name }}
                                        </option>
                                    </select>
                                    <Link
                                        color="green"
                                        @click="updateLach()"
                                    >
                                        <SvgIcon name="check" width="18" height="18" class="mr-2"/>
                                        Zapisz
                                    </Link>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 text-sm  font-light">
                        <tr
                            v-for="(t, key) in formLachName"
                            :key="key"
                            class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white"
                        >
                            <td class="pl-5">{{ key + 1 }}</td>
                            <td class="pl-5 font-bold"> {{ t.club_name }} - {{ t.category_name }}</td>

                            <td class="p-2">
                                <div class="relative w-full text-gray-900 flex justify-end">
                                    <select
                                        class="w-96 bg-white text-gray-500 dark:text-gray-900 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0 border-b-2"
                                        @change="ev => onChange(ev, t)"
                                    >
                                        <option>Brak</option>
                                        <option
                                        v-for="l in laczynaspilka"
                                        :key="l.id"
                                        :selected="t.laczynaspilka && l.name === t.laczynaspilka.name"
                                        :value="l.id"
                                        >
                                        {{ l.name }}
                                        </option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>

            </div>
        </div>
    </AdminLayout>
</template>
<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { computed, ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted, onUpdated, watch } from 'vue'
    import Pagination from '@/Components/Pagination'
    import { Inertia } from '@inertiajs/inertia'
    import Modal from '@/Components/Modal'
    import Listbox from '@/Components/Listbox'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Modal,
            Listbox
        },
        props: {
            league: [Array, Object],
            teams: [Array, Object],
            clubs: [Array, Object],
            laczynaspilka: [Array, Object],
            start_values: [Array, Object],

        },
        data(){
            return{
                confirmModal: false,
            }
        },
        methods: {
            showConfirmModal(){
                this.confirmModal = true;
            }
        },
        setup(props) {
            const showRemove = ref(false);
            const tab = ref(1);
            const deleteForm = useForm({
                ss: props.teams.length > 0 ? props.teams[0].pivot.league_id : null,
            })

            const deleteTeam = (id) => {
                deleteForm.post(route('admin.events.leagues.delete.team', id));
            }
            const formData = ref([]);
            const formLachName = ref([]);

            watch(() => tab.value, (val) => {
                setData();
            })
            setData();
            function setData(){
                formLachName.value = [];
                formData.value = [];

                props.start_values.forEach(el => {
                    formData.value.push({
                        id: el.id,
                        club_id: el.club_id,
                        points: el.start_values[0] ? el.start_values[0].points : null,
                        goal_scored: el.start_values[0] ? el.start_values[0].goal_scored : null,
                        goal_conceded: el.start_values[0] ? el.start_values[0].goal_conceded : null,
                        match_played: el.start_values[0] ? el.start_values[0].match_played : null,
                        predifined_place: el.start_values[0] ? el.start_values[0].predifined_place : null,
                        league: props.teams.length > 0 ? props.teams[0].pivot.league_id : null,
                    });
                    formLachName.value.push({
                        id: el.id,
                        club_id: el.club_id,
                        club_name: el.club_name,
                        category_name: el.category_name,
                        laczynaspilka: props.laczynaspilka.find(e => e.team_id == el.id),
                    })
                });
            }

            const updateStartValue = (key, item) => {
                Inertia.post(route('admin.events.leagues.update.start_values'), {
                    values: formData.value,
                }, {
                    preserveScroll: true,
                });
            }

            const updateLach = (key) => {
                 Inertia.post(route('admin.update.club.lach.name'), {
                     values: formLachName.value,
                 }, {
                    preserveScroll: true,
                });
            }

            const onChange = (ev, element) => {
                element.laczynaspilka = props.laczynaspilka.find(e => e.id == Number(ev.target.value));
            }

            return {
                tab,
                deleteTeam,
                updateStartValue,
                updateLach,
                formLachName,
                formData,
                onChange,
                showRemove,
            }
        }
    }
</script>
