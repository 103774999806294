<template>
    <AdminLayout>
    <div class="py-6 px-3 sm:px-6 lg:px-8">
        <div class="">
            <div class="text-xl dark:text-white font-bold">Główny wpis link</div>
        </div>
        <div class="mt-4 grid grid-cols-2 gap-4">
            <InputLabel
                type="number"
                label="Kolumny (desktop) / max: 12"
                v-model="newsLinkForm.cols"
            />
            <InputLabel
                type="number"
                label="Rzędy (desktop)"
                v-model="newsLinkForm.rows"
            />
            <div class="col-span-full">
                <Link
                    @click="saveForm"
                    class="bg-green-600 hover:bg-green-800"
                >
                    <SvgIcon name="check" width="18" height="18" />
                    <span class="ml-2 hidden lg:block">Zapisz</span>
                </Link>
            </div>
        </div>

        <div class="mt-4 text-gray-600 text-sm font-light">

              <draggable
                :list="news"
                @start="drag=true"
                @end="drag=false"
                @change="changePosition"
                tag="transition-group"
                handle=".handle"
                item-key="id"

                v-bind="dragOptions"
            >
                <template #item="{element, index}">
                    <div
                        class="border-b relative border-gray-200 dark:border-gray-500 text-gray-700 dark:text-white cursor-pointer flex justify-between"

                        :class="[
                            (newsLinkForm.rows * newsLinkForm.cols) >= (index + 1) ? 'bg-green-50 dark:bg-gray-600 dark:bg-opacity-50' : 'bg-white dark:bg-gray-600'
                        ]"
                    >
                        <div class="py-1 pl-3 text-sm flex items-center font-bold">
                            <span class="mr-2">{{ index + 1 }}</span>{{element.title}}<span class="ml-2 text-xs text-gray-400">{{ getDate(element.created_at_news)}}</span>
                        </div>

                        <div class="py-1 mr-3 text-center flex items-center justify-center ml-2 space-x-2">
                            <Link :href="route('admin.news.edit', element.news_id)" class="bg-green-600 hover:bg-green-800">
                                <SvgIcon name="check" width="18" height="18" />
                                <span class="ml-2 hidden lg:block">Edytuj</span>
                            </Link>
                            <Link @click="showRemove= element.id" class="bg-red-600 hover:bg-red-800">
                                <SvgIcon name="x" width="18" height="18" />
                                <!-- <span class="ml-2 hidden lg:block">Usuń</span> -->
                            </Link>
                            <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                             v-if="showRemove == element.id"
                            >
                                <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                                    <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć kategorię "{{element.name}}"?</h2>
                                    <div class="flex justify-center">
                                        <Link @click="removeLink(element.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                                            <SvgIcon name="check" width="18" height="18" />
                                            <span class="ml-2">Tak, Usuń</span>
                                        </Link>
                                        <Link @click="showRemove=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                            <SvgIcon name="x" width="18" height="18" />
                                            <span class="ml-2">Nie, anuluj operację</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-2 handle cursor-move transform text-gray-400">
                                <SvgIcon
                                name="menu"
                                />
                            </div>
                        </div>
                    </div>

                </template>
            </draggable>

        </div>
    </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import ImageModal from '@/Components/ImageModal'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { Inertia } from '@inertiajs/inertia'
    import { nextTick, onMounted, watch } from 'vue'
    import Pagination from '@/Components/Pagination'
    import draggable from 'vuedraggable';
    import moment from 'moment'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            ImageModal,
            Pagination,
            draggable,

        },
        props: {
            gallery: Object,
            news: [Object, Array],
            option_news: [Object, Array],
        },
        setup(props) {
            const showRemove = ref(false);
            const imageModal = ref(false);
            const drag = ref(false);
            const dragOptions = ref({
                animation: 200,
                disabled: false,
            });

            const newsLinkForm = useForm({
                rows: props.option_news.rows ? props.option_news.rows.value : 2,
                cols: props.option_news.cols ? props.option_news.cols.value : 4,
            })

            const changePosition = (e) => {
                let positions = {}
                props.news.forEach((el, key) => {
                    positions[el.id] = key+1
                })
                Inertia.post(route('admin.update.links.position'), {news_links: positions})
                // admin.post.news.delete.link
            }

            const removeLink = (id) => {
                 Inertia.post(route('admin.post.news.delete.link', id));
            }

            function saveForm(){
                newsLinkForm.post(route('admin.update.news_links'), {
                    preserveScroll: true,
                })
            }

            function getDate(date){
                return moment(date).format('DD/MM/YYYY HH:mm')
            }

            return {
                getDate,
                imageModal,
                changePosition,
                dragOptions,
                drag,
                showRemove,
                removeLink,

                saveForm,

                newsLinkForm
            }
        }
    }
</script>
