<template>
    <div class="grid grid-flow-row grid-cols-10">
        <div class=" rounded-md w-full h-16 bg-cover col-span-3" :style="'background-image: url('+image ? image : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')+');'">

        </div>
        <div class="col-span-7 uppercase text-gray-600 font-bold text-l h-full w-full flex flex-row items-end pl-4">
            {{label}}
        </div>
    </div>
</template>

<script>

export default ({

    props:{
        id: {
                type: Number,
                default: 1
            },
        image: {
                type: String,
                default: null,
            },
        label: {
                type: String,
                default: 'unset'
            }

    },


    setup(props) {

    },
})
</script>
