<template>
<div
    :class="[
        id != 1 ? 'clearFix md:col-span-1 lg:col-span-1' : ''
    ]"
>
        <!-- First Card -->

        <inertia-link
        v-if="id == 1"
        class="block relative w-full h-full lg:mb-0"
        :href="href"
        >
            <div
                v-if="label"
                class="absolute min-h-min -left-4 top-5 z-20 shadow-sm bg-gray-800 bg-opacity-80 px-3 py-2  uppercase text-xs text-white"
                v-html="label"
            ></div>
            <div class=" relative overflow-hidden w-full h-full ">

                <div
                    class="absolute inset-0 bg-cover bg-bottom overflow-hidden"
                >
                    <div class="img-wrapper w-full h-full flex items-center justify-center overflow-hidden">
                        <img class="h-full w-full lg:h-full lg:w-auto 2xl:w-full object-cover max-w-none transform scale-100 hover:scale-125 transition"
                        v-lazy="{
                            src: image,
                            loading: '/img/cms/loading.gif',
                            error: '/img/cms/img-error.png'
                        }">
                    </div>
                </div>
                <div class="overflow-hidden absolute bottom-0 left-0 right-0 py-4">
                    <div
                        class="bg-black bg-opacity-60 absolute -inset-4 bg-cover bg-bottom"

                    ></div>
                    <div class="relative z-20 px-4 text-xs lg:text-normal font-thin mb-3" :class="'text-'+color" v-if="date">{{currentDateTime(date)}}</div>
                    <div class="relative z-20 px-4 font-bold text-xs lg:text-xl uppercase" :class="'text-'+color" v-html="title"></div>
                </div>
            </div>

        </inertia-link>

        <!-- Rest of cards -->

        <div class="h-full" v-else>
            <inertia-link
            class="lg:block relative w-full overflow-hidden shadow-md h-full"
            :href="href"
            >

                <!-- <div
                    v-if="label"
                    class="absolute left-0 top-5 z-20 w-full shadow-sm bg-gray-800 bg-opacity-80 px-3 py-2 uppercase text-xs text-white rounded-tr-full rounded-br-full"
                    v-html="label"
                ></div>
                <div class=" relative overflow-hidden w-full h-full ">
                    <div class="img-wrapper w-full h-full flex items-center justify-center overflow-hidden">
                        <img class="h-full w-auto lg:h-full lg:w-auto 2xl:w-full 2xl:object-cover max-w-none transform scale-125 hover:scale-150 transition" :src="image">
                    </div>

                </div>
                <div class="col-span-2 absolute bottom-0 left-0 w-full bg-white dark:bg-gray-900 lg:bg-black bg-opacity-60 dark:lg:bg-black dark:lg:bg-opacity-60">
                    <div class="text-gray-700  lg:text-white darl:lg:text-white dark:text-gray-400 relative z-20 px-4 text-xs lg:text-normal font-thin mt-3 mb-3 lg:mb-5" v-if="date">{{currentDateTime(date)}}</div>
                    <div class="text=gray-700 lg:text-white dark:lg:text-white dark:text-gray-200 relative z-20 px-4 font-bold text-xs lg:text-normal uppercase mb-3" v-html="title"></div>
                </div> -->

                <div
                v-if="label"
                class="absolute -left-4 top-5 z-20 shadow-sm bg-gray-800 bg-opacity-80 px-3 py-2  uppercase text-xs text-white"
                v-html="label"
                ></div>
                <div class=" relative overflow-hidden w-full h-full ">

                    <div
                        class="absolute inset-0 bg-cover bg-bottom overflow-hidden"
                    >
                        <div class="img-wrapper w-full h-full flex items-center justify-center overflow-hidden">
                            <img class="h-full w-full lg:h-full lg:w-auto 2xl:w-full object-cover max-w-none transform scale-100 hover:scale-125 transition"
                            v-lazy="{
                                src: image,
                                loading: '/img/cms/loading.gif',
                                error: '/img/cms/img-error.png'
                            }"
                        >
                        </div>
                    </div>
                    <div class="overflow-hidden absolute bottom-0 left-0 right-0 ">
                        <div
                            class="bg-black bg-opacity-60 absolute inset-0 bg-cover bg-bottom"

                        ></div>
                        <div class="text-white lg:text-white darl:lg:text-white dark:text-gray-400 relative z-20 px-4 text-xs lg:text-normal font-thin mt-3 mb-1" v-if="date">{{currentDateTime(date)}}</div>
                        <div class="text-white lg:text-white dark:lg:text-white dark:text-gray-200 relative z-20 px-4 font-bold text-xs lg:text-normal uppercase mb-3" v-html="title"></div>
                    </div>
                </div>
            </inertia-link>
        </div>
</div>
</template>

<script>
    import { ref } from '@vue/reactivity';
    import moment from 'moment';
    export default {
        components: {

        },
        props: {
            title: {
                type: String,
                default: null
            },
            date: {
                type: String,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            image: {
                type: String,
                default: '/img/cms/bulbasaur.png'
            },
            color: {
                type: String,
                default: 'black'
            },
            href: {
                type: String,
                default: 'home'
            },
            id: {
                type: Number,
                default: null
            },

        },
        methods: {
            currentDateTime(date) {
                return moment(date).format('DD.MM.YYYY')
            }
        },
        setup(props) {


            return {

            }
        }
    }
</script>

