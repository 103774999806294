<template>
    <div class="p-6 bg-white dark:bg-gray-900 shadow mt-5">
        <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-green-700 dark:text-green-600 mr-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div>
                <p class="text-sm sm:text-normal text-green-700 dark:text-green-600 font-bold">
                    {{sTitle}}
                </p>
                <h2 class="text-xl sm:text-2xl font-extrabold tracking-tight dark:text-white">
                    {{title}}
                </h2>
            </div>
        </div>
        <p class="text-gray-800 dark:text-white mt-5">
            {{content}}
        </p>
        <div class="flex items-center mt-5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-5 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <p class="font-bold text-lg sm:text-xl dark:text-white">
                {{emailTitle}}
            </p>
        </div>
        <p class="text-lg font-bold sm:text-xl text-green-700 dark:text-green-600 mt-3">{{email}} <span class="dark:text-gray-300 text-gray-700 font-normal text-normal">{{emailInfo}}</span></p>
        <div class="mt-1 dark:text-gray-300">{{emailGuide}}</div>
        <div class="flex items-center mt-5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-5 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <p class="font-bold text-lg sm:text-xl dark:text-white">
                {{smsTitle}}
            </p>
        </div>
        <p class="text-lg font-bold sm:text-xl text-green-700 dark:text-green-600 mt-3">{{sms}} <span class="text-gray-700 dark:text-gray-300 font-normal text-normal">{{smsInfo}}</span></p>
        <div class="mt-1 dark:text-gray-300">{{smsGuide}}</div>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type: String,
            default: "Wyślij nam wynik meczu"
        },
        sTitle:{
            type: String,
            default: "Bądź żródłem informacji"
        },
        content:{
            type: String,
            default: "Serwis Futbol-Małopolska w dużej mierze współtworzony jest przez pasjonatów futbolu, czytelników takich jak Ty. Tylko dzięki Twojej pomocy Futbol-Małopolska będzie bardziej wiarygodny i aktualny. Jesteś kibicem, piłkarzem albo trenerem? Poinformuj tysiące żądnych informacji czytelników o wyniku Twojej drużyny. Dzięki Tobie tabela w naszym serwisie szybciej będzie zaktualizowana, a serwis stanie się bardziej rzetelny. Wesprzyj redakcję i wyślij nam wynik meczu."
        },
        emailTitle:{
            type: String,
            default: "Opcja E-MAIL"
        },
        email:{
            type: String,
            default: "info@futmal.pl"
        },
        emailInfo:{
            type: String,
            default: "(wszystkie rozgrywki)"
        },
        emailGuide:{
            type: String,
            default: 'przykład: "Klasa Okręgowa (Kraków II): Proszowianka - Piast 2:2 / pozdrawiam Bozydar Kowalski pilkarz Piasta"'
        },
        smsTitle:{
            type: String,
            default: "Opcja SMS"
        },
        sms:{
            type: String,
            default: "604-473-344"
        },
        smsInfo:{
            type: String,
            default: "(wszystkie rozgrywki)"
        },
        smsGuide:{
            type: String,
            default: 'przykład: "Klasa Okręgowa (Kraków II): Proszowianka - Piast 2:2 / pozdrawiam Bozydar Kowalski pilkarz Piasta"'
        },

    },
    setup() {

    },
}
</script>
