<template>

    <inertia-head>
        <title>Kontakt - {{ $page.props.app_name }}</title>
    </inertia-head>

    <MainLayout>
        <div class="sm:p-5 p-3 xl:w-10/12 m-auto">
            <!-- <AddBar
            :addImg="'/storage/adds/'+add.images[0].name"
            :addHref="add.url"
            v-if="add"
            /> -->
            <div class="grid grid-cols-1 md:grid-cols-2 mt-4">
                <div class="sm:p-6 p-0 mr-2 dark:bg-gray-800">
                    <h1 class="text-2xl sm:text-4xl font-extrabold tracking-tight dark:text-white">
                        Kontakt
                    </h1>

                    <div class="flex items-center mt-8">
                        <div class="bg-green-700 shadow text-white font-bold p-3 rounded-full">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                        </div>
                        <div class="ml-4 text-md tracking-wide font-semibold w-40 text-gray-800 dark:text-white">
                            {{address}}
                        </div>
                    </div>

                    <div class="flex items-center mt-5 text-gray-800 dark:text-white">
                        <div class="bg-green-700 shadow text-white font-bold p-3 rounded-full">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 ">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                            </svg>
                        </div>
                        <div class="ml-4 text-md tracking-wide font-semibold w-40">
                            <a class="hover:underline" :href="'tel:'+phone">{{phone}}</a>
                        </div>
                    </div>

                    <div class="flex items-center mt-5 text-gray-800 dark:text-white">
                        <div class="bg-green-700 shadow text-white font-bold p-3 rounded-full">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                            </svg>
                        </div>
                        <div class="ml-4 text-md tracking-wide font-semibold w-40">
                            <a class="hover:underline" :href="'mailto:'+email">{{email}}</a>
                        </div>
                    </div>
                    <h2 class="text-xl sm:text-2xl mt-7 dark:text-white font-extrabold tracking-tight">
                        Prześlij nam informacje:
                    </h2>
                    <div class="mt-5 text-green-700 dark:text-green-600">
                        <a :href="`mailto:${email}`" class="flex items-center hover:underline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mr-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                            </svg>
                            <span>Bądź żródłem informacji (wyślij nam wynik Meczu)</span>
                        </a>
                        <a :href="`mailto:${email}`" class="flex items-center mt-3 hover:underline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mr-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                            </svg>
                            <span>Zostań reporterem (wyślij nam relację)</span>
                        </a>
                        <a :href="`mailto:${email}`" class="flex items-center mt-3 hover:underline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mr-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                            </svg>
                            <span>Zostań informatorem (wyślij nam newsa)</span>
                        </a>
                    </div>
                </div>

                <div class="sm:p-6 p-0 mt-3">
                    <div class="flex flex-col justify-center bg-white dark:bg-gray-900 p-4 shadow">
                        <div class="mt-2">
                            <InputLabel
                            placeholder="Imię"
                            id="name"
                            name="name"
                            type="text"
                            class="mt-2"
                            v-model="contactForm.name"
                        />
                        </div>

                        <div class="mt-2">
                            <InputLabel
                            placeholder="Email"
                            id="email"
                            name="email"
                            type="email"
                            class="mt-2"
                            v-model="contactForm.email"
                        />
                        </div>

                        <div class="mt-2">
                            <InputLabel
                            placeholder="Treść"
                            id="content"
                            name="content"
                            type="textarea"
                            class="mt-2"
                            v-model="contactForm.content"
                        />
                        </div>
                        <p class="text-sm text-gray-600 dark:text-gray-300 my-4">
                            * Korzystając się z formularza kontaktowego akceptujesz naszą <a href="#" class="text-green-700 dark:text-green-600 underline">Politykę prywatności</a>.
                        </p>

                        <div
                        class="w-full text-center py-3 lg:py-5 border-2 text-lg font-bold mb-3 transition-all cursor-pointer border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
                        @click="sendForm()"
                        >
                            Wyślij
                        </div>
                    </div>
                </div>
            </div>

            <InfoCard />

        </div>
    </MainLayout>
</template>


<script>
    import MainLayout from '@/Layouts/Main'
    import AddBar from '@/Components/Adds/addBar'
    import InputLabel from '@/Components/InputLabel'
    import Link from '@/Components/Link'
    import { ref } from 'vue'
    import { useForm } from '@inertiajs/inertia-vue3'
    import InfoCard from '@/Components/Post/infoBlock'

    export default {
        components: {
            MainLayout,
            AddBar,
            InputLabel,
            Link,
            InfoCard,
        },
        props: {
            add: Object,
            address: String,
            phone: String,
            email: String,
        },
        setup(props) {
            const contactForm = useForm({
                name: null,
                email: null,
                content: null,
            })

            const sendForm = () => {
                contactForm.post(route('send.contact.form'));
            }

            return {
                contactForm,
                sendForm
            }
        }
    }
</script>

<style scoped>

/* Придумать решение по-лучше  */

.opened .wrapper{
    max-height: 300vh;
    overflow: hidden;
}
.closed .wrapper{
    max-height: 0;
    overflow: hidden;
}
</style>
