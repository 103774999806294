<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="flex">
                <Link @click="addQueue()" class="mb-3 bg-green-600 hover:bg-green-800">Dodaj</Link>
                <Link @click="round()" :disabled="league.reround == 1 ? true : false" class="mb-3 ml-3 bg-blue-600 hover:bg-blue-800">Generuj rundę rewanżową</Link>

                <Modal v-if="confirmModal" @update="confirmModal = $event">
                    <div class="lg:px-48  py-2">
                        <h2 class="text-xl font-bold text-center pt-4">Czy na pewno chcesz wykonać tą operację?</h2>
                    </div>
                    <div class="w-full">

                            <div class="lg:w-2/5 w-5/5  grid grid-cols-2 mx-auto py-6 text-center">
                                    <Link
                                    class="ml-2 border-1 border-yellow-500 text-center hover:bg-yellow-600 px-6"
                                    color="gray"
                                    @click="confirmModal = false"
                                    >
                                        <SvgIcon name="chevron-left" width="18" height="18"/>
                                        <span class="ml-2  ">Wróć</span>
                                    </Link>
                                    <Link
                                    class="ml-2 bg-yellow-500 hover:bg-yellow-600"
                                    color="gray"
                                    @click="downloadScoresLeague()"
                                    @update="confirmModal = false"
                                    :disabled="league.url?false:true"
                                    >
                                        <SvgIcon name="download" width="18" height="18"/>
                                        <span class="ml-2  ">Pobierz dane</span>
                                    </Link>
                            </div>

                    </div>
                </Modal>
            </div>
            <div class="table-auto rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-6 text-left">Kolejka</div>
                    <div class="py-3 pr-6">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="(q, key) in $props.queues"
                        :key="key"
                        class="border-b border-gray-200 dark:border-gray-500 dark:text-white cursor-pointer flex flex-row justify-between items-center"
                        :class="q.id == $props.league.current_queue ? 'bg-green-100 dark:bg-green-700 text-green-900' : 'bg-white dark:bg-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:bg-opacity-90'"
                    >
                        <div class="pl-3 flex flex-col gap-3 sm:flex-row items-end">
                            <div class=" py-1 font-bold">
                                <div>Kolejka #{{q.number}}</div>
                                <div class="dark:text-gray-300 font-normal mt-1 text-xs">{{ $props.league.name  }}</div>
                            </div>
                            <div class="py-1 dark:text-gray-300 text-xs">{{q.start_date ? q.start_date : 'Brak'}}</div>
                        </div>
                        <div class="py-1 text-center pr-3">
                            <Link :href="route('admin.events.gueues.edit', q.id)" class="bg-green-600 hover:bg-green-800">
                                <SvgIcon name="pencil" width="18" height="18"/>
                                <span class="ml-2 hidden lg:block">Edytuj</span>
                            </Link>
                            <Link @click="removeQueue(q.id)" class="ml-2 bg-red-600 hover:bg-red-800">
                                <SvgIcon name="x" width="18" height="18"/>
                                <span class="ml-2 hidden lg:block">Usuń</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <pagination class="mt-6" :links="leagues.links" /> -->
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref, watch, onMounted } from 'vue'
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Pagination from '@/Components/Pagination'
    import moment from 'moment'
    import Modal from '@/Components/Modal'
import { Inertia } from '@inertiajs/inertia'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Modal
        },
        props: {
            queues: Object,
            league: [Array, Object],
        },
        data(){
            return{
                confirmModal: false,
            }
        },
        methods: {
            showConfirmModal(){
                this.confirmModal = true;
            }
        },
        setup(props) {
            const queue_form = useForm({
                league: route().params.league
            });


            const addQueue = () => {
                queue_form.post(route('admin.clubs.queue.add'))
            }

            const round = () => {
                queue_form.post(route('admin.events.leagues.round', route().params.league))
            }

            onMounted(() => {
                editValues();
            })

            const editValues = () => {
                let checker = true;
                props.queues.forEach(function(el){
                    let isAfter = moment(el.start_date).isAfter(moment())

                    // if (isAfter && checker) {
                    //     checker = false;
                    //     el.current = true;
                    // }
                    // else {
                    //     el.current = false;
                    // }
                })
            }

            const removeQueue = (id) => {
                Inertia.post(route('admin.clubs.queue.remove', id))
            }

            const downloadScoresLeague = () => {
                Inertia.post(route('admin.download.all.scores', props.league.id), {
                    preserveScroll: true,
                });
            }

            return {
                addQueue,
                removeQueue,
                queue_form,
                round,
                downloadScoresLeague,
            }
        }
    }
</script>
