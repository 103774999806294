require('./bootstrap');

// Import modules...
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import VueSocialSharing from 'vue-social-sharing'

import VueLazyLoad from 'vue3-lazyload'
import { Inertia } from '@inertiajs/inertia';

const el = document.getElementById('app');

createInertiaApp({
    resolve: (name) => require(`./Pages/${name}`),

    setup({ el, app, props, plugin }) {
        createApp({
            render: () => h(app, props),

        })
            .mixin({ methods: { route } })
            .use(plugin)
            .use(VueSocialSharing)
            .use(VueLazyLoad)
            .mount(el);

        document.getElementById('app').removeAttribute('data-page');
    },
});

Inertia.on('finish', (page) => {
    runRegioWyniki();
})
window.onload=function(){ runRegioWyniki() };

function runRegioWyniki(){
    if(document.getElementById('regiowyniki')){
        var regiowyniki = new RegioWyniki({
            "html": "regiowyniki", "user": 0, "regions": '7', "teams": '312|320|321|331|407|417|5944|5892|415', "days": 5
        });
        regiowyniki.init();
    }
}

InertiaProgress.init({
    color: '#059669',
    showSpinner: true,
    includeCSS: true,
});
