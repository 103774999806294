<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="flex justify-between mb-3 gap-4">
                <Link :href="route('admin.page.add')" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Dodaj</span>
                </Link>

            </div>
            <div class="w-full table-auto rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-3">Strona</div>
                    <div class="py-3 pr-3">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="n in $props.pages.data"
                        :key="n"
                        class="dark:odd:bg-opacity-70  border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white cursor-pointer flex justify-between flex-wrap"
                    >
                    <div class="flex justify-between w-full">
                        <div class="flex items-center justify-between w-full flex-wrap">
                            <div class="pl-3 font-bold py-1 col-span-3 flex flex-col lg:flex-row items-left lg:items-center justify-between w-full flex-1 mr-3">
                                <inertia-link
                                    :href="route('page.display', n.slug)"
                                >
                                {{ n.title }}
                                </inertia-link>
                            </div>
                            <div class="text-gray-400 border-l gap-2 border-gray-300 px-2 flex flex-col">
                                <span class="text-xs">{{ getDate(n.created_at) }}</span>
                            </div>
                        </div>
                        <div class="py-2 mx-3 text-center flex justify-end items-center">
                            <div class="flex items-center gap-2">
                                <Link :href="route('admin.page.edit', n.id)" class="flex items-center bg-green-600 hover:bg-green-800">
                                    <SvgIcon name="pencil" width="18" height="18" />
                                    <span class="ml-2 hidden lg:block">Edytuj</span>
                                </Link>
                                <Link @click="showRemove.modal = true; showRemove.element = n" class="flex items-center bg-red-600 hover:bg-red-800">
                                     <SvgIcon name="x" width="18" height="18" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <pagination class="mt-6" :links="$props.pages.links" />
        </div>

        <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                v-if="showRemove.modal"
        >
            <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć stronę "{{showRemove.element.title}}"?</h2>
                <div class="flex">
                    <Link :href="route('admin.page.remove', showRemove.element.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                        <SvgIcon name="check" width="18" height="18" />
                        <span class="ml-2">Tak, Usuń</span>
                    </Link>
                    <Link @click="showRemove.modal = false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                        <SvgIcon name="x" width="18" height="18" />
                        <span class="ml-2">Nie, anuluj operację</span>
                    </Link>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import Pagination from '@/Components/Pagination'
    import { useForm } from '@inertiajs/inertia-vue3'
    import { ref } from '@vue/reactivity';
     import moment from 'moment'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination
        },
        props: {
            pages: [Array, Object],
        },
        setup() {
            function getDate(date){
                return moment(date).format('DD/MM/YYYY HH:mm')
            }

            const showRemove = ref({
                modal: false,
                element: null,
            })

            return {
                getDate,
                showRemove
            }
        }
    }
</script>
