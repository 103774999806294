<template>
    <div class="mb-4 text-sm text-gray-600">
        Dziękujemy za zarejestrowanie się! Czy przed rozpoczęciem możesz zweryfikować swój adres e-mail, klikając link, który właśnie do Ciebie wysłaliśmy? Jeśli nie otrzymałeś wiadomości e-mail, chętnie wyślemy Ci kolejną.
    </div>

    <div class="mb-4 font-medium text-sm text-green-600" v-if="verificationLinkSent" >
        Nowy link weryfikacyjny został wysłany na adres e-mail podany podczas rejestracji.
    </div>

    <form @submit.prevent="submit">
        <div class="mt-4 flex items-center justify-between">
            <breeze-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Wyślij ponownie email weryfikacyjny
            </breeze-button>

            <inertia-link :href="route('logout')" method="post" as="button" class="underline text-sm text-gray-600 hover:text-gray-900">Log Out</inertia-link>
        </div>
    </form>
</template>

<script>
    import BreezeButton from '@/Components/Button'
    import BreezeGuestLayout from "@/Layouts/Guest"

    export default {
        layout: BreezeGuestLayout,

        components: {
            BreezeButton,
        },

        props: {
            status: String,
        },

        data() {
            return {
                form: this.$inertia.form()
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('verification.send'))
            },
        },

        computed: {
            verificationLinkSent() {
                return this.status === 'verification-link-sent';
            }
        }
    }
</script>
