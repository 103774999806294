<template>
    <inertia-link
        v-if="href"
        @click.prevent="linkClick()"
        class="inline-flex items-center border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none focus:shadow-outline-gray transition ease-in-out duration-150"
        :class="[
        ['bg-'+color+'-600 hover:bg-'+color+'-700 active:bg-'+color+'-900 focus:border-'+color+'-900'],
        [padding]
        ]"
        :href="href"
        as="a"
    >
        <slot />
    </inertia-link>
    <div
        class="inline-flex cursor-pointer items-center border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none focus:shadow-outline-gray transition ease-in-out duration-150"
        :class="[
        ['bg-'+color+'-600 hover:bg-'+color+'-700 active:bg-'+color+'-900 focus:border-'+color+'-900'],
        [padding],
        {['disabled pointer-events-none select-none opacity-70']:disabled}
        ]"
        v-else
    >
        <slot />
    </div>
</template>

<script>
    import { inject, onMounted, ref } from "vue";
    export default {
        props: {
            href: {
                type: String,
                default: null,
            },
            color: {
                type: String,
                default: 'gray',
            },
            padding: {
                type: String,
                default: 'px-2 py-2',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        setup() {
            // const animate = inject('animate');
            const animate = ref(false);
            const linkClick = () => {
                animate.value = false
            }


            onMounted(() => {

            })
            return {
                animate,
                linkClick
            }
        }
    }
</script>
