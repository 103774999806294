<template>
    <div class="flex w-full flex-wrap gap-2 mb-2">
<!-- v-if="divide" -->
        <div  v-if="divide" class="flex">
            <button
            class="flex items-center lg:text-sm md:text-sm text-xs py-3 lg:px-5 md:px-5 px-2"
            :class="trr==1 ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
            @click="trr=1"
            >
                Tabela
            </button >
            <button
            class="flex items-center lg:text-sm md:text-sm text-xs py-3 lg:px-5 md:px-5 px-2"
            :class="trr==2 ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
            @click="trr=2"
            >
                Runda I
            </button>
            <button
            class="flex items-center lg:text-sm md:text-sm text-xs py-3 lg:px-5 md:px-5 px-2"
            :class="trr==3 ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
            @click="trr=3"
            >
                Runda II
            </button>
        </div>
        <div>
            <button
            class="flex  items-center py-3 px-5 dark:bg-gray-800 bg-gray-300"
            @click="showExpand()"
            >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
            </svg>

            </button>
        </div>
        <div class="relative z-10 sm:w-auto w-full">
            <Roll
                class="sm:w-72 w-full dark:bg-gray-800 bg-gray-300"
                v-if="route().current('league')"
                title="Wybierz ligę"
                :opened='false'
                custom-class="py-3 px-4 justify-between"
                :bg-close="true"
            >
                <div class="absolute z-20 w-full dark:bg-gray-800 bg-gray-300 drop-shadow-2xl max-h-96 overflow-auto">
                    <ul class="dark:bg-gray-900 bg-gray-100 shadow">
                        <li v-for="(l, key) in leagues" :key="key"
                            class="text-sm hover:bg-gray-200 dark:hover:bg-gray-800 flex"
                        >
                            <a :href="route('league', l.slug)" class="p-2 w-full">{{ l.name }}</a>
                        </li>
                    </ul>
                </div>
         </Roll>
        </div>
    </div>
    <div class="overflow-x-scroll xl:overflow-auto relative" :id="'table-load-' + $props.league.id" style="min-height: 100px;">
        <div v-if="loading" class="absolute flex items-center justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <table class="text-sm w-full text-center text-white dark:bg-green-700 bg-green-600">
            <tr class="dark:bg-gray-800 bg-gray-300 text-black dark:text-white" >
                <td
                    class=" text-sm font-bold py-2"
                    v-for="n in 15"
                    :key="n"
                    :colspan="n == 15 ? '4' : '1'"
                >{{n == 15 ? 'MECZE BEZPOŚREDNIE' : n}}</td>
            </tr>
            <tr
                class="text-white"
                v-for="(s, key) in tableResult" :key="key"

                :class="[
                    $props.league && (key >= (tableResult.length - $props.league.teams_drop)) ?
                        'bg-red-700 odd:bg-red-800 bg-opacity-60 odd:bg-opacity-60' :
                            (
                                (key < (tableResult.length - $props.league.teams_drop) && key >= (tableResult.length - ($props.league.teams_drop + $props.league.teams_drop_play_off))) ?
                                    'bg-rose-800 odd:bg-rose-900 bg-opacity-70 odd:bg-opacity-70' :
                                    (
                                        key+1 <= ($props.league.teams_promo) ?
                                            'bg-orange-500 odd:bg-orange-600 bg-opacity-60 odd:bg-opacity-60' :
                                            (
                                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ?
                                                    'bg-yellow-300 odd:bg-yellow-400 bg-opacity-60 odd:bg-opacity-60' :
                                                    'dark:odd:bg-green-800 odd:bg-green-700'
                                            )
                                    )
                            ),

                ]"
            >
                <!-- 1 -->
                <td class="px-2 whitespace-nowrap  py-3 text-white">{{key+1}}</td>
                <!-- 2 -->
                <td class="px-1  py-3" style="max-width: 9rem"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop) ||
                            (
                                key < ( tableResult.length - $props.league.teams_drop ) &&
                                key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                            )
                        ) ? (
                            (
                                key < ( tableResult.length - $props.league.teams_drop ) &&
                                key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                            ) ?
                            (Math.abs(key % 2) == 1 ? 'bg-rose-700 bg-opacity-10' : 'bg-rose-600 bg-opacity-10') :
                            (Math.abs(key % 2) == 1 ? 'bg-red-700 bg-opacity-10' : 'bg-red-600 bg-opacity-10')

                        ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            (Math.abs(key % 2) == 1 ? 'bg-orange-700 bg-opacity-10' : 'bg-orange-600 bg-opacity-10') :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ?
                                (Math.abs(key % 2) == 1 ? 'bg-yellow-200 bg-opacity-20' : 'bg-yellow-100 bg-opacity-20') :
                                (Math.abs(key % 2) == 1 ? 'bg-green-700 bg-opacity-30 dark:bg-opacity-100' : 'bg-green-600 bg-opacity-30 dark:bg-opacity-100')
                            )
                        ),
                    ]"
                >
                <!-- route('singleclub', {
                    season: route().params.season,
                    club: n.host.club_id,
                    team: n.host_id,
                    league: n.league_id
                }) -->
                    <span v-if="rdw===1"><inertia-link :href="route('singleclub', {
                        season: route().params.season,
                        club: s.id,
                        league: $props.league.id,
                        team: s.team ? s.team.id : null,
                    })">{{s.name}}</inertia-link></span>
                    <span v-if="rdw===2"><inertia-link :href="route('singleclub', {
                        season: route().params.season,
                        club: s.id,
                        league: $props.league.id,
                        team: s.team ? s.team.id : null,
                    })">{{s.name}}</inertia-link></span>
                    <span v-if="rdw===3"><inertia-link :href="route('singleclub', {
                        season: route().params.season,
                        club: s.id,
                        league: $props.league.id,
                        team: s.team ? s.team.id : null,
                    })">{{s.name}}</inertia-link></span>
                </td>
                <!-- 3 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.start_values.match_played + s.host_wins + s.host_ties + s.host_lost + s.guest_wins + s.guest_ties + s.guest_lost }}</span>
                    <span v-if="rdw===2">{{s.start_values.match_played + s.host_wins + s.host_ties + s.host_lost}}</span>
                    <span v-if="rdw===3">{{s.start_values.match_played + s.guest_wins + s.guest_ties + s.guest_lost}}</span>
                </td>
                <!-- 4 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.start_values.points + s.host_points+s.guest_points}}</span>
                    <span v-if="rdw===2">{{s.start_values.points + s.host_points}}</span>
                    <span v-if="rdw===3">{{s.start_values.points + s.guest_points}}</span>
                </td>
                <!-- 5 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_wins + s.guest_wins}}-{{s.host_ties + s.guest_ties}}-{{s.host_lost + s.guest_lost}}</span>
                    <span v-if="rdw===2">{{s.host_wins}}-{{s.host_ties}}-{{s.host_lost}}</span>
                    <span v-if="rdw===3">{{s.guest_wins}}-{{s.guest_ties}}-{{s.guest_lost}}</span>
                </td>
                <!-- 6 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.start_values.goal_scored + s.host_goals + s.guest_goals}}-{{s.start_values.goal_conceded + s.host_goals_lost + s.guest_goals_lost}}

                    </span>
                    <span v-if="rdw===2">{{s.start_values.goal_scored + s.host_goals}}-{{s.start_values.goal_conceded + s.host_goals_lost}}</span>
                    <span v-if="rdw===3">{{s.start_values.goal_scored + s.guest_goals}}-{{s.start_values.goal_conceded + s.guest_goals_lost}}</span>
                    <!-- ({{ (s.start_values.goal_scored + s.host_goals + s.guest_goals) - (s.start_values.goal_conceded + s.host_goals_lost + s.guest_goals_lost) }}) -->
                </td>
                <!-- 7 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_wins + s.host_ties + s.host_lost}}</span>
                    <span v-if="rdw===2">{{s.host_wins + s.host_ties + s.host_lost}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 8 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_points}}</span>
                    <span v-if="rdw===2">{{s.host_points}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 9 -->
                <td class="px-2 whitespace-nowrap py-3">
                    <span v-if="rdw===1">{{s.host_wins}}-{{s.host_ties}}-{{s.host_lost}}</span>
                    <span v-if="rdw===2">{{s.host_wins}}-{{s.host_ties}}-{{s.host_lost}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 10 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_goals}}-{{s.host_goals_lost}}</span>
                    <span v-if="rdw===2">{{s.host_goals}}-{{s.host_goals_lost}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 11 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_wins + s.guest_ties + s.guest_lost}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_wins + s.guest_ties + s.guest_lost}}</span>
                </td>
                <!-- 12 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_points}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_points}}</span>
                </td>
                <!-- 13 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_goals}}-{{s.guest_goals_lost}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_goals}}-{{s.guest_goals_lost}}</span>
                </td>
                <!-- 14 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_wins}}-{{s.guest_ties}}-{{s.guest_lost}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_wins}}-{{s.guest_ties}}-{{s.guest_lost}}</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 3) }}
                    </span>
                    <span v-else>-</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 4) }}
                    </span>
                    <span v-else>-</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 5) }}
                    </span>
                    <span v-else>-</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 6) }}
                    </span>
                    <span v-else>-</span>
                </td>
            </tr>
        </table>
    </div>
    <div>
        <div class="flex flex-col md:flex-row w-full mb-3">
            <button
                class="flex items-center py-3 px-5"
                :class="rActive ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                @click="changeData(1)"
            >
                <SvgIcon name="refresh" width="18" height="18" class="mr-2" />
                Razem
            </button >
            <button
                class="flex items-center py-3 px-5"
                :class="dActive ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                @click="changeData(2)"
            >
                <SvgIcon name="home" width="18" height="18" class="mr-2" />
                Dom
            </button>
            <button
                class="flex items-center py-3 px-5"
                :class="wActive ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                @click="changeData(3)"
            >
                <SvgIcon name="arrow-circle-right" width="18" height="18" class="mr-2" />
                Wyjazd
            </button>
            <select
                name="wg"
                v-model="selectedSort"
                id="wg"
                class="dark:bg-gray-800 bg-gray-300 border-none focus:outline-none cursor-pointer"
                @change="sortBySelect"
            >
                <option value="1">wg. bilansu bramkowego</option>
                <option value="2">wg. spotkań bezpośrednich</option>
            </select>
        </div>
    </div>
    <p class="text-sm my-2">* Tabelka jest przewijana dla mniejszych urządzeń</p>

    <TinyContent :value="$props.league.addnotations"></TinyContent>

    <Roll class="w-full mt-4 mb-0" title="Legenda" :opened='false'>
        <div class="grid grid-cols-5 gap-5 col-span-12">
            <div class="col-span-5 md:col-span-1 text-sm">
                <p><strong>1</strong> - miejsce</p>
                <p><strong>2</strong> - drużyna </p>
                <p><strong>3</strong> - rozegrane mecze</p>
                <p><strong>4</strong> - punkty</p>
            </div>
            <div class="col-span-5 md:col-span-1 text-sm">
                <p><strong>5</strong> - zwycięstwa-remisy-porażki</p>
                <p><strong>6</strong> - bramki strzelone-stracone </p>
                <p><strong>7</strong> - mecze u siebie</p>
                <p><strong>8</strong> - punkty u siebie</p>
            </div>
            <div class="col-span-5 md:col-span-1 text-sm">
                <p><strong>9</strong> - zwycięstwa-remisy-porażki u siebie</p>
                <p><strong>10</strong> - bramki strzelone-stracone u siebie </p>
                <p><strong>11</strong> - mecze na wyjeździe</p>
                <p><strong>12</strong> - punkty na wyjeździe</p>
            </div>
            <div class="col-span-5 md:col-span-1 text-sm">
                <p><strong>13</strong> - zwycięstwa-remisy-porażki na wyjeździe</p>
                <p><strong>14</strong> - bramki strzelone-stracone na wyjeździe </p>
            </div>
        </div>
    </Roll>

    <div></div>

    <Modal
        v-if="expand"
        class="overflow-auto"
        @update="expand = $event"
        modal-class="w-full"
    >

        <div class="flex w-full mb-2">
            <div v-if="divide" class="flex">
                <button
                class="flex items-center py-3 px-5"
                :class="trr==1 ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                @click="trr=1"
                >
                    Tabela
                </button >
                <button
                class="flex items-center py-3 px-5"
                :class="trr==2 ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                @click="trr=2"
                >
                    Runda I
                </button>
                <button
                class="flex items-center py-3 px-5"
                :class="trr==3 ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                @click="trr=3"
                >
                    Runda II
                </button>
            </div>
        </div>


        <div class="overflow-x-scroll xl:overflow-auto">
        <table class="text-sm w-full text-center text-white dark:bg-green-700 bg-green-600">
            <tr class="dark:bg-gray-800 bg-gray-300 text-black dark:text-white" >
                <td
                    class=" text-sm font-bold py-2"
                    v-for="n in 15"
                    :key="n"
                    :colspan="n == 15 ? '4' : '1'"
                >{{n == 15 ? 'MECZE BEZPOŚREDNIE' : n}}</td>
            </tr>
            <tr
                class="text-white"
                v-for="(s, key) in tableResult" :key="key"

                :class="[
                    $props.league && (key >= (tableResult.length - $props.league.teams_drop)) ?
                        'bg-red-700 odd:bg-red-800 bg-opacity-60 odd:bg-opacity-60' :
                            (
                                (key < (tableResult.length - $props.league.teams_drop) && key >= (tableResult.length - ($props.league.teams_drop + $props.league.teams_drop_play_off))) ?
                                    'bg-rose-800 odd:bg-rose-900 bg-opacity-70 odd:bg-opacity-70' :
                                    (
                                        key+1 <= ($props.league.teams_promo) ?
                                            'bg-orange-500 odd:bg-orange-600 bg-opacity-60 odd:bg-opacity-60' :
                                            (
                                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ?
                                                    'bg-yellow-300 odd:bg-yellow-400 bg-opacity-60 odd:bg-opacity-60' :
                                                    'dark:odd:bg-green-800 odd:bg-green-700'
                                            )
                                    )
                            ),

                ]"
            >
                <!-- 1 -->
                <td class="px-2 whitespace-nowrap  py-3 text-white">{{ key+1 }}</td>
                <!-- 2 -->
                <td class="px-1  py-3" style="max-width: 9rem"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop) ||
                            (
                                key < ( tableResult.length - $props.league.teams_drop ) &&
                                key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                            )
                        ) ? (
                            (
                                key < ( tableResult.length - $props.league.teams_drop ) &&
                                key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                            ) ?
                            (Math.abs(key % 2) == 1 ? 'bg-rose-700 bg-opacity-10' : 'bg-rose-600 bg-opacity-10') :
                            (Math.abs(key % 2) == 1 ? 'bg-red-700 bg-opacity-10' : 'bg-red-600 bg-opacity-10')

                        ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            (Math.abs(key % 2) == 1 ? 'bg-orange-700 bg-opacity-10' : 'bg-orange-600 bg-opacity-10') :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ?
                                (Math.abs(key % 2) == 1 ? 'bg-yellow-200 bg-opacity-20' : 'bg-yellow-100 bg-opacity-20') :
                                (Math.abs(key % 2) == 1 ? 'bg-green-700 bg-opacity-30 dark:bg-opacity-100' : 'bg-green-600 bg-opacity-30 dark:bg-opacity-100')
                            )
                        ),
                    ]"
                >
                    <span v-if="rdw===1"><inertia-link :href="route('singleclub', {
                        season: route().params.season,
                        club: s.id,
                        league: $props.league.id,
                        team: s.team ? s.team.id : null,
                    })">{{s.name}}</inertia-link></span>
                    <span v-if="rdw===2"><inertia-link :href="route('singleclub', {
                        season: route().params.season,
                        club: s.id,
                        league: $props.league.id,
                        team: s.team ? s.team.id : null,
                    })">{{s.name}}</inertia-link></span>
                    <span v-if="rdw===3"><inertia-link :href="route('singleclub', {
                        season: route().params.season,
                        club: s.id,
                        league: $props.league.id,
                        team: s.team ? s.team.id : null,
                    })">{{s.name}}</inertia-link></span>
                </td>
                <!-- 3 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.start_values.match_played + s.host_wins + s.host_ties + s.host_lost + s.guest_wins + s.guest_ties + s.guest_lost }}</span>
                    <span v-if="rdw===2">{{s.start_values.match_played + s.host_wins + s.host_ties + s.host_lost}}</span>
                    <span v-if="rdw===3">{{s.start_values.match_played + s.guest_wins + s.guest_ties + s.guest_lost}}</span>
                </td>
                <!-- 4 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.start_values.points + s.host_points+s.guest_points}}</span>
                    <span v-if="rdw===2">{{s.start_values.points + s.host_points}}</span>
                    <span v-if="rdw===3">{{s.start_values.points + s.guest_points}}</span>
                </td>
                <!-- 5 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_wins + s.guest_wins}}-{{s.host_ties + s.guest_ties}}-{{s.host_lost + s.guest_lost}}</span>
                    <span v-if="rdw===2">{{s.host_wins}}-{{s.host_ties}}-{{s.host_lost}}</span>
                    <span v-if="rdw===3">{{s.guest_wins}}-{{s.guest_ties}}-{{s.guest_lost}}</span>
                </td>
                <!-- 6 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.start_values.goal_scored + s.host_goals + s.guest_goals}}-{{s.start_values.goal_conceded + s.host_goals_lost + s.guest_goals_lost}}

                    </span>
                    <span v-if="rdw===2">{{s.start_values.goal_scored + s.host_goals}}-{{s.start_values.goal_conceded + s.host_goals_lost}}</span>
                    <span v-if="rdw===3">{{s.start_values.goal_scored + s.guest_goals}}-{{s.start_values.goal_conceded + s.guest_goals_lost}}</span>
                    <!-- ({{ (s.start_values.goal_scored + s.host_goals + s.guest_goals) - (s.start_values.goal_conceded + s.host_goals_lost + s.guest_goals_lost) }}) -->
                </td>
                <!-- 7 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_wins + s.host_ties + s.host_lost}}</span>
                    <span v-if="rdw===2">{{s.host_wins + s.host_ties + s.host_lost}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 8 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_points}}</span>
                    <span v-if="rdw===2">{{s.host_points}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 9 -->
                <td class="px-2 whitespace-nowrap py-3">
                    <span v-if="rdw===1">{{s.host_wins}}-{{s.host_ties}}-{{s.host_lost}}</span>
                    <span v-if="rdw===2">{{s.host_wins}}-{{s.host_ties}}-{{s.host_lost}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 10 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.host_goals}}-{{s.host_goals_lost}}</span>
                    <span v-if="rdw===2">{{s.host_goals}}-{{s.host_goals_lost}}</span>
                    <span v-if="rdw===3">-</span>
                </td>
                <!-- 11 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_wins + s.guest_ties + s.guest_lost}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_wins + s.guest_ties + s.guest_lost}}</span>
                </td>
                <!-- 12 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_points}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_points}}</span>
                </td>
                <!-- 13 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_goals}}-{{s.guest_goals_lost}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_goals}}-{{s.guest_goals_lost}}</span>
                </td>
                <!-- 14 -->
                <td class="px-2 whitespace-nowrap  py-3">
                    <span v-if="rdw===1">{{s.guest_wins}}-{{s.guest_ties}}-{{s.guest_lost}}</span>
                    <span v-if="rdw===2">-</span>
                    <span v-if="rdw===3">{{s.guest_wins}}-{{s.guest_ties}}-{{s.guest_lost}}</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 3) }}
                    </span>
                    <span v-else>-</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 4) }}
                    </span>
                    <span v-else>-</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 5) }}
                    </span>
                    <span v-else>-</span>
                </td>
                <td
                    class="px-2 whitespace-nowrap py-3 dark:bg-opacity-20 bg-opacity-20"
                    :class="[
                        $props.league &&
                        (key >= (tableResult.length - $props.league.teams_drop)) ||
                        (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? (
                            (
                            key < ( tableResult.length - $props.league.teams_drop ) &&
                            key >= ( tableResult.length - ( $props.league.teams_drop + $props.league.teams_drop_play_off ))
                        ) ? 'bg-rose-900' : 'bg-red-900' ) : (
                            key+1 <= ($props.league.teams_promo) ?
                            'bg-orange-900' :
                            (
                                key+1 > ($props.league.teams_promo) && (key+1 <= ($props.league.teams_promo + $props.league.teams_promo_play_off)) ? 'bg-yellow-500' : 'bg-green-900'
                            )
                        )
                    ]"
                >
                    <span v-if="getDirect(s)">
                        {{ getTableValue(getDirect(s, true), 6) }}
                    </span>
                    <span v-else>-</span>
                </td>
            </tr>
        </table>
        </div>
        <div>
            <div class="flex flex-col md:flex-row w-full mb-3">
                <button
                    class="flex items-center py-3 px-5"
                    :class="rActive ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                    @click="changeData(1)"
                >
                    <SvgIcon name="refresh" width="18" height="18" class="mr-2" />
                    Razem
                </button >
                <button
                    class="flex items-center py-3 px-5"
                    :class="dActive ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                    @click="changeData(2)"
                >
                    <SvgIcon name="home" width="18" height="18" class="mr-2" />
                    Dom
                </button>
                <button
                    class="flex items-center py-3 px-5"
                    :class="wActive ? 'dark:bg-gray-700 bg-gray-200 shadow' :  'dark:bg-gray-800 bg-gray-300'"
                    @click="changeData(3)"
                >
                    <SvgIcon name="arrow-circle-right" width="18" height="18" class="mr-2" />
                    Wyjazd
                </button>
                <!-- {{ leagues }} -->
                <select v-model="selectedSort" name="wg" id="wg" class="dark:bg-gray-800 bg-gray-300 border-none focus:outline-none cursor-pointer" @change="sortBySelect">
                    <option  value="1">wg. bilansu bramkowego</option>
                    <option  value="2">wg. spotkań bezpośrednich</option>

                </select>
            </div>
        </div>

        <p class="text-sm my-2"> Tabelka jest przewijana dla mniejszych urządzeń</p>
        <Roll class="w-full mt-4 mb-0" title="Legenda" :opened='true'>
            <div class="grid grid-cols-5 gap-5 col-span-12">
                <div class="col-span-5 md:col-span-1 text-sm">
                    <p><strong>1</strong> - miejsce</p>
                    <p><strong>2</strong> - drużyna </p>
                    <p><strong>3</strong> - rozegrane mecze</p>
                    <p><strong>4</strong> - punkty</p>
                </div>
                <div class="col-span-5 md:col-span-1 text-sm">
                    <p><strong>5</strong> - zwycięstwa-remisy-porażki</p>
                    <p><strong>6</strong> - bramki strzelone-stracone </p>
                    <p><strong>7</strong> - mecze u siebie</p>
                    <p><strong>8</strong> - punkty u siebie</p>
                </div>
                <div class="col-span-5 md:col-span-1 text-sm">
                    <p><strong>9</strong> - zwycięstwa-remisy-porażki u siebie</p>
                    <p><strong>10</strong> - bramki strzelone-stracone u siebie </p>
                    <p><strong>11</strong> - mecze na wyjeździe</p>
                    <p><strong>12</strong> - punkty na wyjeździe</p>
                </div>
                <div class="col-span-5 md:col-span-1 text-sm">
                    <p><strong>13</strong> - zwycięstwa-remisy-porażki na wyjeździe</p>
                    <p><strong>14</strong> - bramki strzelone-stracone na wyjeździe </p>
                </div>
            </div>
        </Roll>
    </Modal>

</template>

<script>
import { ref, onMounted, nextTick, watch, computed } from 'vue'
import Roll from '@/Components/rollUp'
import SvgIcon from '@/Components/SvgIcon'
import Modal from '@/Components/Modal'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import TinyContent from '@/Components/TinyContent';

export default {
    components:{
        Roll,
        SvgIcon,
        Modal,
        TinyContent
    },
    props: {
        scores: [Array, Object],
        queues: [Array, Object],
        leagues: [Array, Object],
        league: [Object, Array],
        divide: {
            type: Boolean,
            default: false
        },
        loadingData: [Boolean],
    },
    setup(props) {
        const expand = ref(false);
        const loading = ref(false);
        const idTable = 'table-load-' + props.league.id;

        nextTick(() => {
            if(document.getElementById(idTable)){
            document.getElementById(idTable).insertAdjacentHTML('afterbegin', `
            <div class="loading-table absolute flex items-center justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50">
                <div class="font-bold text-sm flex items-center justify-center flex-col">
                    <p>Ładowanie</p>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>`)
            }
        })

        const showExpand = () => {
            expand.value = true;
        }
        // zmienna Tabela Runda I Runda II
        const trr = ref(1);
        // zmienna Razem, Dom, Wyjazd
        const rdw = ref(1);

        const rActive = ref(true);
        const dActive = ref(false);
        const wActive = ref(false);
        const selectedSort = props.league.face_to_face != 0 ? ref(2) : ref(1);

        const sortBySelect = (e) => {
            selectedSort.value = e.target.value
            changeData(rdw.value, selectedSort.value)
        }
        // console.log(props.scores, props.queues)
        watch(() => props.scores, (val, old) => {
            sortedScores.value = val;
        })
        const sortedScores = ref(props.scores);
        const tableResult = computed(() => {
            return getScores();
        })

        // podmiana datki
        const changeData = (index, sortType) => {
            rdw.value=index;
            rActive.value=false;
            dActive.value=false;
            wActive.value=false;

            if (index===2){
                dActive.value=true;
            }
            else if (index===3){
                wActive.value=true;
            }
            else {
                rActive.value=true;
            }
        }
        function getDirect(element, value = false){
            let find = setDirect.value.find(e => e.find(el => el.id == element.id));
            let hasMatch = element.start_values.match_played + element.host_wins + element.host_ties + element.host_lost + element.guest_wins + element.guest_ties + element.guest_lost;

            if(find && hasMatch){
                if(!value) return find;
                else return find.find(e => e.id == element.id)
            }
            else return null;
        }
        function getTableValue(el, type){
            let buf = JSON.parse(JSON.stringify(el))
            if(rdw.value == 2) {
                buf['guest_goals'] = 0;
                buf['guest_goals_lost'] = 0;
                buf['guest_points'] = 0;
            }
            else if(rdw.value == 3){
                buf['host_goals'] = 0;
                buf['host_goals_lost'] = 0;
                buf['host_points'] = 0;
            }
            let result = 0;
            if(type == 3){
                result = buf.host_wins + buf.host_ties + buf.host_lost + buf.guest_wins + buf.guest_ties + buf.guest_lost
            }
            else if(type == 4) {
                result = buf.host_points + buf.guest_points;
            }
            else if(type == 5){
                result = `${buf.host_wins + buf.guest_wins}-${buf.host_ties + buf.guest_ties}-${buf.host_lost + buf.guest_lost}`;
            }
            else if(type == 6){
                result = `${buf.host_goals + buf.guest_goals}-${buf.host_goals_lost + buf.guest_goals_lost}`;
                // (${(buf.host_goals + buf.guest_goals) - (buf.host_goals_lost + buf.guest_goals_lost)})
            }
            return result;
        }
        function getBuf(s, first = true){
        let buf = {
                id: s.id,
                name: s.name,
            };

            if(first){
                let startValues = {
                    points: 0,
                    goal_scored: 0,
                    goal_conceded: 0,
                    match_played: 0,
                    predifined_place: null,
                }
                if(s.start_values){
                    startValues.points = s.start_values.points ? s.start_values.points : 0;
                    startValues.goal_scored = s.start_values.goal_scored ? s.start_values.goal_scored : 0;
                    startValues.goal_conceded = s.start_values.goal_conceded ? s.start_values.goal_conceded : 0;
                    startValues.match_played = s.start_values.match_played ? s.start_values.match_played : 0;
                    startValues.predifined_place = s.start_values.predifined_place;
                }

                buf.start_values = startValues;
            }
            buf.direct = null;
            buf.direct = s.direct ? s.direct : null;

            buf.team = s.team ? s.team : null;

            if (trr.value == 2) {
                buf.host_goals = s[0].host_goals
                buf.guest_goals = s[0].guest_goals
                buf.host_goals_lost = s[0].host_goals_lost
                buf.guest_goals_lost = s[0].guest_goals_lost
                buf.host_wins = s[0].host_wins
                buf.host_ties = s[0].host_ties
                buf.guest_ties = s[0].guest_ties
                buf.host_lost = s[0].host_lost
                buf.guest_lost = s[0].guest_lost
                buf.guest_wins = s[0].guest_wins
                buf.host_points = s[0].host_points
                buf.guest_points = s[0].guest_points
            }
            else if (trr.value == 3) {
                buf.host_goals = s[1].host_goals
                buf.guest_goals = s[1].guest_goals
                buf.host_goals_lost = s[1].host_goals_lost
                buf.guest_goals_lost = s[1].guest_goals_lost
                buf.host_wins = s[1].host_wins
                buf.host_ties = s[1].host_ties
                buf.guest_ties = s[1].guest_ties
                buf.host_lost = s[1].host_lost
                buf.guest_lost = s[1].guest_lost
                buf.guest_wins = s[1].guest_wins
                buf.host_points = s[1].host_points
                buf.guest_points = s[1].guest_points
            }
            else {
                buf.host_goals = s[0].host_goals + s[1].host_goals
                buf.guest_goals = s[0].guest_goals + s[1].guest_goals
                buf.host_goals_lost = s[0].host_goals_lost + s[1].host_goals_lost
                buf.guest_goals_lost = s[0].guest_goals_lost + s[1].guest_goals_lost
                buf.host_wins = s[0].host_wins + s[1].host_wins
                buf.host_ties = s[0].host_ties + s[1].host_ties
                buf.guest_ties = s[0].guest_ties + s[1].guest_ties
                buf.host_lost = s[0].host_lost + s[1].host_lost
                buf.guest_lost = s[0].guest_lost + s[1].guest_lost
                buf.guest_wins = s[0].guest_wins + s[1].guest_wins
                buf.host_points = s[0].host_points + s[1].host_points
                buf.guest_points = s[0].guest_points + s[1].guest_points
            }
            return buf;
        }

        function sortDirectSum(values, a, buf = false){
            var newResult = [
                {
                'host_goals': 0,
                'guest_goals': 0,
                'host_goals_lost': 0,
                'guest_goals_lost': 0,
                'host_wins': 0,
                'host_ties': 0,
                'guest_ties': 0,
                'host_lost': 0,
                'guest_lost': 0,
                'guest_wins': 0,
                'host_points': 0,
                'guest_points': 0,
                },
                {
                'host_goals': 0,
                'guest_goals': 0,
                'host_goals_lost': 0,
                'guest_goals_lost': 0,
                'host_wins': 0,
                'host_ties': 0,
                'guest_ties': 0,
                'host_lost': 0,
                'guest_lost': 0,
                'guest_wins': 0,
                'host_points': 0,
                'guest_points': 0,
                }
            ];
            let season = 0;
            values.forEach((el, key) => {
                let start_date_queue = props.queues.find(e => e.id == el.queue_id).start_date;
                let year = parseInt( new Date(start_date_queue).getFullYear() );
                let season_year = parseInt(props.league.season.year);

                if (parseInt(props.league.season.type)) season = year == season_year ? 0 : 1;
                else season = year == season_year ? 1 : 0;
                if(new Date(start_date_queue) <= new Date()){
                    // console.log(a, el.host.club_name, el.guest.club_name)
                    if (a.id == el.host_club_id) {
                        if (el.walkover == 'for_host') {
                            newResult[season]['host_goals'] += 3;
                            newResult[season]['host_points'] += 3;
                        }
                        else if (el.walkover == 'for_guest') {
                            newResult[season]['host_lost'] += 1;
                            newResult[season]['host_goals_lost'] += 3;
                        }
                        else if(el.host_goals !== null && el.guest_goals !== null){
                            newResult[season]['host_goals'] += el.host_goals;
                            newResult[season]['host_goals_lost'] += el.guest_goals;

                            if (el.host_goals > el.guest_goals && el.host_goals !== null) {
                                newResult[season]['host_wins'] += 1;
                                newResult[season]['host_points'] += 3;
                            }
                            else if (el.host_goals == el.guest_goals && el.host_goals !== null) {
                                newResult[season]['host_ties'] += 1;
                                newResult[season]['host_points'] += 1;
                            }
                            else if(el.host_goals === null){
                                newResult[season]['host_wins'] += 0;
                                newResult[season]['host_points'] += 0;
                                newResult[season]['host_ties'] += 0;
                                newResult[season]['host_points'] += 0;
                            }
                            else newResult[season]['host_lost'] += 1;
                        }
                    }
                    else {

                        if (el.walkover == 'for_guest') {
                            newResult[season]['guest_goals'] += 3;
                            newResult[season]['guest_points'] += 3;
                        }
                        else if (el.walkover == 'for_host') {
                            newResult[season]['guest_lost'] += 1;
                            newResult[season]['guest_goals_lost'] += 3;
                        }
                        else if(el.host_goals !== null && el.guest_goals !== null){

                            newResult[season]['guest_goals'] += el.guest_goals;
                            newResult[season]['guest_goals_lost'] += el.host_goals;

                            if (el.guest_goals > el.host_goals && el.guest_goals !== null) {
                                    newResult[season]['guest_wins'] += 1;
                                    newResult[season]['guest_points'] += 3;
                                }
                                else if (el.guest_goals == el.host_goals && el.guest_goals !== null) {
                                    newResult[season]['guest_ties'] += 1;
                                    newResult[season]['guest_points'] += 1;
                                }
                                else if(el.guest_goals === null){
                                    newResult[season]['host_wins'] += 0;
                                    newResult[season]['host_points'] += 0;
                                    newResult[season]['host_ties'] += 0;
                                    newResult[season]['host_points'] += 0;
                                }
                                else newResult[season]['guest_lost'] += 1;
                        }
                    }
                }
            });
            // console.log(newResult)
            let arr = {};
            arr = newResult;
            arr['id'] = a.id;
            arr['name'] = a.name;

            if(buf) return getBuf(arr, false);
            else return arr;
        }
        watch(() => props.loadingData, (val) => {
            // if(val) loading.value = true;

        })
        const getScores = () => {
            // loading.value = true;

            let result = [];
            if(sortedScores.value && sortedScores.value.length){
                sortedScores.value.forEach((s) => {
                    result.push(getBuf(s, true));
                })
            }
            setDirect.value = [];

            result.sort(function(a, b) {
                let aStart = a['start_values'];
                let bStart = b['start_values'];

                if(rdw.value == 2) {
                    a['guest_goals'] = 0; a['guest_goals_lost'] = 0; a['guest_points'] = 0;
                    b['guest_goals'] = 0; b['guest_goals_lost'] = 0; b['guest_points'] = 0;
                }
                else if(rdw.value == 3){
                    a['host_goals'] = 0; a['host_goals_lost'] = 0; a['host_points'] = 0;
                    b['host_goals'] = 0; b['host_goals_lost'] = 0; b['host_points'] = 0;
                }

                let aPoints = (aStart['points'] + a['host_points'] + a['guest_points']);
                let bPoints = (bStart['points'] + b['host_points'] + b['guest_points']);

                    if (aPoints < bPoints){
                        return 1;
                    }
                    else if (aPoints == bPoints) {
                        // Punkty równe
                        if(selectedSort.value == 1){
                            if (
                                (
                                    (aStart['goal_scored'] + a['host_goals'] + a['guest_goals']) -
                                    (aStart['goal_conceded'] + a['host_goals_lost'] + a['guest_goals_lost'])
                                ) > (
                                    (bStart['goal_scored'] + b['host_goals'] + b['guest_goals']) -
                                    (bStart['goal_conceded'] + b['host_goals_lost'] + b['guest_goals_lost'])
                                )
                            ) {
                                return -1;
                            }
                            else if (
                                (
                                    (aStart['goal_scored'] + a['host_goals'] + a['guest_goals']) -
                                    (aStart['goal_conceded'] + a['host_goals_lost'] + a['guest_goals_lost'])
                                ) == (
                                    (bStart['goal_scored'] + b['host_goals'] + b['guest_goals']) -
                                    (bStart['goal_conceded'] + b['host_goals_lost'] + b['guest_goals_lost'])
                                )
                            ){
                                // Punkty i bilans bramkowy równe
                                if(
                                    (aStart['goal_scored'] + a['host_goals'] + a['guest_goals']) >
                                    (bStart['goal_scored'] + b['host_goals'] + b['guest_goals'])
                                ){
                                    // Więcej bramek
                                    return -1;
                                }
                                else {
                                    if(
                                        (aStart['goal_scored'] + a['host_goals'] + a['guest_goals']) ==
                                        (bStart['goal_scored'] + b['host_goals'] + b['guest_goals'])
                                    ){
                                        // Dalsza równość
                                        return sortDirect(a, b, false);
                                    }
                                    else return 1;
                                }
                            }

                            else return 1;
                        }
                        else return sortDirect(a, b, true);
                    }
                    else return -1;
            });

            let arr = [];

            // finde and remove place
            let cloded_result = JSON.parse(JSON.stringify(result));
            result
            .filter(el => el.start_values && el.start_values.predifined_place)
            .forEach((e) => {
                let clonedIndex = cloded_result.map((el, i) => i === (e.start_values.predifined_place - 1) ? i : '').filter(e => e !== '')[0];
                let clonedFirstIndex = cloded_result.map((el, i) => el.id == e.id ? i : '').filter(e => e !== '')[0];

                if(clonedIndex === undefined){
                    clonedIndex = cloded_result.length - 1;
                }

                let firstIndex = result.map((el, i) => el.id == e.id ? i : '').filter(e => e !== '')[0];

                arr.push({ key: clonedIndex, index: clonedFirstIndex, el: e });
                result.splice(firstIndex, 1);
            })

            //set predefined placeZ
            arr.sort((a,b) => a.key > b.key ? 1 : -1)
            .forEach((e, key) => {
                result.splice(e.key, 0, e.el);
            })

           nextTick(() => {
                if(
                    (sortedScores.value && sortedScores.value.length)
                    // (sortedScores.value && !sortedScores.value.length)
                ){
                    setTimeout(() => {
                        document.querySelectorAll('.loading-table').forEach(e => e.remove());
                    }, 500)
                }
                else if(sortedScores.value){
                    if(sortedScores.value.length == 0){
                         setTimeout(() => {
                            document.querySelectorAll('.loading-table').forEach(e => e.remove());
                        }, 500)
                    }
                }
           })

            return result;
        }
        const setDirect = ref([]);
        function sortDirect(a, b, variantB = false){
            // Wyliczenie meczów bezpośrednich

            // if(sortedScores.value && sortedScores.value.length){
            //     return 0;
            // }

            // START
            let aTest = JSON.parse(JSON.stringify(a));

            if(rdw.value == 2) {
                aTest['guest_goals'] = 0;
                aTest['guest_goals_lost'] = 0;
                aTest['guest_points'] = 0;
            }
            else if(rdw.value == 3){
                aTest['host_goals'] = 0;
                aTest['host_goals_lost'] = 0;
                aTest['host_points'] = 0;
            }

            let direct = props.scores.filter(e => {

                if(rdw.value == 2) {
                    e['guest_goals'] = 0; e['guest_goals_lost'] = 0; e['guest_points'] = 0;
                }
                else if(rdw.value == 3){
                    e['host_goals'] = 0; e['host_goals_lost'] = 0; e['host_points'] = 0;
                }
                if(
                    (
                        getBuf(e)['start_values']['points'] +
                        getBuf(e)['host_points'] +
                        getBuf(e)['guest_points']
                    ) == (
                        aTest['start_values']['points'] +
                        aTest['host_points'] +
                        aTest['guest_points']
                    )
                ){
                    if(!variantB){
                        if(
                            (
                                (getBuf(e)['start_values']['goal_scored'] + getBuf(e)['host_goals'] + getBuf(e)['guest_goals']) -
                                (getBuf(e)['start_values']['goal_conceded'] + getBuf(e)['host_goals_lost'] + getBuf(e)['guest_goals_lost'])
                            ) == (
                                (aTest['start_values']['goal_scored'] + aTest['host_goals'] + aTest['guest_goals']) -
                                (aTest['start_values']['goal_conceded'] + aTest['host_goals_lost'] + aTest['guest_goals_lost'])
                            )
                        ){
                            return true;
                        }
                    }
                    else return true;
                }
            })

            if(direct.length > 1){
                // get all queue elements
                let findDirect = [];
                JSON.parse(JSON.stringify(props.queues))
                .map(e => e.items)
                .forEach((el) => {
                    findDirect = findDirect.concat(el);
                })

                let matchs = findDirect.filter(e => (
                    direct.map(el => el.id).includes(e.host_club_id)
                    ) && (
                        direct.map(el => el.id).includes(e.guest_club_id)
                    )
                );

                let afterBuf = [];
                direct.forEach(e => {
                    afterBuf.push(sortDirectSum(matchs.filter(el => el.host_club_id == e.id || el.guest_club_id == e.id), e, true))
                })
                setDirect.value.push(afterBuf);
            // END

            let aSort = getDirect(a, true);
            let bSort = getDirect(b, true);

            if(aSort && bSort){
                if ((aSort['host_points'] + aSort['guest_points']) > (bSort['host_points'] + bSort['guest_points'])) {
                    return -1;
                }
                // Równość pkt bezpośrednie
                else if((aSort['host_points'] + aSort['guest_points']) == (bSort['host_points'] + bSort['guest_points'])){

                    // Bilans bezpośrednie
                    if (
                        (
                            (aSort['host_goals'] + aSort['guest_goals']) -
                            (aSort['host_goals_lost'] + aSort['guest_goals_lost'])
                        ) > (
                            (bSort['host_goals'] + bSort['guest_goals']) -
                            (bSort['host_goals_lost'] + bSort['guest_goals_lost'])
                        )
                    ) return -1;
                    // bilans i pkt równe bezpośrednie
                    else if (
                        (
                            (aSort['host_goals'] + aSort['guest_goals']) -
                            (aSort['host_goals_lost'] + aSort['guest_goals_lost'])
                        ) == (
                            (bSort['host_goals'] + bSort['guest_goals']) -
                            (bSort['host_goals_lost'] + bSort['guest_goals_lost'])
                        )
                    ){
                        if(
                            (a['start_values']['goal_scored'] + a['host_goals'] + a['guest_goals']) >
                            (b['start_values']['goal_scored'] + b['host_goals'] + b['guest_goals'])
                        ){
                            // Więcej bramek
                            return -1;
                        }
                        else if(variantB){
                            // Idealna równość
                            if(
                                (aSort['host_goals'] + aSort['guest_goals']) == (bSort['host_goals'] + bSort['guest_goals']) &&
                                (aSort['host_goals_lost'] + aSort['guest_goals_lost']) == (bSort['host_goals_lost'] + bSort['guest_goals_lost'])
                            ){
                                // Bilans kolumna 6
                                if (
                                    (
                                        (a['start_values']['goal_scored'] + a['host_goals'] + a['guest_goals']) -
                                        (a['start_values']['goal_conceded'] + a['host_goals_lost'] + a['guest_goals_lost'])
                                    ) > (
                                        (b['start_values']['goal_scored'] + b['host_goals'] + b['guest_goals']) -
                                        (b['start_values']['goal_conceded'] + b['host_goals_lost'] + b['guest_goals_lost'])
                                    )
                                ) {
                                    return -1;
                                }
                                else return 1;
                            }
                        }
                        else return 1;
                    }
                    else return 1;
                }
                else return 1;
                }
            }
        }

        return {
            trr,
            rdw,
            changeData,
            rActive,
            wActive,
            dActive,
            sortBySelect,
            selectedSort,
            getScores,
            showExpand,
            expand,
            tableResult,

            getDirect,
            setDirect,
            getTableValue,

            loading,
        }
    },
}
</script>

<style scoped>

</style>
