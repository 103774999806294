<template>
    <AdminLayout>
        <div>

            <!-- <Link @click="sendForm()">Test</Link> -->

            <div class="px-6 my-3 grid lg:grid-cols-2 grid-cols-1 gap-3">
                <div class="relative">
                    <InputLabel
                    placeholder="Nazwa strony"
                    label="Nazwa strony"
                    id="app_name"
                    name="app_name"
                    type="text"
                    v-model="settings.app_name"
                    />
                    <div v-if="settings.app_name != options.app_name" @click="changeOption('app_name', settings.app_name)" class="bg-green-600 text-white px-4 py-1 rounded-md absolute right-2 top-1/2 cursor-pointer">Zapisz</div>
                </div>

                <div class="relative">
                    <InputLabel
                    placeholder="Email"
                    label="Email"
                    id="email"
                    name="email"
                    type="text"
                    v-model="settings.email"
                    />
                    <div v-if="settings.email != options.email" @click="changeOption('email', settings.email)" class="bg-green-600 text-white px-4 py-1 rounded-md absolute right-2 top-1/2 cursor-pointer">Zapisz</div>
                </div>

                <div class="relative">
                    <InputLabel
                    placeholder="Phone"
                    label="Phone"
                    id="phone"
                    name="phone"
                    type="text"
                    v-model="settings.phone"
                    />
                    <div v-if="settings.phone != options.phone" @click="changeOption('phone', settings.phone)" class="bg-green-600 text-white px-4 py-1 rounded-md absolute right-2 top-1/2 cursor-pointer">Zapisz</div>
                </div>

                <div class="relative">
                    <InputLabel
                    placeholder="Adres"
                    label="Adres"
                    id="address"
                    name="address"
                    type="text"
                    v-model="settings.address"
                    />
                    <div v-if="settings.address != options.address" @click="changeOption('address', settings.address)" class="bg-green-600 text-white px-4 py-1 rounded-md absolute right-2 top-1/2 cursor-pointer">Zapisz</div>
                </div>

                <div class="relative col-start-1">
                    <ListboxNew
                        label="Motyw domyślny"
                        :data="[
                            { slug: 'dark', name: 'Ciemny' },
                            { slug: 'light', name: 'Jasny' }
                        ]"
                        index="slug"
                        v-model="settings.site_mode"
                        id="system_mode"
                    />
                    <div
                        v-if="settings.site_mode != options.site_mode"
                        @click="changeOption('site_mode', settings.site_mode)"
                        class="bg-green-600 text-white px-4 py-1 rounded-md absolute right-2 top-1/2 cursor-pointer"
                    >Zapisz</div>
                </div>

                <div class=" col-start-1">
                    <p class="text-lg dark:text-white">Logo</p>
                    <ImageUpload
                        class="-mt-4"
                        @imagesUploaded="changeImage"
                        :image="image ? image.encoded : null"
                    />

                    <Link
                        type="button"
                        class="mt-3"
                        @click="changeLogo()"
                        color="green"
                    >Zapisz logo</Link>
                </div>

                <div class="">
                    <p class="text-lg dark:text-white">Brak grafiki</p>
                    <ImageUpload
                        class="-mt-4"
                        id="no_image"
                        @imagesUploaded="e => no_image = e"
                        :image="no_image ? no_image.encoded : null"
                    />

                    <Link
                        type="button"
                        class="mt-3"
                        @click="changeNoImage()"
                        color="green"
                    >Zapisz grafikę</Link>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import ListboxNew from '@/Components/ListboxNew'
    import { ref } from '@vue/reactivity';
    import { Inertia } from '@inertiajs/inertia'
    import ImageUpload from '@/Components/ImageUpload';

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            ListboxNew,
            ImageUpload
        },
        props: {
            options: [Array, Object],
            categories: [Array, Object],
            image: [Array, Object],
            no_image: [Array, Object],
        },
        setup(props) {
            const settings = ref(JSON.parse(JSON.stringify(props.options)));
            const image = ref(props.image ? props.image : null);
            const no_image = ref(props.no_image ? props.no_image : null);

            settings.value.site_mode = settings.value.site_mode ? settings.value.site_mode : 'light';

            const changeOption = (name, value) => {
                Inertia.post(route('admin.change.option'), {
                    name: name,
                    value: value
                });
            }

            const changeImage = (e) => {
               image.value = e;
            }

            function changeLogo(e){
                Inertia.post(route('admin.change.logo'), {
                    image: image.value,
                });
            }

            function changeNoImage(e){
                Inertia.post(route('admin.change.no_image'), {
                    image: no_image.value,
                });
            }


            return {
                changeOption,
                settings,

                image,
                no_image,

                changeImage,
                changeLogo,
                changeNoImage,
            }
        }
    }
</script>
