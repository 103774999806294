<template>
<div class="relative font-bold">
    <input
        type="time"
        class="w-full relative group text-left rounded-none border-0 border-b-2 px-4 py-2 outline-none focus:outline-none focus:ring-0 bg-white dark:bg-gray-300 dark:border-gray-300 border-gray-400 dark:hover:border-gray-200 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600"
        @click="openTimepicker"
        @change="changeTime"
        :value="valueTime"
    />
    <div v-if="showTimer" :ref="el => menu = el" class="z-30 bg-white shadow-xl rounded absolute px-2 pt-4 pb-2 right-0 mt-2 w-96">
        <div class=" text-center pb-1 border-b">
            <h1 class="text-sm">Wybierz Godzinę</h1>
        </div>

        <div class="inline-flex pt-2 w-full">
            <div class="w-1/6 pt-2">
                <p class="text-sm mr-2">Godzina</p>
            </div>
            <div class="w-5/6 px-2 relative">
                <input
                    :ref="el => { if(!hours) hours = el }"
                    type="range"
                    min="0"
                    max="23"
                    step="1"
                    v-model.number="time.h"
                    class="w-full"
                >
                <div class="relative w-full pt-3">
                    <p
                        v-for="item in hourSteps"
                        :key="'h-' + item"
                        class="absolute bottom-0"
                        :style="`font-size: 10px; width: 10px; left:${getPosition(item, hours)}`"
                    >{{ item }}</p>
                </div>
            </div>
        </div>
        <div class="w-full inline-flex mt-2">
            <div class="w-1/6 pt-2">
                <p class="text-sm mr-2">Minuta</p>
            </div>
            <div class="w-5/6 px-2 pb-2">
                <input
                    :ref="el => { if(!mins) mins = el }"
                    type="range"
                    min="0"
                    max="59"
                    step="1"
                    v-model.number="time.m"
                    class="w-full"
                >
                <div class="relative w-full pt-3">
                    <p
                        v-for="item in minSteps"
                        :key="'h-' + item"
                        class="absolute bottom-0"
                        :style="`font-size: 10px; width: 10px; left:${getPosition(item, mins)}`"
                    >{{ item }}</p>
                </div>
            </div>
        </div>

        <div class="w-full inline-flex border-t pt-1">

            <div class="text-center w-full">
                <button
                    @click="showTimer = false"
                    class="text-xs w-24  bg-green-500 text-white mx-auto p-1 rounded">
                    Akceptuj
                </button>
            </div>
        </div>
    </div>

    <div v-if="showTimer" class="fixed inset-0 z-10" @click.self="showTimer = false"></div>
</div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { nextTick, onMounted, onUpdated, watch } from '@vue/runtime-core';
export default {
    props: {
        modelValue: [Object, Array, String],
    },

    setup(props, { emit }) {
        const showTimer = ref(false);
        const time = ref({
            h: props.modelValue ? props.modelValue.split(':')[0] : null,
            m: props.modelValue ? props.modelValue.split(':')[1] : null,
        })
        const hours = ref(null);
        const mins = ref(null);
        const hourSteps = [2,4,6,8,10,12,14,16,18,20,22];
        const minSteps = [5,10,15,20,25,30,35,40,45,50,55];

        const menu = ref(null);

        function getPosition(val, el){
            if(el){
                const min = el.min ? el.min : 0;
                const max = el.max ? el.max : 100;

                const newVal = Number(((val - min) * 100) / (max - min));

                return `calc(${newVal}% + (${3 - newVal * 0.15}px))`;
            }
        }

        const valueTime = computed(() => {
            var currentTime = {
                h: time.value.h,
                m: time.value.m,
            }
            if(
                (time.value.m !== null || time.value.h !== null) &&
                Number(time.value.h) < 10
            ) {
                currentTime.h = '0' + Number(time.value.h);
            }

            if(
                (time.value.m !== null || time.value.h !== null) &&
                Number(time.value.m) < 10
            ) {
                currentTime.m = '0' + Number(time.value.m);
            }

            if(time.value.m !== null || time.value.h !== null){

                if(hours.value) {
                    hours.value.value = Number(currentTime.h);
                }
                if(mins.value) {
                    mins.value.value = Number(currentTime.m);
                }

                emit('update:modelValue', `${ currentTime.h }:${ currentTime.m }`);
                return `${ currentTime.h }:${ currentTime.m }`;
            }
            else {
                return null;
            }
        });

        function changeTime(ev){
            let h = ev.target.value.split(':')[0];
            let m = ev.target.value.split(':')[1];

            if(hours.value) {
                hours.value.value = Number(h);
                time.value.h = Number(h);
            }
            if(mins.value) {
                mins.value.value = Number(m);
                time.value.m = Number(m);
            }
        }

        function openTimepicker(ev){
            const isMobile = navigator.userAgentData.mobile;

            if(!isMobile){
                ev.preventDefault();
                showTimer.value = true;
            }

            nextTick(() => {
                if(menu.value && menu.value.getBoundingClientRect().bottom > window.innerHeight) {
                    menu.value.classList.add('bottom-full');
                    menu.value.classList.add('mb-2');
                }
            })
        }

        watch(() => props.modelValue, (val) => {
            time.value.h = val ? val.split(':')[0] : null;
            time.value.m = val ? val.split(':')[1] : null;
        })

        return {
            showTimer,
            valueTime,

            openTimepicker,

            getPosition, changeTime,
            hourSteps,
            minSteps,
            menu,

            time,
            mins,
            hours,
        }
    }
}
</script>
