<template>
    <AdminLayout>
        <ImageModal
            v-if="imageModal !== null"
            :image="'/storage/galleries/'+gallery.name+'/'+ gallery.images[imageModal].name"
            @closeModal="imageModal = null"
            @prev="ev => {
                if(gallery.images[imageModal - 1]){
                    imageModal--;
                }
            }"
            @next="ev => {
                if(gallery.images[imageModal + 1]){
                    imageModal++;
                }
            }"

        />
        <div class="px-3 mt-4">
            <div class="">
            <Link :href="route('admin.dashboard.galleries')" class="mr-2 mb-6">Wróć</Link>
            <div class="text-xl dark:text-white font-bold">{{ gallery.name }}</div>
            </div>
            <div class="grid mt-5 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
                <div
                    class=" rounded-md overflow-hidden border-2 border-white dark:border-gray-700 shadow-sm"
                    v-for="(image, key) in gallery.images"
                    :key="image"
                >
                    <img
                        class="h-full w-full cursor-pointer"
                        v-lazy="{
                            src: '/storage/galleries/'+gallery.name+'/'+image.name,
                            loading: '/img/cms/loading.gif',
                            error: '/img/cms/img-error.png'
                        }"
                        @click="imageModal = key"
                    >
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import ImageModal from '@/Components/ImageModal'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import { Inertia } from '@inertiajs/inertia'
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            ImageModal,
            Pagination
        },
        props: {
            gallery: Object,
            news: [Object, Array],
        },
        setup() {
            const imageModal = ref(null);

            return {
                imageModal,

            }
        }
    }
</script>
