<template>
    <div>

    <inertia-head>
        <title>{{ $page.props.app_name }}</title>
    </inertia-head>


    <MainLayout :adsLogo="adsLogo">
        <!-- leagues dropdown -->
        <div class="w-full h-14 relative block z-30 border-b dark:border-gray-700 dark:bg-gray-900 bg-gray-100 dark:bg-opacity-50">
            <Roll
                class="w-full mx-auto h-full absolute dark:hover:bg-gray-900 hover:bg-gray-200"
                title="Wybierz ligę"
                :opened='false'
                custom-class="xl:w-10/12 mx-auto py-3 px-4 h-full"
                :bg-close="true"
            >
                <div class="md:w-10/12 w-full mx-auto col-span-full z-30 dark:text-white max-h-96 overflow-auto">
                    <ul class="dark:bg-gray-900 bg-gray-100 shadow ">
                        <li v-for="(l, key) in leagues" :key="key"
                            class="text-sm hover:bg-gray-200 dark:hover:bg-gray-800 flex"
                        >
                            <a :href="route('league', l.slug)" class="p-2 w-full">{{ l.name }}</a>
                        </li>
                    </ul>
                </div>
            </Roll>
        </div>
        <div
            class="grid grid-cols-2 xl:w-10/12 md:gap-3 mx-auto sm:px-5 px-3"
            v-if="main_category && main_category.locations[0]"
        >
            <div v-if="main_category.locations[0].adds.length == 1"
                class="col-span-full w-full lg:w-140 mx-auto"
            >
                <AddBar
                    :addImg="'/storage/adds/'+main_category.locations[0].adds[0].images[0].name"
                    :addHref="main_category.locations[0].adds[0].url"
                    v-if="main_category.locations[0].adds[0]"
                    class="mx-auto"
                />

            </div>
            <div
                v-if="main_category.locations[0].adds.length > 1"
                class="col-span-2 md:col-span-1 lg:col-span-1 flex items-center w-full"
            >
                <AddBar
                :addImg="'/storage/adds/'+main_category.locations[0].adds[0].images[0].name"
                :addHref="main_category.locations[0].adds[0].url"
                v-if="main_category.locations[0].adds[0]"
                class="mx-auto"

                />
            </div>
            <div
                v-if="main_category.locations[0].adds.length > 1"
                class="col-span-2 md:col-span-1 lg:col-span-1 flex items-center w-full"
            >
                <AddBar
                    :addImg="'/storage/adds/'+main_category.locations[0].adds[1].images[0].name"
                    :addHref="main_category.locations[0].adds[1].url"
                    v-if="main_category.locations[0].adds[1]"
                />
            </div>
        </div>

        <div class="grid grid-cols-12 xl:w-10/12 mx-auto sm:p-4 sm:pt-3 p-3 gap-4" >
            <div class="lg:col-span-9 md:col-span-8 col-span-12">
                <Roll
                    v-for="(category, key) in categories"
                    :secondary="key % 2 == 1 ? true : false"
                    :key="key"
                    class='col-span-12'
                    custom-class="py-3 px-4 h-16 mb-3 hover:bg-gray-100 dark:hover:bg-gray-900 dark:hover:bg-opacity-50"
                    :title="category.name"
                    :opened="isMobile ? (category.rolled_mobile ? true : false) : (category.rolled_desktop ? true : false)"
                    :class="[
                        category.visible_mobile && !category.visible_desktop ? 'block md:hidden md:opacity-0' : '',
                        !category.visible_mobile && category.visible_desktop ? 'hidden opacity-0 md:opacity-100 md:block' : '',
                        !category.visible_mobile && !category.visible_desktop ? 'hidden opacity-0' : ''
                    ]"
                >
                <template #top>
                <div v-if="category.locations.length && category.locations[0].adds.length" class="col-span-full grid gap-4 grid-cols-2 w-full lg:w-full mx-auto mb-4">
                    <div v-if="category.locations.length && category.locations[0].adds.length <= 1" class="col-span-full w-full mx-auto lg:w-140">
                        <AddBar
                        :addImg="'/storage/adds/'+category.locations[0].adds[0].images[0].name"
                        :addHref="category.locations[0].adds[0].url"
                        v-if="category.locations.length > 0  && category.locations[0].adds[0]"
                        />
                    </div>
                    <div v-if="category.locations.length && category.locations[0].adds.length > 1" class="flex items-center w-full col-span-2 md:col-span-2 lg:col-span-1">
                        <AddBar
                        :addImg="'/storage/adds/'+category.locations[0].adds[0].images[0].name"
                        :addHref="category.locations[0].adds[0].url"
                        v-if="category.locations.length > 0  && category.locations[0].adds[0]"
                        />
                    </div>
                    <div  v-if="category.locations.length && category.locations[0].adds.length > 1" class="flex items-center w-full col-span-2 md:col-span-2 lg:col-span-1">
                        <AddBar
                        :addImg="'/storage/adds/'+category.locations[0].adds[1].images[0].name"
                        :addHref="category.locations[0].adds[1].url"
                        v-if="category.locations.length > 0 && category.locations[0].adds[1]"
                        />
                    </div>
                </div>
                </template>
                    <Posts
                        :title="category.name"
                        :category="category"
                        :cat2a="cat2a"
                        :cat2b="cat2b"
                        bColor='green'
                        :hasAds="true"
                        class='col-span-12'
                    />

                    <template v-if="$props.categories.find(e => e.is_main_category) && category.is_main_category">
                        <div class="col-span-full mb-3">
                            <NewsList
                                v-if="$props.news_links && $props.news_links.length"
                                :posts="$props.news_links"
                                :settings="$props.news_links_settings"
                            />
                        </div>
                    </template>
                </Roll>
            </div>


            <div class="lg:col-span-3 md:col-span-4 col-span-12">
                <div class="boxes-card sticky bottom-0">
                    <CardImageLess
                        v-for="c in boxes"
                        :key="c"
                        :category="c"
                        :box2="box2"
                        :class="[
                            c.visible_mobile ? 'block' : 'hidden',
                            c.visible_desktop ? 'lg:block' : 'lg:hidden'
                        ]"
                        color="text-gray-600 dark:text-gray-300"
                        bgsize='cover'
                    />
                </div>
            </div>
        </div>
    </MainLayout>
    </div>
</template>


<script>
    import MainLayout from '@/Layouts/Main'
    import CardImage from '@/Components/CardImage'
    import NewsList from '@/Components/newsList'
    import CardImageLess from '@/Components/cardImageLess'
    import AddBar from '@/Components/Adds/addBar'
    import Posts from '@/Components/Post/gridOnMain'
    import Roll from '@/Components/rollUp'
    import { ref, onMounted } from 'vue';

    export default {
        components: {
            MainLayout,
            CardImage,
            CardImageLess,
            AddBar,
            NewsList,
            Posts,
            Roll,
        },
        props: {
            canLogin: Boolean,
            canRegister: Boolean,
            categories: [Array, Object],
            boxes: [Array, Object],
            main_category: [Array, Object],
            // adds
            cat2a: [Object, Array],
            cat2b: [Object, Array],
            box2: [Object, Array],
            adsLogo: [Object, Array],
            news_links: [Object, Array],
            leagues: [Object, Array],
            news_links_settings: [Array, Object],
        },
        setup(props) {
            const moreWiadomosci = ref(false);
            const moreOgloszenia = ref(false);
            const mainNews = ref(null);
            const firstBatch = parseInt(props.main_category ? props.main_category.rows : 0) * parseInt(props.main_category ? props.main_category.cols : 0);

            const isMobile = ref(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

            onMounted(() => {

                // props.categories.forEach((el, key) => {
                //     if ((isMobile.value && !el.visible_mobile) || (!isMobile.value && !el.visible_desktop)) props.categories.splice(key,1)
                // })

                // props.boxes.forEach((el, key) => {
                //     if ((isMobile.value && !el.visible_mobile) || (!isMobile.value && !el.visible_desktop)) props.boxes.splice(key,1)
                // })

                if (props.main_category) {

                    let checker = false;

                    if(props.main_category.main_news) {
                        mainNews.value = props.main_category.main_news;
                        checker = true;
                    }

                    // Jeśli nie ma głownego posta, pierwszy będzie głównym
                    if (!checker) {
                        if (props.main_category.news.length) {
                            mainNews.value = props.main_category.news[0];
                            props.main_category.news.splice(0, 1);
                        }
                    }
                }
            })

            return {
                moreWiadomosci,
                moreOgloszenia,
                isMobile,
                mainNews,
                firstBatch,
            }
        }
    }
</script>
