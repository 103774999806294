<template>
        <div class="transition-all">
            <div :ref="el => element = el"
                class="grid grid-cols-12"
                :class="[
                    moreWiadomosci ? '' : 'wrapper'
                ]"
                >
                <div class="grid grid-flow-row col-span-12 lg:grid-cols-4 sm:grid-cols-2 lg:gap-4 mb-4" v-if="mainNews">
                    <CardImage
                        v-if="mainNews"
                        :image="mainNews.images.length ?
                            ('/storage/news/' + mainNews.images[0].name) :
                                ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')"
                        :title="mainNews.title"
                        :href="route('news.id', mainNews.slug)"
                        :id='1'
                        :date="mainNews.created_at"
                        color="white"
                        class="h-48 sm:h-60 md:h-96 lg:object-cover col-span-full lg:col-span-3 md:object-cover mb-4"
                        :class="[
                            getPosts(0,2).length >= 2 ? 'lg:h-full' : '2xl:h-96 h-52'
                        ]"
                    />

                    <div class="lg:col-span-1 col-span-full grid lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 lg:gap-3 gap-4">
                        <CardImage v-for="n in getPosts(0,2)" :key='n'
                            :image="n.images.length ?
                                (n.images[0].thumb ? ('/storage/thumbnails/' + n.images[0].thumb) : ('/storage/news/' + n.images[0].name)) :
                                    ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')"
                            :title="n.title"
                            :date="n.created_at"
                            :href="route('news.id', n.slug)"
                            color="white"
                            class="2xl:object-scale-down 2xl:h-48 h-52"
                        />
                    </div>
                </div>
                <div class="col-span-12 grid sm:grid-cols-2 gap-4" :class="[ 'lg:grid-cols-'+category.cols ]" >
                    <CardImage v-for="(n, key) in getPosts(2,firstBatch)" :key='key'
                        :image="n.images.length ?
                            (n.images[0].thumb ? ('/storage/thumbnails/' + n.images[0].thumb) : ('/storage/news/' + n.images[0].name)) :
                            ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')"
                        :title="n.title"
                        :href="route('news.id', n.slug)"
                        :date="n.created_at"
                        class="2xl:h-48 h-52"
                        color="white"
                    />
                </div>

                <!-- Pokaż więcej wiadomości -->
                <!-- <div class="col-span-12 grid md:grid-cols-1 gap-4 mt-4" v-if="moreWiadomosci" :class="['lg:grid-cols-'+category.cols]" >
                    <CardImage v-for="n in getPosts(firstBatch+2,firstBatch)" :key='n'
                        :image="n.images.length ? '/storage/news/' + n.images[0].name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')"
                        :title="n.title"
                        :date="n.created_at"
                        :href="route('news.id', n.slug)"
                        class="2xl:h-full"
                        color="white"
                    />
                </div> -->
            </div>
            <!-- <div v-if="!moreWiadomosci" class="col-span-12 flex items-center flex-wrap justify-center">
            <button
                class="w-full py-3 lg:py-5 border-2 text-lg font-bold my-3 transition-all cursor-pointer"
                @click="moreWiadomosci = true"
                :class="{['border-'+bColor+'-600 text-'+bColor+'-600 hover:bg-'+bColor+'-600 hover:text-white']:bColor}">Pokaż więcej wiadomości</button>
            </div> -->
                <!-- Pokaż więcej - Przycisk -->
                <div class="col-span-12 flex items-center flex-wrap justify-center">
                    <inertia-link
                        :href="route('page.display', category.slug)"
                        class="w-full py-3 lg:py-5 border-2 text-lg font-bold my-3 transition-all cursor-pointer text-center"
                        :class="{['border-'+bColor+'-600 text-'+bColor+'-600 hover:bg-'+bColor+'-600 hover:text-white']:bColor}"
                    >Pokaż wszystkie wiadomości</inertia-link>
                </div>
            <!-- </div> -->
        </div>
</template>

<script>

import CardImage from '@/Components/CardImage'
import CardImageLess from '@/Components/cardImageLess'
import { ref } from '@vue/reactivity';
import { onMounted, computed, onBeforeMount, nextTick, onUpdated } from 'vue';
import AddBar from '@/Components/Adds/addBar'

export default {
    components:{
        CardImage,
        CardImageLess,
        AddBar
    },
    props: {
        category: [Object, Array],
        secondary: {
            type: Boolean,
            default: false
        },
        title: String,
        opened: {
            type: Boolean,
            default: false
        },
        bColor: {
            type: String,
            default: "green"
        },
        hasAds: {
            type: Boolean,
            dafault: false
        },
        addTitle: {
            type: String,
            default: "Reklama 🔥"
        },
        // adds
        cat2a: [Object, Array],
        cat2b: [Object, Array],

    },
    setup(props) {
        const moreWiadomosci = ref(false);
        const rightAdds = ref([]);
        const mainNews = ref(null);
        const element = ref(null);

        const firstBatch = parseInt(props.category.rows)*parseInt(props.category.cols);

        onBeforeMount(() => {
            let checker = false;
            // console.log(props.category.news)
            mainNews.value = props.category.main_news;

            if(mainNews.value){
                checker = true;
            }

            // Jeśli nie ma głownego posta, pierwszy będzie głównym
            if (!checker) {
                if (props.category.news && props.category.news.length) {
                    mainNews.value = props.category.news[0];
                    // props.category.news.splice(0, 1);
                }
            }
        })

        const getPosts = (from, num) => {

            if(props.category && props.category.news){
                const news = props.category.news
                .filter(e => (e.id != props.category.main_news_id && (e.id != mainNews.value.id)));

                return news.slice(from, from + num);
            }
            else return [];
        }

        onUpdated(() => {
            nextTick(() => {
                if(element.value && element.value.offsetHeight >= element.value.scrollHeight){
                    moreWiadomosci.value = true;
                }
            })
        })

        nextTick(() => {
            if(element.value && element.value.offsetHeight >= element.value.scrollHeight){
                moreWiadomosci.value = true;
            }
        })


        return {
            moreWiadomosci,
            rightAdds,
            getPosts,
            mainNews,
            firstBatch,

            element,
        }
    },
}
</script>

<style scoped>
/*
.opened .wrapper {
    max-height: 200vh;
    overflow: hidden;
} */
.closed .wrapper{
    max-height: 0;
    overflow: hidden;
}
</style>
