<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <Link @click="addSeason()" class="mb-3 bg-green-600 hover:bg-green-800">
                <SvgIcon name="plus" width="18" height="18" />
                <span class="ml-2">Dodaj</span>
            </Link>

            <div class="min-w-max w-full table-auto rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex justify-between items-center">
                    <div class="py-3 pl-3 w-48">Sezon</div>
                    <div class="py-3 text-center">Aktywny</div>
                    <div class="py-3 pr-3 text-center">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="(s, key) in seasons"
                        :key="key"
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white cursor-pointer flex justify-between items-center"
                    >
                        <div class="py-1 pl-3 w-48 flex">
                            <div v-if="s.is_editing" class="flex flex-col gap-1">
                                <div class="flex gap-3 items-start flex-col">
                                    <InputLabel
                                        v-model="s.year"
                                        type="number"
                                    />
                                    <div class="space-x-1 mb-3">
                                        <input id="season-type" type="checkbox" v-model="s.type">
                                        <label for="season-type">Sezon Jesień-wiosna</label>
                                    </div>
                                </div>
                                <div class="flex gap-3">
                                    <Link color="green" class="justify-center" @click="editSeason(key)">
                                        <span>Zapisz</span>
                                    </Link>
                                    <Link @click="s.is_editing = !s.is_editing" color="red" class="justify-center">
                                        <span>Anuluj</span>
                                    </Link>
                                </div>
                            </div>
                            <div v-else class="flex items-center space-x-3 justify-between w-full" >
                                <div v-if="s.type">{{s.year}}/{{s.year+1}} <span class="text-gray-400">({{ s.leagues_count }})</span></div>
                                <div v-else>{{s.year}} <span class="text-gray-400">({{ s.leagues_count }})</span></div>
                                <Link @click="s.is_editing = !s.is_editing" color="green" class="justify-center">
                                    <SvgIcon name="pencil" width="18" height="18"/>
                                </Link>
                            </div>
                        </div>
                        <div class="py-1 text-center">
                            <Checkbox type="checkbox" class="w-5 h-5" v-model="s.is_current" @change="editSeason(key)" />
                        </div>
                        <div class="py-1 mr-3 text-center">
                            <Link @click="showRemove.modal = true; showRemove.element = s;" class="bg-red-600 hover:bg-red-800">
                                <SvgIcon name="x" width="18" height="18" />
                                <!-- <span class="ml-2 hidden lg:block">Usuń</span> -->
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                v-if="showRemove.modal"
        >
            <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć sezon?</h2>
                <div class="flex">
                    {{ s }}
                    <Link @click="removeSeason(showRemove.element)" class="bg-red-600 hover:bg-red-800 mr-2">
                        <SvgIcon name="x" width="18" height="18" />
                        <span class="ml-2">Tak, Usuń</span>
                    </Link>
                    <Link @click="showRemove.modal=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                        <SvgIcon name="x" width="18" height="18" />
                        <span class="ml-2">Nie, anuluj operację</span>
                    </Link>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted } from 'vue'
    import { Inertia } from '@inertiajs/inertia'
    import Checkbox from '@/Components/Checkbox.vue'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Checkbox
        },
        props: {
            seasons: [Array, Object]
        },
        setup(props) {
            onMounted(() => {
                reloadSeasons();
            })

            const showRemove = ref({
                modal: false,
                element: null,
            });
            const addSeason = () => {
                Inertia.post(route('admin.season.add'));
            }
            const removeSeason = (el) => {
                Inertia.post(route('admin.season.remove', el.id));
                showRemove.value.modal = false;
                showRemove.value.element = null;
            }

            const editSeason = (key) => {
                let season_form = useForm({
                    year: props.seasons[key].year,
                    type: props.seasons[key].type,
                    is_current: props.seasons[key].is_current,
                })
                season_form.post(route('admin.season.edit', props.seasons[key].id), {
                    onSuccess: () => {
                        reloadSeasons();
                    },
                })
            }

            const reloadSeasons = () => {
                props.seasons.forEach(function(el){
                    el.is_editing = false;
                    el.type = el.type ? true:false;
                })
            }

            return {
                editSeason,
                addSeason,
                removeSeason,
                showRemove
            }
        }
    }
</script>
