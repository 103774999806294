<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <h1
                class="text-xl font-thin mb-3 dark:text-white"
            >
                Dodawanie nowej strony
            </h1>
            <form>
                <InputLabel
                    placeholder="Tytuł"
                    label="Tytuł"
                    id="title"
                    name="title"
                    type="text"
                    v-model="form.title"
                />

                <TinyEditor
                    v-model="form.description"
                    id="description"
                    name="description"
                    class="mt-2"
                />

                <div class="mt-2 mb-10 flex justify-end">
                    <Link @click="addPage()" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Dodaj</span>
                    </Link>
                </div>

            </form>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
    import TinyEditor from '@/Components/TinyEditor';

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox,
            TinyEditor
        },
        props: {
            page: [Array, Object],
        },
        setup(props) {
            const form = useForm({
                page_id: props.page ? props.page.id : null,
                title: props.page ? props.page.title : '',
                description: props.page ? props.page.description : '',
            });
            function addPage(){
                form.post(route('admin.page.create'), {
                    preserveScroll: true,
                })
            }
            return {
                form,
                addPage
            }
        }
    }
</script>

<style>
.tox-tinymce {
    border: 0 !important;
    border-bottom: 2px solid #9ca3af !important;
}
</style>
