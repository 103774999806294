<template>

    <inertia-head>
        <title>{{ league.name }} - {{ $page.props.app_name }}</title>
    </inertia-head>

    <MainLayout>
        <!-- {{ leagues }} -->
        <div class="sm:p-5 p-3 bg-white dark:bg-gray-800">
            <div class="dark:text-white w-full text-center pb-4">
                <h1 class="lg:text-3xl md:text-3xl text-2xl">{{ league.name }}</h1>
            </div>
            <div class="col-span-full grid grid-cols-2 w-full xl:w-11/12 m-auto mb-4 gap-4" v-if="$props.add_league && $props.add_league.length">
                <div
                    v-if="$props.add_league.length && $props.add_league.length <= 1"
                    class="col-span-full w-full mx-auto lg:w-140 flex items-center"
                >
                    <AddBar
                    :addImg="'/storage/adds/'+$props.add_league[0].images[0].name"
                    :addHref="$props.add_league[0].url"
                    v-if="$props.add_league.length > 0  && $props.add_league[0]"
                    />
                </div>
                <div v-if="$props.add_league.length && $props.add_league.length > 1" class="col-span-2 md:col-span-2 lg:col-span-1 flex items-center">
                    <AddBar
                    :addImg="'/storage/adds/'+$props.add_league[0].images[0].name"
                    :addHref="$props.add_league[0].url"
                    v-if="$props.add_league.length > 0  && $props.add_league[0]"
                    />
                </div>
                <div  v-if="$props.add_league.length && $props.add_league.length > 1" class="col-span-2 md:col-span-2 lg:col-span-1 flex items-center">
                    <AddBar
                    :addImg="'/storage/adds/'+$props.add_league[1].images[0].name"
                    :addHref="$props.add_league[1].url"
                    v-if="$props.add_league.length > 0 && $props.add_league[1]"
                    />
                </div>
            </div>
            <div class="flex gap-2 justify-between">
                <div class="px-4 bg-gray-100 text-white dark:bg-gray-900 w-full xl:w-11/12 lg:m-auto shadow">
                    <!-- {{ league.minDate }}
                    {{ league.maxDate }} -->
                    <Roll class="w-full  m-0" title="Konfiguruj tabelę" :opened='false'>
                        <div class="col-span-12 gap-4 text-gray-700 justify-between w-full pb-4 grid grid-cols-4">

                            <InputLabel
                                class="col-span-2 sm:col-span-2 lg:col-span-1"
                                placeholder="Min date"
                                id="minDate"
                                name="minDate"
                                type="date"
                                v-model="league.minDate"
                                @update:modelValue="getData($event, key, true) "
                                label="Data OD"
                            />
                            <InputLabel
                                class="col-span-2 sm:col-span-2 lg:col-span-1"
                                placeholder="Max date"
                                id="maxDate"
                                name="maxDate"
                                type="date"
                                v-model="league.maxDate"
                                @update:modelValue="getData($event, key, true)"
                                label="Data DO"
                            />
                            <InputLabel
                                class="col-span-2 sm:col-span-2 lg:col-span-1"
                                placeholder="Min queue"
                                id="minQueue"
                                name="minQueue"
                                type="number"
                                v-model="league.minQueue"
                                @update:modelValue="getData($event, key, true)"
                                label="Kolejka OD"
                            />
                            <InputLabel
                                class="col-span-2 sm:col-span-2 lg:col-span-1"
                                placeholder="Max queue"
                                id="maxQueue"
                                name="maxQueue"
                                type="number"
                                v-model="league.maxQueue"
                                @update:modelValue="getData($event, key, true)"
                                @update="league.minDate.reset()"
                                label="Kolejka DO"
                            />
                        </div>

                    </Roll>
                </div>
            </div>

        <Table
            class="w-full xl:w-11/12 lg:m-auto"
            :league="refLeague"
            :leagues="leagues"
            :scores="refLeague.scores"
            :table1="table1"
            :table2="table2"
            :queue="queue"
            :current_queue="refLeague.current_queue_index"
            :leagueHasTitle="true"
            :linkBack="true"
            :full="true"
            :openedAll="true"
        />


        </div>
    </MainLayout>
</template>


<script>
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import MainLayout from '@/Layouts/Main'
    import Table from '@/Components/Results/tableResults'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref, watch, onMounted, nextTick } from 'vue'
    import moment from 'moment'
    import Roll from '@/Components/rollUp'
    import AddBar from '@/Components/Adds/addBar';



    export default {
        components: {
            MainLayout,
            SvgIcon,
            InputLabel,
            Link,
            Table,
            Roll,
            AddBar,
            PulseLoader,
        },
        props: {
            league: Object,
            leagues: [Array, Object],

            // ads
            table1: [Object, Array],
            table2: [Object, Array],
            queue: [Object, Array],
            add_league: [Object, Array],
        },
        setup(props) {

            const refLeague = ref(props.league);

            onMounted(() => {
                setCurrentQueue();
                refLeague.value.minQueue = 1;
                refLeague.value.maxQueue = props.league.queues.length;

                nextTick(() => getData())
            })

            const setCurrentQueue = () => {
                props.league.queues.forEach((q, qkey) => {
                    if (q.id == props.league.current_queue) props.league.current_queue_index = qkey
                })

            }

            const getData = async () => {
                try {
                    const resp = await axios.get(route('get.league.scores', refLeague.value.id), {
                        'minQueue': refLeague.value.minQueue,
                        'maxQueue': refLeague.value.maxQueue,
                        'minDate': refLeague.value.minDate,
                        'maxDate': refLeague.value.maxDate,
                    });

                    refLeague.value.scores = resp.data.scores;
                    // refLeague.value.queues = resp.data.queues;

                } catch (err) {
                    console.error(err);
                }
            }

            const slideEnd = (e) => {

            }

            return {
                getData,
                slideEnd,
                refLeague
            }
        },

    }
</script>

<style scoped>
.active-tab{
    position: relative;
}
/* .active-tab p{
    margin-bottom: 15px;
} */
/* .active-tab::after{
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 30px;
    background: #D1D5DB;
} */
.active-tab:hover::after{
    background: #059669;
}
</style>
