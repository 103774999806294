<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <h1 class="text-xl font-thin mb-3 dark:text-gray-200" >
                Dodawanie nowego klubu
            </h1>
            <form>
                <div class="grid grid-cols-2 gap-2">
                    <div class="mb-2">
                        <InputLabel
                        placeholder="Prefiks"
                        id="prefix"
                        name="prefix"
                        type="text"
                        autofocus
                        v-model="club_form.prefix"
                    />
                    </div>

                    <div class="mb-2">
                        <InputLabel
                        placeholder="Nazwa"
                        id="name"
                        name="name"
                        type="text"
                        v-model="club_form.name"
                    />
                    </div>
                    <div v-if="errors && errors.name" class="text-red-500 mt-2">{{errors.name}}</div>
                </div>

                <!-- <InputLabel
                    placeholder="Krótka nazwa"
                    id="name_short"
                    name="name_short"
                    type="text"
                    class="mt-2"
                    v-model="club_form.name_short"
                /> -->
                <div class="mb-2">
                    <InputLabel
                    placeholder="Nazwa na laczynaspilka.pl"
                    id="laczynaspilka_name"
                    name="laczynaspilka_name"
                    type="text"
                    class="mt-2"
                    v-model="club_form.laczynaspilka_name"
                />
                <div v-if="errors && errors.laczynaspilka_name" class="text-red-500 mt-2">{{errors.laczynaspilka_name}}</div>
                </div>

                <div class="mb-2">
                    <InputLabel
                    placeholder="Krótki opis"
                    id="short_description"
                    name="short_description"
                    type="textarea"
                    class="mt-2"
                    height="24"
                    v-model="club_form.webpages"
                />
                </div>

                <ImageUpload
                    class="mt-2 mb-4"
                    @imagesUploaded="changeImage"
                    :autosend="false"
                />

                <div class="mb-2">
                    <InputLabel
                    placeholder="Opis"
                    id="describtion"
                    name="describtion"
                    type="textarea"
                    class=""
                    height="48"
                    v-model="club_form.describtion"
                />
                </div>

                <div class="grid grid-cols-2 gap-2">
                    <div class="mb-2">
                        <InputLabel
                        placeholder="Miasto"
                        id="city"
                        name="city"
                        type="text"
                        v-model="club_form.city"
                    />
                    </div>

                    <div class="mb-2">
                        <InputLabel
                        placeholder="Rok"
                        id="year"
                        name="year"
                        type="text"
                        v-model="club_form.year"
                    />
                    </div>
                </div>

                <div class="mb-2">
                    <InputLabel
                    placeholder="Barwy"
                    id="colors"
                    name="colors"
                    type="text"
                    class="mt-2"
                    v-model="club_form.colors"
                />
                </div>

                <div class="grid grid-cols-2 gap-2">
                    <div class="mb-2">
                        <InputLabel
                        placeholder="Telefon"
                        id="phone"
                        name="phone"
                        type="text"
                        v-model="club_form.phone"
                    />
                    </div>

                    <div class="mb-2">
                        <InputLabel
                        placeholder="Email"
                        id="email"
                        name="email"
                        type="text"
                        v-model="club_form.email"
                    />
                    </div>
                </div>

                <div class="mb-2">
                    <InputLabel
                    placeholder="Info o stadionie"
                    id="stadium_info"
                    name="stadium_info"
                    type="textarea"
                    height="36"
                    class="mt-2"
                    v-model="club_form.stadium_info"
                />
                </div>

                <Link
                    class="mt-2 float-right bg-green-600 hover:bg-green-800 mb-10"
                    @click.prevent="add_club()"
                >
                <SvgIcon name="plus" width="18" height="18" />
                <span class="ml-2">Dodaj</span>
                </Link>

            </form>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import ImageUpload from '@/Components/ImageUpload'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            ImageUpload
        },
        setup() {
            const club_form = useForm({
                prefix: null,
                name: null,
                city: null,
                laczynaspilka_name: null,
                year: null,
                colors: null,
                phone: null,
                address: null,
                stadium_info: null,
                email: null,
                webpages: null,
                describtion: null,
                logo: null,
                errors: [Array, Object],
            });

            const add_club = () => {
                club_form.post(route('admin.post.events.clubs.add'));
            }

            const changeImage = (e) => {
                club_form.logo = e;
            }

            return {
                club_form,
                add_club,
                changeImage
            }
        }
    }
</script>
