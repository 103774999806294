<template>

    <nav
        class="flex justify-center text-white  bg-white dark:bg-gray-900 py-2 sticky top-0 h-20 z-50 shadow"
    >
        <div class="w-full xl:w-10/12 xl:px-0 sm:px-5 relative px-3 flex justify-between items-center">
            <div
                class="flex items-center gap-2 h-full sm:w-auto w-full"
            >
                <BreezeApplicationLogo
                    class="w-20 h-auto lg:w-24"
                />

                <div
                    class="uppercase font-semibold hidden lg:flex"
                >
                    <BreezeNavLink
                        v-for="link in navLinks"
                        :key="link"
                        :href="route(link.href)"
                        :active="route().current(link.href)"
                        v-html="link.name"
                        class="py-2 mr-2"
                        :hidden="link.mobile"
                    />
                </div>

                <div class="h-full sm:w-96 w-full sm:flex-auto flex-1">
                    <a
                    :href="$page.props.ads_logo.adds[0].url"
                    v-if="$page.props.ads_logo && $page.props.ads_logo.adds[0] "
                    target="_blank"
                    >
                        <div class="w-full flex items-center justify-center overflow-hidden h-full lg:relative md:flex">
                            <img
                                v-if="'/storage/adds/'+$page.props.ads_logo.adds[0].images[0].name"
                                :src="'/storage/adds/'+$page.props.ads_logo.adds[0].images[0].name"
                                class="max-h-full object-cover w-full lg:w-full mx-auto 2xl:w-full" readonly>
                        </div>
                    </a>
                </div>
            </div>

            <div class="flex items-center relative justify-end">
                <SearchBar class="hidden lg:block"/>
            <Dropdown
                class="lg:ml-4 sm:pl-0 pl-2 lg:hidden w-full"
            >
                <template #trigger>
                    <span class="inline-flex rounded-md">
                        <button type="button" class="inline-flex text-gray-200 items-center border border-transparent text-sm leading-4 font-medium rounded-md focus:outline-none transition ease-in-out duration-150">
                            <SvgIcon
                                name="menu"
                                width="28"
                                height="28"
                                class="text-gray-400 hover:text-gray-600 "
                            />
                        </button>
                    </span>
                </template>
                <template #content>
                    <div>
                        <div
                            v-for="link in navLinks"
                            :key="link"
                        >
                            <DropdownLink
                                class="flex  font-bold items-center"
                                method="get"
                                as="a"
                                :href="route(link.href)"
                                v-html="link.name"
                            >
                            </DropdownLink>

                        </div>
                    </div>
                </template>
            </Dropdown>
            </div>
        </div>
    </nav>

</template>

<script>
    import BreezeApplicationLogo from '@/Components/ApplicationLogo'
    import BreezeNavLink from '@/Components/NavLink'
    import Dropdown from '@/Components/Dropdown'
    import DropdownLink from '@/Components/DropdownLink'
    import SearchBar from '@/Components/SearchBar'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import AddBar from '@/Components/Adds/addBar'

    export default {
        components: {
            BreezeApplicationLogo,
            BreezeNavLink,
            SearchBar,
            Dropdown,
            DropdownLink,
            SvgIcon,
            AddBar,
        },
        props: {
            // ads
            // adsLogo: [Object, Array],
        },
        setup(props) {
            // const adds = props.ads_logo[Math.floor(Math.random() * props.ads_logo.length)];
            const navLinks = ref([
                {
                    name: 'Wiadomości',
                    href: 'newspage',
                    mobile: false,
                },
                {
                    name: 'Wyniki',
                    href: 'results',
                    mobile: false,
                },
                {
                    name: 'Szukaj',
                    href: 'search',
                    mobile: true,
                },
                {
                    name: 'Kontakt',
                    href: 'contact',
                    mobile: false
                },
                // {
                //     name: 'Wyślij wyniki meczu',
                //     href: 'dashboard'
                // }
            ]);

            return {
                navLinks,
            }
        }
    }
</script>

