<template>
    <div class="flex flex-col">
        <div
            class="grid grid-cols-12 px-2 py-2 hover:bg-green-100 dark:hover:bg-green-800 hover:border-green-100 border-gray-200 border-b-2"
            v-for="s in scores" :key="s"
        >
            <div class="col-span-12 lg:col-span-3 text-center">
                {{s.match_date ? s.match_date : '0000-00-00 00:00:00'}}
            </div>
            <div class="col-span-12 lg:col-span-2 text-center">
                {{s.league.name}}, <span class="text-gray-500 dark:text-gray-400 text-xs">Kolejka:&nbsp;{{s.queue.number}}</span>
            </div>
            <div class="col-span-12 lg:col-span-3 font-bold text-center">
                <p v-if="s.host">{{ s.host.club_name }} <span class="text-gray-500 dark:text-gray-400 text-xs">({{ s.host.category_name }})</span></p>
                <p v-else>--</p>
            </div>
            <div class="col-span-12 lg:col-span-3 font-bold text-center">
                <p v-if="s.guest">{{ s.guest.club_name }} <span class="text-gray-500 dark:text-gray-400 text-xs">({{ s.guest.category_name }})</span></p>
                <p v-else>--</p>
             </div>
            <div class="col-span-12 lg:col-span-1 text-center">
                <inertia-link
                    v-if="s.news && s.news.length"
                    class="text-blue-500 hover:text-blue-600 hover:underline"
                    :href="route('news.id', s.news[0].slug)"
                >
                    {{getGoals(s)}}
                </inertia-link>
                <span v-else>{{getGoals(s)}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import moment from 'moment'
export default {
    components: {
        moment
    },
    props: {
        scores: [Object, Array]
    },
    setup(props) {
        const getGoals = (item) => {
            let walkover = '';
            let host_goals = item.host_goals !== null ? item.host_goals : '-';
            let guest_goals = item.guest_goals !== null ? item.guest_goals : '-';

            if(item.walkover != 'none'){
                walkover = ' v.o';

                if(item.walkover == 'for_host'){
                    host_goals = 3;
                    guest_goals = 0;
                }
                else if(item.walkover == 'for_guest'){
                    host_goals = 0;
                    guest_goals = 3;
                }
                else if(item.walkover == 'equal') {
                    host_goals = 3;
                    guest_goals = 3;
                }

            }
            else {
                if(item.host_id === 0 || item.guest_id === 0){
                    host_goals = '-';
                    guest_goals = '-';
                }
            }

            return host_goals + ":" + guest_goals + walkover;
        }

        return {
            getGoals
        }
    }
}
</script>
