<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="px-3 pb-12 pt-6 sm:px-6 lg:px-8 ">
            <Link :href="route('admin.dashboard.users')" class="mr-2 mb-6">Wróć</Link>
            <h1
                class="text-xl font-thin mt-2 mb-3"
            >
                Dodawanie nowego użytkownika
            </h1>
            <form>
                <div class="mb-2">
                    <InputLabel
                        placeholder="Nazwa użytkownika"
                        id="name"
                        name="new_name"
                        type="text"
                        v-model="user.name"
                        :autocomplete="false"
                    />
                </div>

                <div class="mb-2">
                    <MultiListbox
                        :data="permissions"
                        v-model="user.permissions"
                        type="slug"
                        text="Wybrane uprawnienia"
                    />
                </div>

                <div class="mb-2">
                    <InputLabel
                        placeholder="Email"
                        id="email"
                        name="new_email"
                        type="email"
                        v-model="user.email"
                        :autocomplete="false"
                    />
                </div>

                <div class="mb-2">
                    <InputLabel
                        placeholder="Hasło"
                        id="password"
                        name="new_password"
                        type="password"
                        v-model="user.password"
                        :autocomplete="false"
                    />
                </div>
                

                <div class="mt-2 flex justify-end">
                    <Link @click="addUser()" class="bg-green-600 hover:bg-green-800">
                        <SvgIcon name="plus" width="18" height="18" />
                        <span class="ml-2">
                            Dodaj
                        </span>
                    </Link>
                </div>

            </form>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox
        },
        props: {
            categories: Object,
            permissions: Array,
            clubs: Array
        },
        setup(props) {
            const user = useForm({
                name: null,
                password: null,
                email: null,
                permissions: []
            });


            const addUser = () => {
                user.post(route('admin.post.dashboard.users.add'))
            }

            return {
                addUser,
                user
            }
        }
    }
</script>

<style>
.tox-tinymce {
    border: 0 !important;
    border-bottom: 2px solid #9ca3af !important;
}
</style>
