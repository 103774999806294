<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="px-3 py-6 sm:px-6 lg:px-8 ">
            <div class="inline-flex">
                <h1 class="text-xl font-thin mb-3 dark:text-white" >
                    Edycja klubu
                </h1>
                <!-- <Link @click="deleteClub()" class="bg-red-600 ml-2 hover:bg-red-800 relative z-99">
                    <SvgIcon name="x" width="18" height="18"/>
                    <span class="ml-2 hidden sm:block">USUŃ</span>
                </Link> -->
            </div>

            <form class="pb-5">
                <div class="grid grid-cols-2 gap-2">
                    <div class="mb-2">
                        <InputLabel
                        placeholder="Prefiks"
                        id="prefix"
                        name="prefix"
                        type="text"
                        autofocus
                        v-model="club_form.prefix"
                    />
                    </div>

                    <div class="mb-3">
                        <InputLabel
                        placeholder="Nazwa"
                        id="name"
                        name="name"
                        type="text"
                        v-model="club_form.name"
                    />
                    </div>
                </div>

                <!-- <InputLabel
                    placeholder="Krótka nazwa"
                    id="name_short"
                    name="name_short"
                    type="text"
                    class=""
                    v-model="club_form.name_short"
                /> -->
                <div class="mb-3">
                    <InputLabel
                    placeholder="Nazwa na laczynaspilka.pl"
                    id="laczynaspilka_name"
                    name="laczynaspilka_name"
                    type="text"
                    class=""
                    v-model="club_form.laczynaspilka_name"
                />
                </div>

                <div class="mb-3">
                    <InputLabel
                    placeholder="Krótki opis"
                    id="short_description"
                    name="short_description"
                    type="textarea"
                    class=""
                    height="24"
                    v-model="club_form.webpages"
                />
                </div>

                <div class="mb-3">
                    <ImageUpload
                    class=" mb-4"
                    @imagesUploaded="changeImage"
                    :autosend="false"

                    :preimage="club.logo ? '/storage/clubs/'+club.logo : null"
                />
                </div>

                <div class="mb-3">
                    <InputLabel
                    placeholder="Opis"
                    id="describtion"
                    name="describtion"
                    type="textarea"
                    class=""
                    height="48"
                    v-model="club_form.describtion"
                />
                </div>

                <div class="grid grid-cols-2 gap-2 ">
                    <div class="mb-3">
                        <InputLabel
                        placeholder="Miasto"
                        id="city"
                        name="city"
                        type="text"
                        v-model="club_form.city"
                    />
                    </div>

                    <div class="mb-3">
                        <InputLabel
                        placeholder="Rok"
                        id="year"
                        name="year"
                        type="text"
                        v-model="club_form.year"
                    />
                    </div>
                </div>

                <div class="mb-3">
                    <InputLabel
                    placeholder="Barwy"
                    id="colors"
                    name="colors"
                    type="text"
                    class=""
                    v-model="club_form.colors"
                />
                </div>

                <div class="grid grid-cols-2 gap-2 ">
                    <div class="mb-3">
                        <InputLabel
                        placeholder="Telefon"
                        id="phone"
                        name="phone"
                        type="text"
                        v-model="club_form.phone"
                    />
                    </div>

                    <div class="mb-3">
                        <InputLabel
                        placeholder="Email"
                        id="email"
                        name="email"
                        type="text"
                        v-model="club_form.email"
                    />
                    </div>
                </div>

                <div class="mb-3">
                    <InputLabel
                        placeholder="Info o stadionie"
                        id="stadium_info"
                        name="stadium_info"
                        type="textarea"
                        height="36"
                        class=""
                        v-model="club_form.stadium_info"
                    />
                </div>

                <div class="mb-3" v-if="$page.props.auth.user.permissions.find(e => e.slug == 'admin')">
                    <h1>ADMIN</h1>
                    <h1 class="text-xl font-thin mb-3 dark:text-white flex flex-col gap-1" >
                        <span>Przypisane drużyny</span>
                        <span class="text-xs">Zmień klub drużyn przypisanych do aktualniego klubu <b class="font-bold">{{ $props.club.name }}</b>.</span>
                    </h1>
                    <div class="flex items-center space-x-3 mb-6">
                        <Checkbox
                            id="assign-all"
                            name="assign"
                            v-model="club_form.marge"
                        />
                        <Label
                            class="ml-2 dark:text-white"
                            for="assign-all"
                        >Scal wszystkie informacje klubowe (newsy, ligi, drużyny) i usuń klub</Label>
                    </div>
                    <div class="mb-3 space-y-3"
                        :class="[
                            club_form.marge ? 'opacity-50 pointer-events-nones' : ''
                        ]"
                    >
                        <div
                            v-for="item in $props.club.teams" :key="item.id"
                            class="flex items-center space-x-3"
                        >
                            <Checkbox
                                v-model="club_form.teams"
                                :value="item.id"
                                :id="`assign-${item.id}`"
                                name="assign"
                                :disabled="club_form.marge"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                :for="`assign-${item.id}`"
                            ><span>{{ item.name }}</span> / <span class="text-xs">{{ item.laczynaspilka_name ?? '-' }}</span> ({{ item.category_name }})</Label>
                        </div>
                    </div>
                    <div v-if="club_form.assign_club" class="flex">
                        <span class="border bg-white px-2 py-0.5">{{ club_form.assign_club.name }}</span>
                        <button class="text-red-500 w-6 bg-red-100" @click="club_form.assign_club = null">x</button>
                    </div>

                    <div  class="fixed inset-0 bg-gray-200 bg-opacity-30" v-if="active_search" @click.self="active_search = false;"></div>
                    <div class="relative">
                        <InputLabel
                            placeholder="Przypisz do klubu"
                            id="assign"
                            name="assign"
                            @input="searchClub"
                            @click="active_search = true;"
                            v-model="search_club"
                        />
                        <div v-if="active_search" class="bg-white absolute bottom-full max-h-96 overflow-auto mb-1 shadow-sm border w-full" style="min-height: 50px;">
                            <div v-if="search_club_loading" class="absolute flex items-center justify-center inset-0 bg-gray-200 bg-opacity-50">
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </div>
                            <ul v-else>
                                <li
                                    v-for="item in finded_clubs" :key="item.id"
                                    class="px-3 py-2 hover:bg-gray-100 space-x-2"
                                    style="cursor: pointer;"
                                    :class="[
                                        club_form.assign_club && club_form.assign_club.id == item.id ? 'border-l-2 border-green-700 bg-gray-100' : '',
                                    ]"
                                    @click="club_form.assign_club = item; active_search = false;"
                                ><span>{{ item.name }}</span><span class="text-xs">{{ item.laczynaspilka_name }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end">
                    <Link
                        class=" bg-green-600 hover:bg-green-800"
                        @click.prevent="edit_club()"
                    >
                        <SvgIcon name="check" width="18" height="18" />
                        <span class="ml-2">Zapisz</span>
                    </Link>
                 </div>
            </form>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, watch } from 'vue'
    import ImageUpload from '@/Components/ImageUpload'
    import { Inertia } from '@inertiajs/inertia'
    import Checkbox from '@/Components/Checkbox'
    import Label from '@/Components/Label'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            ImageUpload,
            Checkbox,
            Label,
        },
        props: {
            club: Object
        },
        setup(props) {
            const club_form = useForm({
                prefix: props.club.prefix,
                name: props.club.name,
                city: props.club.city,
                laczynaspilka_name: props.club.laczynaspilka_name,
                year: props.club.year,
                colors: props.club.colors,
                phone: props.club.phone,
                address: props.club.address,
                stadium_info: props.club.stadium_info,
                email: props.club.email,
                webpages: props.club.webpages,
                describtion: props.club.describtion,
                logo: props.club.logo,
                teams: [],
                assign_club: null,
                marge: false,
            });

            const search_club = ref('');
            const search_club_loading = ref(false);
            const active_search = ref(false);

            watch(() => active_search.value, (val, old) => {
                if(val) {
                    document.getElementsByTagName('main')[0].classList.remove('overflow-y-auto');
                    document.getElementsByTagName('main')[0].classList.add('overflow-hidden');
                }
                else {
                    document.getElementsByTagName('main')[0].classList.remove('overflow-hidden');
                    document.getElementsByTagName('main')[0].classList.add('overflow-y-auto');
                }
            })

            const edit_club = () => {
                club_form.post(route('admin.post.events.clubs.edit', props.club.id));
            }

            const changeImage = (e) => {
                club_form.logo = e;
            }

            const deleteClub = () => {
                Inertia.post(route('admin.clubs.delete', props.club.id))
            }

            const timer = ref(null);
            const finded_clubs = ref([]);
            function searchClub(){
                search_club_loading.value = true;
                active_search.value = true;

                if(timer.value) clearTimeout(timer.value);

                timer.value = setTimeout(() => {
                    axios.get(route('api.clubs',{ search: search_club.value, current: props.club.id }))
                    .then(response => {
                        finded_clubs.value = response.data;
                        search_club_loading.value = false;
                    })
                }, 1500)
            }

            return {
                club_form,
                changeImage,
                edit_club,
                deleteClub,

                searchClub,
                active_search,

                search_club,
                search_club_loading,
                finded_clubs,
            }
        }
    }
</script>
