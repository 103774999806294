<template>
    <MainLayout>
        <div class="xl:w-10/12 m-auto sm:p-5 p-3">

            <div class="">
                <div class="bg-white dark:bg-gray-900 sticky top-16 shadow-md py-5 pt-7 flex justify-center z-10">
                    <div class="grid grid-cols-4 w-full px-3 lg:px-8">
                        <div class="relative col-span-2 w-full lg:col-span-3">
                            <input
                                name="search"
                                type="text"
                                placeholder="Search"
                                v-model="serachContent"
                                v-on:keyup.enter="search()"
                                class="w-full h-full bg-transparent text-gray-800 dark:text-gray-200 dark:border-gray-600 border-gray-400 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 border-b-2 px-4 py-2 outline-none focus:outline-none focus:ring-0"
                            />

                            <SvgIcon
                                class="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer text-gray-700 hover:text-gray-200"
                                name="search"
                                width="24"
                                height="24"
                                @click="search()"
                            />

                        </div>
                        <Listbox
                            :data="searchTypes"
                            :modelValue="currentType"
                            @update:modelValue="changeTypes"
                            colors="normal"
                            type="key"
                            class=" mt-1 ml-2 w-full lg:col-span-1 col-span-2"
                        />
                    </div>
                </div>
                <div class="p-5 px-3 lg:px-5 shadow-md w-full bg-white dark:bg-gray-900 mb-4">

                    <div class="dark:text-white w-full">
                        <div
                            class="flex text-normal lg:text-xl"
                            v-if="route().params['search']"
                        >
                           <div class="">Wyniki wyszukiwania dla:</div>
                           <div class="font-semibold ml-1">{{route().params['search']}}</div>
                        </div>
                        <div class="mt-6 relative">
                            <div v-if="loading" class="absolute flex items-start pt-10 justify-center inset-0 bg-gray-200 bg-opacity-30 dark:bg-gray-900 dark:bg-opacity-50">
                                <div class="font-bold sticky top-48 text-sm flex items-center justify-center flex-col">
                                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </div>
                            <div
                                class="shadow-sm mb-4"
                                v-if="currentType == 0"
                            >
                                <div
                                    v-if="$props.news.data.length"
                                    class="text-gray-600 text-sm font-light"
                                >
                                    <inertia-link
                                        v-for="(n, key) in $props.news.data"
                                        :href="route('news.id', n.slug)"
                                        :key="n.id"
                                        class=" border-b border-gray-200 dark:border-gray-300 bg-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:text-white cursor-pointer flex flex-row justify-start items-center"
                                        :class="[
                                            (key % 2 != 0) ? 'dark:bg-gray-900 bg-white' : 'bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50'
                                        ]"
                                    >
                                        <div class="ml-3 my-2 flex justify-center items-center lg:ml-6 w-12 h-12 rounded-full overflow-hidden">
                                            <img class="h-full max-w-none w-auto"
                                                v-lazy="{
                                                    src: n.images.length ? '/storage/news/'+n.images[0].name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg'),
                                                    loading: '/img/cms/loading.gif',
                                                    error: '/img/cms/img-error.png'
                                                }"
                                                >
                                        </div>
                                        <div class="pl-3 lg:pl-6 py-1">{{n.title}}</div>
                                    </inertia-link>
                                </div>
                                <div
                                    class="text-center p-3 bg-white dark:bg-gray-900 dark:text-white uppercase text-normal lg:text-xl font-bold"
                                    v-else
                                >
                                Brak
                                </div>

                                <div class="flex justify-center">
                                    <pagination class="mt-6" :links="$props.news.links" />
                                </div>
                            </div>
                            <div
                                class="rounded-md overflow-hidden shadow-sm mb-4"
                                v-else
                            >
                                <div
                                    v-if="$props.clubs.data.length"
                                    class="text-gray-600 text-sm font-light"
                                >
                                    <inertia-link
                                        v-for="(c, key) in $props.clubs.data"
                                        :key="c.id"
                                        :href="route('singleclub', c.id)"
                                        class=" border-b border-gray-200 dark:border-gray-300 bg-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:text-white cursor-pointer flex flex-row justify-start items-center"
                                        :class="[
                                            (key % 2 != 0) ? 'dark:bg-gray-900 bg-white' : 'bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50'
                                        ]"
                                    >
                                        <div class="ml-3 my-2 flex justify-center items-center lg:ml-6 w-12 h-12 rounded-full overflow-hidden">
                                            <img class="h-full max-w-none w-auto"
                                            v-lazy="{
                                                    src: c.images.length ? '/storage/club/'+c.images[0].name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg'),
                                                    loading: '/img/cms/loading.gif',
                                                    error: '/img/cms/img-error.png'
                                                }"
                                            >
                                        </div>
                                        <div class="pl-6 py-1">{{c.name}}</div>
                                    </inertia-link>
                                </div>
                                <div
                                class="text-center p-3 bg-white dark:bg-gray-900 dark:text-white uppercase text-normal lg:text-xl font-bold"
                                v-else
                                >
                                Brak
                                </div>

                                <div class="flex justify-center">
                                    <pagination class="mt-6" :links="$props.clubs.links" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>


<script>
    import MainLayout from '@/Layouts/Main'
    import SvgIcon from '@/Components/SvgIcon'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import { Inertia } from '@inertiajs/inertia'
    import moment from 'moment'
    import {onMounted} from 'vue'
    import { ref } from '@vue/reactivity';
    import Pagination from '@/Components/Pagination'
    export default {
        components: {
            MainLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Pagination
        },
        props: {
            news: [Array, Object],
            clubs: [Array, Object],
        },
        setup(props){
            const serachContent = ref(route().params['search']);
            const searchTypes = ref([
                'Wiadomości',
                'Kluby'
            ]);
            const currentType = ref(route().params.hasOwnProperty('type') ? Number(route().params.type) : 0);
            const loading = ref(false);

            const search = () => {
                Inertia.get(route('search', {
                    search: serachContent.value,
                    type: currentType.value,
                }))
            }

            function changeTypes(ev){

                loading.value = true;
                Inertia.get(route('search', {
                    search: serachContent.value,
                    type: ev,
                }, {
                    onSuccess: () => currentType.value = ev,
                }))
            }

            onMounted(() => {

            })
            return {
                searchTypes,
                currentType,
                serachContent,
                search,
                loading,

                changeTypes
            }
        }
    }
</script>

<style>


.opened .wrapper{
    max-height: 300vh;
    overflow: hidden;
}
.closed .wrapper{
    max-height: 0;
    overflow: hidden;
}
.wysiwyg ul {
    list-style: disc !important;
    margin-left: 1em;
}

.wysiwyg p {
    margin-top: .5em;
    margin-bottom: .5em;
}
.wysiwyg h1, h1{
    font-size: 2.2rem !important;
}
.wysiwyg h2{
    font-size: 2rem !important;
}
.wysiwyg h3{
    font-size: 1.8rem !important;
}
.wysiwyg h4{
    font-size: 1.6rem !important;
}
.wysiwyg h5{
    font-size: 1.4rem !important;
}
.wysiwyg h6{
    font-size: 1.2rem !important;
}
.wyswig *{
    line-height: 110% !important;
}
</style>
