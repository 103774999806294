<template>
    <p class="text-xl font-bold py-3" v-if="queue">
        {{queue.number}}. Kolejka {{getDate(queue.start_date)}}
    </p>
    <p class="text-xl font-bold py-3" v-else>Brak zaplanowanych spotkań.</p>
    <div v-if="queue">
        <div class="grid grid-cols-4 py-3 border-b xl:border-transparent border-gray-200 dark:border-gray-800" v-for="n in queue.items" :key="n">
            <div class="xl:col-span-1 lg:col-span-full sm:col-span-1 col-span-full">
                <div class="flex w-full items-center xl:flex-col lg:flex-row sm:flex-col flex-row  xl:justify-start justify-center xl:gap-0 lg:gap-3 sm:gap-0 gap-3 xl:mb-0 mb-2">
                    <p class="md:text-sm text-xs">{{getDate(n.match_date)}}</p>
                    <p class="text-xs">{{getTime(n.match_date)}}</p>
                    <p class="text-xs text-gray-500">#{{n.id}}</p>
                </div>
            </div>
            <div class="xl:col-span-3 lg:col-span-full sm:col-span-3 col-span-full">
                <div class="flex w-full gap-3 text-lg justify-center">
                    <p class="flex justify-end text-right items-center col-span-2 w-1/2">
                        <inertia-link class="sm:text-sm text-xs"
                            v-if="n.host"
                            :href="route('singleclub', {
                                season: route().params.season,
                                club: n.host.club_id,
                                team: n.host_id,
                                league: n.league_id
                            })"
                        >{{n.host.club_name}}</inertia-link>
                        <span v-else>---</span>
                    </p>
                    <p
                        class="md:text-lg text-sm text-center font-bold whitespace-nowrap"
                    >
                        <inertia-link class="text-blue-500 hover:text-blue-600 hover:underline" v-if="n.news && n.news.length" :href="route('news.id', n.news[0].slug)">
                            {{ getGoals(n )}}
                        </inertia-link>
                        <span v-else>{{ getGoals(n )}}</span>
                    </p>
                    <p class="flex justify-start items-center col-span-2 w-1/2">
                        <inertia-link class="sm:text-sm text-xs"
                            v-if="n.guest"
                            :href="route('singleclub', {
                                season: route().params.season,
                                club: n.guest.club_id,
                                team: n.guest_id,
                                league: n.league_id
                            })"
                        >{{n.guest.club_name}}</inertia-link>
                        <span v-else>---</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import moment from 'moment'
export default {
    components: {
        moment
    },
    props: {
        queue: Object
    },
    setup(props) {
        const getDate = (date) => {
            return date ? moment(date).format('DD-MM-YYYY') : '--/--/----'
        }

        const getTime = (date) => {
            return date ? moment(date).format('HH:mm') : '--:--'
        }

        const getGoals = (item) => {
            let walkover = '';
            let host_goals = item.host_goals !== null ? item.host_goals : '-';
            let guest_goals = item.guest_goals !== null ? item.guest_goals : '-';

            if(item.walkover != 'none'){
                walkover = ' v.o';

                if(item.walkover == 'for_host'){
                    host_goals = 3;
                    guest_goals = 0;
                }
                else if(item.walkover == 'for_guest'){
                    host_goals = 0;
                    guest_goals = 3;
                }
                else if(item.walkover == 'equal') {
                    host_goals = 3;
                    guest_goals = 3;
                }

            }
            else {
                if(item.host_id === 0 || item.guest_id === 0){
                    host_goals = '-';
                    guest_goals = '-';
                }
            }

            return host_goals + ":" + guest_goals + walkover;
        }

        return {
            getTime,
            getDate,

            getGoals
        }
    }
}
</script>
