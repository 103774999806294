<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="px-3 pb-12 pt-6 sm:px-6 lg:px-8 ">
            <Link :href="route('admin.dashboard.users')" class="mr-2 mb-6">Wróć</Link>
            <h1
                class="text-xl font-thin mt-2 mb-3 dark:text-white"
            >
                Edycja użytkownika
            </h1>
            <form>
                <div class="mb-2">
                    <InputLabel
                        placeholder="Nazwa użytkownika"
                        id="name"
                        name="new_name"
                        type="text"
                        v-model="user_form.name"
                    />
                </div>
                <!-- v-if="selected_permissions.includes('admin')" -->
                <div class="mb-2">
                    <MultiListbox
                        v-if="adminPermission"
                        :data="permissions"
                        v-model="user_form.permissions"
                        :selected="selected_permissions"
                        type="slug"
                        text="Wybrane uprawnienia"
                    />
                </div>

                <div class="mb-2">
                    <InputLabel
                        placeholder="Email"
                        id="email"
                        name="new_email"
                        type="email"
                        v-model="user_form.email"
                        :autocomplete="false"
                    />
                </div>

                <div class="mb-2">
                    <InputLabel
                        placeholder="Hasło"
                        id="password"
                        name="new_password"
                        type="password"
                        v-model="user_form.password"
                        :autocomplete="false"
                        autofocus="false"
                    />
                </div>
                <small class="text-xs text-gray-700 dark:text-gray-300">* Jeśli puste, hasło nie zostanie zmienione.</small>

                <div class="mt-2 flex justify-end gap-3">
                    <Link @click="editUser()" class="bg-green-600 hover:bg-green-800">
                        <SvgIcon name="check" width="18" height="18" />
                        <span class="ml-2">
                            Zapisz
                        </span>
                    </Link>
                    <Link @click="removeUser()" class="bg-red-600 hover:bg-red-800">
                        <SvgIcon name="trash" width="18" height="18" />
                    </Link>
                </div>

            </form>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
import { Inertia } from '@inertiajs/inertia'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox
        },
        props: {
            user: Object,
            permissions: Array,
            selected_permissions: Array,
            clubs: Array,
            // checkpermission
            adminPermission: Boolean,
        },
        setup(props) {
            const user_form = useForm({
                name: props.user.name,
                password: null,
                email: props.user.email,
                permissions: props.selected_permissions
            });


            const editUser = () => {
                user_form.post(route('admin.post.dashboard.users.edit', props.user.id))
            }

            const removeUser = () => {
                if(confirm('Czy chcesz usunąć użytkownika ' + props.user.name + '?\nWszystkie informacje z nim związane zostaną utracone.')){
                    Inertia.post(route('admin.user.remove', props.user.id))
                }
            }

            return {
                editUser,
                removeUser,
                user_form
            }
        }
    }
</script>

<style>
.tox-tinymce {
    border: 0 !important;
    border-bottom: 2px solid #9ca3af !important;
}
</style>
