<template>
    <!-- props: title, opened, secondary -->

    <div class="col-span-12" :class="openRef ? 'opened' : 'closed'">
        <!-- Roll-up Title -->
        <slot name="top" />
        <div
            class="flex items-center text-gray-600 dark:text-gray-200 cursor-pointer w-full truncate"
            @click="clickEl()"
            :class="{
                'bg-gray-50 dark:bg-gray-900 dark:bg-opacity-50' : secondary,
                [customClass] : customClass,
                'py-3 px-4 h-16' : !customClass
            }"
            v-if="title"
        >
            <span class="pointer-events-none" :class="{'text-normal font-normal capitalised':small}">{{title}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-6 w-6 pointer-events-none transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'transform rotate-180':openRef}">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
        </div>

        <transition
            enter-active-class="transition ease-out duration-200 origin-top"
            enter-from-class="transform opacity-0 scale-y-0"
            enter-to-class="transform opacity-100 scale-y-100"
            leave-active-class="transition ease-in duration-200 origin-top"
            leave-from-class="transform opacity-100 scale-y-100"
            leave-to-class="transform opacity-0 scale-y-0"
        >
        <div
            @click.self="ev => { if(bgClose && openRef) clickEl() }"
            class="grid grid-cols-12 transition-all z-20"
            v-if="openRef"
        >
            <!-- Roll-Up Content -->
            <slot></slot>
        </div>
        </transition>

        <div @click.self="clickEl()" v-if="bgClose && openRef" class="fixed bg-gray-200 bg-opacity-30 dark:bg-gray-900 dark:bg-opacity-30 z-10 inset-0"></div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'

export default {
    components:{

    },
    props:{
        title: {
            type: String,
            default: null
        },
        opened: {
            type: Boolean,
            default: true
        },
        bgClose: Boolean,
        secondary: {
            type: Boolean,
            default: false
        },
        customClass: String,
        small: {
            type: Boolean,
            default: false
        }
    },
    emits: ['clicked'],
    setup(props, {emit}) {

        const openRef = ref(props.opened)

        const clickEl = () => {
            openRef.value = !openRef.value
            emit('clicked', openRef.value);
        }

        watch(() => props.opened, (val, old) => {
            if(!val) openRef.value = val;
        })

        // onMounted(() => {
        //     emit('clicked', openRef.value);
        // })

        return {
            clickEl,
            openRef
        }
    },
}
</script>

<style scoped>
    /* .opened .wrapper{
        max-height: 500vh;
        overflow: hidden;
    } */
    .closed .wrapper{
        max-height: 0;
        overflow: hidden;
    }
</style>
