<template>
    <div class="relative">
        <input
            type="text"
            class="bg-transparent text-gray-800 dark:text-gray-200 dark:border-gray-600 border-gray-400 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 border-b-2 px-4 py-2 outline-none focus:outline-none focus:ring-0"
            placeholder="Szukaj..."
            v-model="serachContent"
            v-on:keyup.enter="search()"
        >
        <SvgIcon
            class="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-800"
            name="search"
            width="20"
            height="20"
            @click="search()"
        />
    </div>
</template>

<script>
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { Inertia } from '@inertiajs/inertia'


    export default {
        components: {
            SvgIcon
        },
        props: {

        },
        setup(props) {
            const serachContent = ref(null);

            const search = () => {
                Inertia.get(route('search', {
                    search: serachContent.value
                }))
            }

            return {
                serachContent,
                search
            }
        }

    }
</script>
