<template>
    <div
        class="flex flex-col relative"
    >
        <label
            v-if="label"
            :for="id"
            class="mb-2 dark:text-white"
        >{{label}}</label>
        <Listbox v-model="selectedValue" v-slot="{ open }">
            <div class="relative"
                :class="[
                    open ? 'z-40' : ''
                ]"
            >
            <ListboxButton
                class="w-full group text-left rounded-none border-0 border-b-2 px-4 py-2 outline-none focus:outline-none focus:ring-0"
                :class="[
                {'bg-white dark:bg-gray-300 dark:border-gray-300 border-gray-400 dark:hover:border-gray-200 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600':colors=='normal'},
                {'text-white border-white':colors=='transparent'}
                ]
                "
                >
                <span
                class="block truncate dark:text-gray-900"
                :class="[{'text-white dark:text-gray-900':colors=='transparent'}]"
                >{{ currentName }}</span>
                <span
                class="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center pr-2 pointer-events-none"
                >
                <SvgIcon
                    name="selector"
                    class=""
                    :class="[
                    {'text-gray-400 group-hover:text-gray-600':colors=='normal'},
                    {'text-white':colors=='transparent'}
                    ]"
                    :size="20"
                />
                </span>

            </ListboxButton>

            <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-gray-300 shadow-lg max-h-80 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                >
                <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="(item, key) in data"
                    :key="type == 'objectkey' ? key : ( (type == 'object' || type == 'teams' || type == 'model' || type == 'queue') ? item.id : Array.isArray(data)) ? key : item.id"
                    :value="type == 'teams' || type == 'queue' ? item.id : type == 'objectkey' ? key : type == 'object' ? item.id : ((type == 'teams' || type == 'model') ? item.id : Array.isArray(data)) ? key : item.id"
                    @click="currentName = getName(item)"
                    as="template"
                >
                    <li
                        :class="[
                            active ? 'text-red-900 bg-red-100' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-10 pr-4',
                            selected || (type == 'objectkey' && key==selectedValue) ? 'bg-green-200 text-gray-900 hover:bg-green-100 hover:text-gray-800':''
                        ]"
                    >
                    <span
                        :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                        ]"
                        >{{ getName(item) }}</span
                    >
                    <span
                        v-if="selected"
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                    >

                    </span>
                    </li>
                </ListboxOption>
                </ListboxOptions>
            </transition>
            </div>
        </Listbox>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import SvgIcon from '@/Components/SvgIcon'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        SvgIcon
    },

    // props: ['data', 'modelValue', 'type'],
    props: {
        data: {
            type: Object,
            default: null
        },
        modelValue: [String, Number],
        type: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        colors: {
            type: String,
            default: 'normal'
        },
        selected: {
            type: [String, Number],
            default: null
        },
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const currentName = ref(null);
        const selectedValue = ref(props.modelValue);
        onMounted(() => {
        //     if (props.selected && props.type == 'objectkey') {
        //         currentName.value = props.data[props.selected]
        //     }
        // })
            getValue();
        })

        function getValue(){
            if (props.type == 'objectkey') {
                if (props.selected !== null) {
                    currentName.value = props.data[props.selected]
                }
                else currentName.value = 'Brak';
            }
            else if (props.type == 'object') {
                currentName.value = 'Brak';
                props.data.forEach(function(el){
                    if (el.id == props.modelValue) currentName.value = el.name
                })
            }

            else if (props.type == 'teams') {
                // console.log('1');
                currentName.value = 'Brak drużyny';
                props.data.forEach(function(el){
                    if (el.id == selectedValue.value) {
                        // console.log(el.id, selectedValue.value)
                        // console.log(el.club_laczynaspilka_name + ' - ' + el.name)
                        currentName.value = el.club_laczynaspilka_name + ' - ' + el.name
                        return false;
                    }
                    // else currentName.value = 'Brak drużyny';
                })
            }

            else if (props.type == 'model') {
                if (props.selected !== null) {
                    currentName.value = props.data[props.selected].name
                }
                else currentName.value = 'Brak';
                // else currentName.value = props.data[0].name;
            }

            else if (props.type == 'queue') {
                if (props.selected !== null) {
                    currentName.value = props.data[props.selected].id
                }
                else currentName.value = 'Brak';
            }

            else {
                if((props.modelValue) && Array.isArray(props.data)){
                    if(props.type == 'key') {
                        if(typeof props.data[props.modelValue] == 'string') currentName.value = props.data[props.modelValue];
                        else currentName.value = props.data[props.modelValue].name;
                    }
                    else currentName.value = props.data.find(e => e.id == props.modelValue).name;
                }
                else if(props.data[props.modelValue]){
                    if(typeof props.data[props.modelValue] == 'string') currentName.value = props.data[props.modelValue];
                    else currentName.value = props.data[props.modelValue].name;
                }
                else currentName.value = Array.isArray(props.data) ? props.data[0] : props.data[Object.keys(props.data)[0]].name;
            }
        }

        const getName = (item) => {
            if (props.type == 'objectkey')  return item
            else if (props.type == 'teams') return item.club_laczynaspilka_name + ' - ' + item.name
            else if (props.type == 'object') return item.name
            else if (props.type == 'model') return item.name
            else if (props.type == 'queue') return item.number
            else if (Array.isArray(props.data)) return item.name ? item.name : item;
            else return item.name
        }

        watch(selectedValue, (val, prevVal) => {
            emit('update:modelValue', val);
        })

        return {
            selectedValue,
            currentName,
            getName,
        }
    },

    watch: {
        modelValue(val) {
            this.selectedValue = val;
        }
    }
}
</script>
