<template>
    <AdminLayout>
        <div class="mb-5">

            <!-- 4 kafelki na górze -->
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">
                <div
                v-for="tile in tiles"
                :key="tile"
                >
                    <inertia-link
                        :href="tile.url"
                    >
                        <div
                        class="bg-green-500 hover:bg-green-600 dark:bg-gray-900 dark:hover:bg-opacity-50 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 hover:border-green-700 dark:border-gray-600 text-white font-medium group"
                        >
                            <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                                <SvgIcon
                                :name="tile.icon"
                                width="30"
                                height="30"
                                class="text-green-800"
                                />
                            </div>
                            <div class="text-right">
                            <p class="text-2xl">{{tile.value}}</p>
                            <p>{{tile.title}}</p>
                            </div>
                        </div>
                    </inertia-link>
                </div>
            </div>


            <div class="px-4 mt-2">
            <div class="relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-gray-200 text-black dark:text-gray-100 dark:bg-gray-800 w-full rounded">
                <div class="rounded-t mb-0 px-0 border-0">
                <div class="flex flex-wrap items-center px-4 py-2">
                    <div class="relative overflow-x-scroll lg:overflow-x-auto w-full max-w-full flex-grow flex-1">
                        <div class="flex">
                            <inertia-link
                                :href="route(route().current(), { view: 'main' })"
                                class="uppercase text-sm leading-normal text-black dark:text-gray-100 cursor-pointer"
                                @success="current_view = 'main'"
                                :class="{'text-green-500 dark:text-green-500': current_view == 'main'}"
                            >Główne ({{ $props.views['main'] ? (typeof $props.views['main'] === 'object' ? Object.keys($props.views['main']).length : $props.views['main']) : '0'}})</inertia-link>
                            <inertia-link
                                :href="route(route().current(), { view: 'news' })"
                                class="uppercase text-sm leading-normal text-black dark:text-gray-100 cursor-pointer ml-4"
                                @success="current_view = 'news'"
                                :class="{'text-green-500 dark:text-green-500' :current_view == 'news'}"
                            >Newsy ({{ $props.views['news'] ? (typeof $props.views['news'] === 'object' ?
                                $props.views['news'].total : $props.views['news']) : '0'}})</inertia-link>
                            <inertia-link
                                :href="route(route().current(), { view: 'categories' })"
                                class="uppercase text-sm leading-normal text-black dark:text-gray-100 cursor-pointer ml-4"
                                @success="current_view = 'categories'"
                                :class="{'text-green-500 dark:text-green-500' :current_view == 'categories'}"
                            >Kategorie ({{ $props.views['categories'] ? (typeof $props.views['categories'] === 'object' ? $props.views['categories'].total : $props.views['categories']) : '0'}})</inertia-link>
                            <inertia-link
                                :href="route(route().current(), { view: 'clubs' })"
                                class="uppercase text-sm leading-normal text-black dark:text-gray-100 cursor-pointer ml-4"
                                @success="current_view = 'clubs'"
                                :class="{'text-green-500 dark:text-green-500' :current_view == 'clubs'}"
                            >Kluby ({{ $props.views['clubs'] ? (typeof $props.views['clubs'] === 'object' ? $props.views['clubs'].total : $props.views['clubs']) : '0'}})</inertia-link>
                            <inertia-link
                                :href="route(route().current(), { view: 'leagues' })"
                                class="uppercase text-sm leading-normal text-black dark:text-gray-100 cursor-pointer ml-4"
                                @success="current_view = 'leagues'"
                                :class="{'text-green-500 dark:text-green-500' :current_view == 'leagues'}"
                            >Ligi ({{ $props.views['leagues'] ? (typeof $props.views['leagues'] === 'object' ? $props.views['leagues'].total : $props.views['leagues']) : '0'}})</inertia-link>
                        </div>
                    </div>
                </div>
                <div class="block w-full overflow-x-auto bg-white relative" id="table-result">
                    <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                        <th
                        class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >Strona</th>
                        <th
                        class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >Ostatnie wyświetlenie</th>
                        <th
                        class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
                        >Odwiedziny</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="view in $props.currect_view_table.data"
                        :key="view"
                        class="text-gray-700 dark:text-gray-100 bg-white dark:bg-gray-600"
                        >
                            <th class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                <inertia-link v-if="current_view == 'news'" :href="route('news.id', view.slug)">
                                    {{ view.title }}, <span class="text-xs font-normal">{{ getDate(view.created_at) }}</span>
                                </inertia-link>
                                <inertia-link v-else-if="current_view == 'categories'" :href="route('page.display', view.slug)">
                                    {{ view.name }}
                                </inertia-link>
                                <inertia-link v-else-if="current_view == 'leagues'" :href="route('league', { league: view.slug, season: view.season.year })">
                                    {{ view.name }} / {{ view.season.year }}
                                </inertia-link>
                                <inertia-link v-else-if="current_view == 'clubs'" :href="route('singleclub', view.id)">
                                    {{ view.name }}
                                </inertia-link>
                                <inertia-link v-else :href="getRoute(view)">
                                    {{ view.name }}
                                </inertia-link>
                            </th>
                            <td  class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <span class="bg-gray-600 px-4 py-2 rounded-full text-white font-bold">{{getDate(view.last_visited)}}</span>
                            </td>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <div class="flex items-center">
                                <span class="mr-2">{{view.visited}}</span>

                                <Progress
                                    :percent="getPercent(view.visited)"
                                />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
                <div class="flex justify-center" v-if="$props.currect_view_table.links">
                        <pagination class="mt-6" :links="$props.currect_view_table.links" />
                    </div>
            </div>
            </div>

        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted, onUnmounted } from 'vue'
    import moment from 'moment'
    import Pagination from '@/Components/Pagination'
    import Progress from '@/Components/Progress'
    import { Inertia } from '@inertiajs/inertia'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Progress
        },
        props: {
            news: Number,
            users: Number,
            adds: Number,
            clubs: Number,
            current_season: Object,
            max_views: Number,
            views: Object,
            currect_view_table: [Array, Object],
        },
        setup(props) {
            const sorted_views = ref({});
            const views_settings = ref({});
            const current_view = ref(route().params.view ? route().params.view : 'main');

            // onUnmounted(
            //     Inertia.on('start', () => {
            //         nextTick(() => {
            //             if(document.getElementById('table-result')){
            //                 document.getElementById('table-result').insertAdjacentHTML('afterbegin', `
            //                 <div class="loading-table absolute flex items-start justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50">
            //                     <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            //                 </div>`)
            //             }
            //         })
            //     })
            // )

            const tiles = ref([
                {
                    title: 'Newsy',
                    value: props.news,
                    icon: 'pencil',
                    url: route('admin.news.list')
                },
                {
                    title: 'Aktualny sezon',
                    value: props.current_season ? (props.current_season.type ? props.current_season.year + '/' + (props.current_season.year + 1) : (props.current_season.year - 1) + '/' + props.current_season.year) : '-',
                    icon: 'user',
                    url: route('admin.events.seasons')
                },
                {
                    title: 'Reklamy',
                    value: props.adds,
                    icon: 'cash',
                    url: route('admin.adds.main')
                },
                {
                    title: 'Kluby',
                    value: props.clubs,
                    icon: 'chart-bar',
                    url: route('admin.events.clubs')
                }
            ])

            const getPercent = (value) => {
                if (value > 0) {

                    return Math.round((value * 100) / props.max_views)
                }
                else {
                    return 0
                }
            }

            const getDate = (date) => {
                if(date){
                    date = moment(date);
                    return date.isValid() ? date.format('YYYY-MM-DD HH:mm') : 'Brak wyświetleń'
                }
                else return 'Brak danych';
            }

            const getRoute = (view) => {
                if (current_view.value == 'main') return route(view.route)
                else return route(view.route, view.id)
            }
            // const getViews = () => {
            //     return sorted_views.value[current_view.value];
            //     // return sorted_views.value[current_view.value].slice((views_settings.value[current_view.value].current_page - 1)*views_settings.value[current_view.value].per_page, views_settings.value[current_view.value].per_page * views_settings.value[current_view.value].current_page)
            // }


            onMounted(() => {
                // Object.keys(props.views).forEach(function(key) {
                //     sorted_views.value[key] = []
                //     views_settings.value[key] = []
                //     // console.log(props.views[key])
                //     let keysSorted = Object.keys(props.views[key]).sort(function(a,b){return props.views[key][b].value - props.views[key][a].value})
                //     keysSorted.forEach((el) => {
                //         sorted_views.value[key].push({
                //             id: props.views[key][el].id,
                //             name: props.views[key][el].name,
                //             slug: props.views[key][el].slug,
                //             value: props.views[key][el].value,
                //             date: getDate(props.views[key][el].date),
                //             route: props.views[key][el].route
                //         })
                //     })
                //     // current_page: 1,
                //     // per_page: 5,
                //     // pages: 1
                //     views_settings.value[key].current_page = 1;
                //     views_settings.value[key].per_page = 10;
                //     views_settings.value[key].pages = Math.ceil(sorted_views.value[key].length / views_settings.value[key].per_page)
                // })


                // let keysSorted = Object.keys(props.views).sort(function(a,b){return props.views[b].value-props.views[a].value})
                // keysSorted.forEach((el) => {
                //     sorted_views.value.push({
                //         name: props.views[el].name,
                //         value: props.views[el].value,
                //         date: moment(props.views[el].date).format('DD.MM.YYYY, HH:mm'),
                //         route: props.views[el].route
                //     })
                // })
                // views_settings.value.pages = Math.ceil(sorted_views.value.length / views_settings.value.per_page)
                // props.views.sort((a, b) => (a.value > b.value) ? 1 : -1)
            })

            return {
                tiles,
                sorted_views,
                getPercent,
                views_settings,
                current_view,
                getRoute,
                // getViews,
                getDate
            }
        }
    }
</script>
