<template>
<div class="mb-3">
        <AddBar
            :addImg="'/storage/adds/'+category.locations[0].adds[0].images[0].name"
            :addHref="category.locations[0].adds[0].url"
            v-if="category.locations.length > 0 && category.locations[0].adds[0]"
            class="mb-4"
        />
        <!-- First Card -->
        <div style="min-height: 4rem;" class="text-lg flex shadow-md text-center mb-3 dark:bg-gray-900 dark:text-gray-200" >
            <inertia-link
                class="px-4 py-3 w-full flex items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:bg-opacity-50"
                :href="route('box', category.slug)"
            >{{category.name}}</inertia-link>
        </div>
        <div
            class="block relative w-full col-span-3"
            v-if="mainNews"
        >
            <div class="relative overflow-hidden shadow-md border-gray-400 w-full h-full">
                <inertia-link
                    class="block bg-no-repeat bg-cover bg-bottom overflow-hidden"
                    :class="{
                            ['bg-'+bgsize] : bgsize
                        }"
                    :href="route('news.id', mainNews.slug)"
                >
                    <img
                        class="mx-auto w-full transform hover:scale-125 transition"
                        :src="mainNews.images.length ?
                            (mainNews.images[0].thumb ? ('/storage/thumbnails/' + mainNews.images[0].thumb) : ('/storage/news/' + mainNews.images[0].name)) :
                            ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')"
                    />
                </inertia-link>

                <div class="overflow-hidden absolute bottom-0 left-0 right-0">
                    <div class="bg-black bg-opacity-60 absolute -inset-4 bg-cover bg-bottom"></div>
                    <div class="text-white lg:text-white darl:lg:text-white dark:text-gray-400 relative z-20 px-4 text-xs lg:text-normal font-thin mt-3 mb-1" v-if="mainNews.created_at">{{ currentDateTime(mainNews.created_at) }}</div>
                    <div class="text-white lg:text-white dark:lg:text-white dark:text-gray-200 relative z-20 px-4 font-bold text-xs lg:text-normal uppercase mb-3" v-html="mainNews.title"></div>
                </div>
            </div>

            <!-- <div class="flex text-gray-500 dark:text-gray-300">
                <inertia-link class="w-full p-2 dark:hover:bg-gray-900 hover:bg-gray-200" :href="route('news.id', mainNews.slug)" >{{ mainNews.title }}</inertia-link>
            </div> -->
        </div>
        <div class="text-white">
            <div
                v-for="(n, key) in category.news.slice(0, category.rows)" :key="key"
                class="relative z-20 z-12 flex text-gray-500 dark:text-gray-300 odd:bg-gray-100 dark:odd:bg-gray-900 dark:odd:bg-opacity-50"
            >
                <inertia-link class="p-2 w-full flex flex-col text-sm dark:hover:bg-gray-900 hover:bg-gray-200" :href="route('news.id', n.slug)">
                    <span class="text-xs mb-0.5 text-gray-400 whitespace-nowrap">{{getDate(n.created_at)}}</span>
                    <span v-html="n.title"></span>
                </inertia-link>
            </div>



        </div>



    	  <!-- {{ category.locations }} -->



</div>
</template>

<style scoped>
    .padding-60{
        padding-top: 66.66%;
    }

</style>

<script>
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import AddBar from '@/Components/Adds/addBar'
import moment from 'moment';

    export default {
        components: {
            AddBar,
        },
        props: {
            category: [Object, Array],
            title: {
                type: String,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            color: {
                type: String,
                default: 'black'
            },
            bgsize:{
                type: String,
                default: 'cover'
            },
            box2: [Object, Array],
        },
        setup(props) {

            const mainNews = ref(null);

            const randInt = (min, max) => { // min and max included
                return Math.floor(Math.random() * (max - min + 1) + min)
            }

            const randAdd = () => {
                return props.category.adds[randInt(0, props.category.adds.length - 1)]
            }

            const getPosts = (from, num) => {
                let test = JSON.parse(JSON.stringify(props.category.news));
                return test.slice(from, from + num);
            }

            onMounted(() => {
                let checker = false;
                if(props.category.main_news) {
                    mainNews.value = props.category.main_news;
                    checker = true;
                }

                // Jeśli nie ma głownego posta, pierwszy będzie głównym
                if (!checker) {
                    if (props.category.news.length) {
                        mainNews.value = props.category.news[0];
                        props.category.news.splice(0, 1);
                    }
                }
            })

            function getDate(date){
                return moment(date).format('DD/MM/YYYY HH:mm')
            }

            function currentDateTime(date) {
                return moment(date).format('DD.MM.YYYY')
            }


            return {
                currentDateTime,
                randAdd,
                mainNews,
                getPosts,
                getDate
            }
        }
    }
</script>

