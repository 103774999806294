<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <h1
                class="text-xl font-thin  mb-3 dark:text-white"
            >
                Nowy baner reklamowy
            </h1>
            <form>
                <div class="mt-2">
                        <InputLabel
                        placeholder="Tytuł"
                        id="name"
                        name="name"
                        type="text"
                        v-model="add.name"
                    />
                </div>

                <div class="mt-2">
                        <InputLabel
                        placeholder="Url"
                        id="url"
                        name="url"
                        type="text"
                        v-model="add.url"
                    />
                </div>

                <div class="mt-2">
                    <MultiListbox
                    :data="locations"
                    v-model="add.locations"
                    type="array"
                    text="Wybrane Lokalizacja"
                />
                </div>

                <!-- <div class="mt-2">
                    <Listbox
                    :data="age_categories"
                    v-model="add.category"
                    type="objectkey"
                />
                </div>

                <div class="mt-2">
                    <Listbox
                    :data="positions"
                    type="objectkey"
                    v-model="add.position"
                    required
                />
                </div> -->

                <ImageUpload
                    class="mt-2"
                    @imagesUploaded="changeImage"
                    :autosend="true"
                />


                <div
                    class="flex justify-end mt-4 mb-10"

                >
                    <Link class="bg-green-600 hover:bg-green-800"
                    @click="addAdd"
                    >
                        <SvgIcon name="plus" width="18" height="18" />
                        <span class="ml-2">Dodaj</span>
                    </Link>
                </div>

            </form>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, forceUpdate, onMounted } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
    import ImageUpload from '@/Components/ImageUpload'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox,
            ImageUpload
        },
        props: {
            age_categories: Array,
            categories: [Object, Array],
            locations: [Object, Array]
        },
        setup(props) {
            const add = useForm({
                name: null,
                location: null,
                locations: [],
                category: null,
                categories: [],
                url: null,
                image: null,
            });

            const addAdd = () => {
                add.post(route('admin.post.adds.add'))
            }

            const changeImage = (e) => {
                add.image = e;
            }

            // Flush categories in next tick, because vue renders last state until page refreshes
            nextTick(() => {add.categories = [];})



            return {
                add,
                addAdd,
                changeImage
            }
        }
    }
</script>

<style>
.tox-tinymce {
    border: 0 !important;
    border-bottom: 2px solid #9ca3af !important;
}
</style>
