<template>
    <div >
        <Notifications />
        <div class="min-h-screen bg-white">
            <!-- Navbar -->
            <Navbar :adsLogo="adsLogo"/>
            <div class="w-full flex justify-center">

                <!-- Page Content -->
                <main
                    class="w-full bg-gray-50 dark:bg-gray-800"
                    style='min-height: 100vh'
                >
                    <slot />
                </main>
            </div>
            <footer class="w-full bg-gray-700 dark:bg-gray-900">
                <div class="xl:w-10/12 mx-auto sm:p-5 p-3 xl:pr-5 sm:pr-20">
                    <div class="grid grid-cols-4 gap-3 pb-3">
                        <div class="col-span-full flex items-center justify-start gap-4 text-gray-400">
                            <a target="_blank" href="https://www.facebook.com/Futmal/">
                            <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer hover:text-blue-600" fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
                            </a>
                            <a target="_blank" href="https://www.youtube.com/user/FutbolMalopolska">
                            <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer hover:text-red-700"  fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/></svg>
                            </a>
                            <a target="_blank" href="https://twitter.com/FutMal_pl">
                            <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer hover:text-blue-400"  fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z"/></svg>
                            </a>
                        </div>
                        <div class="col-span-full flex items-center gap-3 flex-wrap sm:flex-row flex-col">
                            <BreezeApplicationLogo
                                class="w-24 h-auta"
                            />
                            <div class="space-y-4 sm:my-0 my-4 text-gray-200">
                                <div>
                                    <ul class="flex gap-2 sm:justify-start justify-center flex-wrap">
                                        <li><inertia-link class="hover:text-green-600 hover:border-green-600 border-b-2 border-transparent" :href="route('newspage')">Wiadomości</inertia-link></li>
                                        <li><inertia-link class="hover:text-green-600 hover:border-green-600 border-b-2 border-transparent" :href="route('results')">Wyniki</inertia-link></li>
                                        <li><inertia-link class="hover:text-green-600 hover:border-green-600 border-b-2 border-transparent" :href="route('archives')">Archiwum</inertia-link></li>
                                        <li>
                                            <inertia-link class="hover:text-green-600 hover:border-green-600 border-b-2 border-transparent"
                                                :href="route('contact')"
                                            >Kontakt</inertia-link>
                                        </li>
                                    </ul>
                                </div>
                                <ul class="flex gap-2 sm:justify-start justify-center flex-wrap text-sm">
                                    <li
                                        v-for="page in $page.props.pages"
                                        :key="page.id"
                                    >
                                        <inertia-link
                                            class="hover:text-green-600 hover:border-green-600 border-b-2 border-transparent"
                                            :href="route('page.display', page.slug)"
                                        >{{ page.title }}</inertia-link>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="$page.props.ads_footer" class="sm:max-w-xs w-full sm:flex-auto flex-1 sm:ml-auto sm:m-0 m-auto">
                                <a
                                :href="$page.props.ads_footer.url"
                                v-if="$page.props.ads_footer"
                                target="_blank"
                                >
                                    <div class="w-full flex items-center justify-center overflow-hidden h-full lg:relative md:flex">
                                        <img
                                            v-if="'/storage/adds/'+$page.props.ads_footer.images[0].name"
                                            :src="'/storage/adds/'+$page.props.ads_footer.images[0].name"
                                            class="max-h-full object-cover sm:ml-auto ml-auto sm:mr-0 mr-auto" readonly>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="text-gray-400 text-xs pb-3 flex flex-wrap items-center gap-1">
                        <span class="font-bold text-sm">Tagi:</span>
                        <span class="-mb-1" v-for="(k, key) in $page.props.keywords" :key="key">{{ k }}</span>
                    </div>
                    <div class="sm:pr-0 pr-12 sm:-mb-3 text-gray-400 text-xs flex sm:flex-row flex-col sm:items-center items-start justify-between">
                        <div class="sm:mb-0 mb-2">&copy; {{ new Date().getFullYear() }} Copyright Futbol-Małopolska, Wszelkie prawa zastrzeżone</div>
                        <div>Created by <a href="https://webfalcon.pl/" class="hover:underline">WEBFALCON</a></div>
                    </div>
                </div>
                <div class="fixed sm:bottom-6 bottom-3 sm:right-6 right-3 z-40 flex items-center justify-center p-2 text-white bg-green-600 rounded-full hover:bg-white hover:text-green-600 cursor-pointer shadow-md"
                @click="scrollToTop()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                </div>
                <div @click="changeMode()" class="fixed z-40 sm:bottom-20 bottom-16 sm:right-6 right-3 flex items-center justify-center p-2 text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-700 rounded-full cursor-pointer shadow-md">
                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    </svg>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
    import Navbar from '@/Components/Navbar'
    import BreezeApplicationLogo from '@/Components/ApplicationLogo'
    import BreezeDropdown from '@/Components/Dropdown'
    import BreezeDropdownLink from '@/Components/DropdownLink'
    import BreezeNavLink from '@/Components/NavLink'
    import BreezeResponsiveNavLink from '@/Components/ResponsiveNavLink'
    import Notifications from '@/Components/Notifications'
    import { ref, watch, reactive, onMounted } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3'

    export default {
        components: {
            BreezeApplicationLogo,
            BreezeDropdown,
            BreezeDropdownLink,
            BreezeNavLink,
            BreezeResponsiveNavLink,
            Navbar,
            Notifications,
        },
        props: {
            // ads
            adsLogo: [Object, Array],
        },
        methods: {
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
           }
        },
        setup() {
            const isDarkMode = () => {
                return localStorage.getItem('fbmpl_Futmal') ?
                    ( localStorage.getItem('fbmpl_Futmal') == 'true' ? true : false ) :
                        (usePage().props.value.default_mode && usePage().props.value.default_mode.value == 'dark' ? true : false);
            }

            const changeMode = () => {
                if (isDarkMode()) {
                    document.documentElement.classList.remove('dark')
                    localStorage.setItem('fbmpl_Futmal', 'false')
                }
                else {
                    document.documentElement.classList.add('dark')
                    localStorage.setItem('fbmpl_Futmal', 'true')
                }
            }

            onMounted(()=>{
                if (isDarkMode()) document.documentElement.classList.add('dark')
                else document.documentElement.classList.remove('dark')
            })

            return {
                isDarkMode,
                changeMode,
            }
        }
    }
</script>
