<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="flex justify-between mb-3 gap-4">
                <Link :href="route('admin.adds.add')" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Dodaj</span>
                </Link>
            </div>
            <div
            class="rounded-md overflow-hidden shadow-sm mb-4"
            >
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-3">Reklamy</div>
                    <div class="py-3 pr-3">Akcje</div>
                </div>
                <div
                v-if="adds.length"
                class="text-gray-600 text-sm font-light"
                >
                    <div
                        v-for="(n, key) in adds"
                        :key="key"
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white cursor-pointer flex flex-row justify-between items-center"
                    >
                        <div class="py-2 ml-3 hidden sm:block">
                            <div class="w-16 h-16 overflow-hidden rounded flex items-center justify-center relative">
                                <img class="w-auto h-full flex-shring-0" v-if="n.images.length"
                                    v-lazy="{
                                        src: '/storage/adds/'+n.images[0].name,
                                        loading: '/img/cms/loading.gif',
                                        error: '/img/cms/img-error.png'
                                    }"
                                >
                            </div>
                        </div>
                        <div class="flex w-full items-start lg:items-center justify-between flex-col lg:flex-row mr-5 sm:ml-2">
                            <div class="pl-3 py-1 font-bold">{{n.name}} </div>
                            <div class="pl-3 py-1 flex text-gray-500 dark:text-gray-400">
                                <SvgIcon name="link" width="18" height="18" class="mr-2 flex-shrink-0"/>
                                {{n.url}}</div>
                            <div class="pl-3 py-1 text-gray-500 dark:text-gray-400">
                                <div
                                class="flex"
                                v-for="c in n.locations"
                                :key="c"
                                >
                                    <SvgIcon name="puzzle" width="18" height="18" class="mr-2 flex-shrink-0"/>
                                    {{c.name}}
                                </div>
                            </div>
                        </div>
                        <div class="py-1 mr-3 text-center flex ">
                            <Link :href="route('admin.adds.edit', n.id)" class="bg-green-600 hover:bg-green-800">
                                <SvgIcon name="pencil" width="18" height="18" class=""/>
                                <span class="ml-2 hidden lg:block">Edytuj</span>
                            </Link>
                            <Link @click="showRemove = n.id" class="bg-red-600 hover:bg-red-800 ml-2">
                                <SvgIcon name="x" width="18" height="18" class=""/>
                                <!-- <span class="ml-2 hidden lg:block">Usuń</span> -->
                            </Link>
                        </div>

                        <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                             v-if="showRemove == n.id"
                        >
                            <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                                <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć Reklamę "{{n.name}}"?</h2>
                                <div class="flex">
                                    <Link @click="removeAdd(n.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                                        <SvgIcon name="check" width="18" height="18" />
                                        <span class="ml-2">Tak, Usuń</span>
                                    </Link>
                                    <Link @click="showRemove=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                        <SvgIcon name="x" width="18" height="18" />
                                        <span class="ml-2">Nie, anuluj operację</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div
                class="text-center p-3 bg-white dark:bg-gray-600 dark:text-white uppercase text-2xl font-bold"
                v-else
                >
                Brak
                </div>

            </div>
            <!-- <pagination class="mt-6" :links="adds.links" /> -->
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import Pagination from '@/Components/Pagination'
    import { Inertia } from '@inertiajs/inertia'
    import { useForm } from '@inertiajs/inertia-vue3'
    import { ref } from '@vue/reactivity';

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination
        },
        props: {
            adds: [Object, Array]
        },
        setup() {
            const showRemove = ref(false);
            const removeAdd = (id) => {
                Inertia.post(route('admin.post.adds.remove', id))
                showRemove.value = false;
            }
            const removePopup = (key) => {
                showRemove.value = true;
            }
            return {
                removeAdd,
                removePopup,
                showRemove
            }
        }
    }
</script>
