<template>
<MainLayout>
    <inertia-head>
        <title>{{ $props.page.title }} - {{ $page.props.app_name }}</title>
    </inertia-head>

    <div class="xl:w-10/12 m-auto sm:p-5 p-3">
    <div class="sm:p-5 p-3 shadow-md w-full bg-white dark:bg-gray-900 mb-4">
        <div class="dark:text-white w-full text-center pb-4">
            <h1 class="lg:text-3xl md:text-3xl text-2xl">{{ $props.page.title }}</h1>
        </div>

        <TinyContent class="dark:text-white" :value="$props.page.description"></TinyContent>
    </div>
    </div>
</MainLayout>
</template>


<script>
    import MainLayout from '@/Layouts/Main'
    import SvgIcon from '@/Components/SvgIcon'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox';
    import TinyContent from '@/Components/TinyContent';

    export default {
        components: {
            MainLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            TinyContent
        },
        props: {
            page: [Array, Object],
        },
        setup(props){
            return {

            }
        }
    }
</script>
