<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <h1
                class="text-xl font-thin mb-3 dark:text-white"
            >
                Dodawanie nowego wpisu
            </h1>
            <form>
                <InputLabel
                    placeholder="Tytuł"
                    label="Tytuł"
                    id="title"
                    name="title"
                    type="text"
                    v-model="newsForm.title"
                />

                <div class="mt-2 grid grid-cols-2 gap-2 lg:gap-4">
                    <MultiListbox
                        :data="categories"
                        v-model="newsForm.categories"
                        :selected="newsForm.categories"
                        class="col-span-2 lg:col-span-1"
                        type="array"
                        text="Wybrane kategorie"
                        id="categories"
                    />
                    <MultiListbox
                        :data="boxes"
                        v-model="newsForm.boxes"
                        :selected="newsForm.boxes"
                        class="col-span-2 lg:col-span-1"
                        type="array"
                        text="Wybrane boxy"
                    />
                </div>
                <div class="grid grid-cols-2 gap-4 mt-4">
                    <div class="flex flex-col gap-4">
                        <div>
                            <div class="flex items-center dark:text-white">
                                <Checkbox
                                    v-model="newsForm.is_league_news"
                                    :checked="newsForm.is_league_news"
                                    id="is_league_post"
                                    class="w-5 h-5"
                                />
                                <Label
                                    class="ml-2 dark:text-white"
                                    for="is_league_post"
                                >Wpis ligowy</Label>
                            </div>
                            <MultiListbox
                                v-if="newsForm.is_league_news"
                                :data="$props.leagues"
                                v-model="newsForm.leagues"
                                :selected="newsForm.leagues"

                                class="col-span-2 lg:col-span-1 mt-2"
                                type="array"
                                text="Wpis do ligi"
                                id=""
                            />
                        </div>
                        <div>
                            <div class="flex items-center dark:text-white">
                                <!-- <input type="checkbox" id="is_club_result" v-model="newsForm.is_result">
                                <label for="is_club_result" class="ml-2">Wpis do wyniku</label> -->
                                <Checkbox
                                    v-model="newsForm.is_result"
                                    :checked="newsForm.is_result"
                                    id="is_club_result"
                                    class="w-5 h-5"
                                />
                                <Label
                                    class="ml-2 dark:text-white"
                                    for="is_club_result"
                                >Wpis do wyniku</Label>
                            </div>
                            <div
                                v-if="newsForm.is_result"
                                class="my-2"
                            >
                                <InputLabel
                                    placeholder="ID elementu kolejki"
                                    id="results"
                                    name="results"
                                    type="text"
                                    class="mt-2"
                                    v-model="newsForm.results"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="flex items-center dark:text-white">
                                <label for="is_main_news">Główny wpis</label>
                            </div>
                            <MultiListbox
                                :data="$props.categories"
                                v-model="newsForm.main_news_category"
                                :selected="newsForm.main_news_category"

                                class="col-span-2 lg:col-span-1 mt-2"
                                type="array"
                                text="Główny wpis kategorie"
                                id=""
                            />
                            <MultiListbox
                                :data="$props.boxes"
                                v-model="newsForm.main_news_boxes"
                                :selected="newsForm.main_news_boxes"

                                class="col-span-2 lg:col-span-1 mt-2"
                                type="array"
                                text="Główny wpis box"
                            />
                        </div>
                        <!-- <div>
                            <div class="flex items-center dark:text-white">
                                <input type="checkbox" id="is_main_news" v-model="newsForm.is_main_news">
                                <label for="is_main_news" class="ml-2">Główny wpis</label>
                            </div>
                            <MultiListbox
                                v-if="newsForm.is_main_news"
                                :data="categories"
                                v-model="newsForm.main_news_category"
                                class="col-span-2 lg:col-span-1 mt-2"
                                type="array"
                                text="Główny wpis kategorie"
                                id=""
                            />
                        </div> -->
                        <div class="flex items-center dark:text-white">
                            <!-- <input type="checkbox" id="is_main_news_link" v-model="newsForm.is_main_news_link">
                            <label for="is_main_news_link" class="ml-2">Główny wpis link</label> -->
                            <Checkbox
                                v-model="newsForm.is_main_news_link"
                                :checked="newsForm.is_main_news_link"
                                id="is_main_news_link"
                                class="w-5 h-5"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="is_main_news_link"
                            >Główny wpis link</Label>
                        </div>
                    </div>
                    <div>
                        <div class="flex items-center dark:text-white">
                            <label for="is_club_post">Wpis klubowy</label>
                        </div>
                        <div class="mt-2 flex flex-wrap gap-2" v-if="newsForm.clubs.length">
                            <div
                                class="bg-green-600 pl-2 text-white text-sm rounded-2xl"
                                v-for="club in newsForm.clubs"
                                :key="club"
                            >
                                <span>{{ $props.clubs.find(e => e.id == club).laczynaspilka_name }}</span>
                                <button
                                    type="button"
                                    @click="setClub(club, true)"
                                    class="ml-2 bg-gray-600 hover:bg-red-600 rounded-full w-6 h-6"
                                >
                                    x
                                </button>
                            </div>
                        </div>
                        <div class="my-2">
                            <InputLabel
                                placeholder="Szukaj klubu"
                                id="club"
                                name="club"
                                type="text"
                                v-model="clubSearch"
                            />
                            <div class="max-h-48 overflow-y-auto bg-white">
                                <div
                                    v-for="club in getClubs()"
                                    :key="club.id"
                                    @click="setClub(club)"
                                    class="px-4 py-1 border-b border-gray-200 cursor-pointer flex items-center"
                                    :class="newsForm.clubs.includes(club.id) ? 'bg-green-50 text-green-900':''"
                                >
                                    {{club.laczynaspilka_name}}
                                    <SvgIcon
                                    name="check"
                                    class="text-green-900 ml-2"
                                    v-if="newsForm.clubs.includes(club.id)"
                                    :size="20"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="mt-3"><label class="dark:text-white">Galeria</label></div>
                    <Listbox
                        :data="gallerries"
                        v-model="newsForm.gallery"
                        class="mt-2"
                        type="model"
                    />
                    <div v-if="!mainImage" class="grid grid-cols-2 gap-3 my-3">
                        <div>
                            <label
                            for="files"
                            class="block w-full md:py-2 lg:py-4 border-2 text-base font-bold transition-all cursor-pointer text-center text-green-700 border-green-700 dark:border-green-500 dark:text-green-500 hover:text-white hover:bg-green-700"
                            >
                            Dodaj nową grafikę</label>


                            <input type="file" class="hidden" name="filess" id='files' @change="onFileChange(mainImage, $event)">
                        </div>
                        <button
                            type="button"
                            @click="useExistImage = !useExistImage"
                            class="block w-full md:py-2 lg:py-4 border-2 text-base font-bold transition-all cursor-pointer text-center text-blue-700 border-blue-700 dark:border-blue-500 dark:text-blue-500 hover:text-white hover:bg-blue-700"
                        >
                            Dodaj grafikę z serwera
                        </button>
                    </div>
                    <div v-else>
                        <CropImg
                        :img="mainImage"
                        @imagesUploaded="cropImage($event)"

                        />
                        <button @click="removeImage(mainImage)"
                        class="w-full md:py-2 lg:py-4 border-2 text-lg font-bold my-5 transition-all cursor-pointer text-center text-red-700 border-red-700 hover:text-white hover:bg-red-700">Usuń grafikę</button>
                    </div>
                <div v-if="$page.props.errors && $page.props.errors.image" class="text-red-500 mt-2">{{$page.props.errors.image}}</div>



                <TinyEditor
                    v-model="newsForm.description"
                    id="description"
                    name="description"
                    class="mt-2"
                />

                <div class="mt-3">
                    <InputLabel
                    placeholder="Źródło"
                    id="source"
                    name="source"
                    type="text"
                    class="mt-2"
                    v-model="newsForm.source"

                />
                </div>


                <div class="mt-3">
                    <InputLabel
                        placeholder="Tagi"
                        id="tags"
                        name="tags"
                        type="text"
                        class="mt-2"
                        v-model="tagSearch"
                        @keyup.enter="setTag()"
                        @update:unfocus="setTag()"
                    >
                        <template #searchList>
                            <div class=" max-h-60 overflow-y-auto z-30 relative bg-white">
                                <div
                                    v-for="tag in getTags()"
                                    :key="tag.id"
                                    @click="setTag(tag)"
                                    class="px-4 py-1 border-b border-gray-200 z-30 cursor-pointer flex items-center"
                                    :class="[
                                        newsForm.tags.filter(el => el.id == tag.id).length ? 'bg-green-50 text-green-900' : ''
                                    ]"
                                >
                                    {{ tag.name }}
                                    <SvgIcon
                                        name="check"
                                        class="text-green-900 ml-2"
                                        v-if="newsForm.tags.filter(el => el.id == tag.id).length"
                                        :size="20"
                                    />
                                </div>
                            </div>
                        </template>
                    </InputLabel>

                </div>

                <div
                    class="flex space-x-2 mt-4"
                    v-if="newsForm.tags.length"
                >
                    <transition
                        enter-active-class="transition ease-out duration-200"
                        enter-from-class="transform opacity-0"
                        enter-to-class="transform opacity-100"
                        leave-active-class="transition ease-in duration-200"
                        leave-from-class="transform opacity-100"
                        leave-to-class="transform opacity-0"
                        v-for="(tag, key) in newsForm.tags"
                        :key="key"
                    >
                    <div
                        class="bg-gray-400 dark:bg-gray-700 hover:bg-gray-600 pl-2 p-1 text-white rounded-full flex items-between items-center"

                    >
                        {{ typeof tag === 'object' ? tag.name : tag }}
                        <SvgIcon @click="removeTag(key)" name="x-circle" class="ml-2 text-gray-200 hover:text-white cursor-pointer" width="18" height="18"/>
                    </div>
                    </transition>
                </div>

                <div class="mt-3">
                    <InputLabel
                    label="Wiadomość zamrożona do"
                    id="frozen_top"
                    name="frozen_top"
                    type="date"
                    class="mt-2 z-0 w-2/6"
                    v-model="newsForm.frozen_top"

                /></div>

                <div class="flex justify-end sticky bottom-0 py-4 dark:bg-gray-800 z-10">
                    <Link @click="addNews()" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Zapisz</span>
                    </Link>
                </div>

            </form>
        </div>
        <Modal
            v-if="useExistImage"
            class="overflow-auto"
            @update="useExistImage = false"
            modal-class="w-full"
        >
            <InputLabel
                placeholder="Tytuł newsa"
                name="search_text"
                type="text"
                class="w-full mt-5"
                v-model="searchImage.search"
                @update:modelValue="ev => {
                    searchImage.search = ev;
                    loadMoreImage(true);
                }"
            />
            <div class="grid mt-5 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 relative">
                <div
                    v-if="searchImage.loading"
                    class="absolute flex items-start justify-center inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50 z-10"
                >
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
                <template v-for="(n) in display_news_images"
                    :key="n.id"
                >
                    <div class="rounded-md overflow-hidden border-2 border-white dark:border-gray-700 shadow-sm relative">
                        <img
                            style="min-height: 160px;"
                            class="h-full w-full cursor-pointer scale-100 hover:scale-125 transform transition-all duration-150"
                            v-lazy="{
                                src: n.name ? '/storage/news/' + n.name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg'),
                                loading: '/img/cms/loading.gif',
                                error: '/img/cms/img-error.png'
                            }"
                            @click="getBase64FromUrl($event.target.src)"
                        >
                        <div
                            @click="getBase64FromUrl(
                                n.name ? '/storage/news/' + n.name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg')
                            )"
                            class="overflow-hidden cursor-pointer absolute bottom-0 left-0 right-0"
                            >
                            <div
                                class="bg-black bg-opacity-60 absolute inset-0 bg-cover bg-bottom"

                            ></div>
                            <div class="text-white lg:text-white darl:lg:text-white dark:text-gray-400 relative z-20 px-2 text-xs lg:text-normal font-thin pt-2 mb-1">{{currentDateTime(n.news_created_at)}}</div>
                            <div class="text-white lg:text-white dark:lg:text-white dark:text-gray-200 relative z-20 px-2 font-bold text-xs lg:text-normal uppercase mb-2" v-html="n.news_title"></div>
                        </div>
                    </div>
                </template>
            </div>
            <button
                type="button"
                @click="loadMoreImage(false)"
                class="block w-full md:py-3 lg:py-5 border-2 text-lg font-bold my-5 transition-all cursor-pointer text-center text-green-700 border-green-700 dark:border-green-500 dark:text-green-500 hover:text-white hover:bg-green-700"
            >
                Załaduj więcej
            </button>
        </Modal>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, watch } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
    import ImageUpload from '@/Components/ImageUpload'
    import CropImg from '@/Components/Cropper'
    import TinyEditor from '@/Components/TinyEditor';
    import Checkbox from '@/Components/Checkbox';
    import Label from '@/Components/Label'
    import Modal from '@/Components/Modal'
import axios from 'axios'
import moment from 'moment'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox,
            ImageUpload,
            CropImg,
            TinyEditor,
            Checkbox,
            Label,
            Modal
        },
        props: {
            categories: [Array, Object],
            boxes: [Array, Object],
            clubs: [Array, Object],
            gallerries: [Array, Object],
            results: [Array, Object],
            tags: [Array, Object],
            leagues: [Array, Object],
            news_images: [Array, Object],
        },
        setup(props) {
            const tagSearch = ref(null);
            const clubSearch = ref(null);
            const resultSearch = ref(null);
            const isClubPost = ref(true);
            const selectedClub = ref(null);
            const mainImage = ref(null);
            const useExistImage = ref(false);
            const loadMorePage = ref(0);
            const searchImage = ref({
                search: '',
                loading: false,
            });
            const newsForm = useForm({
                title: null,
                description: null,
                gallery: null,
                categories: [],
                boxes: [],
                source: null,
                frozen_top: null,
                tags: [],
                clubs: [],
                results: null,
                leagues: [],
                is_league_news: false,
                is_club: false,
                is_result: false,
                is_main_news: false,
                is_main_news_link: false,
                main_news_category: [],
                main_news_boxes: [],
                image: null,
                exist_image: null,
            });

            const display_news_images = ref(props.news_images ? props.news_images : []);

            watch(() => useExistImage.value, (val, old) => {
                if(val && !display_news_images.value.length){
                    loadMoreImage(false);
                }
            })
            const getBase64FromUrl = async (url) => {
                const data = await fetch(url);
                const blob = await data.blob();

                new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        mainImage.value = base64data;

                        useExistImage.value = false;
                    }
                });
            }

            const removeTag = (key) => {
                nextTick(() => { newsForm.tags.splice(key, 1) })
            }

            const addNews = () => {
                newsForm.post( route('admin.post.news.add') )
            }
            const getClubs = () => {
                if (clubSearch.value) {
                    let buf = [];
                    props.clubs.forEach(function(el){
                        if (el.name.toLowerCase().includes(clubSearch.value.toLowerCase()) || el.laczynaspilka_name.toLowerCase().includes(clubSearch.value.toLowerCase())) {
                            buf.push(el)
                        }
                    })
                    return buf
                }
                else return props.clubs
            }

            const getTags = () => {
                if (tagSearch.value) {
                    let buf = [];
                    props.tags.forEach(function(el){
                        if (el.name.toLowerCase().includes(tagSearch.value.toLowerCase())) {
                            buf.push(el)
                        }
                    })
                    return buf
                }
                else return props.tags
            }

            const getResults = () => {
                if (resultSearch.value) {
                    let buf = [];
                    props.results.forEach(function(el){
                        if (el.guest.club_name.toLowerCase().includes(resultSearch.value.toLowerCase()) || el.host.club_name.toLowerCase().includes(resultSearch.value.toLowerCase())) {
                            buf.push(el)
                        }
                    })
                    return buf
                }
                else return props.results
            }

            const changeImage = (e) => {
                newsForm.image = e;
            }

            const setClub = (club, isId = false) => {
                if(newsForm.clubs.includes(club.id)) {
                    newsForm.clubs.splice(newsForm.clubs.indexOf(club.id), 1);
                }
                else {
                    newsForm.clubs.push(club.id)
                }
            }
            const timeout = ref(null);
            const buff = ref('');
            function loadMoreImage(search = false){
                let time = 0;
                searchImage.value.loading = true;
                let refresh = false;

                if(buff.value != searchImage.value.search){
                    refresh = true;
                    loadMorePage.value = 1;
                }

                if(!search) {
                    loadMorePage.value += 1;
                    time = 0;
                }
                else {
                    if(timeout.value) clearTimeout(timeout.value);
                    time = 1000;
                }

                timeout.value = setTimeout(() => {
                    axios.get(
                        route('admin.galleries.news.paginate', {
                            page: loadMorePage.value,
                            search: searchImage.value.search,
                        }),
                    )
                    .then((response) => {
                        if(!search && !refresh){
                            display_news_images.value = display_news_images.value.concat(response.data);
                        }
                        else {
                            display_news_images.value = response.data;
                        }

                        searchImage.value.loading = false;
                        buff.value = searchImage.value.search;
                    })
                }, time)
            }

            const setTag = (tag) => {
                var arrayTags = [];

                if(tagSearch.value){
                    arrayTags = tagSearch.value.split(',');
                }

                let exist = false;

                if(tag && tag.id){
                    let indexOf = newsForm.tags.indexOf(tag && tag.id ? tag.id : tagSearch.value);

                    exist = newsForm.tags.filter(el => el.id == tag.id).length;
                    if(exist) newsForm.tags.splice(indexOf, 1);

                    if( !exist && tag){
                        newsForm.tags.push(tag ? tag : tagSearch.value);
                    }
                }
                else {
                    arrayTags.forEach(e => {
                        let newTag = e.trim();
                        exist = newsForm.tags.includes(tagSearch.value);

                        if(!exist && newTag){
                            newsForm.tags.push(newTag);
                        }
                    })
                }

                if(tagSearch.value) tagSearch.value = null;
            }

            const setResult = (r) => {
                if(newsForm.results.includes(r.id)) {
                    newsForm.results.splice(newsForm.results.indexOf(r.id), 1);
                }
                else {
                    newsForm.results.push(r.id)
                }
            }

            const onFileChange = (item, e) => {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                createImage(item, files[0]);
            }
            const createImage = (item, file) => {
                var image = new Image();
                var reader = new FileReader();

                reader.onload = (e) => {
                    mainImage.value = e.target.result;
                };
                reader.readAsDataURL(file);
            }
            const removeImage = (item) => {
                mainImage.value = null;
                newsForm.image = null;
            }

            const cropImage = (e) => {
                newsForm.image = e
            }

            function currentDateTime(date) {
                return moment(date).format('DD.MM.YYYY')
            };

            return {
                currentDateTime,
                newsForm,
                tagSearch,
                removeTag,
                addNews,
                isClubPost,
                getClubs,
                clubSearch,
                selectedClub,
                changeImage,
                setClub,
                setResult,
                getResults,
                resultSearch,
                mainImage,
                onFileChange,
                removeImage,
                cropImage,
                getTags,
                setTag,
                useExistImage,
                loadMoreImage,
                searchImage,
                display_news_images,
                getBase64FromUrl,
            }
        }
    }
</script>

<style>
.tox-tinymce {
    border: 0 !important;
    border-bottom: 2px solid #9ca3af !important;
}
</style>
