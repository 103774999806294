<template>
    <AdminLayout>
        <ImageModal
            v-if="imageModal !== null"
            :image="'/storage/news/' + news.data[imageModal].image_name"
            @closeModal="imageModal = null"
            @prev="ev => {
                if(news.data[imageModal - 1]){
                    imageModal--;
                }
            }"
            @next="ev => {
                if(news.data[imageModal + 1]){
                    imageModal++;
                }
            }"
        />
        <div class="px-3 mt-4">
            <div class="">
                <Link :href="route('admin.dashboard.galleries')" class="mr-2 mb-6">Wróć</Link>
                <div class="flex flex-wrap justify-between items-center">
                    <div class="text-xl font-bold dark:text-white">Galeria newsów</div>
                    <!-- <Link color="blue" @click="startTag">Otaguj zdjęcia danymi newsów</Link> -->
                </div>
            </div>
            <!-- <div v-if="tagLoading" class="flex items-center justify-start">
                Tagowanie zdjęć. Proces może potrwać kilka minut...
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div> -->
            <!-- news -->
             <div class="grid mt-5 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
                <template v-for="(n, key) in $props.news.data"
                    :key="n"
                >
                    <div
                        class="rounded-md flex flex-col relative overflow-hidden border-2 border-white dark:border-gray-700 shadow-sm"
                    >
                        <img
                        class="w-full cursor-pointer"
                        v-lazy="{
                            src: n.image_name ? '/storage/news/' + n.image_name : ($page.props.no_image ? $page.props.no_image : '/img/cms/noimage.jpg'),
                            loading: '/img/cms/loading.gif',
                            error: '/img/cms/img-error.png'
                        }"
                        @click="imageModal = key"
                        >
                    </div>
                </template>
            </div>
            <div class="flex justify-center mb-5">
                <pagination class="mt-6" :links="$props.news.links" />
            </div>

        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import ImageModal from '@/Components/ImageModal'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            ImageModal,
            Pagination
        },
        props: {
            gallery: Object,
            news: [Object, Array],
        },
        setup() {
            const imageModal = ref(null);
            const tagLoading = ref(false);

            function startTag(){
                tagLoading.value = true;
                axios
                .get(`/admin/galleries/tag`, {
                    _method: 'patch',
                    onDownloadProgress: progressEvent => {
                        let contentLength = event.srcElement.getResponseHeader(
                        "content-length"
                        );
                        let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                        );
                        /*let data = this.$session.get("progress");
                        console.log(data);*/
                        console.log(this)
                        console.log(progressEvent);
                        console.log(percentCompleted);
                    }
                })
                .then(resp => {
                    console.log(resp);
                    tagLoading.value = false;
                })
                .catch(err => {
                    console.log(err);
                    tagLoading.value = false;
                });
            }

            return {
                imageModal,
                startTag,
                tagLoading,

            }
        }
    }
</script>
