<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <h1 class="text-xl font-thin dark:text-white mb-3" >
                Dodawanie Ligi
            </h1>
            <form>
                <div class="grid grid-cols-2 gap-2">
                    <InputLabel
                        placeholder="Skrót ligi"
                        id="short"
                        name="short"
                        type="text"
                        autofocus
                        v-model="league_form.short"
                    />

                    <InputLabel
                        placeholder="Nazwa ligi"
                        id="name"
                        name="name"
                        type="text"
                        v-model="league_form.name"
                    />
                </div>

                <ListboxNew
                    :data="categories"
                    v-model="league_form.category"
                    index="slug"
                    class="mt-2"
                />
                <div class="mb-2">
                    <ListboxNew
                        :data="seasons"
                        v-model="league_form.season_id"
                        class="mt-2"
                    />
                </div>

                <TinyEditor
                    placeholder="Uwagi"
                    id="addnotations"
                    name="addnotations"
                    class="mt-2"
                    v-model="league_form.addnotations"
                />

                <div class="grid grid-cols-2 gap-2 mt-2">
                    <InputLabel
                        placeholder="Ilość drużyn awansujących"
                        id="teams_promo"
                        name="teams_promo"
                        type="text"
                        v-model="league_form.teams_promo"
                    />

                    <InputLabel
                        placeholder="Ilość drużyn awansujących w barażach"
                        id="teams_promo_play_off"
                        name="teams_promo_play_off"
                        type="text"
                        v-model="league_form.teams_promo_play_off"
                    />
                </div>

                <div class="grid grid-cols-2 gap-2 mt-2">
                    <InputLabel
                        placeholder="Ilość drużyn spadających"
                        id="teams_drop"
                        name="teams_drop"
                        type="text"
                        v-model="league_form.teams_drop"
                    />

                    <InputLabel
                        placeholder="Ilość drużyn spadających w barażach"
                        id="teams_drop_play_off"
                        name="teams_drop_play_off"
                        type="text"
                        v-model="league_form.teams_drop_play_off"
                    />
                </div>

                <div class="grid grid-cols-2">
                    <div>
                        <div class="flex flex-start items-center my-4">
                            <Checkbox
                                v-model="league_form.divide"
                                id="divide"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="divide"
                            >Uwzględniaj w tabeli podział na rundy</Label>
                        </div>

                        <div class="flex flex-start items-center my-2">
                            <Checkbox
                                v-model="league_form.hidden"
                                id="hidden"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="hidden"
                            >Liga ukryta</Label>
                        </div>
                    </div>

                    <div>
                        <div class="flex flex-start items-center my-4">
                            <Checkbox
                                v-model="league_form.face_to_face"
                                :checked="league_form.face_to_face"
                                @change="league_form.goal_balance = false"
                                id="spotkan"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="spotkan"
                            >wg. spotkań bezpośrednich</Label>
                        </div>

                        <div class="flex flex-start items-center my-2">
                            <Checkbox
                                v-model="league_form.goal_balance"
                                :checked="league_form.goal_balance"
                                @change="league_form.face_to_face = false"
                                id="bilansu"
                            />
                            <!-- <input type="radio"
                            class="focus:outline-none text-cyan-700 focus:border-white focus:ring-white"
                            v-model="league_form.goal_balance"
                            id="bilansu"
                            @change="league_form.face_to_face = true"
                            > -->
                            <!-- <label for="application_link" class="text-sm font-semibold ml-3">Online Form</label> -->
                            <!-- {{ league_form.goal_balance }} -->
                            <Label
                                class="ml-2 dark:text-white"
                                for="bilansu"
                            >wg. bilansu bramkowego</Label>
                        </div>
                    </div>

                </div>




                <div>

                    <!-- {{ teams }} -->
                    <div class="flex flex-col lg:flex-row my-2">

                        <div class="flex flex-col w-full">
                            <InputLabel
                                placeholder="Szukaj drużyny"
                                id="searchTeam"
                                name="searchTeam"
                                type="text"
                                class=""
                                :border="false"
                                v-model="searchTeam"
                            />
                            <select
                                multiple
                                class="h-96 bg-white text-gray-500 dark:text-gray-900 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0 w-full border-b-2"
                                v-model="selected_teams">
                                <option
                                v-for="(t, key) in available_teams"
                                :key="key"
                                :value="key"

                                >
                                {{ t.club_name }} - {{ t.name }} - {{t.category_name}}
                                </option>

                            </select>
                        </div>
                        <div class="flex lg:flex-col justify-center my-5 lg:my-0 px-2">
                            <Link class="transform rotate-90 lg:rotate-0 font-bold bg-green-600 hover:bg-green-800"
                            @click="moveRight()"
                            >
                                <SvgIcon name="chevron-double-right" width="18" height="18"/>
                            </Link>

                            <Link class="transform rotate-90 lg:rotate-0 font-bold bg-red-600 hover:bg-red-800 lg:mt-2"
                            @click="moveLeft()"
                            >
                                <SvgIcon name="chevron-double-left" width="18" height="18"/>
                            </Link>
                        </div>

                        <select multiple
                            class="bg-white text-gray-500 dark:text-gray-900 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0 w-full border-b-2"
                            v-model="selected_good_teams"
                        >
                            <option
                            v-for="(t, key) in league_form.teams"
                            :key="key"
                            :value="key"
                            >
                            {{key + 1}}. {{ t.club_name }} - {{ t.name }} - {{ t.category_name }}
                            </option>
                        </select>
                    </div>
                </div>

                <InputLabel
                    placeholder="Url"
                    id="url"
                    name="url"
                    type="text"
                    class="mt-2"
                    v-model="league_form.url"
                />
                <div class="space-x-2 flex items-center justify-end sticky bottom-0 bg-gray-200 dark:bg-gray-800 py-2 border-t border-gray-300 dark:border-gray-600">
                    <Link
                        class="bg-green-600 hover:bg-green-800 float-right"
                        @click.prevent="add_league()"
                    >
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Zapisz</span></Link>
                </div>
            </form>
        </div>

        <Modal v-if="confirmModal" @update="confirmModal = $event">
            <div class="lg:px-48  py-2">
                <div class="text-red-500 text-lg" v-if="downloadTables.values.length">
                    <p>Znaleziono więcej niż jedną tabelę z wynikami. Proszę wybrać właściwą i ponownie pobrać dane lub anulować operację!</p>
                    <div class="flex items-center" v-for="(item, key) in downloadTables.values" :key="key">
                        <input
                            type="radio"
                            name="table"
                            v-model="downloadTables.selected"
                            :value="key"
                            :id="'table-' + key"
                        />
                        <Label
                            class="ml-2 dark:text-white py-2"
                            :for="'table-' + key"
                        >{{ item }}</Label>
                    </div>
                </div>
                <div v-if="downloadTables.loading" class="absolute flex items-center justify-center inset-0 bg-gray-900 bg-opacity-50">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
            <div class="w-full">
                    <div class="lg:w-2/5 w-5/5  grid grid-cols-2 mx-auto py-6 text-center">
                        <Link
                            class="ml-2 text-center px-6"
                            color="gray"
                            @click="confirmModal = false"
                            >
                                <SvgIcon name="chevron-left" width="18" height="18"/>
                                <span class="ml-2  ">Wróć</span>
                            </Link>
                            <Link
                                class="ml-2"
                                color="gray"
                                :class="[
                                    downloadTables.type == 'teams' ? 'bg-green-500 hover:bg-green-600' : 'bg-yellow-500 hover:bg-yellow-600'
                                ]"
                                @click="ev => {
                                    if(league_form.url && downloadTables.type == 'teams') add_league();
                                }"
                                @update="confirmModal = false"
                                :disabled="league_form.url ? false : true"
                            >
                                <SvgIcon name="download" width="18" height="18"/>
                                <span class="ml-2  ">{{downloadTables.type == 'teams' ? 'Zapisz' : 'Pobierz dane'}}</span>
                            </Link>
                   </div>
            </div>
        </Modal>
    </AdminLayout>
</template>

<script>
import SvgIcon from '@/Components/SvgIcon'
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Checkbox from '@/Components/Checkbox'
    import Label from '@/Components/Label'
    import ListboxNew from '@/Components/ListboxNew'
    import Link from '@/Components/Link'
    import { useForm, usePage } from '@inertiajs/inertia-vue3'
    import { ref, watch } from 'vue'

    import TinyEditor from '@/Components/TinyEditor';
    import Modal from '@/Components/Modal';

    export default {
        components: {
            AdminLayout,
            InputLabel,
            Link,
            Checkbox,
            Label,
            ListboxNew,
            SvgIcon,
            TinyEditor,
            Modal
        },
        props: {
            categories: Array,
            seasons: Array,
            teams: Array,
        },
        setup(props) {
            const available_teams = ref(props.teams);
            const selected_teams = ref([]);
            const selected_good_teams = ref([]);
            const searchTeam = ref(null);
            const league_form = useForm({
                short: null,
                name: null,
                season_id: usePage().props.value.current_season ? usePage().props.value.current_season.value : props.seasons[0].id,
                category: 0,
                teams_promo: null,
                teams_promo_play_off: null,
                teams_drop: null,
                teams_drop_play_off: null,
                divide: false,
                hidden: false,
                addnotations: null,
                teams: [],
                goal_balance: false,
                face_to_face: false,
                url: null,
            });
            const confirmModal = ref(false);
            const downloadTables = ref({
                selected: null,
                values: [],
                type: 'scores',
                loading: false,
            });

            const add_league = () => {
                league_form
                .transform((data) => ({
                    ...data,
                    table: downloadTables.value.selected,
                }))
                .post(route('admin.post.events.leagues.add'), {
                    preserveScroll: true,
                    onSuccess: (e) => {
                        if(e.props.custom && !e.props.custom['not-found'] && e.props.custom.length){
                            confirmModal.value = true;
                            downloadTables.value.type = 'teams';
                            downloadTables.value.values = e.props.custom;
                        }
                        else {
                            confirmModal.value = false;
                            league_form.teams = e.props.league.teams;
                            downloadTables.value.values = [];
                        }
                        downloadTables.value.loading = false;
                    },
                    onError: () => {
                        downloadTables.value.loading = false;
                    }
                });
            }

            watch(searchTeam, (searchTeam, prevSearchTeam) => {
                available_teams.value = [];
                props.teams.forEach(function(el){
                    if (
                        el.category_name.toLowerCase().includes(searchTeam.toLowerCase()) ||
                        el.club_name.toLowerCase().includes(searchTeam.toLowerCase())
                    )
                        available_teams.value.push(el)
                })
            })

            const moveRight = () => {
                selected_teams.value.forEach(function(el, key){
                    league_form.teams.push(available_teams.value[el])
                })
                selected_teams.value.forEach(function(el, key){
                    available_teams.value.splice(el, 1)
                })
                selected_teams.value = []
            }
            const moveLeft = () => {
                selected_good_teams.value.forEach(function(el, key){
                    available_teams.value.push(props.teams[el])
                })
                selected_good_teams.value.forEach(function(el, key){
                    league_form.teams.splice(el, 1)
                })
                selected_good_teams.value = []
            }
            const run = () => {
                console.log("please")
            }

            return {
                league_form,
                add_league,
                available_teams,
                selected_teams,
                selected_good_teams,
                searchTeam,
                moveRight,
                moveLeft,
                run,

                confirmModal,
                downloadTables,
            }
        }
    }
</script>
