<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">

            <Link :href="route('admin.dashboard.users.add')" class="mr-2 bg-green-600 hover:bg-green-800">
                <SvgIcon name="plus" width="18" height="18" />
                <span class="ml-2">
                    Dodaj użytkownika
                </span>
            </Link>

            <Link :href="route('admin.dashboard.users.stats')" class="mr-2 bg-blue-600 hover:bg-blue-800">
                <SvgIcon name="chart-bar" width="18" height="18" />
                <span class="ml-2">
                   Wszystkie statystyki
                </span>
            </Link>

            <div class="rounded-md overflow-hidden shadow-sm mt-2">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-3">Użytkownicy</div>
                    <div class="py-3 pr-3">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="(user, key) in users.data"
                        :key="key"
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white qwehover:bg-gray-100  cursor-pointer"
                    >
                        <div
                        class="flex flex-row justify-between items-center"
                        >
                            <div class="flex">
                            <div class="pl-3 py-1 font-bold">{{user.name}}</div>

                            </div>
                            <div class="py-1 pr-2 text-center flex">
                                <div class="ml-4 flex items-center mr-2 ">
                                    <div
                                    v-for="permission in user.permissions"
                                    :key="permission"
                                    class="bg-gray-400 px-2 py-1 mr-1 rounded-md text-white text-sm lg:text-normal"
                                    >
                                    {{ permission.name }}
                                    </div>
                                </div>
                                <Link :href="route('admin.dashboard.user.stats', user.id)" class="mr-2 bg-blue-600 hover:bg-blue-800">
                                    <SvgIcon name="chart-bar" width="18" height="18"/>
                                    <!-- <p class="hidden lg:block lg:ml-2">Statystyki</p> -->
                                </Link>
                                <Link :href="route('admin.dashboard.users.edit', user.id)" class="bg-green-600 hover:bg-green-800">
                                    <SvgIcon name="pencil" width="18" height="18"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination
        },
        props: {
            users: Array
        },
        setup() {
            return {
            }
        }
    }
</script>
