<template>
    <div class="modal-bg fixed z-50 bg-black inset-0 bg-opacity-50 flex items-center justify-center py-10 px-4" @click.self="modalBg($event)">
        <transition name="slide-fade"
            @after-leave="afterLeave"
        >
            <div
                class="modal max-w-screen-2xl bg-gray-200 dark:bg-gray-800 dark:text-gray-200 shadow-md rounded-md p-3 m-auto relative"
                v-if="show"
                :class="[
                    modalClass ? modalClass : '',
                ]"
            >
                <Svg
                    name="remove-x"
                    viewBox="0 0 24 24"
                    class="-right-3 -top-3 absolute bg-white p-1 rounded dark:bg-gray-500 shadow-md cursor-pointer"
                    @click="show = false"
                />
                <slot />
            </div>
        </transition>
        <!-- <CustomModal v-if="diploma_modal" @update="diploma = $event" class="overflow-y-scroll">
            <ShowDiploma :diploma="quiz.diploma" class="select-none" />
        </CustomModal> -->
    </div>
</template>

<script>
import Button from '@/Components/Button';
import Svg from '@/Components/SvgIcon';
import {ref, onMounted} from 'vue';
export default {
    components: {
        Button,
        Svg,
    },
    props: ['data', 'modalClass'],
    emits: ['update'],
    setup(props, { emit }) {
        const show = ref(false);
        onMounted(() => { show.value = true; })
        const afterLeave = (el) => {
            emit('update', false);
        }
        const modalBg = (ev) => {
            // if($(ev.target).hasClass('modal-bg')) {
                show.value = false;
            // }
        }
        return {
            show,
            afterLeave,
            modalBg,
        }
    }
}
</script>
