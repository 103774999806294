<template>
    <div
        class="fixed inset-0 bg-gray-900 bg-opacity-50 cursor-pointer z-50 p-5"
        @click.self="$emit('closeModal', false)"
        v-if="image"
    >

        <div class="flex w-full justify-center items-center h-full" @click.self="$emit('closeModal', false)">
            <div class="fixed top-4 right-4 text-gray-600" @click="$emit('closeModal', false)">
                <SvgIcon
                    name="x-circle"
                    class="text-white shadow-sm hover:text-gray-300"
                    :size="32"
                />
            </div>
            <button @click="$emit('prev', $event)" class="w-14 mr-2 rounded-md h-full hover:text-gray-300 hover:bg-gray-100 hover:bg-opacity-10">
                <SvgIcon
                    name="arrow-left"
                    class="text-white mx-auto shadow-sm"
                />
            </button>
            <img
                v-lazy="{
                    src: image,
                    loading: '/img/cms/loading.gif',
                    error: '/img/cms/img-error.png'
                }"
                class="rounded-md shadow-lg max-w-full max-h-full"
            >
            <button @click="$emit('next', $event)" class="w-14 ml-2 rounded-md h-full hover:text-gray-300 hover:bg-gray-100 hover:bg-opacity-10">
                <SvgIcon
                    name="arrow-right"
                    class="text-white mx-auto shadow-sm"
                />
            </button>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/Components/SvgIcon';
import { ref, watch, onMounted } from 'vue';

export default {
    components: {
        SvgIcon,
    },
    props: {
        image: [String, Boolean],
    },
    emits: ['closeModal', 'prev', 'next'],
    setup(props, {emit}) {
        const isVisible = ref(false)

        const closeModal = (event) => {
            // if (event.target.tagName != 'IMG') {
            //     isVisible.value = false;
            //     props.image = false;
            //     emit('closeModal')
            // }
        }

        watch(() => props.image, (val, prevVal) => {
            isVisible.value = true;
        })
        return {
            closeModal,
            isVisible
        }
    },
}
</script>
