<template>
    <AdminLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard
            </h2>
        </template>


        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <Link :href="route('admin.events.leagues')" class="mr-2 bg-green-500">
                <SvgIcon name="chevron-left" height="16" width="16"/> Wróć
            </Link>
            <h1 class="text-xl font-thin mb-3 mt-2 dark:text-white" >
                Edycja ligi
            </h1>
            <form>
                <div class="grid grid-cols-2 gap-2">
                    <InputLabel
                        placeholder="Skrót ligi"
                        id="short"
                        name="short"
                        type="text"
                        autofocus
                        v-model="league_form.short"
                    />

                    <InputLabel
                        placeholder="Nazwa ligi"
                        id="name"
                        name="name"
                        type="text"
                        v-model="league_form.name"
                    />
                </div>


                <ListboxNew
                    :data="categories"
                    v-model="league_form.category"
                    index="slug"
                    class="mt-2"
                />

                <ListboxNew
                    :data="seasons"
                    v-model="league_form.season_id"
                    class="mt-2"
                />

                <TinyEditor
                    class="mt-2"
                    placeholder="Uwagi"
                    id="addnotations"
                    name="addnotations"
                    v-model="league_form.addnotations"
                />

                <div class="grid grid-cols-2 gap-2 mt-2">
                    <InputLabel
                        placeholder="Awansujące"
                        id="teams_promo"
                        name="teams_promo"
                        type="text"
                        v-model="league_form.teams_promo"
                    />

                    <InputLabel
                        placeholder="Awansujące w barażach"
                        id="teams_promo_play_off"
                        name="teams_promo_play_off"
                        type="text"
                        v-model="league_form.teams_promo_play_off"
                    />
                </div>

                <div class="grid grid-cols-2 gap-2 mt-2">
                    <InputLabel
                        placeholder="Spadających"
                        id="teams_drop"
                        name="teams_drop"
                        type="text"
                        v-model="league_form.teams_drop"
                    />

                    <InputLabel
                        placeholder="Spadające w barażach"
                        id="teams_drop_play_off"
                        name="teams_drop_play_off"
                        type="text"
                        v-model="league_form.teams_drop_play_off"
                    />
                </div>



                <div class="grid grid-cols-2">
                    <div>
                        <div class="flex flex-start items-center my-4">
                            <Checkbox
                                v-model="league_form.divide"
                                :checked="league_form.divide"
                                id="divide"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="divide"
                            >Uwzględniaj w tabeli podział na rundy</Label>
                        </div>

                        <div class="flex flex-start items-center my-2">
                            <Checkbox
                                v-model="league_form.hidden"
                                id="hidden"
                                :checked="league_form.hidden"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="hidden"
                            >Liga ukryta</Label>
                        </div>
                    </div>

                    <div>
                        <div class="flex flex-start items-center my-4">
                            <Checkbox
                                v-model="league_form.face_to_face"
                                :checked="league_form.face_to_face"
                                @change="league_form.goal_balance = false"

                                id="spotkan"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="spotkan"
                            >wg. spotkań bezpośrednich</Label>
                        </div>

                        <div class="flex flex-start items-center my-2">
                            <Checkbox
                                v-model="league_form.goal_balance"
                                :checked="league_form.goal_balance"

                                @change="league_form.face_to_face = false"
                                id="bilansu"
                            />
                            <Label
                                class="ml-2 dark:text-white"
                                for="bilansu"
                            >wg. bilansu bramkowego</Label>
                        </div>
                    </div>

                </div>


                <div>
                    <div v-if="$props.lacznames && $props.lacznames.length " class="flex flex-wrap gap-3 dark:text-white text-sm mt-2">
                        <p class="w-full font-bold">Drużyny nieprzypisane z ŁNP:</p>
                        <div
                            class=" dark:text-white text-xs border border-gray-500 px-2 py-1 flex"
                            v-for="item in $props.lacznames"
                            :key="item.id"
                        >
                            <span class="mt-0.5">{{ item.name }}</span>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row my-2">
                        <div class="flex flex-col w-full">
                            <InputLabel
                                placeholder="Szukaj drużyny"
                                id="searchTeam"
                                name="searchTeam"
                                type="text"
                                input-class="bg-gray-300"
                                :border="false"
                                v-model="searchTeam"
                            />
                            <select
                                multiple
                                class="h-96 bg-white text-gray-500 dark:text-gray-900 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0 w-full border-b-2"
                                v-model="selected_teams"
                            >
                                <option
                                    class="whitespace-pre-line"
                                    style="white-space: pre-line;"
                                    v-for="(t, key) in available_teams.filter(e => !league_form.teams.find(el => el.id == e.id))"
                                    :key="key"
                                    :value="t"

                                >
                                {{ t.club_name }} - {{ t.name }} - {{t.category_name}}
                                </option>

                            </select>
                        </div>
                        <div class="flex md:flex-col justify-center my-5 md:my-0 md:space-x-0 space-x-2 px-2">
                            <Link class="transform rotate-90 md:rotate-0 font-bold bg-green-600 hover:bg-green-800"
                            @click="moveRight()"
                            >
                                <SvgIcon name="chevron-double-right" width="18" height="18"/>
                            </Link>

                            <Link class="transform rotate-90 md:rotate-0 font-bold bg-red-600 hover:bg-red-800 md:mt-2"
                            @click="moveLeft()"
                            >
                                <SvgIcon name="chevron-double-left" width="18" height="18"/>
                            </Link>
                        </div>
                        <div class="flex flex-col w-full">
                            <span class="h-10 bg-gray-300 w-full px-4 py-2 text-xs text-red-700 font-bold">Usuwanie drużyn należy wykonać w zakładce "Narzędzia"</span>
                            <select
                                multiple
                                class="h-96 bg-white text-gray-500 dark:text-gray-900 dark:hover:border-gray-700 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600 rounded-none border-0 px-4 py-2 outline-none focus:outline-none focus:ring-0 w-full border-b-2"
                                v-model="selected_good_teams"
                            >
                                <option
                                    class="whitespace-pre-line"
                                    style="white-space: pre-line;"
                                    v-for="(t, key) in league_form.teams"
                                    :key="key"
                                    :value="t"
                                    :class="[
                                        selected_good_teams.find(e => e.id == t.id) ? 'bg-gray-100' : ''
                                    ]"
                                >
                                {{key + 1}}. {{ t.club_name }} - {{ t.name }} - {{ t.category_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-start items-center my-2">
                    <div class="w-full">
                        <InputLabel
                            placeholder="Url"
                            id="url"
                            name="url"
                            type="text"
                            class="w-full"
                            v-model="league_form.url"
                        />
                    </div>
                    <Link
                        v-if="$props.league.url"
                        class="ml-2 h-full bg-yellow-500 hover:bg-yellow-600"
                        color="gray"
                        :disabled="$props.league.url == null ? true : $props.league.url != '' ? false : true"
                        @click="confirmModal = true"
                    >
                        <SvgIcon name="download" height="18" width="18" />
                        <span>Pobierz</span>
                    </Link>
                </div>
                <div class="flex my-3" v-if="league_form.url && $props.league">
                    <Checkbox
                        v-model="league_form.download_teams"
                        :checked="league_form.download_teams"
                        id="download_teams"
                    />
                    <Label
                        class="ml-2 dark:text-white"
                        for="download_teams"
                    >Ponownie pobierz nazwy drużyn <span class="text-xs font-bold">(Uruchamiane po kliknieciu "Zapisz")</span></Label>
                </div>

                <div class="grid grid-cols-2 bottom-0 bg-gray-200 dark:bg-gray-800 py-2 border-t border-gray-300 dark:border-gray-600">

                    <div >
                        <button  v-if="league_form.url && $props.league" @click="downloadLacz()" class="border-white border text-white px-4 py-1.5 rounded">Wymuszenie Pobierania Nazw</button>
                    </div>

                    <div class="space-x-2 flex items-center justify-end sticky ">
                        

                        <Link
                            class="bg-green-600 hover:bg-green-800"

                            @click.prevent="edit_league()"
                        >
                            <SvgIcon name="check" width="18" height="18" />
                            <span class="ml-2">Zapisz</span>
                        </Link>
                        <Link @click="removePopup()" class="bg-red-600 hover:bg-red-800">
                            <SvgIcon name="x" width="18" height="18" class=""/>
                            <span class="ml-2 hidden lg:block">Usuń</span>
                        </Link>

                        <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                        v-if="showRemove"
                        >
                            <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                                <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć Ligę "{{league.name}}"?</h2>
                                <div class="flex">
                                    <Link @click="removeAdd()" class="bg-red-600 hover:bg-red-800 mr-2">
                                        <SvgIcon name="x" width="18" height="18" />
                                        <span class="ml-2">Tak, Usuń</span>
                                    </Link>
                                    <Link @click="showRemove=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                        <SvgIcon name="x" width="18" height="18" />
                                        <span class="ml-2">Nie, anuluj operację</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
        <Modal v-if="confirmModal" @update="confirmModal = $event">
            <div class="lg:px-48  py-2">
                <h2 class="text-xl font-bold text-center pt-4">Czy na pewno chcesz pobrać wszystkie dane?</h2>
                <h1 class="text-sm text-center">Upewnij się że <b>WSZYSTKIE</b> drużyny są dodane oraz zmapowane.
                <br>Drużyny które nie będą odpowiednio skonfigurowane mogą zostać pominięte przy pobieraniu danych z kolejek.</h1>

                <div class="text-red-500 text-lg" v-if="downloadTables.values.length">
                    <p>Znaleziono więcej niż jedną tabelę z wynikami. Proszę wybrać właściwą i ponownie pobrać dane lub anulować operację!</p>

                    <div class="flex items-center" v-for="(item, key) in downloadTables.values" :key="key">
                        <input
                            type="radio"
                            name="table"
                            v-model="downloadTables.selected"
                            :value="key"
                            :id="'table-' + key"
                        />
                        <Label
                            class="ml-2 dark:text-white py-2"
                            :for="'table-' + key"
                        >{{ item }}</Label>
                    </div>
                </div>
                <div v-if="downloadTables.loading" class="absolute flex items-center justify-center inset-0 bg-gray-900 bg-opacity-50">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
            <div class="w-full">
                    <div class="lg:w-2/5 w-5/5  grid grid-cols-2 mx-auto py-6 text-center">
                        <Link
                            class="ml-2 text-center px-6"
                            color="gray"
                            @click="confirmModal = false"
                            >
                                <SvgIcon name="chevron-left" width="18" height="18"/>
                                <span class="ml-2  ">Wróć</span>
                            </Link>
                            <Link
                                class="ml-2"
                                color="gray"
                                :class="[
                                    downloadTables.type == 'teams' ? 'bg-green-500 hover:bg-green-600' : 'bg-yellow-500 hover:bg-yellow-600'
                                ]"
                                @click="ev => {
                                    if(league.url && downloadTables.type == 'scores') downloadScoresLeague()
                                    else if(league.url && downloadTables.type == 'teams') edit_league();
                                }"
                                @update="confirmModal = false"
                                :disabled="league.url ? false : true"
                            >
                                <SvgIcon name="download" width="18" height="18"/>
                                <span class="ml-2  ">{{downloadTables.type == 'teams' ? 'Zapisz' : 'Pobierz dane'}}</span>
                            </Link>
                    </div>

            </div>
        </Modal>
    </AdminLayout>
</template>

<script>
    import SvgIcon from '@/Components/SvgIcon'
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Checkbox from '@/Components/Checkbox'
    import ListboxNew from '@/Components/ListboxNew'
    import Label from '@/Components/Label'
    import Link from '@/Components/Link'
    import { useForm, usePage } from '@inertiajs/inertia-vue3'
    import { onMounted, ref, watch } from 'vue'
    import { Inertia } from '@inertiajs/inertia'
    import Modal from '@/Components/Modal';
    import TinyEditor from '@/Components/TinyEditor';

    export default {
        components: {
            AdminLayout,
            InputLabel,
            Link,
            Checkbox,
            Label,
            ListboxNew,
            SvgIcon, Modal,
            TinyEditor,
        },
        props: {
            categories: Array,
            seasons: Object,
            league: Object,
            teams: Array,
            lacznames: Array,
        },
        setup(props) {
            const available_teams = ref(props.teams);
            const selected_teams = ref([]);
            const selected_good_teams = ref([]);
            const searchTeam = ref(null);
            const confirmModal = ref(false);
            const league_form = useForm({
                short: props.league.short,
                name: props.league.name,
                season_id: props.league.season_id,
                category: props.league.category,
                teams_promo: props.league.teams_promo,
                teams_promo_play_off: props.league.teams_promo_play_off,
                teams_drop: props.league.teams_drop,
                teams_drop_play_off: props.league.teams_drop_play_off,
                divide: props.league.divide ? true : false,
                hidden: props.league.hidden ? true : false,
                addnotations: props.league.addnotations,
                url: props.league.url,
                teams: props.league.teams,
                goal_balance: props.league ? props.league.goal_balance : false,
                face_to_face: props.league ? props.league.face_to_face : false,
                download_teams: props.league ? false : true,
            });

            const showRemove = ref(false)

            const removeAdd = () => {
                Inertia.post(route('admin.events.leagues.delete', props.league.id))
                showRemove.value = false;
            }
            const removePopup = () => {
                showRemove.value = true;
            }


            onMounted(() => {
                available_teams.value.forEach((el, key) => {
                    el.key = key
                })
            })

            const downloadTables = ref({
                selected: null,
                values: [],
                type: 'scores',
                loading: false,
            });

            const edit_league = () => {
                league_form
                .transform((data) => ({
                    ...data,
                    table: downloadTables.value.selected,
                }))
                .post(route('admin.post.events.leagues.edit', props.league.id), {
                    preserveScroll: true,
                    onSuccess: (e) => {
                        if(e.props.custom && !e.props.custom['not-found'] && e.props.custom.length){
                            confirmModal.value = true;
                            downloadTables.value.type = 'teams';
                            downloadTables.value.values = e.props.custom;
                        }
                        else {
                            confirmModal.value = false;
                            league_form.teams = e.props.league.teams;
                            downloadTables.value.values = [];
                        }
                        downloadTables.value.loading = false;
                    },
                    onError: () => {
                        downloadTables.value.loading = false;
                    }
                });
            }

            const downloadScoresLeague = () => {
                if(props.league.url){
                    downloadTables.value.loading = true;
                    Inertia.post(route('admin.download.all.scores', props.league.id), {
                        table: downloadTables.value.selected
                    }, {
                        preserveScroll: true,
                        onSuccess: (e) => {
                            if(e.props.custom && !e.props.custom['not-found'] && e.props.custom.length){
                                downloadTables.value.values = e.props.custom;
                            }
                            else {
                                confirmModal.value = false;
                                league_form.teams = e.props.league.teams;
                                downloadTables.value.values = [];
                            }
                            downloadTables.value.loading = false;
                        },
                        onError: () => {
                            downloadTables.value.loading = false;
                        }
                    });
                }
            }

            const downloadLacz = () => {
                Inertia.post(route('admin.download.all.lacz', props.league.id));
            }

            watch(searchTeam, (searchTeam, prevSearchTeam) => {
                available_teams.value = [];
                props.teams.forEach(function(el){
                    if (
                        el.category_name.toLowerCase().includes(searchTeam.toLowerCase()) ||
                        el.club_name.toLowerCase().includes(searchTeam.toLowerCase())
                    )
                        available_teams.value.push(el)
                })
            })

            const moveRight = () => {
                selected_teams.value.forEach(function(el, key){
                    // console.log(el)
                    league_form.teams.push(el)
                })
                selected_teams.value.forEach(function(el, key){
                    available_teams.value.splice(el, 1)
                })
            }
            const moveLeft = () => {

                selected_good_teams.value.forEach(function(el, key){
                    available_teams.value.push(el)
                })
                selected_good_teams.value.forEach(function(el, key){
                    league_form.teams.forEach(function(ell, keyy){
                        if (ell == el) league_form.teams.splice(keyy, 1)
                    })
                })

                // available_teams.value.sort((a,b) => a.club_name < b.club_name);
            }

            const selected = (e) => {
                console.log(e.target)
            }

            return {
                league_form,
                edit_league,
                available_teams,
                selected_teams,
                selected_good_teams,
                searchTeam,
                moveRight,
                moveLeft,
                selected,
                downloadScoresLeague,
                showRemove,
                removeAdd,
                removePopup,
                downloadLacz,

                confirmModal,
                downloadTables,
            }
        }
    }
</script>
