<template>
    <div
        class="grid"
        :class="[
            $props.settings && $props.settings['cols'] && Number($props.settings['cols']) <= 12 ?
                `grid-cols-${$props.settings['cols']}` : 'grid-cols-4',

        ]"
    >
        <div
            class="col-span-full lg:col-span-1 odd:bg-gray-100 dark:odd:bg-gray-900 dark:odd:bg-opacity-50"
            v-for="(n, key) in posts" :key="n"
            :class="[
                Math.floor(key / ($props.settings ? $props.settings['cols'] : 4)) % 2 == 0 ?
                    'lg:odd:bg-transparent lg:dark:odd:bg-gray-800 lg:dark:odd:bg-opacity-100' :
                        'lg:bg-gray-100 lg:dark:bg-gray-900 lg:dark:bg-opacity-50',
            ]"
        >
            <inertia-link
                :href="route('news.id', n.slug)"
                class="single-news dark:hover:bg-opacity-100 flex flex-col p-2 dark:hover:bg-gray-900 hover:bg-gray-200"

            >
            <!-- {{ Math.floor(key / (2) ) }} -->
            <!-- :class="[
                    key % 2 == 0 ? 'dark:hover:bg-gray-900' : 'bg-gray-100 dark:bg-gray-900 dark:bg-opacity-50',
                ]" -->

                <span class="date text-gray-400 text-xs lg:text-normal">{{currentDateTime(n.created_at_news ? n.created_at_news : n.created_at)}}</span>
                <span class="title text-gray-700 text-normal text-sm dark:text-gray-200 truncate">{{ n.title ? n.title : (n.title ? n.title : '') }}</span>
            </inertia-link>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        posts: [Array, Object],
        settings: [Array, Object],
    },
    setup() {
        const currentDateTime = (date) => {
            return moment(date).format('DD.MM.YYYY')
        }

        return {
            currentDateTime
        }
    },
}
</script>
