<template>
    <AdminLayout>
        <div class="py-40 flex items-center justify-center flex-col text-center mx-auto dark:text-white text-gray-500">
            <img class="col-span-1" src="https://img.icons8.com/emoji/96/000000/waving-hand-emoji.png"/>
            <span class="text-4xl col-span-3 pt-3">Witaj na Futbol Małopolska!</span>
            <div class="col-span-4 text-center mt-4">
                <Link color="green" class="text-center py-3 mx-auto px-4" :href="route('home')">
                    <p>Strona główna</p>
                </Link>
            </div>

        </div>
    </AdminLayout>
</template>
<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import { Inertia } from '@inertiajs/inertia'
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
        },
        props: {
            galleries: Array
        },
        setup() {
            const loading = ref(false);
            const scanForGalleries = () => {
                loading.value = true;
                Inertia.post(route('admin.scan.galleries'), null, {
                    onSuccess: function(){
                        loading.value = false;
                    },
                });
            }
            return {
                scanForGalleries,
                loading
            }
        }
    }
</script>

<style scoped>
.make-smaller{
    max-width: 400px;
}
@media (max-width: 568px){
    .make-smaller{
        max-width: 150px;
    }
}
</style>
