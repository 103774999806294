<template>
    <div
    class=""
    v-for="(l, lkey) in results"
    :key="lkey"
    >
        <div
        class="mb-4 bg-white dark:bg-gray-900 shadow-md"
        v-if="l.items.length"
        >
            <inertia-link
            class="lg:text-xl md:text-xl py-1 grid grid-cols-1 md:grid-cols-3 text-center text-lg text-white shadow-md bg-green-700 hover:bg-green-600 px-4"
            :href="route('league', l.slug)"
            >
            <div class="md:col-span-2">{{l.name}}</div>
            </inertia-link>
            <div
            v-for="i in l.items"
            :key="i"
            class="px-4 py-1.5 grid grid-cols-1 md:grid-cols-3 odd:bg-gray-100 dark:odd:bg-gray-800 dark:odd:bg-opacity-30"
            >
                <div class="grid grid-cols-5 md:col-span-2">
                    <div class="col-span-2 text-center md:text-right">
                        <inertia-link v-if="i.host"
                            :href="route('singleclub', {
                                club: i.host.club_id,
                                team: i.host_id,
                                league: l.id
                            })"
                            class="hover:text-green-600 hover:underline lg:text-md md:text-md text-sm">{{i.host.club_name}}</inertia-link>
                        <div v-else>---</div>
                    </div>
                    <div class="col-span-1 text-center text-blue-600" v-if="i.news.length >0">
                        <inertia-link :href="route('news.id', i.news[0].slug)">
                            {{getGoals(i)}}
                        </inertia-link>
                    </div>
                    <div class="col-span-1 text-center" v-else>{{getGoals(i)}}</div>
                    <div class="col-span-2 text-center md:text-left">
                        <inertia-link v-if="i.guest"
                            :href="route('singleclub', {
                                club: i.guest.club_id,
                                team: i.guest_id,
                                league: i.league
                            })"
                            class="hover:text-green-600 hover:underline lg:text-md md:text-md text-sm">{{i.guest.club_name}}</inertia-link>
                        <div v-else>---</div>
                    </div>

                    <!-- <p > {{ i.news }} </p> -->
                </div>
                <div class="col-span-1 text-center text-sm text-gray-400 dark:text-gray-300 md:text-normal md:text-gray-700 md:text-right mb-4 mt-1 md:mb-0">
                    <span>{{currentDateTime(i.match_date)}}</span>
                    <span class="text-xs text-gray-500 ml-3">#{{ i.id }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { onMounted } from '@vue/runtime-core'
export default {
    props: {
        results: [Object, Array]
    },
    methods: {
            currentDateTime(date) {
                return moment(date).format('DD.MM.YYYY HH:mm')
            }
        },
    setup(props) {

        onMounted(() => {
            // props.leagues.forEach((l) => {
            //     l.queues.forEach((q, qkey) => {
            //         console.log(q)
            //         if (l.current_queue == q.id) l.current_queue_index = qkey
            //     })
            // })
        })

        const getGoals = (item) => {
            let walkover = '';
            let host_goals = item.host_goals !== null ? item.host_goals : '-';
            let guest_goals = item.guest_goals !== null ? item.guest_goals : '-';

            if(item.walkover != 'none'){
                walkover = ' v.o';

                if(item.walkover == 'for_host'){
                    host_goals = 3;
                    guest_goals = 0;
                }
                else if(item.walkover == 'for_guest'){
                    host_goals = 0;
                    guest_goals = 3;
                }
                else if(item.walkover == 'equal') {
                    host_goals = 3;
                    guest_goals = 3;
                }

            }
            else {
                if(item.host_id === 0 || item.guest_id === 0){
                    host_goals = '-';
                    guest_goals = '-';
                }
            }

            return host_goals + ":" + guest_goals + walkover;
        }

        return {
            getGoals,
        }
    },

}
</script>
