<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="mb-3 flex gap-3 justify-between">
                <Link @click="isOpen = true; modalMode = null; teamForm.name = '';" class="flex gap-2 items-center">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span>Dodaj</span>
                </Link>
                <Link
                    :href="route('admin.events.clubs')" color="green"
                >Kluby</Link>
            </div>
            <div class="rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-3">Kategoria wiekowa</div>
                    <div class="py-3 pr-3">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="(c, club_key) in team_categories.data"
                        :key="club_key"
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:hover:bg-opacity-80 dark:bg-gray-600 dark:text-white cursor-pointer"

                    >
                        <div
                            class="flex flex-row  justify-between items-center"
                            @click.self="c.teams_show = !c.teams_show"
                        >
                            <div
                                class="pl-3 py-1 hover:text-green-500"
                                :href="route('singleclub', c.id)"
                            ><span class="font-bold">{{c.name}}</span></div>
                            <div class="py-1 pr-3 text-center flex-shrink-0">
                                <Link

                                    @click="ev => {
                                        teamForm.name = c.name;
                                        teamForm.category_id = c.id;
                                        modalMode = 'edit';
                                        isOpen = true;
                                    }"

                                    class="bg-green-600 hover:bg-green-800"
                                >
                                    <SvgIcon name="pencil" width="18" height="18" />
                                </Link>
                                <Link @click="showRemove = c.id" class="bg-red-600 ml-2 hover:bg-red-800 relative z-99">
                                    <SvgIcon name="x" width="18" height="18"/>
                                </Link>
                                <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                                    v-if="showRemove == c.id"
                                >
                                    <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                                        <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć kategorię "{{c.name}} "?</h2>
                                        <div class="flex justify-center">
                                            <Link @click="deleteCategory(c.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                                                <SvgIcon name="check" width="18" height="18" />
                                                <span class="ml-2">Tak, Usuń </span>
                                            </Link>
                                            <Link @click="showRemove = false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                                <SvgIcon name="x" width="18" height="18" />
                                                <span class="ml-2">Nie, anuluj operację</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination class="mt-6" :links="team_categories.links" />
        </div>


        <div
            class="fixed inset-0 bg-gray-900 bg-opacity-50"
            v-if="isOpen"
        >
            <div class="flex closeModal flex-col h-screen items-center justify-center z-2" @click="closeModal($event)">
                <div class="p-4 px-8 bg-white dark:bg-gray-600 rounded-md shadow w-10/12 lg:w-1/2">
                    <div class="mt-2 mb-4 font-bold text-xl dark:text-white">{{modalMode == 'edit' ? 'Edycja kategorii' : 'Dodaj nową kategorię'}}</div>
                    <InputLabel
                        placeholder="Nazwa kategorii"
                        id="name"
                        name="name"
                        v-model="teamForm.name"
                        type="text"
                    />
                    <div class="flex justify-end mt-4">
                        <Link
                        class=""
                        color="green"
                        @click="ev => { if(modalMode == 'edit') editCategory(); else addCategory(); }"
                        >{{modalMode == 'edit' ? 'Edytuj' : 'Dodaj'}}</Link>
                    </div>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Pagination from '@/Components/Pagination'
    import Listbox from '@/Components/Listbox'
    import { Inertia } from '@inertiajs/inertia'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Listbox
        },
        props: {
            team_categories: [Array, Object]
        },
        setup(props) {
            const showRemove = ref(false);
            let isOpen = ref(false);
            let modalMode = ref(null);


            const closeModal = (el) => {
                if (el.target.classList.contains('closeModal')) {
                    isOpen.value = false;
                }
            }

            const teamForm = useForm({
                name: null,
                category_id: null,
            });
            const addCategory = (edit = false) => {
                teamForm.post(route('admin.team.category.add'), {
                    preserveScroll: true,
                    onSuccess: () => {
                        isOpen.value = false;
                        teamForm.reset();
                    }
                })

            }

            const editCategory = (id) => {
                teamForm.post(route('admin.team.category.edit', teamForm.category_id), {
                    preserveScroll: true,
                    onSuccess: () => {
                        isOpen.value = false;
                        teamForm.reset();
                    }
                })

            }

            const deleteCategory = (id) => {
                console.log(id)
                Inertia.post(route('admin.team.category.remove', id), {
                    preserveScroll: true,
                    onSuccess: () => {
                        showRemove.value = false;
                    }
                })
            }

            return {
                isOpen,
                closeModal,
                modalMode,
                addCategory,
                editCategory,
                teamForm,
                showRemove,
                deleteCategory,
            }
        }
    }
</script>
