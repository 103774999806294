<template>
    <div v-if="links.length > 3">
        <div class="flex flex-wrap -mb-1">
            <template v-for="(link, p) in $props.links" :key="p">
                <!-- <div v-if="link.url === null" class="mr-1 mb-1 bg-white px-4 py-3 text-xs leading-4 dark:bg-gray-600 dark:text-gray-300 dark:border-gray-800 text-gray-400 border border-gray-400 rounded"
                    v-html="link.label" /> -->
                <inertia-link v-if="link.url !== null"
                    class="mr-1 mb-1 px-4 py-3 text-xs leading-4 border rounded bg-white dark:bg-gray-700 text-black dark:text-white dark:border-gray-800 focus:border-indigo-500 focus:text-indigo-500"
                    :class="{
                        'bg-green-700 dark:bg-green-900 dark:border:green-900 border-green-800 text-white': link.active,
                        'border-gray-400':!link.active
                    }"
                    :href="getUrl(link.url)"
                    v-html="link.label"
                />
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            links: Array,
        },

        methods: {
            getUrl(value){
                return value;
            }
        },
    }

</script>
