<template>
    <input
        type="checkbox"
        :value="value"
        v-model="proxyChecked"
        :disabled="disabled"
        class="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
    >
</template>

<script>
export default {
    emits: ['update:checked', 'update:modelValue'],

    props: {
        checked: {
            type: [Array, Boolean, Number],
            default: false,
        },
        modelValue: [Array, Boolean, Number],
        value: {
            default: null,
        },
        disabled: Boolean,
    },

    computed: {
        proxyChecked: {
            get() {
                let checked = true;

                if(Array.isArray(this.modelValue)){
                    if(this.modelValue.find(e => e == this.value)){
                        checked = true;
                    }
                    else checked = false;
                }
                else {
                    if(this.modelValue === false || this.modelValue === true){
                        checked = this.modelValue;
                    }
                    else if(!this.value && this.value !== 0 && this.modelValue == false){
                        checked = false;
                    }
                    else if(this.value == this.modelValue) checked = false;
                }

                return checked;
            },

            set(val) {
                if(Array.isArray(this.modelValue)){
                   if(val){
                       this.modelValue.push(this.value);
                   }
                   else this.modelValue.splice(this.modelValue.indexOf(this.value), 1);
                }
                else this.$emit("update:modelValue", val);
            },
        },
    },
}
</script>
