<template>
    <div class="col-span-12" :class="closed ? 'closed':'opened'">

        <div
            class="text-xl flex items-center text-gray-600 font-bold uppercase mb-4 cursor-pointer w-full px-5 py-4 shadow-md dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-opacity-50 bg-gray-100 hover:bg-opacity-50"
            @click="hideShow()"
        >
            <span class="pointer-events-none">Kolejka #{{queue.number}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-6 w-6 pointer-events-none transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <!-- :class="{ 'transform rotate-180': opened }" -->
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
        </div>

        <div class="wrapper transition-all w-full m-auto ">
            <div class="w-full">
                <Qs v-for="item in queue.items" :key="item"
                :item="item"
                :league='league_name'
                borders='false'
               />

               <!-- {{ queue }} -->
            </div>
        </div>
    </div>
</template>

<script>

import Qs from '@/Components/Results/RegioResults.vue'

export default {
    components:{
        Qs
    },
    props: {
        news: Object,
        secondary: {
            type: Boolean,
            default: false
        },
        league_name: {
            type: String,
            default: '---'
        },
        closed: {
            type: Boolean,
            default: false
        },
        queue: [Object, Array]
    },
    data(){
            return{
                moreWiadomosci: false,
            }
        },
    methods: {

        hideShow(){
            let el = event.target
            if (el.parentNode.classList.contains('opened')){
                el.parentNode.classList.add('closed')
                el.parentNode.classList.remove('opened')

                for (let i=1; i < el.parentNode.children.length; i++){
                    el.children[1].classList.remove('transform')
                    el.children[1].classList.remove('rotate-180')
                }
            }
            else{
                el.parentNode.classList.add('opened')
                el.parentNode.classList.remove('closed')
                for (let i=1; i < el.parentNode.children.length; i++){
                    el.children[1].classList.add('transform')
                    el.children[1].classList.add('rotate-180')
                }
            }
        }
    },
    setup() {

    },
}
</script>

<style scoped>

/* Придумать решение по-лучше  */

.opened .wrapper{
    max-height: 300vh;
    overflow: hidden;
}
.closed .wrapper{
    max-height: 0;
    overflow: hidden;
}
</style>
