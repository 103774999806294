<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="flex justify-between mb-3 gap-4">
                <Link @click="addBox()" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Dodaj</span>
                </Link>
            </div>
            <div class="rounded-md overflow-hidden shadow-sm">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex justify-between">
                    <div class="py-3 pl-3">Kategoria</div>
                    <div class="py-3 pr-3 text-center">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                <draggable
                :list="boxes"
                @start="drag=true"
                @end="drag=false"
                @change="changePosition"
                tag="transition-group"
                handle=".handle"
                item-key="id"
                v-bind="dragOptions"
                >
                <template #item="{element}">
                    <div
                    class="border-b relative border-gray-200 dark:border-gray-500 bg-white text-gray-700 dark:bg-gray-600 dark:text-white cursor-pointer flex justify-between w-full"
                    >

                        <div class="w-full flex pl-3 flex-col lg:flex-row items-start lg:items-center justify-between">
                            <div class="py-1 text-sm flex items-center font-bold">
                                {{element.name}}
                            </div>
                            <div class="flex text-gray-500 dark:text-gray-400">
                                <div class="py-1 text-center flex items-center justify-center">
                                    <SvgIcon name="pencil" width="18" height="18" />
                                    <span class="ml-2 flex mr-3">
                                        <span class="hidden lg:block">Newsy:&nbsp;</span>
                                        <span>{{element.news_count }}</span>
                                    </span>
                                </div>
                                <div class="py-1 text-center flex items-center justify-center">
                                    <SvgIcon name="dollar" width="18" height="18" />
                                    <span class="ml-2 flex mr-3">
                                        <span class="hidden lg:block">Reklamy:&nbsp;</span>
                                        <span>{{element.adds_count}}</span>
                                    </span>
                                </div>
                                <div class="py-1 text-center flex items-center justify-center">

                                </div>
                            </div>
                        </div>
                        <div class="py-1 mr-3 ml-5 text-center flex items-center justify-center">
                            <Link class="mr-2 bg-green-600 hover:bg-green-800" :href="route('admin.edit.box', element.id)">
                                <SvgIcon name="pencil" width="18" height="18" />
                                <span class="ml-2 hidden lg:block">Edytuj</span>
                            </Link>
                            <Link @click="showRemove = element.id" class="mr-2 bg-red-600 hover:bg-red-800">
                                <SvgIcon name="x" width="18" height="18" />
                                <!-- <span class="ml-2 hidden lg:block">Usuń</span> -->
                            </Link>
                            <div class="handle cursor-move transform text-gray-400 dark:text-gray-300">
                                <SvgIcon
                                name="menu"
                                />
                            </div>
                        </div>
                        <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                             v-if="showRemove == element.id"
                        >
                            <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                                <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć Box "{{element.name}}"?</h2>
                                <div class="flex">
                                    <Link @click="removeBox(element.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                                        <SvgIcon name="check" width="18" height="18" />
                                        <span class="ml-2">Tak, Usuń</span>
                                    </Link>
                                    <Link @click="showRemove=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                        <SvgIcon name="x" width="18" height="18" />
                                        <span class="ml-2">Nie, anuluj operację</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                </draggable>
                </div>
            </div>
        </div>

    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted, watch } from 'vue'
    import Link from '@/Components/Link'
    import { Inertia } from '@inertiajs/inertia'
    import draggable from 'vuedraggable'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Link,
            draggable
        },
        props: {
            boxes: Array,
        },
        setup(props) {
            const showRemove = ref(false);
            const drag = ref(false);
            const dragOptions = ref({
                animation: 200,
                disabled: false,
            });
            onMounted(() => {

            })

            const removeBox = (id) => {
                Inertia.post(route('admin.box.delete', id), {}, {
                    onSuccess: () => {
                        // reloadCategories();
                    }
                })
                showRemove.value = false;
            }

            const addBox = () => {
                Inertia.post(route('admin.box.add'))
            }

            const changePosition = (e) => {
                let positions = {}
                props.boxes.forEach((el, key) => {
                    positions[el.id] = key+1
                })
                Inertia.post(route('admin.boxes.change.position'), {boxes: positions}, {
                    onSuccess: () => {
                    }
                })
            }


            return {
                addBox,
                removeBox,
                changePosition,
                drag,
                dragOptions,
                showRemove
            }
        }
    }
</script>
