<template>
    <MainLayout>
        Testowa stronka
    </MainLayout>
</template>


<script>

    import MainLayout from '@/Layouts/Main'
    import Regio from '@/Components/Results/RegioResults'
    import MainResults from '@/Components/Results/MainResults'
    import Table from '@/Components/Results/tableResults'
    import { ref, watch, onMounted } from 'vue'
    import moment from 'moment'
    import Roll from '@/Components/rollUp'

    export default {
        components: {
            MainLayout,
            Regio,
            Table,
            Roll,
            MainResults
        },
        props: {
            news: Object,
            leagues: Array,
            categories: [Array, Object],
            results: [Object, Array]
        },
        setup(props) {
            
        },

    }
</script>

