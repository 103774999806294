<template>
    <inertia-link v-if="href" :href="href"><slot /></inertia-link>
    <button v-else><slot /></button>
</template>

<script>
export default {
    props: {
        href: String,
    }
}
</script>

<style>

</style>
