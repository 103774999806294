<template>
    <div class="relative w-full">
        <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
            <transition
            enter-active-class="transition ease-out duration-200 delay-100 origin-left"
            enter-from-class="transform scale-x-0"
            enter-to-class="transform scale-x-100"
            leave-active-class="transition ease-in duration-200 origin-left"
            leave-from-class="transform scale-x-100"
            leave-to-class="transform scale-x-0">
            <div v-if="loaded" :style="'width: '+percent+'%'" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-600"></div>
            </transition>
        </div>
        <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" v-if="number !== null">
            {{number}}
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from "vue";
    export default {
        props: {
            percent: {
                type: Number,
                default: 50,
            },
            number: {
                type: Number,
                default: null,
            },
        },
        setup(props) {
            const loaded = ref(false);

            onMounted(() => {
                loaded.value = true;
            })

            return {
                loaded
            }
        }
    }
</script>
