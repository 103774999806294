<template>
<!-- {{ item }} -->
    <div class="grid grid-cols-12 relative dark:odd:bg-gray-900 odd:bg-gray-100 odd:bg-opacity-50 p-1" v-if="!item.pause">

        <div class="col-span-full sm:pb-0 pb-2 sm:col-span-2 lg:col-span-2 md:col-span-2 relative ">
            <div class="flex sm:border-b-0 border-b border-gray-700 sm:pb-0 pb-2 sm:flex-col sm:justify-center justify-start sm:items-start items-center h-full">
                <!-- Data Meczu -->
                <div v-if="item.match_date != null" class="flex sm:flex-col sm:items-start items-center sm:gap-0 gap-2">
                    <p class="text-sm">{{getDate(item.match_date, 'DD/MM/YYYY')}}</p>
                    <p class="text-xs text-gray-500">{{getDate(item.match_date, 'HH:mm')}}</p>
                    <!-- <p class="xl:text-lg text-base text-red-600 font-bold">{{getDate(item.match_date, 'HH:mm')}}</p> -->
                </div>
                <div v-else>
                    <p class="text-base font-bold">00/00/0000 00:00</p>
                    <p class="text-xs text-gray-500">00:00</p>
                    <!-- <p class="xl:text-lg text-base text-red-600 font-bold">00:00</p> -->
                </div>
                <i class="text-xs sm:hidden text-gray-400 block ml-auto">  # {{ item.id }} </i>
            </div>
        </div>
        <div class="col-span-full sm:order-2 order-1 sm:col-span-7 flex justify-center align-center relative font-bold">
            <!-- Zespoły + wyniki -->
            <div class="wrapper w-full col-span-full grid grid-cols-5 md:grid-cols-5 items-center justify-center gap-2">
                <div class="col-span-2 flex items-center justify-between md:flex-row flex-col">
                    <img
                        v-if="$props.item && $props.item.host &&$props.item.host.logo"
                        v-lazy="{
                                src: $props.item.host.logo ? $props.item.host.logo : imgS,
                                loading: '/img/cms/loading.gif',
                                error: '/img/cms/img-error.png'
                            }"
                        alt="" srcset="" class="md:order-1 order-2 max-w-full max-h-full sm:w-auto h-16 ml-auto"
                    >
                    <p
                        class="md:text-base ml-auto text-sm md:order-2 order-1 font-bold text-right"
                        :class="[
                            item.host_id === 0 ? 'text-blue-500' : ''
                        ]"
                    >{{item.host ? item.host.club_name : (item.host_id === 0 ? 'PAUZUJE' : '---')}}</p>
                </div>
                <p class="text-base text-center">
                    <inertia-link v-if="item.news && item.news.length" class="text-blue-500 hover:text-blue-600 hover:underline" :href="route('news.id', item.news[0].slug)">
                        {{getGoals()}}
                    </inertia-link>
                    <span v-else>{{getGoals()}}</span>
                </p>
                <div class="col-span-2 flex items-center justify-between md:flex-row flex-col">
                    <p
                        class="md:text-base mr-auto md:order-1 order-2 text-sm font-bold text-left"
                        :class="[
                            item.guest_id === 0 ? 'text-blue-500' : ''
                        ]"
                    >{{item.guest ? item.guest.club_name : (item.guest_id === 0 ? 'PAUZUJE' : '---')}}</p>
                    <img
                        v-if="$props.item && $props.item.guest && $props.item.guest.logo"
                        v-lazy="{
                            src: $props.item.guest.logo ? $props.item.guest.logo : imgS,
                            loading: '/img/cms/loading.gif',
                            error: '/img/cms/img-error.png'
                        }"
                        alt="" srcset="" class="order-2 max-w-full max-h-full sm:w-auto h-16 mr-auto"
                    >
                </div>
                <div v-if="item.comment" class="wrapper w-full col-span-full lg:text-center md:text-center text-left lg:grid-cols-5 md:grid-cols-5">
                    <p class="text-xs col-span-full w-full  text-yellow-300"> {{ item.comment }}</p>
                </div>
            </div>
        </div>
        <div class="lg:col-span-3 sm:col-span-3 order-4  text-left justify-center align-center relative hidden sm:block" >
            <!-- Liga -->
            <div class="wrapper text-sm w-full flex items-center justify-end">
                <span class="flex flex-col items-end gap-1"> {{league}}  <br/><i class="text-xs text-gray-500"> #{{ item.id }} </i></span><br>
            </div>


        </div>
        <!-- <div class="" v-if="item.news.length > 0">
            {{ item.news[0].slug }}
        </div> -->

    </div>
</template>

<script>
import moment from 'moment'
export default {
    setup(props) {
        const getDate = (date, format) => {
            return moment(date).format(format)
        }

        const getGoals = () => {
            let walkover = '';
            let host_goals = props.item.host_goals !== null ? props.item.host_goals : '-';
            let guest_goals = props.item.guest_goals !== null ? props.item.guest_goals : '-';

            if(props.item.walkover != 'none'){
                walkover = ' v.o';

                if(props.item.walkover == 'for_host'){
                    host_goals = 3;
                    guest_goals = 0;
                }
                else if(props.item.walkover == 'for_guest'){
                    host_goals = 0;
                    guest_goals = 3;
                }
                else if(props.item.walkover == 'equal') {
                    host_goals = 3;
                    guest_goals = 3;
                }

            }
            else {
                if(props.item.host_id === 0 || props.item.guest_id === 0){
                    host_goals = '-';
                    guest_goals = '-';
                }
            }

            return host_goals + ":" + guest_goals + walkover;
        }

        return {
            getDate,
            getGoals
        }
    },
    props: {
        item: Object,
        imgF: {
            type: String,
            default: "/img/cms/bulbasaur.png"
        },
        imgS: {
            type: String,
            default: "/img/cms/bulbasaur.png"
        },
        league: {
            type: String,
            default: "Nameless League"
        },
        // borders: {
        //     type: Boolean,
        //     default: true
        // }

    }
}
</script>
