<template>
    <div>


        <div class="mt-6">
            <form @submit.prevent="upload_images" enctype="multipart/form-data" method="post">
                <label
                    for="file"
                    class="group relative bg-white dark:bg-gray-300 h-48 border-2 rounded py-6 text-center flex flex-col justify-center items-center border-dotted border-gray-300 hover:border-gray-400 cursor-pointer"
                >
                <img
                    v-if="image_base642"
                    class="h-full w-full absolute inset-0"
                    :src="image_base642"
                >
                <img
                    v-else-if="preimage"
                    class="h-full w-full absolute inset-0"
                    :src="preimage"
                >
                <div class="mt-2 flex" :class="image_base642 || preimage ? 'mb-4':''">
                    <div
                        class="flex-1 rounded-md mx-2 product-image flex justify-center hover:border-gray-400 relative group shadow-md"
                    >
                        <div class="absolute top-1 right-1 group-hover:text-gray-400 text-gray-200">
                            <SvgIcon
                                name="x-circle"
                                class="text-gray-400 hover:text-red-600 cursor-pointer rounded-full"
                                width="36"
                                height="36"
                                v-if="image_base642 || preimage"

                            />
                        </div>

                    </div>
                </div>
                    <SvgIcon
                        name="plus-circle"
                        class="text-gray-400 group-hover:text-gray-600 cursor-pointer rounded-full"
                        width="36"
                        height="36"

                    />
                </label>
                <input
                    type="file"
                    @input="upload_form.image = $event.target.files[0]"
                    class="hidden"
                    id="file2"
                    name="filess2"
                    @change="upload_images"
                />

                <div class="w-full h-4 bg-gray-400" v-if="upload_form.progress">
                    <div
                        class="bg-green-600 h-full"
                        :style="'width: '+upload_form.progress.percentage+'%'"
                    >
                    </div>
                </div>

                <!-- <button type="submit" class="border-2 px-3 py-1 border-gray-400 rounded-sm mt-2 mx-auto">Submit</button> -->
            </form>

        </div>
    </div>
</template>

<script>
import SvgIcon from '@/Components/SvgIcon';
import { Inertia } from '@inertiajs/inertia'
import { useForm } from '@inertiajs/inertia-vue3'
import { ref, watch, onMounted } from 'vue';

export default {
    components: {
        SvgIcon,
    },
    props: {
        product: Object,
        autosend: Boolean,
        variation: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default: null
        },
        preimage: {
            type: String,
            default: null
        },
    },
    emits: ['imageRemoved', 'imagesUploaded'],
    setup (props, {emit}) {
        const upload_form = useForm({
            image: props.image,
        })


        const upload_images = () => {
                getImageInBase64(upload_form.image)
                // upload_form.post(route('admin.images.add'))
                emit('imagesUploaded', upload_form.image)
        }

        const image_base642 = ref(null);

        onMounted(() => {
            if (upload_form.image)
            image_base642.value = props.image
            // getImageInBase64(upload_form.image);
        })

        const getImageInBase64 = (image) => {
            var FR= new FileReader();

            FR.addEventListener("load", function(e) {
                image_base642.value = e.target.result
            });
            FR.readAsDataURL( image );

        }

        return { image_base642, upload_form, upload_images, getImageInBase64 }
    },
}
</script>

<style>
.product-image:first-of-type {margin-left: 0 !important;}
.product-image:last-of-type {margin-right: 0 !important;}
</style>
