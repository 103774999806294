<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="mb-3 flex gap-3 justify-between">
                <Link class="flex gap-2 items-center" :href="route('admin.post.events.clubs.add')">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span>Dodaj</span>
                </Link>
                <Link :href="route('admin.team.category')" color="green">Kategorie drużyn</Link>
            </div>
            <div class="w-full my-3 lg:hidden flex relative">
                <input
                        class="w-full rounded bg-white border-gray-400 border-2 inner-shadow-sm text-gray-700"
                        type="text"
                        placeholder="Szukaj po nazwie"
                        v-model="searchForm.string"
                        @change="searchClubs()"
                        />
                        <button @click="searchClubs()" class=" px-6 flex items-center justify-center bg-green-600 hover:bg-green-800 ml-2 text-white rounded">
                            <SvgIcon name="search" width="18" height="18" />
                        </button>
            </div>
            <div class="rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-3">Klub / ŁNP</div>
                    <div class="w-1/2 hidden lg:block">
                        <input
                        class="w-full bg-gray-800 border-none inner-shadow-sm text-white"
                        type="text"
                        placeholder="Szukaj po nazwie"
                        v-model="searchForm.string"
                        @change="searchClubs()"
                        />
                    </div>
                    <div class="py-3 pr-3">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="(c, club_key) in clubs.data"
                        :key="club_key"
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:hover:bg-opacity-80 dark:bg-gray-600 dark:text-white cursor-pointer"

                    >
                        <div
                            class="flex sm:flex-row flex-col justify-between sm:items-center items-start"
                            @click.self="c.teams_show = !c.teams_show"
                        >
                            <inertia-link
                                class="pl-3 py-1 hover:text-green-500"
                                :href="route('singleclub', c.id)"
                            ><span class="font-bold">{{c.name}}</span> / <span class="text-xs font-normal">{{ c.laczynaspilka_name }}</span></inertia-link>
                            <div class="py-1 pr-3 text-center flex flex-shrink-0 sm:flex-row flex-col">
                                <div class="flex divide-x text-gray-500 dark:text-gray-200">
                                    <div class="flex items-center px-2">
                                        <SvgIcon class="mr-1" name="book-open" width="18" height="18" />
                                        <span class="text-sm font-bold">{{ c.news_count }}</span>
                                    </div>
                                    <div class="flex items-center px-2">
                                        <svg class="mr-1" fill="currentColor" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M17.422 20.394c-.177-.415-.219-.698-.289-1.118.625-.697 1.189-1.432 1.692-2.204.58-.196 1.271-.438 2.281-.956-.795 1.756-2.08 3.239-3.684 4.278m-8.181 1.212c1.039-.558 1.89-1.193 2.831-1.899 1.012.253 2.079.395 3.194.443l.001.007c.083.435.205.803.362 1.153-1.987.777-4.182.93-6.388.296m-7.24-9.619l1.273 1.217c-.194 1.076-.248 2.069-.234 3.214-.659-1.334-1.04-2.83-1.04-4.418l.001-.013m11.371-9.882l-.758 1.737c-2.139.56-3.384 1.125-5.214 2.107l-2.863-.586c2.128-2.389 5.337-3.74 8.835-3.258m-1.804 11.769c-1.083-.726-1.941-1.464-3.466-2.727l.546-3.576c1.446-.848 2.566-1.239 4.477-1.849.999.687 1.984 1.428 2.934 2.216l-.002.023c-.138 1.739-.42 3.066-.845 4.495-1.196.524-2.41.998-3.644 1.418m-4.758 6.661c-.555-.339-1.072-.728-1.549-1.164-.256-1.921-.361-3.89-.003-5.865l.001-.004 1.716-.745c1.211 1.126 2.346 2.004 3.676 2.928l.063 2.525c-1.323 1.046-2.369 1.738-3.904 2.325m15.108-7.311c-1 .722-1.776 1.225-3.025 1.683l-1.734-2.007c.451-1.449.738-3 .866-4.727l2.499-1.381c1.147 1.872 1.681 4.066 1.394 6.432m-9.918-13.224c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12"/></svg>
                                        <span class="text-sm font-bold">{{ c.leagues_count }}</span>
                                    </div>
                                    <div  class="flex items-center px-2">
                                        <SvgIcon name="user" width="18" height="18" />
                                        <span class="text-sm font-bold">{{ c.teams.length }}</span>
                                    </div>

                                </div>
                                <div class="sm:mt-0 mt-2">
                                    <Link class="mx-2 bg-gray-800"
                                        @click="c.teams_show = !c.teams_show"
                                    >
                                    <SvgIcon name="menu" width="18" height="18" />
                                    <span class="ml-2 hidden sm:block">Drużyny</span>
                                    </Link>
                                    <Link
                                    class="mr-2 bg-blue-600 hover:bg-blue-800"
                                        @click="
                                            modalMode = 'add';
                                            isOpen = true;
                                            currentClub = club_key;
                                        "
                                    >
                                        <SvgIcon name="plus" width="18" height="18" />
                                        <span class="ml-2 hidden sm:block">Dodaj</span>
                                    </Link>
                                    <Link :href="route('admin.post.events.clubs.edit', c.id)" class="bg-green-600 hover:bg-green-800">
                                        <SvgIcon name="pencil" width="18" height="18" />
                                        <!-- <span class="ml-2 hidden sm:block">Edytuj</span> -->
                                    </Link>
                                    <Link @click="showRemove = c.id" class="bg-red-600 ml-2 hover:bg-red-800 relative z-99">
                                        <SvgIcon name="x" width="18" height="18"/>
                                    </Link>
                                </div>
                                <div class="popup bg-opacity-20 fixed top-0 left-0 w-screen h-screen bg-gray-900 flex items-center justify-center z-50"
                                    v-if="showRemove == c.id"
                                >
                                    <div class="w-10/12 md:w-6/12 bg-white rounded p-5 shadow">
                                        <h2 class="mb-5 font-bold text-gray-900">Napewno chcesz usunąć Klub "{{c.name}} "?</h2>
                                        <div class="flex justify-center">
                                            <Link @click="deleteClub(c.id)" class="bg-red-600 hover:bg-red-800 mr-2">
                                                <SvgIcon name="check" width="18" height="18" />
                                                <span class="ml-2">Tak, Usuń </span>
                                            </Link>
                                            <Link @click="showRemove=false" class="bg-gray-600 hover:bg-gray-800 mr-2">
                                                <SvgIcon name="x" width="18" height="18" />
                                                <span class="ml-2">Nie, anuluj operację</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <transition

                        >
                        <div class="flex flex-col px-6 bg-gray-300 dark:bg-gray-700 dark:text-white shadow-sm" style="min-height: 25px;" v-if="c.teams_show">
                            <div
                            v-for="(team, team_key) in c.teams"
                            :key="team_key"
                            class="border-b border-gray-200 dark:border-gray-500 dark:text-white w-full py-2 flex justify-between items-center"
                            >
                                <div v-if="!team.editing">
                                    {{team.name}} ({{team.category_name}}) <template v-if="team.laczynaspilka_name">/ <span class="text-xs font-normal">{{ team.laczynaspilka_name }}</span></template>
                                </div>
                                <div v-else>
                                    <input type="text" v-model="team.name">
                                </div>
                                <div class="flex gap-2 flex-wrap">
                                    <div class="flex items-center px-2">
                                        <svg class="mr-1" fill="currentColor" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M17.422 20.394c-.177-.415-.219-.698-.289-1.118.625-.697 1.189-1.432 1.692-2.204.58-.196 1.271-.438 2.281-.956-.795 1.756-2.08 3.239-3.684 4.278m-8.181 1.212c1.039-.558 1.89-1.193 2.831-1.899 1.012.253 2.079.395 3.194.443l.001.007c.083.435.205.803.362 1.153-1.987.777-4.182.93-6.388.296m-7.24-9.619l1.273 1.217c-.194 1.076-.248 2.069-.234 3.214-.659-1.334-1.04-2.83-1.04-4.418l.001-.013m11.371-9.882l-.758 1.737c-2.139.56-3.384 1.125-5.214 2.107l-2.863-.586c2.128-2.389 5.337-3.74 8.835-3.258m-1.804 11.769c-1.083-.726-1.941-1.464-3.466-2.727l.546-3.576c1.446-.848 2.566-1.239 4.477-1.849.999.687 1.984 1.428 2.934 2.216l-.002.023c-.138 1.739-.42 3.066-.845 4.495-1.196.524-2.41.998-3.644 1.418m-4.758 6.661c-.555-.339-1.072-.728-1.549-1.164-.256-1.921-.361-3.89-.003-5.865l.001-.004 1.716-.745c1.211 1.126 2.346 2.004 3.676 2.928l.063 2.525c-1.323 1.046-2.369 1.738-3.904 2.325m15.108-7.311c-1 .722-1.776 1.225-3.025 1.683l-1.734-2.007c.451-1.449.738-3 .866-4.727l2.499-1.381c1.147 1.872 1.681 4.066 1.394 6.432m-9.918-13.224c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12"/></svg>
                                        <span class="text-sm font-bold">{{ team.leagues_count }}</span>
                                    </div>
                                    <Link
                                    padding="p-2"
                                    color="green"
                                    @click="
                                        currentClub = club_key;
                                        currentTeam = team_key;
                                        isOpen = !isOpen;
                                        modalMode = 'edit';
                                        selectedAgeCategory = $props.age_categories.map(e => e.id).indexOf(team.team_category_id);
                                        teamForm.laczynaspilka_name = team.laczynaspilka_name
                                    "
                                    >
                                        <SvgIcon name="pencil" width="18" height="18" />
                                        <span class="ml-2 hidden sm:block">Edytuj</span>
                                    </Link>
                                    <Link @click="deleteTeam(team.id)" padding="p-2" class="bg-red-600 hover:bg-red-800 relative z-99">
                                        <SvgIcon name="x" width="18" height="18"/>
                                    </Link>


                                </div>
                            </div>
                        </div>
                        </transition>
                    </div>
                </div>
            </div>
            <pagination class="mt-6" :links="clubs.links" />
        </div>


        <div
        class="fixed inset-0 bg-gray-900 bg-opacity-50"

        v-if="isOpen && currentClub != null"
        >
            <div class="flex closeModal flex-col h-screen items-center justify-center z-2" @click="closeModal($event)">
                <div class="p-4 px-8 bg-white dark:bg-gray-600 rounded-md shadow w-10/12 lg:w-1/2">
                    <div class="mt-2 mb-4 font-bold text-xl dark:text-white">{{modalMode == 'edit' ? 'Edycja drużyny' : 'Dodaj nową drużynę'}}</div>
                    <InputLabel
                        placeholder="Nazwa drużyny"
                        id="name"
                        name="name"
                        type="text"
                        autofocus
                        v-model="clubs.data[currentClub].teams[currentTeam].name"
                        v-if="modalMode == 'edit'"
                    />
                    <InputLabel
                        placeholder="Nazwa drużyny"
                        id="name"
                        name="name"
                        type="text"
                        autofocus
                        v-model="teamForm.name"
                        v-if="modalMode == 'add'"
                    />

                    <InputLabel
                        placeholder="Nazwa drużyny na ŁNP"
                        id="laczynaspilka_name"
                        name="laczynaspilka_name"
                        class="mt-2"
                        v-model="teamForm.laczynaspilka_name"
                        type="text"
                    />
                    <Listbox
                        :data="age_categories"
                        v-model="selectedAgeCategory"
                        type="key"
                        class="mt-2"
                    />
                    <div class="flex justify-end mt-4">
                        <Link
                        class=""
                        color="green"
                        @click="editTeam()"
                        v-if="modalMode == 'edit'"
                        >Zapisz</Link>
                    </div>
                    <div class="flex justify-end mt-4">
                        <Link
                        class=""
                        color="green"
                        @click="addTeam()"
                        v-if="modalMode == 'add'"
                        >Dodaj</Link>
                    </div>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Pagination from '@/Components/Pagination'
    import Listbox from '@/Components/Listbox'
    import { Inertia } from '@inertiajs/inertia'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Listbox
        },
        props: {
            clubs: Object,
            age_categories: Array
        },
        setup(props) {
            const showRemove = ref(false);
            let isOpen = ref(false);
            let modalMode = ref(null);
            let newTeamName = ref(null);
            let currentTeam = ref(0);
            let currentClub = ref(2);
            const selectedAgeCategory = ref(0);

            const searchForm = useForm({
                string: route().params.search ? route().params.search : null
            });
            props.clubs.data.forEach(function(el) {
                el.teams_show = false;
                el.teams.forEach(function(team) {
                    team.editing = false
                })
            })
            const searchClubs = () => {
                Inertia.get(route('admin.events.clubs'), { search: searchForm.string }, {preserveState: true})
            }

            const closeModal = (el) => {
                if (el.target.classList.contains('closeModal')) {
                    isOpen.value = false;
                }
            }

            const editTeam = () => {
                teamForm.club_id = props.clubs.data[currentClub.value].id;
                teamForm.name = props.clubs.data[currentClub.value].teams[currentTeam.value].name;
                teamForm.category = props.age_categories[selectedAgeCategory.value];
                teamForm.team_id = props.clubs.data[currentClub.value].teams[currentTeam.value].id;

                teamForm.post(route('admin.clubs.team.edit', props.clubs.data[currentClub.value].teams[currentTeam.value].id), {
                    preserveScroll: true,
                    onSuccess: () => {
                        isOpen.value=false,
                        teamForm.reset();

                        teamForm.category = 0;
                    },
                })
            }

            const teamForm = useForm({
                club_id: null,
                team_id: null,
                laczynaspilka_name: null,
                name: null,
                category: null
            });
            const addTeam = () => {
                teamForm.club_id = props.clubs.data[currentClub.value].id;
                teamForm.category = props.age_categories[selectedAgeCategory.value];

                teamForm.post(route('admin.clubs.team.add'), {
                    preserveScroll: true,
                    onSuccess: () => {
                        isOpen.value=false,
                        teamForm.reset();

                        teamForm.category = 0;
                    }
                })
            }

            const deleteClub = (id) => {
                Inertia.post(route('admin.clubs.delete', id))
            }

            const deleteTeam = (id) => {
                Inertia.post(route('admin.clubs.team.delete', id))
            }

            return {
                searchForm,
                searchClubs,
                isOpen,
                currentTeam,
                currentClub,
                closeModal,
                selectedAgeCategory,
                editTeam,
                modalMode,
                newTeamName,
                addTeam,
                teamForm,
                deleteClub,
                showRemove,
                deleteTeam,
            }
        }
    }
</script>
