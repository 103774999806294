<template>
    <!-- <AdminLayout> -->
        dupa
        <div @click="dupadupa()" class="p-4 bg-green-200">DUPA</div>
    <!-- </AdminLayout> -->
</template>

<script>
    // import AdminLayout from '@/Layouts/Authenticated'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            // AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination
        },
        props: {

        },
        setup() {
            const dupadupa = () => {
                    console.log("test")
                }
            return {
                dupadupa
            }
        }
    }
</script>
