<template>
    <AdminLayout>
        <div class="px-3 pb-5">

            <div class="flex justify-center">
                <div
                class="rounded-full w-24 h-24 lg:w-36 lg:h-36 bg-green-600 flex justify-center items-center text-gray-100 my-4 cursor-pointer"
                @click="scanForGalleries()"
                >
                    <div v-if="!loading" class="text-lg lg:text-xl uppercase font-bold">Skanuj</div>
                    <div v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="5rem" height="5rem" fill="currentColor" class="animate-spin" style="animation-duration: 2s;" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                        </svg>
                    </div>
                </div>
            </div>


            <div class="w-full table-auto rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal grid grid-cols-3 gap-2">
                    <div class="py-3 pl-3">News</div>
                    <div class="py-3 text-center">Zdjęcia</div>
                    <div class="py-3 text-right pr-3">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-if="$props.news"
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white cursor-pointer flex flex-row justify-between items-center"
                    >
                        <div class="pl-3 py-1 flex flex-wrap make-smaller">
                            <inertia-link
                                :href="route('admin.dashboard.gallery.news')"
                                class="flex flex-wrap "
                            >
                            Galeria newsów
                            </inertia-link>
                        </div>
                        <div class="py-1 px-4 text-center text-white bg-gray-400 rounded-full">{{ news }}</div>
                        <div class="py-1 mr-3 text-center">
                            <Link :href="route('admin.dashboard.gallery.news')" class="bg-green-600 hover:bg-green-800">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                               <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
                            </svg>
                            <span class="ml-2 hidden sm:block">
                                zdjęcia
                            </span>
                            </Link>
                        </div>
                    </div>
                    <div
                        v-for="g in $props.galleries"
                        :key="g"
                        class="border-b grid grid-cols-3 gap-2 border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white cursor-pointer"
                    >
                        <div class="pl-3 py-1 flex-wrap make-smaller flex justify-start items-center">
                            <inertia-link
                                :href="route('admin.dashboard.gallery', g.id)"
                                class="flex flex-wrap "
                            >
                            {{g.name}}
                            </inertia-link>
                        </div>
                        <div class="py-1 px-4 text-center text-white flex justify-center items-center">
                            <div class="bg-gray-400 rounded-full flex items-center justify-center px-3">{{g.images.length}}</div>
                        </div>
                        <div class="py-1 mr-3 text-right">
                            <Link :href="route('admin.dashboard.gallery', g.id)" class="bg-green-600 hover:bg-green-800">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                               <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
                            </svg>
                            <span class="ml-2 hidden sm:block">
                                zdjęcia
                            </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick } from 'vue'
    import { Inertia } from '@inertiajs/inertia'
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination
        },
        props: {
            galleries: Array,
            news: [Object, Array, Number],
        },
        setup() {
            const loading = ref(false);
            const scanForGalleries = () => {
                loading.value = true;
                Inertia.post(route('admin.scan.galleries'), null, {
                    onSuccess: function(){
                        loading.value = false;
                    },
                });
            }
            return {
                scanForGalleries,
                loading
            }
        }
    }
</script>

<style scoped>
.make-smaller{
    max-width: 400px;
}
@media (max-width: 568px){
    .make-smaller{
        max-width: 150px;
    }
}
</style>
