<template>
    <div v-if="img" class="grid grid-cols-4 gap-5">

        <div class="col-span-4 sm:col-span-3 dark:text-white gap-3">
            <h2 class="mb-3 font-bold">Edycja:</h2>
            <Cropper
                class="cropper"
                :src="img"
                :stencil-props="{
                aspectRatio: 5/3
                }"
                @change="change"
                :default-size="defaultSize"
            ></Cropper>
        </div>
        <div class="col-span-4 sm:col-span-1">
            <h2 class="mb-3 dark:text-white font-bold">Podgląd:</h2>
            <img
                v-lazy="{
                    src: image,
                    loading: '/img/cms/loading.gif',
                    error: '/img/cms/img-error.png'
                }"
                alt="">
        </div>
    </div>
</template>

<script>
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

	export default {
        data(){
            return{
                // To trzeba jakoś przesłać do backendu po wgraniu obrazka
                image: null
            }
        },
        props:{
            img:{
                type: String,
                default: null
            }
        },
        methods: {

            change({coordinates, canvas}) {
                // przeskalowany obrazek:
                this.image = canvas.toDataURL()
                this.$emit('imagesUploaded', canvas.toDataURL())

            },
            defaultSize(imageSize, visibleArea) {
                return {
                    width: imageSize.imageSize.width,
                    height: imageSize.imageSize.height,
                };
		}

        },
        components: {
            Cropper
        },
        emits: ['imagesUploaded'],

	}
</script>
