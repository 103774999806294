<template>
    <AdminLayout>

        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <Link :href="route('admin.news.boxes')">Wróć</Link>
            <h1
                class="text-xl font-thin mb-3 dark:text-white mt-6"
            >
                Edycja boxa: <span class="font-bold">{{box.name}}</span>
            </h1>
            <div class="">
                <InputLabel
                type="text"
                label="Nazwa kategorii"
                v-model="boxForm.name"
                />
            </div>

            <!-- <div class="mt-4" v-if="$props.news_list_box">
                <label class="dark:text-white">Główny wpis</label>
                <Listbox
                    :data="$props.news_list_box"
                    v-model="boxForm.main_news"
                    :selected="boxForm.main_news"
                    class="mt-2"
                    type="objectkey"
                />
            </div> -->

            <div class="mt-4 grid md:grid-cols-2 gap-4">
                <div class="dark:text-white space-y-4">
                    <div class="">
                        <input type="checkbox" id="visible_mobile" v-model="boxForm.visible_mobile">
                        <label for="visible_mobile" class="ml-2">Widoczny na wersji mobilnej</label>
                    </div>
                    <div class="dark:text-white space-y-4">
                        <input type="checkbox" id="visible_desktop" v-model="boxForm.visible_desktop">
                        <label for="visible_desktop" class="ml-2">Widoczny na wersji desktopowej</label>
                    </div>
                </div>

                <div class="dark:text-white col-start-1">
                    <InputLabel
                    type="number"
                    label="Rzędy"
                    v-model="boxForm.rows"
                    />
                </div>
            </div>
            <div class="mt-4" v-if="$props.box && $props.box.main_news">
                <h1 class="mb-2 dark:text-white text-gray-500">Wpis główny</h1>
                <div
                    class="border-b relative border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-700 dark:text-white cursor-pointer flex justify-between hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                    <div class="py-1 pl-2 gap-3 text-sm flex items-center flex-wrap font-bold">
                        <span>{{$props.box.main_news.title}}</span>
                         <span class="text-gray-500 dark:text-gray-300 mt-1 font-normal text-xs">{{ getDate($props.box.main_news.created_at) }}</span>
                    </div>
                    <div>
                        <div class="py-1 text-center flex items-center justify-center mr-2">
                            <Link :href="route('admin.news.edit', $props.box.main_news.id)" class="bg-green-600 hover:bg-green-800">
                                <SvgIcon name="pencil" width="18" height="18" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
             <div class="mt-4" v-if="$props.news && $props.news.data.length">
                 <h1 class="mb-2 dark:text-white text-gray-500">Newsy przypisane do boxa</h1>
                <ul>
                    <li
                        class="border-b relative border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-700 dark:text-white cursor-pointer flex justify-between hover:bg-gray-100 sm:flex-row flex-col dark:hover:bg-gray-700"
                        v-for="cat in $props.news.data" :key="cat.id"
                    >
                        <div class="py-1 pl-2 gap-3 text-sm flex items-center flex-wrap font-bold">
                            <span>{{cat.title}}</span> <span class="text-gray-500 dark:text-gray-300 mt-1 font-normal text-xs">{{ getDate(cat.created_at) }}</span>
                        </div>
                        <div class="ml-auto">
                            <div class="py-1 gap-2 text-center flex items-center justify-center mr-2">
                                <Link
                                    @click="ev => {
                                        if(boxForm.main_news == cat.id) boxForm.main_news = null;
                                        else boxForm.main_news = cat.id;
                                    }"
                                    :class="[
                                        cat.id == boxForm.main_news ?
                                            'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-500 dark:hover:bg-yellow-600' :
                                                'bg-gray-500 hover:bg-gray-600 dark:bg-gray-500 dark:hover:bg-gray-600'
                                    ]"
                                >
                                    Wpis główny
                                </Link>
                                <Link :href="route('admin.news.edit', cat.id)" class="bg-green-600 hover:bg-green-800">
                                    <SvgIcon name="pencil" width="18" height="18" />
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="flex justify-center">
                    <Pagination class="mt-6" :links="$props.news.links" />
                </div>
            </div>

            <div class="mt-6 flex justify-end sticky bottom-0 py-1 bg-gray-200 dark:bg-gray-800">
                <Link @click="editBox()" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="check" width="18"  height="18" />
                    <span class="ml-2">Zapisz</span>
                </Link>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted } from 'vue'
    import Link from '@/Components/Link'
    import Listbox from '@/Components/Listbox'
    import moment from 'moment';
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Listbox
        },
        props: {
            box: [Array, Object],
            news: [Array, Object],
            news_list_box: [Array, Object],
        },
        setup(props) {


            function getDate(date){
                return moment(date).format('DD.MM.YYYY, HH:mm')
            }
            const boxForm = useForm({
                name: props.box.name,
                visible_mobile: props.box.visible_mobile ? true:false,
                visible_desktop: props.box.visible_desktop ? true:false,
                cols: props.box.cols,
                rows: props.box.rows,
                main_news: props.box.main_news_id !== null ? props.box.main_news_id : 0,
            })

            const editBox = () => {
                boxForm.post(route('admin.box.edit', props.box.id));
            }
            return {
                boxForm,
                editBox,

                getDate
            }
        }
    }
</script>
