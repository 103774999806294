<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <h1
                class="text-xl font-thin  mb-3 dark:text-white"
            >
                Edytuj baner reklamowy
            </h1>
            <form>
                <div class="mt-2">
                    <InputLabel
                    placeholder="Tytuł"
                    id="name"
                    name="name"
                    type="text"
                    v-model="addForm.name"
                />
                <!-- <div v-if="errors && errors.name" class="text-red-500 mt-2">{{errors.name}}</div> -->
                </div>

                <div class="mt-2">
                    <InputLabel
                    placeholder="Url"
                    id="url"
                    name="url"
                    type="text"
                    v-model="addForm.url"
                />
                <div v-if="errors && errors.url" class="text-red-500 mt-2">{{errors.url}}</div>
                </div>

                <div class="mt-2">
                    <MultiListbox
                    :data="locations"
                    v-model="addForm.locations"
                    :selected="selectedLocations"
                    type="array"
                    text="Wybrane Lokalizacja"
                />
                <!-- {{ locations }} -->
                </div>


                <!-- <div class="mt-2">
                    <MultiListbox
                    :data="categories"
                    v-model="addForm.categories"
                    :selected="selectedCategories"
                    type="array"
                    text="Wybrane kategorie"
                />
                <div v-if="errors && errors.categories" class="text-red-500 mt-2">{{errors.categories}}</div>
                </div>

                <div class="mt-2">
                    <Listbox
                    :data="age_categories"
                    v-model="addForm.category"
                    :selected="add.category"
                    type="objectkey"
                />
                <div v-if="errors && errors.category" class="text-red-500 mt-2">{{errors.category}}</div>
                </div>

                <div class="mt-2">
                    <Listbox
                    :data="positions"
                    type="objectkey"
                    v-model="addForm.position"
                    :selected="add.position"
                />
                <div v-if="errors && errors.position" class="text-red-500 mt-2">{{errors.position}}</div>
                </div> -->

                <div class="mt-2">
                    <ImageUpload
                    @imagesUploaded="changeImage"
                    :autosend="true"
                    v-if="add.images"
                    :image="image ? image.encoded : null"
                />

                </div>
                <div v-if="errors && errors.image" class="text-red-500 mt-2">{{errors.image}}</div>


            </form>


            <div
                class="flex mt-4 justify-end mb-10"
            >
                <Link @click="editAdd()" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="check" width="18" height="18" />
                    <span class="ml-2">Zapisz</span>
                </Link>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
    import ImageUpload from '@/Components/ImageUpload'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox,
            ImageUpload
        },
        props: {
            age_categories: Array,
            locations: Object,
            add: Object,
            image: String,
            errors: String,
        },
        setup(props) {
            const selectedCategories = ref([]);
            const selectedLocations = ref([]);

            const addForm = useForm({
                name: props.add.name,
                // position: props.add.position,
                // category: props.add.category,
                // categories: props.add.categories,
                locations: props.add.locations,
                url: props.add.url,
                image: props.add.images.length ? props.add.images[0].name : null,
            });

            const editAdd = (e) => {
                // addForm.image = e;
                addForm.post(route('admin.post.adds.edit', props.add.id))
            }

            const changeImage = (e) => {
                addForm.image = e
            }

            onMounted(() => {
                props.add.locations.forEach((el) => {
                    selectedLocations.value.push(el.id)
                })

            })

            return {
                addForm,
                selectedCategories,
                changeImage,
                editAdd,
                selectedLocations,
            }
        }
    }
</script>

<style>
.tox-tinymce {
    border: 0 !important;
    border-bottom: 2px solid #9ca3af !important;
}
</style>
