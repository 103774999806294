<template>
    <div
        class="flex flex-col relative"
    >
        <label
            v-if="label"
            :for="id"
            class="mb-2 dark:text-white"
        >{{label}}</label>
        <Listbox v-model="selectedValue">
            <div class="relative">
            <ListboxButton
                class="w-full group text-left rounded-none border-0 border-b-2 px-4 py-2 outline-none focus:outline-none focus:ring-0"
                :class="[
                {'bg-white dark:bg-gray-300 dark:border-gray-300 border-gray-400 dark:hover:border-gray-200 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600':colors=='normal'},
                {'text-white border-white':colors=='transparent'}
                ]

                "
                >
                <span
                class="block truncate dark:text-gray-900"
                :class="[{'text-white dark:text-gray-900':colors=='transparent'}]"
                >{{ currentName }}</span>
                <span
                class="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center pr-2 pointer-events-none"
                >
                <SvgIcon
                    name="selector"
                    class=""
                    :class="[
                    {'text-gray-400 group-hover:text-gray-600':colors=='normal'},
                    {'text-white':colors=='transparent'}
                    ]"
                    :size="20"
                />
                </span>

            </ListboxButton>

            <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-gray-300 shadow-lg max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                >
                <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="(item) in data"
                    :key="item.id"
                    :value="item.id"
                    @click="selectThisOne(item)"
                    as="template"
                >
                    <li
                        :class="[
                            active ? 'text-red-900 bg-red-100' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-10 pr-4',
                            selected || item[$props.index ? $props.index : 'id'] == $props.modelValue ? 'bg-green-200 text-gray-900 hover:bg-green-100 hover:text-gray-800':''
                        ]"
                    >
                    <span
                        :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                        ]"
                        >{{ item.name }}</span
                    >
                    <span
                        v-if="selected"
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                    >

                    </span>
                    </li>
                </ListboxOption>
                </ListboxOptions>
            </transition>
            </div>
        </Listbox>
    </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import SvgIcon from '@/Components/SvgIcon'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        SvgIcon
    },

    // props: ['data', 'modelValue', 'type'],
    props: {
        data: {
            type: Object,
            default: null
        },
        modelValue: [String, Number],
        type: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        colors: {
            type: String,
            default: 'normal'
        },
        selected: {
            type: [String, Number],
            default: null
        },
        index: {
            type: String,
            default: null,
        }
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {

        const currentName = computed( () => {
            if(props.modelValue) {
                let find = props.data.find(e => e[props.index ? props.index : 'id'] == props.modelValue);
                return find ? find.name : 'Brak';
            }
            else return 'Brak';
        });
        const selectedValue = ref(props.modelValue);

        function selectThisOne(item){
            let value = item[props.index ? props.index : 'id'];
            selectedValue.value = value;
            emit('update:modelValue', value)
        }
        return {
            selectedValue,
            currentName,

            selectThisOne,
        }
    },
}
</script>
