<template>

    <inertia-head>
        <title>Archiwum - {{ $page.props.app_name }}</title>
    </inertia-head>

    <MainLayout>
        <div
            class="h-36 lg:h-52 bg-cover relative"
            style="background-image: url(/img/cms/bg1.webp)"
        >
            <div class="absolute top-0 left-0 h-full w-full bg-black bg-opacity-20"></div>
            <div class="relative page-title md:w-10/12 mx-auto h-full w-full flex items-center">
                <h1 class="text-white uppercase text-2xl lg:text-5xl font-bold px-5 lg:px-0">Archiwum</h1>
            </div>
        </div>
        <div class="sm:p-5 p-3 xl:w-10/12 m-auto">
        <div
            class=""
            v-for="(s, skey) in $props.seasons"
            :key="skey"
        >
                <div
                    class="mb-4 bg-white dark:bg-gray-900 shadow-md transform transition duration-300 overflow-hidden"
                >
                    <div
                        @click="ev => { if(showLeagues.includes(s.id)) showLeagues.splice(showLeagues.indexOf(s.id), 1); else showLeagues.push(s.id) }"
                        :class="[
                            showLeagues.includes(s.id) ? 'bg-green-600 hover:bg-green-500 bg-opacity-80 hover:bg-opacity-60' : 'hover:bg-green-600 bg-green-700'
                        ]"
                        class="lg:text-xl md:text-xl py-1 text-lg block text-white shadow-md px-4 cursor-pointer"
                    >
                        {{ s.type ? s.year + "/" + (s.year + 1) : (s.year) }} <span class="text-sm mr-2" v-if="s.is_current">(Bieżący)</span><small class="text-sm">{{ s.type ? 'jesień-wiosna' : 'wiosna-jesień' }}</small>
                    </div>
                    <transition
                        enter-active-class="ease-out duration-200 origin-top"
                        enter-from-class="transform opacity-0 scale-y-0"
                        enter-to-class="transform opacity-100 scale-y-100"
                        leave-active-class="ease-in duration-200 origin-top absolute"
                        leave-from-class="transform opacity-100 scale-y-100"
                        leave-to-class="transform opacity-0 scale-y-0"
                        mode="out-in"
                    >
                        <div v-if="showLeagues.includes(s.id)" class="transition duration-300">
                            <div
                                v-for="l in s.leagues"
                                :key="l.id"
                                class="px-4 py-1.5 grid grid-cols-1 md:grid-cols-3 dark:text-white odd:bg-gray-100 dark:odd:bg-gray-800 dark:odd:bg-opacity-30"
                            >
                                <inertia-link
                                    class="hover:text-green-600 hover:underline lg:text-md md:text-md text-sm"
                                    :href="route('league', { league: l.slug, season: s.year})"
                                >
                                    {{ l.name }}
                                </inertia-link>
                                <div class="col-span-1 text-center text-sm text-gray-400 dark:text-gray-300 md:text-normal md:text-gray-700 md:text-right mb-4 mt-1 md:mb-0">Liczba kolejek: <b>{{ l.queues_count }}</b></div>
                                <div class="col-span-1 text-center text-sm text-gray-400 dark:text-gray-300 md:text-normal md:text-gray-700 md:text-right mb-4 mt-1 md:mb-0">
                                    <span v-if="$props.categories.find(e => e.slug == l.category)">
                                        {{ $props.categories.find(e => e.slug == l.category).name }}
                                    </span>
                                    <span v-else>Brak</span>
                                </div>
                            </div>
                            <div
                                class="px-4 py-1.5 grid grid-cols-1 text-gray-600 md:grid-cols-3 odd:bg-gray-100 dark:odd:bg-gray-800 dark:odd:bg-opacity-30"
                                v-if="!s.leagues.length"
                            >Dany sezon nie posiada przypisanych lig</div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </MainLayout>
</template>


<script>
    import MainLayout from '@/Layouts/Main'
    import Listbox from '@/Components/Listbox'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import Roll from '@/Components/rollUp'
import { ref } from '@vue/reactivity'

    export default {
        components: {
            MainLayout,
            Link,
            SvgIcon,
            Roll,
            Listbox,
            InputLabel,
        },
        props: {
            seasons: [Array, Object],
            categories: [Array, Object],
        },
        setup(props) {
            const showLeagues = ref([props.seasons.find(e => e.is_current).id]);
            return {
                showLeagues,
            }
        },

    }
</script>

<style scoped>
.active-tab{
    position: relative;
}

.active-tab:hover::after{
    background: #059669;
}
</style>
