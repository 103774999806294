<template>
    <AdminLayout>
        <div class="pb-12 px-3 pt-6 sm:px-6 lg:px-8 ">
            <Link :href="route('admin.dashboard.users')" class="mr-2 mb-6">Wróć</Link>
            <h1
                class="text-xl font-thin mt-2 mb-3 dark:text-white"
            >
                Statystyki użytkownika <span class="font-semibold underline" v-html="user.name"></span>
            </h1>
            <div class="dark:text-white">Ostatnio zalogowany: {{ getDate(user.last_login).format('LLLL') }}</div>


            <div
            class="my-4 grid grid-cols-2 lg:grid-cols-5 gap-4"
            >

                <div
                class="bg-green-500 hover:bg-green-600 dark:bg-gray-900  shadow-lg rounded-md flex items-center justify-end p-3 border-b-4 border-green-600 hover:border-green-700 dark:border-gray-600 text-white font-medium group"
                >
                    <div class="text-right">
                        <p class="text-2xl">{{ visited }}</p>
                        <p>Wyświetlenia newsów</p>
                    </div>
                </div>

                <div
                class="bg-green-500 hover:bg-green-600 dark:bg-gray-900  shadow-lg rounded-md flex items-center justify-end p-3 border-b-4 border-green-600 hover:border-green-700 dark:border-gray-600 text-white font-medium group"
                v-for="s in stats"
                :key="s"
                >
                    <div class="text-right" v-if="s">
                    <p class="text-2xl">{{s.value}}</p>
                    <p>{{s.name}}</p>
                    </div>
                </div>
            </div>

            <div class="mt-2">
                <div class="relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words w-fullrounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                    <div class="flex flex-wrap items-center py-2">
                        <div class="relative w-full max-w-full flex justify-center items-center">
                            <!-- <inertia-link
                                preserve-scroll
                                :href="route('admin.dashboard.user.stats', user.id)"
                                :data="{
                                    month_from: ( momentDateFrom.month() == 0 ? 12 : momentDateFrom.month() ),
                                    year_from: ( momentDateFrom.month() == 0 ? (momentDateFrom.year() - 1) : momentDateFrom.year() ),
                                    month_to: route().params.month_to,
                                    year_to: route().params.year_to,
                                }"
                                class="bg-green-600 hover:bg-green-500 rounded-full flex items-center justify-center w-8 h-8"
                            >
                            <SvgIcon name="arrow-left" />
                            </inertia-link> -->
                            <h3 class="uppercase text-sm flex lg:flex-row flex-col items-center justify-center gap-3 leading-normal transition ease-in-out duration-150">
                                <div class="flex gap-3 md:flex-row flex-col items-center">
                                    <InputLabel
                                        input-class="text-black dark:text-white"
                                        placeholder="Od"
                                        class="lg:w-52 md:w-44"
                                        :transparant="true"
                                        type="month"
                                        v-model="curMonth.from"
                                    />
                                    <SvgIcon name="arrow-long-right" class=" transform md:rotate-0 rotate-90" />
                                    <InputLabel
                                        input-class="text-black dark:text-white"
                                        placeholder="Do"
                                        :transparant="true"
                                        type="month"
                                        class="lg:w-52 md:w-44"
                                        v-model="curMonth.to"
                                    />
                                </div>
                                <Link
                                    @click="getData()"
                                    class="bg-green-600 hover:bg-green-800"
                                >Wyszukaj</Link>
                            </h3>
                            <!-- <inertia-link
                                preserve-scroll
                                :href="route('admin.dashboard.user.stats', user.id)"
                                :data="{
                                    month_from: route().params.month_from,
                                    year_from: route().params.year_from,
                                    month_to: ( momentDateTo.month() == 11 ? 1 : (momentDateTo.month() + 2) ),
                                    year_to: ( momentDateTo.month() == 11 ? (momentDateTo.year() + 1) : momentDateTo.year() )
                                }"
                                class="bg-green-600 hover:bg-green-500 rounded-full flex items-center justify-center w-8 h-8"
                            >
                            <SvgIcon name="arrow-right" />
                            </inertia-link> -->
                        </div>
                    </div>
                    <!-- <h2 class="mb-3 dark:text-white">Newsy użytkownika:</h2> -->
                    <div class="block w-full overflow-x-auto bg-white shadow-lg rounded">
                        <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr class="bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100">
                            <th
                            class="px-4 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            >News</th>
                            <th
                            class="px-4 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            >Data</th>
                            <th
                            class="px-4 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
                            >Odwiedziny</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="view in $props.news.data"
                            :key="view"
                            class="border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-800 dark:text-white"
                            >
                                <th class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <inertia-link :href="route('news.id', view.slug)" preserve-scroll>
                                        {{view.title}}
                                    </inertia-link>
                                </th>
                                <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <span class="bg-gray-600 px-4 py-1 rounded-full text-white font-bold">{{getDate(view.created_at).format('DD.MM.YYYY HH:mm')}}</span>
                                </td>
                                <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <div class="flex items-center">
                                    <span class="mr-2">{{view.visited}}</span>
                                        <Progress
                                            :percent="getPercent(view.visited)"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <Pagination
                        class="mt-4 flex justify-center"
                        :links="$props.news.links" />
                    </div>
                </div>
            </div>

        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import Listbox from '@/Components/Listbox'
    import SvgIcon from '@/Components/SvgIcon'
    import Progress from '@/Components/Progress'
    import { computed, ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted, watch } from 'vue'
    import Editor from '@tinymce/tinymce-vue'
    import Link from '@/Components/Link'
    import MultiListbox from '@/Components/MultiListbox'
    import moment from 'moment';
import { Inertia } from '@inertiajs/inertia'

import Pagination from '@/Components/Pagination'


    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Listbox,
            Editor,
            Link,
            MultiListbox,
            Progress,
            Pagination
        },
        props: {
            user: Object,
            stats: [Object, Array],
            news: [Array, Object],
            visited: [Array, Object],
        },
        setup(props) {
            moment.locale('pl')
            const sorted_views = ref([]);
            const max_views = ref(0);
            const months = ref(route().params.month ? parseInt(route().params.month) : 0);
            // const curMonth = ref(moment().add(months.value, 'months').format('MMMM, YYYY'));

            const momentDateFrom = computed(() => moment({
                year: route().params.year_from ?  Number(route().params.year_from) : new Date().getFullYear(),
                month: route().params.month_from && route().params.month_from <= 12 ? Number(route().params.month_from - 1) : new Date().getMonth()
            }));
            const momentDateTo = computed(() => moment({
                year: route().params.year_to ?  Number(route().params.year_to) : momentDateFrom.value.year(),
                month: route().params.month_to && route().params.month_to <= 12 ? Number(route().params.month_to - 1) : momentDateFrom.value.month()
            }));
            const curMonth = ref({
                from: momentDateFrom.value.format('YYYY-MM'),
                to: momentDateTo.value.format('YYYY-MM'),
            });

            function getData(val){
                let dateFrom = moment({
                    year: Number(curMonth.value.from.split('-')[0]),
                    month: Number(curMonth.value.from.split('-')[1]) - 1,
                })

                let dateTo = moment({
                    year: Number(curMonth.value.to.split('-')[0]),
                    month: Number(curMonth.value.to.split('-')[1]) - 1,
                })

                Inertia.get(route('admin.dashboard.user.stats', props.user.id), {
                    year_from: dateFrom.year(),
                    month_from: dateFrom.month() + 1,
                    year_to: dateTo.year(),
                    month_to: dateTo.month() + 1,
                }, {
                    preserveScroll: true,
                })
            };

            const views_settings = ref({
                current_page: 1,
                per_page: 10,
                pages: 1
            });


            // const viewSum = () => {
            //     // var news = props.news.data, 
            //     //     num = 0;
            //     var nums  = props.visited;

            //     // console.log(news)

            //    var total = nums.reduce(function(a,b) {
            //         return (+a)+(+b);
            //     });

            //     return total;
            // }

            // props.user.news

            onMounted(() => {
                props.news.data.forEach((el) => {
                    if (max_views.value < el.visited) max_views.value = el.visited;
                })
            })

            const getPercent = (value) => {
                if (value > 0) {
                    return Math.round((value * 100) / max_views.value)
                }
                else {
                    return 0
                }
            }

            function getDate(value){
                moment.locale('pl');
                return moment(value);
            };
            return {
                sorted_views,
                getPercent,
                max_views,
                views_settings,
                months,
                // stats,
                getData,
                curMonth,
                getDate,
                momentDateFrom,
                momentDateTo,
            }
        }
    }
</script>
<style>
.dark input::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
</style>
