<template>

    <inertia-head>
        <title>{{ club.name }} - {{ $page.props.app_name }}</title>
    </inertia-head>


    <MainLayout>
        <!-- {{ stats }} -->
        <div class="grid grid-cols-12 xl:w-10/12 mx-auto sm:p-5 p-3 dark:text-white">
                <div class="my-5 col-span-12 flex items-center">
                    <img
                    v-lazy="{
                        src: club.logo ? '/storage/clubs/' + club.logo : '/img/cms/bulbasaur.png',
                        loading: '/img/cms/loading.gif',
                        error: '/img/cms/img-error.png'
                    }"
                    alt="logo-klubu" class="w-20"
                >
                    <h1 class="ml-5 text-xl font-bold dark:text-white space-x-4">
                        <inertia-link
                            :href="route('singleclub', club.id)"
                            class="hover:text-green-500 dark:hover:text-green-500"
                        ><span>{{ club.name }}</span></inertia-link>
                        <template v-if="route().params.team">
                            <span class="border-l-2 pl-4">{{ club.teams.find(e => Number(e.id) == Number(route().params.team)).name }}</span>
                            <span
                                v-if="club.teams.find(e => Number(e.id) == Number(route().params.team)).name != club.name"
                                class="border-l-2 pl-4 text-green-500"
                            >{{club.teams.find(e => Number(e.id) == Number(route().params.team)).category_name}}</span>
                        </template>
                    </h1>
                    <div class="ml-auto flex gap-3 text-xl text-green-600 font-bold dark:text-white">
                        <inertia-link
                            :href="route('singleclub', {...route().params, club: club.id, season: Number($props.season.year) - 1})"
                            class="rounded flex items-center justify-center hover:bg-green-700 bg-green-600 text-white"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        </inertia-link>
                        <span>{{ $props.season.year }}</span>
                        <inertia-link
                            :href="route('singleclub', {...route().params, club: club.id, season: Number($props.season.year) + 1})"
                            class="rounded flex items-center justify-center hover:bg-green-700 bg-green-600 text-white"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                        </inertia-link>
                    </div>
                </div>
                <div class="shadow-md col-span-12 my-5  dark:text-white">
                    <div class="grid grid-cols-3 bg-gray-100 dark:bg-gray-800">
                        <div class="flex items-center justify-center text-sm lg:text-normal col-span-1 text-center py-5 hover:bg-green-600 hover:text-white cursor-pointer font-bold" @click.prevent="setActiveQ('prev')" :class="{ 'bg-white shadow-md active-tab dark:bg-gray-900': isActiveQ('prev') }">
                            <p>⚽ Rozgrywki</p>
                        </div>
                        <div class="flex items-center justify-center text-sm lg:text-normal col-span-1  text-center py-5 hover:bg-green-600 hover:text-white cursor-pointer font-bold" @click.prevent="setActiveQ('cur')" :class="{ 'bg-white shadow-md active-tab dark:bg-gray-900': isActiveQ('cur') }">
                            <p>📆 Terminarz</p>
                        </div>
                        <div class="flex items-center justify-center text-sm lg:text-normal col-span-1 text-center py-5 hover:bg-green-600 hover:text-white cursor-pointer font-bold" @click.prevent="setActiveQ('next')" :class="{ 'bg-white shadow-md active-tab dark:bg-gray-900': isActiveQ('next') }">
                            <p>📈 Statystyki</p>
                        </div>
                    </div>

                    <div class="tab-pane bg-white px-4 py-4 dark:bg-gray-900" :class="{ 'show': isActiveQ('prev') }" id="prev" v-if="isActiveQ('prev')">
                        <Games
                        :leagues="club.leagues"
                        />
                    </div>

                    <div class="tab-pane px-4 bg-white py-4 dark:bg-gray-900" :class="{ 'show': isActiveQ('cur') }" id="cur" v-if="isActiveQ('cur')">
                        <Terminarz
                        :scores="scores"
                        />
                    </div>

                    <div class="tab-pane px-4 py-4 bg-white dark:bg-gray-900" :class="{ 'show': isActiveQ('next') }" id="next" v-if="isActiveQ('next')">
                        <div class="grid grid-cols-2 gap-5" v-if="stats.host_win != null">
                            <div class="col-span-2 lg:col-span-1">
                                <div class="stat h-full border-b-2 border-green-600 p-3">
                                    <span class='font-semibold'>Najwyższa wygrana u siebie</span>
                                    <inertia-link
                                        v-if="stats.host_win"
                                        :href="route('league', { league: stats.host_win.league.slug, season: route().params.season })"
                                        class="flex items-center hover:text-green-700 my-2"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                        </svg>
                                        <span>
                                            {{stats.host_win.league.season.type ? stats.host_win.league.season.year + '/' + (stats.host_win.league.season.year + 1) : (stats.host_win.league.season.year - 1) + '/' + stats.host_win.league.season.year}} - {{stats.host_win.league.name}}
                                        </span>
                                    </inertia-link>
                                    <span
                                        v-if="stats.host_win"
                                        class='font-semibold'>{{stats.host_win.host ? stats.host_win.host.club_name : '---'}} - {{stats.host_win.guest ? stats.host_win.guest.club_name : '---'}} {{stats.host_win.host_goals}}:{{stats.host_win.guest_goals}}</span>
                                    <div class="text-gray-500 dark:text-gray-300" v-else>Brak</div>
                                </div>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <div class="stat h-full border-b-2 border-green-600 p-3">
                                    <span class='font-semibold'>Najwyższa wygrana na wyjeździe</span>
                                    <inertia-link
                                        v-if="stats.guest_win"
                                        :href="route('league', { league: stats.guest_win.league.slug, season: route().params.season })"
                                        class="flex items-center hover:text-green-700 my-2"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                        </svg>
                                        <span>
                                            {{stats.guest_win.league.season.type ? stats.guest_win.league.season.year + '/' + (stats.guest_win.league.season.year + 1) : (stats.guest_win.league.season.year - 1) + '/' + stats.guest_win.league.season.year}} - {{stats.guest_win.league.name}}
                                        </span>
                                    </inertia-link>
                                    <span v-if="stats.guest_win" class='font-semibold'>
                                        {{stats.guest_win.host ? stats.guest_win.host.club_name : '---'}} - {{stats.guest_win.guest ? stats.guest_win.guest.club_name : '---'}} {{stats.guest_win.host_goals}}:{{stats.guest_win.guest_goals}}
                                    </span>
                                    <div class="text-gray-500 dark:text-gray-300" v-else>Brak</div>
                                </div>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <div class="stat h-full border-b-2 border-green-600 p-3">
                                    <span class='font-semibold'>Najwyższa porażka u siebie</span>
                                    <inertia-link v-if="stats.host_lost" :href="route('league', { league: stats.host_lost.league.slug, season: route().params.season })" class="flex items-center hover:text-green-700 my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                        </svg>
                                        <span>
                                            {{stats.host_lost.league.season.type ? stats.host_lost.league.season.year + '/' + (stats.host_lost.league.season.year + 1) : (stats.host_lost.league.season.year - 1) + '/' + stats.host_lost.league.season.year}} - {{stats.host_lost.league.name}}
                                        </span>
                                    </inertia-link>
                                    <span v-if="stats.host_lost" class='font-semibold'>{{stats.host_lost.host ? stats.host_lost.host.club_name : '---'}} - {{stats.host_lost.guest ? stats.host_lost.guest.club_name : '---'}} {{stats.host_lost.host_goals}}:{{stats.host_lost.guest_goals}}</span>
                                    <div class="text-gray-500 dark:text-gray-300" v-else>Brak</div>
                                </div>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <div class="stat h-full  border-b-2 border-green-600 p-3">
                                    <span class='font-semibold'>Najwyższa porażka na wyjeździe</span>
                                    <inertia-link v-if="stats.guest_lost" :href="route('league', { league: stats.guest_lost.league.slug, season: route().params.season })" class="flex items-center hover:text-green-700 my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                        </svg>
                                        <span>
                                            {{stats.guest_lost.league.season.type ? stats.guest_lost.league.season.year + '/' + (stats.guest_lost.league.season.year + 1) : (stats.guest_lost.league.season.year - 1) + '/' + stats.guest_lost.league.season.year}} - {{stats.guest_lost.league.name}}
                                        </span>
                                    </inertia-link>
                                    <span v-if="stats.guest_lost" class='font-semibold'>{{stats.guest_lost.host ? stats.guest_lost.host.club_name : '---'}} - {{stats.guest_lost.guest ? stats.guest_lost.guest.club_name : '---'}} {{stats.guest_lost.host_goals}}:{{stats.guest_lost.guest_goals}}</span>
                                    <div class="text-gray-500 dark:text-gray-300" v-else>Brak</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-span-12 space-y-4">
                    <Roll
                        v-if="club.teams && club.teams.length && !route().params.team"
                        class="w-full"
                        :secondary="true"
                        custom-class="py-3 px-4 h-16 mb-3 hover:bg-gray-100 dark:hover:bg-gray-900 dark:hover:bg-opacity-50"
                        :title="'Drużyny klubu'"
                    >
                        <div class="col-span-full grid lg:grid-cols-4 sm:grid-cols-2 gap-3">
                            <div
                                class="flex gap-2 bg-gray-100 dark:bg-gray-900 dark:hover:bg-opacity-50 shadow-md hover:bg-opacity-50 justify-center items-center"
                                v-for="team in club.teams"
                                :key="team.id"
                            >
                                <inertia-link
                                    :href="route('singleclub', {
                                        club: club.id,
                                        team: team.id,
                                        season: route().params.season
                                    })"
                                    class="flex p-3 w-full gap-3 flex-col items-center hover:text-green-700 font-semibold"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                    </svg>
                                    <span class="text-lg">
                                        <span>{{ team.name != team.club_name ? team.name : team.club_name }}</span>
                                    </span>
                                    <span class="text-xs text-gray-500">({{ team.category_name }})</span>
                                </inertia-link>
                            </div>
                        </div>
                    </Roll>
                    <Roll
                        v-if="$props.news && $props.news.data.length"
                        class="w-full"
                        :secondary="true"
                        custom-class="py-3 px-4 h-16 mb-3 hover:bg-gray-100 dark:hover:bg-gray-900 dark:hover:bg-opacity-50"
                        :title="'Wiadomości klubowe'"
                    >
                        <NewsList
                            class="col-span-full"
                            :posts="$props.news.data"
                        />

                        <div class="flex justify-center col-span-full">
                            <pagination class="mt-6" :links="news.links" />
                        </div>
                    </Roll>
                </div>
        </div>
    </MainLayout>
</template>


<script>
    import {ref} from 'vue'
    import Games from '@/Components/Club/Games'
    import Terminarz from '@/Components/Club/Terminarz'
    import Regio from '@/Components/Results/RegioResults'
    import LeaguesMini from '@/Components/LeaguesMini'
    import MainLayout from '@/Layouts/Main'
    import CardImage from '@/Components/CardImage'
    import MiniNews from '@/Components/miniNews'
    import NewsList from '@/Components/newsList'
    import CardImageLess from '@/Components/cardImageLess'
    import AddBar from '@/Components/Adds/addBar'
    import Posts from '@/Components/Post/gridOnMain'
    import Roll from '@/Components/rollUp'
    import Pagination from '@/Components/Pagination'

    export default {
        components: {
            MainLayout,
            CardImage,
            LeaguesMini,
            MiniNews,
            CardImageLess,
            AddBar,
            NewsList,
            Posts,
            Roll,
            Regio,
            Games,
            Terminarz,
            Pagination,
        },
        props: {
            add: [Object, Array],
            club: [Object, Array],
            scores: [Object, Array],
            test: [Object, Array],
            stats: [Object, Array],
            adsLogo: [Object, Array],
            news: [Object, Array],
            season: [Object, Array],
        },
        data(){
            return{
                moreWiadomosci: false,
                moreOgloszenia: false
            }
        },
        setup(props) {
            const activeQ = ref("prev");

            const isActiveQ = (tabItem) => {
                return activeQ.value === tabItem
            }
            const setActiveQ = (tabitem) => {
                console.log(tabitem)
                activeQ.value = tabitem
            }



            return {
                isActiveQ,
                setActiveQ,
                activeQ
            }
        }
    }
</script>
