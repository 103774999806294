<template>
    <inertia-head>
        <title>Administracja - {{ $page.props.app_name }}</title>
        <meta name="description" :content="`Futmal.pl - ${ $page.props.app_name }`">
    </inertia-head>
    <div>

        <Notifications />
        <div class="h-screen bg-gray-200 dark:bg-gray-800 flex">
            <transition
            enter-active-class="transition ease-out duration-200 origin-left"
            enter-from-class="transform -translate-x-full"
            enter-to-class="transform translate-x-0"
            leave-active-class="transition ease-in duration-75 origin-left"
            leave-from-class="transform translate-x-0"
            leave-to-class="transform -translate-x-full"
            >
            <!-- Left Menu -->
            <div
                class="border-r border-gray-700 w-full sm:w-56 px-6 bg-gray-800 text-white relative h-screen overflow-y-auto overscroll-x-none flex flex-col"
                v-if="menuVisible"
            >
                <div class="flex justify-center my-4">
                    <inertia-link :href="route('home')">
                        <BreezeApplicationLogo
                            class="h-16 flex w-auto"
                            :dark="true"
                        />
                    </inertia-link>
                </div>
                <div class="mt-5">
                    <!-- <div
                        class="inline-flex items-center justify-between w-full text-base group font-semibold transition duration-150 cursor-pointer"
                    >
                        <inertia-link
                            class="inline-flex items-center text-sm font-semibold group-hover:text-green-400"
                            :href="route('admin.dashboard.user.stats', $page.props.auth.user.id)"
                        >
                            <SvgIcon
                                name="chart-bar"
                            />
                            <span class="ml-4">Statystyki</span>
                        </inertia-link>
                    </div> -->
                    <template
                         v-for="item in menuItems"
                        :key="item"
                    >
                    <div
                        v-if="item.can_open"
                        class="select-none mt-3"
                    >
                        <div
                            class="inline-flex items-center justify-between w-full text-base group font-semibold transition duration-150 cursor-pointer"
                            @click="item.active = !item.active"
                            :class="item.active ? 'text-green-500':'text-white'"
                        >
                            <inertia-link
                                class="inline-flex items-center text-sm font-semibold group-hover:text-green-400"
                                v-if="item.hasItems === false"
                                :href="route(item.route)"
                            >
                                <SvgIcon
                                    :name="item.icon"
                                />
                                <span class="ml-4" v-html="item.name"></span>
                            </inertia-link>
                            <div v-else class="inline-flex items-center justify-between w-full">
                                <span
                                    class="inline-flex items-center text-sm font-semibold group-hover:text-green-400"
                                >
                                    <SvgIcon
                                        :name="item.icon"
                                    />
                                    <span class="ml-4" v-html="item.name"></span>
                                </span>
                                <SvgIcon
                                name="chevron-right"
                                width="24"
                                height="24"
                                class="ml-1 w-4 h-4 group-hover:text-green-400 transform transition duration-200"
                                :class="item.active ? 'rotate-90':''"
                                />
                            </div>

                        </div>


                        <div >
                            <transition
                                enter-active-class="transition ease-out duration-200"
                                enter-from-class="transform opacity-100 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                            <ul
                            v-if="item.active"
                                class="my-2 overflow-hidden text-sm font-medium rounded-md shadow-inner bg-gray-500 transition"
                            >
                                <template v-for="subitem in item.items" :key="subitem.name">
                                    <InertiaLink
                                        :href="subitem.route ? route(subitem.route) : subitem.url"
                                        @click.prevent="linkClick"
                                        class="block px-3 py-2 text-white transition-colors duration-150 hover:bg-green-600 cursor-pointer"
                                        v-if="subitem.can_open"
                                        :class="[
                                            subitem.route ? (route().current(subitem.route) || route().current(subitem.route + '.*') ? 'bg-green-500':'') : '',
                                            subitem.active ? 'bg-green-500' : ''
                                        ]"
                                    >
                                        <div class="flex items-center">
                                            <SvgIcon
                                                :name="subitem.icon"
                                            />
                                            <div v-html="subitem.name" class="w-full ml-2 text-sm font-semibold"></div>
                                        </div>
                                    </InertiaLink>
                                </template>
                            </ul>
                            </transition>
                        </div>
                    </div>
                    </template>
                </div>

                <div class="mt-auto">
                    <SvgIcon
                        name="arrow-left"
                        class="sticky bg-green-600 mb-4 mt-2 left-1/2 transform -translate-x-1/2 rounded-full cursor-pointer"
                        width="32"
                        height="32"
                        @click="menuVisible = !menuVisible"
                    />
                </div>
            </div>
            </transition>

            <!-- Right Content -->
            <div class="flex-1 flex flex-col overflow-hidden">
                <!-- Navbar -->
                <nav
                    class="flex items-center justify-between bg-white dark:bg-gray-700 py-3 px-6 shadow-sm"
                >
                    <div @click="changeMode()" class=" p-2 text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-700 rounded-full cursor-pointer shadow-md">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                        </svg>
                    </div>
                    <transition
                            enter-active-class="transition ease-out delay-300 duration-200"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                        <div v-if="!menuVisible" class="absolute bottom-3 left-6 z-50">
                            <SvgIcon
                                name="arrow-right"
                                class=" bg-green-600  transform -translate-x-1/2 rounded-full text-white cursor-pointer"
                                width="32"
                                height="32"
                                @click="menuVisible = !menuVisible"
                            />
                        </div>
                    </transition>
                    <Dropdown
                        class="select-none"
                    >
                        <template #trigger>
                            <span class="inline-flex rounded-md">
                                <button type="button" class="flex justify-between items-center text-white bg-gray-600 dark:bg-gray-800 hover:bg-gray-700 pl-4 p-1.5 border border-transparent text-sm leading-4 font-medium rounded-md focus:outline-none transition ease-in-out duration-150">
                                    <span class="mr-2">{{$page.props.auth.user.name}}</span>
                                    <SvgIcon
                                    name="chevron-down"
                                    width="16"
                                    height="16"
                                    />
                                </button>
                            </span>
                        </template>
                        <template #content>
                            <DropdownLink
                                class="flex font-bold items-center"
                                method="get"
                                as="a"
                                :href="route('admin.dashboard.user.stats', $page.props.auth.user.id)"
                            >
                            Statystyki
                            </DropdownLink>
                            <DropdownLink
                                class="flex font-bold items-center"
                                method="get"
                                as="a"
                                :href="route('admin.dashboard.users.edit', $page.props.auth.user.id)"
                            >
                            Ustawienia
                            </DropdownLink>

                            <DropdownLink
                                class="flex font-bold items-center"
                                method="post"
                                as="button"
                                :href="route('logout')"
                            >
                            Wyloguj
                            </DropdownLink>

                        </template>
                    </Dropdown>
                </nav>
                <!-- Page Content -->
                <transition
                enter-active-class="transition ease-in duration-100 origin-top"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-out duration-100 origin-top"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <main
                    class="w-full flex-1 overflow-x-hidden overflow-y-auto "
                    v-if="animate"
                >
                    <slot />
                </main>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from '@/Components/Navbar'
    import Notifications from '@/Components/Notifications'
    import BreezeApplicationLogo from '@/Components/ApplicationLogo'
    import Dropdown from '@/Components/Dropdown'
    import DropdownLink from '@/Components/DropdownLink'
    import BreezeNavLink from '@/Components/NavLink'
    import BreezeResponsiveNavLink from '@/Components/ResponsiveNavLink'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref, onMounted, provide } from 'vue';
    import { usePage } from '@inertiajs/inertia-vue3';

    export default {
        components: {
            BreezeApplicationLogo,
            Dropdown,
            DropdownLink,
            BreezeNavLink,
            BreezeResponsiveNavLink,
            Navbar,
            SvgIcon,
            Notifications
        },
        setup(props) {
            const menuVisible = ref(true);
            const animate = ref(false);
            const menuItems = ref([
                {
                    name: 'Pulpit',
                    route: 'admin.dashboard',
                    icon: 'chart-bar',
                    active: route().current('admin.dashboard.*'),
                    can_open: true,
                    items: [
                        {
                            name: 'Moje statystyki',
                            active: route().current('admin.dashboard.user.stats', usePage().props.value.auth.user.id),
                            url: route('admin.dashboard.user.stats', usePage().props.value.auth.user.id),
                            icon: 'chart-bar',
                            can_open: true,
                        },
                        {
                            name: 'Podsumowanie',
                            route: 'admin.dashboard.main',
                            icon: 'database',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'podsumowanie' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Załoga',
                            route: 'admin.dashboard.users',
                            icon: 'user',
                            can_open:  usePage().props.value.auth.user.permissions.find(e => e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Galeria',
                            route: 'admin.dashboard.galleries',
                            icon: 'server',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'galeria' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Ustawienia',
                            route: 'admin.dashboard.settings',
                            icon: 'server',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'ustawienia' || e.slug == 'admin') ? true : false,
                        },
                    ]
                },
                {
                    name: 'Aktualności',
                    route: 'admin.news',
                    icon: 'archive',
                    can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'editor' || e.slug == 'banery' || e.slug == 'kategorie' || e.slug == 'boxy' || e.slug == 'admin') ? true : false,
                    active: route().current('admin.news.*') || route().current('admin.adds.*') || route().current('admin.category.*') || route().current('admin.box.*')  || route().current('admin.boxes.*')  || route().current('admin.edit.category') || route().current('admin.edit.box'),
                    items: [
                        {
                            name: 'Lista aktualności',
                            route: 'admin.news.list',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'editor' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Kategorie',
                            route: 'admin.news.categories',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'kategorie' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Boxy',
                            route: 'admin.news.boxes',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'boxy' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Banery reklamowe',
                            route: 'admin.adds.main',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'banery' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Główny wpis link',
                            route: 'admin.news.link.list',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'editor' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Dodaj nowy wpis',
                            route: 'admin.news.add',
                            icon: 'plus',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'editor' || e.slug == 'admin') ? true : false,
                        },
                    ]
                },
                {
                    name: 'Rozgrywki',
                    route: 'admin.events',
                    icon: 'selector',
                    can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'ligi' || e.slug == 'kluby' || e.slug == 'sezony' || e.slug == 'admin') ? true : false,
                    active: route().current('admin.events.*'),
                    items: [
                        {
                            name: 'Sezony',
                            route: 'admin.events.seasons',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'sezony' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Kluby',
                            route: 'admin.events.clubs',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'kluby' || e.slug == 'admin') ? true : false,
                        },
                        {
                            name: 'Ligi',
                            route: 'admin.events.leagues',
                            icon: 'selector',
                            can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'ligi' || e.slug == 'admin') ? true : false,
                        },

                    ]
                },
                {
                    name: 'Strony statyczne',
                    route: 'admin.pages',
                    icon: 'book-open',
                    can_open: usePage().props.value.auth.user.permissions.find(e => e.slug == 'editor' || e.slug == 'admin') ? true : false,
                    hasItems: false,
                    active: route().current('admin.pages') || route().current('admin.pages.*'),
                },
            ]);

            onMounted(() => {
                animate.value = true;
                if (isDarkMode()) document.documentElement.classList.add('dark')
                else document.documentElement.classList.remove('dark')
                if (window.innerWidth < 991) menuVisible.value = !menuVisible.value;
            })

            const linkClick = () => {
                animate.value = false
            }

            const isDarkMode = () => {
                return localStorage.getItem('fbmpl_Futmal') ?
                    ( localStorage.getItem('fbmpl_Futmal') == 'true' ? true : false ) :
                        (usePage().props.value.default_mode && usePage().props.value.default_mode.value == 'dark' ? true : false);
            }

            const changeMode = () => {
                if (isDarkMode()) {
                    document.documentElement.classList.remove('dark')
                    localStorage.setItem('fbmpl_Futmal', 'false')
                }
                else {
                    document.documentElement.classList.add('dark')
                    localStorage.setItem('fbmpl_Futmal', 'true')
                }
            }

            return {
                menuItems,
                menuVisible,
                animate,
                linkClick,
                changeMode,
            }
        },

        watch: {
            '$page.props.errors': {
                deep: true,
                handler(val){
                    if(Object.keys(val).length){
                        this.$page.props.notifications.push({
                            'type': 'error',
                            'message': 'Nie wszystkie pola nie zostały uzupełnione ('+Object.keys(val).length+')',
                        });
                    }
                }
            },
        },
    }
</script>

<style>
.ads {
   width: 1px;
}
</style>

