<template>
<div
    :class="[
        $page.props.errors && $page.props.errors[$props.name ? $props.name : $props.id] ? 'is-invalid' : ''
    ]"
>
    <editor
        api-key="nyzs8l4j5ri378ig7fottisriivstj5qvfwkedk9caop61jq"
        v-model="value"
        :id="$props.id"
        :name="$props.name"
        :init="{
            auto_focus: false,
            placeholder: $props.placeholder,
            min_height: $props.minHeight,
            language: 'pl',
            docs_language: 'pl',
            menubar: false,
            plugins: [
                'advlist autolink lists link charmap preview anchor charmap emoticons',
                'searchreplace visualblocks fullscreen',
                'insertdatetime media table paste help wordcount',
                'image code'
            ],
            toolbar:
                'undo redo | formatselect | link unlink image | media | bold italic forecolor backcolor | \
                alignleft aligncenter alignright alignjustify removeformat | \
                bullist numlist outdent indent | charmap emoticons | \
                table tabledelete | code fullscreen help'
            ,
            branding: false,
            image_title: true,
            automatic_uploads: true,
            link_context_toolbar: true,
            paste_data_images: true,
            file_picker_types: 'image',
            file_picker_callback: filePickerFunction,
            extended_valid_elements: 'script[language|type|src]'
        }"
    />
    <div v-if="$page.props.errors && $page.props.errors[$props.name ? $props.name : $props.id]" class="text-red-500">
        {{$page.props.errors[$props.name ? $props.name : $props.id]}}
    </div>
</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { filePickerFunction } from '@/common/helper';
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
export default {
    components: {
        editor: Editor,
    },
    props: {
        modelValue: [String, Object, Array],
        placeholder: String,
        id: String,
        name: String,
        minHeight: {
            type: [Number, String],
            default: 400,
        },
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        // setup: function (editor) {
        //         editor.on('init', function (e) {
        //             editor.execCommand('JustifyFull', false);
        //         });
        //     }
        const value = ref(props.modelValue);

        watch(() => value.value, (val) => {
            emit('update:modelValue', val);
        })

        return {
            value,
            filePickerFunction
        }
    }
}
</script>

<style>
.is-invalid .tox-tinymce {
    border: 1px solid rgba(239, 68, 68) !important;
}
</style>
