<template>

    <div
    class="fixed top-20 right-5 z-50"
    v-if="$page.props.notifications.length"
    >
        <transition
            enter-active-class="transition duration-300"
            enter-from-class="transform opacity-0 scale-50 -translate-x-full"
            enter-to-class="transform opacity-100 scale-100 translate-x-0"
            leave-active-class="transition duration-300"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-50"

            mode="out-in"
            v-for="(n, key) in $page.props.notifications"
            :key="key"
        >
        <div class="overflow-hidden shadow-md max-w-2xl mb-2" v-if="n.show">
            <div
                class="font-medium border-2 px-4 py-2 text-sm w-100 rounded flex items-center justify-between"
                :class="{
                    ['border-red-700 text-red-700 bg-red-100'] : n.type == 'error',
                    ['border-green-700 text-green-700 bg-green-100'] : n.type == 'success',
                }"
            >
                <h5 class="font-semibold text-base mr-2">
                    {{
                        n.message
                    }}
                </h5>
                <SvgIcon
                    name="x"
                    class="cursor-pointer"
                    height= "22"
                    width= "22"
                    @click="closeFlash(key)"
                />
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
    import SvgIcon from '@/Components/SvgIcon';
    import { computed, onMounted, ref } from '@vue/runtime-core';
    import { usePage } from '@inertiajs/inertia-vue3';
    import { watch } from 'vue';

    export default {
        components: {
            SvgIcon
        },
        setup(props) {
            const closeFlash = (key) => {
                // usePage().props.value.notifications.splice(key, 1);
                usePage().props.value.notifications[key].show = false;
            }

            if (usePage().props.value.notifications.length) {
                usePage().props.value.notifications.forEach((el, key) => {
                    el.show = true;
                });

                setTimeout(() => {
                    let timer = 0;
                    usePage().props.value.notifications.reduceRight((_, item, key) => {
                        setTimeout(() => {
                            item.show = false
                        }, timer);
                        timer += 100;
                    }, null);

                }, 5000);
            }

            const sleep = (time) => {
                return new Promise((resolve) => setTimeout(resolve, time));
            }

            watch(() => usePage().props.value.notifications, () => {
                usePage().props.value.notifications.forEach((el, key) => {
                    el.show = true;
                });
                setTimeout(() => {
                    let timer = 0;
                    usePage().props.value.notifications.reduceRight((_, item, key) => {
                        setTimeout(() => {
                            item.show = false
                        }, timer);
                        timer += 100;
                    }, null);

                }, 5000);
            })

            return {
                sleep,
                closeFlash,
            }
        }
    }
</script>
