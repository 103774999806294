<template>
    <div class="flex flex-col" v-if="leagues.length">
        <inertia-link
        :href="route('league', { league: l.slug, season: route().params.season })"
        class="flex items-center hover:text-green-700 my-2 font-semibold"
        v-for="l in leagues"
        :key="l"
        >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>
            <span class="text-lg">
                <span>{{l.season.type ? l.season.year + '/' + (l.season.year + 1) : (l.season.year - 1) + '/' + l.season.year}}</span> - <span>{{l.name}}</span>
            </span>
        </inertia-link>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import moment from 'moment'
export default {
    components: {
        moment
    },
    props: {
        leagues: [Object, Array]
    },
    setup(props) {

    }
}
</script>
