<template>
    <div
        class="flex flex-col"
    >
        <label
            v-if="label"
            :for="id"
            class="mb-2 dark:text-white"
        >{{label}}</label>

        <div class="relative">
            <div
            class="w-full cursor-pointer truncate group text-left rounded-none border-0 border-b-2 px-4 py-2 outline-none focus:outline-none focus:ring-0"
            :class="[
            {'bg-white dark:bg-gray-300 dark:border-gray-300 border-gray-400 dark:hover:border-gray-200 hover:border-gray-500 focus:border-green-500 dark:focus:border-green-600':colors=='normal'},
            {'text-white border-white':colors=='transparent'}
            ]

            "
            @click="visible = !visible"
            >
            {{ currentName }}
            <span
            class="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center pr-2 pointer-events-none"
            >
            <SvgIcon
                name="selector"
                class=""
                :class="[
                {'text-gray-400 group-hover:text-gray-600':colors=='normal'},
                {'text-white':colors=='transparent'}
                ]"
                :size="20"
            />
            </span>
            </div>

            <div
            class="absolute w-full bg-gray-100 max-h-96 overflow-y-auto overflow-x-hidden z-50 px-2 font-normal text-base"
            v-if="visible"
            v-click-outside="() => {visible = false}"
            >
                <div
                v-for="item in sortedData"
                :key="item.id"
                :value="item.id"
                @click="selectItem(item)"
                class="cursor-pointer py-1 border-b-2 border-gray-300"
                :class="{
                    'bg-green-300' : selectedValue == item.id
                }"
                >
                    {{ getName(item) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import SvgIcon from '@/Components/SvgIcon'
import vClickOutside from 'click-outside-vue3'


export default {
    components: {
        SvgIcon
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    props: {
        data: {
            type: Object,
            default: null
        },
        modelValue: [String, Number],
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        colors: {
            type: String,
            default: 'normal'
        },
        selected: {
            type: [String, Number],
            default: null
        },
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const sortedData = ref(props.data);
        const currentName = ref(null);
        const visible = ref(false);
        const selectedValue = ref(props.modelValue);

        onMounted(() => {
            sortedData.value.sort((a,b) => (a.club_laczynaspilka_name > b.club_laczynaspilka_name) ? 1 : ((b.club_laczynaspilka_name > a.club_laczynaspilka_name) ? -1 : 0))
            currentName.value = 'Brak drużyny';
            props.data.forEach(function(el){
                if (el.id == selectedValue.value) {
                    currentName.value = `${el.club_name} - ${el.category_name ? (el.category_name + ' ') : ''}(${ el.name })`
                    return false;
                }
            })

        })

        const selectItem = (item) => {
            currentName.value = getName(item);
            console.log(item.club_name)
            emit('update:modelValue', item.id);
        }

        const getName = (item) => {
            return `${item.club_name} - ${item.category_name ? (item.category_name + ' ') : ''}(${ item.name })`
        }


        return {
            selectedValue,
            currentName,
            getName,
            visible,
            selectItem,
            sortedData
        }
    },

    watch: {
        modelValue(val) {
            this.selectedValue = val;

            this.$props.data.forEach((el) => {
                if (el.id == val) {
                    this.currentName = `${el.club_name} - ${el.category_name ? (el.category_name + ' ') : ''}(${ el.name })`
                    return false;
                }
            })
        }
    }
}
</script>
