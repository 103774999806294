<template>
    <div class="fb-comments" :data-href="url" data-width="100%" data-numposts="5"></div>
</template>

<script>
	export default {
        props:{
            url:{
                type: String
            }
        },
        setup(){
            return{

            }
        }
	}
</script>
