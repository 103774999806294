<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="flex justify-between items-center gap-3 mb-3">
                <Link :href="route('admin.events.leagues.add')">Dodaj</Link>
                <ListboxNew
                    :data="$props.seasons.map(e => {
                        return {
                            id: e.id,
                            name: e.type ? (e.year + '/' + (e.year + 1)) : e.year,
                        }
                    })"
                    class="w-52"
                    :modelValue="searchSeason"
                    @update:modelValue="getLeagues"
                />
            </div>
            <table class="rounded-md overflow-hidden shadow-sm w-full">
                <thead class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal">
                    <tr >
                        <th class="py-3 pl-3 text-left">Liga</th>
                        <th class="py-3 text-right pr-3">Akcje</th>
                    </tr>
                </thead>
                <tbody class="text-gray-600 text-sm font-light">
                <draggable
                    :list="$props.leagues"
                    @start="drag=true"
                    @end="drag=false"
                    @change="changePosition"
                    tag="transition-group"
                    handle=".handle"
                    item-key="id"
                    v-bind="dragOptions"
                >
                <template #item="{element}">
                    <tr
                        class="border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:bg-opacity-90"
                    >
                        <td class="pl-3 py-1 make-smaller font-bold">
                            <div class="flex gap-2 flex-col">
                                <div class="space-x-1">
                                    <inertia-link :href="route('league', {
                                        league: element.slug,
                                        season: route().params.season,
                                    })">{{element.name}}</inertia-link>
                                    <span class="text-gray-400 text-xs">({{ element.queues_count }})</span>
                                </div>
                            </div>
                        </td>
                        <td class="py-1 text-right pr-3">
                            <div class="flex items-center justify-end">
                            <Link :href="route('admin.events.leagues.tools', element.id)" class="mr-2 text-white bg-gray-800">
                                <SvgIcon name="settings" width="18" height="18"/>
                                <!-- <span class="ml-2 hidden lg:block">Narzędzia</span> -->
                            </Link>
                            <Link :href="route('admin.events.leagues.gueues', element.id)" class="mr-2 text-white bg-blue-600 hover:bg-blue-800">
                                <SvgIcon name="list" width="18" height="18"/>
                                <span class="ml-2 hidden lg:block">Kolejki</span>
                            </Link>
                            <Link :href="route('admin.events.leagues.edit', element.id)" class="bg-green-600 hover:bg-green-800">
                                <SvgIcon name="pencil" width="18" height="18"/>
                                <span class="ml-2 hidden lg:block">Edytuj</span>
                            </Link>
                            <button class="ml-4 handle cursor-move transform text-gray-400">
                                <SvgIcon
                                    name="menu"
                                />
                            </button>
                            </div>
                        </td>
                    </tr>
                    </template>
                </draggable>
                </tbody>
            </table>
            <!-- <pagination class="mt-6" :links="leagues.links" /> -->

        </div>
    </AdminLayout>
</template>
<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import ListboxNew from '@/Components/ListboxNew';
    import Pagination from '@/Components/Pagination'
    import { Inertia } from '@inertiajs/inertia'
    import Modal from '@/Components/Modal'
    import draggable from 'vuedraggable'
import { ref } from '@vue/reactivity'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination,
            Modal,
            ListboxNew,

            draggable
        },
        props: {
            leagues: Object,
            seasons: [Array, Object],
        },
        data(){
            return{
                confirmModal: false,
                searchSeason: null,
            }
        },
        mounted(){
            if(this.$props.seasons.length){
                this.searchSeason = this.$props.seasons.find(e => {
                    if(Number(route().params.season) == Number(e.year)) return true;
                    else if(!route().params.season && e.is_current) return true;
                }).id;
            }
        },
        methods: {
            showConfirmModal(){
                this.confirmModal = true;
            },



            getLeagues(el){
                this.searchSeason = el;

                this.$inertia.get(route( route().current() ), {
                    season: this.$props.seasons.find(e => e.id == el).year,
                }, {
                    preserveScroll: true,
                    preserveState: true,
                });
            }
        },
        setup(props) {

            const downloadScoresLeague = (id) => {
                Inertia.post(route('admin.download.all.scores', id));
            }

            const changePosition = (e) => {
                let positions = {}
                props.leagues.forEach((el, key) => {
                    positions[el.id] = key + 1;
                })
                Inertia.post(route('admin.post.events.leagues.position'), {leagues: positions}, {
                    onSuccess: () => {
                    }
                })
            }

            const dragOptions = ref({
                animation: 200,
                disabled: false,
            });

            return {
                downloadScoresLeague,

                dragOptions, changePosition,
            }
        }
    }
</script>

<style scoped>
    .make-smaller{
        max-width: 400px;
    }
    @media (max-width: 568px){
        .make-smaller{
            max-width: 100px;
        }
    }
</style>
