<template>
    <AdminLayout>

        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <Link :href="route('admin.news.categories')">Wróć</Link>
            <h1
                class="text-xl font-thin mb-3 dark:text-white mt-6"
            >
                Edytujesz: <span class="font-bold">{{category.name}}</span>
            </h1>
            <div class="">
                <InputLabel
                type="text"
                label="Nazwa kategorii"
                v-model="categoryForm.name"
                />
            </div>

             <div class="mt-4" v-if="$props.news_list_box">
                <label class="dark:text-white">Główny wpis</label>
                <Listbox
                    :data="$props.news_list_box"
                    v-model="categoryForm.main_news"
                    :selected="categoryForm.main_news"
                    class="mt-2"
                    type="objectkey"
                />

                    <!-- <select class="mt-2" placeholder="Wpis do ligi" v-model="categoryForm.main_news">
                        <option
                            v-for="(value, key) in $props.news_list_box"
                            :key="key"
                        >{{ value }}</option>
                    </select> -->
            </div>

            <!-- {{ category.news.frozen }} -->

            <!-- <div class="mt-4">
                <label class="dark:text-white">Główny wpis</label>
                <Listbox
                    :data="news"
                    v-model="categoryForm.main_news"
                    :selected="categoryForm.main_news"
                    class="mt-2"
                    type="objectkey"
                />
            </div> -->

            <div class="mt-4 grid md:grid-cols-2 gap-4">
                <div class="dark:text-white space-y-4">
                    <div class="flex items-center dark:text-white">
                        <Checkbox
                            v-model="categoryForm.visible_mobile"
                            :checked="categoryForm.visible_mobile"
                            id="visible_mobile"
                        />
                        <Label
                            class="ml-2 dark:text-white"
                            for="visible_mobile"
                        >Widoczny na wersji mobilnej</Label>
                    </div>
                    <div class="flex items-center dark:text-white">
                        <Checkbox
                            v-model="categoryForm.visible_desktop"
                            :checked="categoryForm.visible_desktop"
                            id="visible_desktop"
                        />
                        <Label
                            class="ml-2 dark:text-white"
                            for="visible_desktop"
                        >Widoczny na wersji desktopowej</Label>
                    </div>
                </div>
                <div class="dark:text-white space-y-4">
                    <div class="flex items-center dark:text-white">
                        <Checkbox
                            v-model="categoryForm.rolled_mobile"
                            :checked="categoryForm.rolled_mobile"
                            id="rolled_mobile"
                        />
                        <Label
                            class="ml-2 dark:text-white"
                            for="rolled_mobile"
                        >Rozwinięty na wersji mobilnej</Label>
                    </div>
                    <div class="flex items-center dark:text-white">
                        <Checkbox
                            v-model="categoryForm.rolled_desktop"
                            :checked="categoryForm.rolled_desktop"
                            id="rolled_desktop"
                        />
                        <Label
                            class="ml-2 dark:text-white"
                            for="rolled_desktop"
                        >Rozwinięty na wersji desktopowej</Label>
                    </div>
                </div>
            </div>
            <div class="mt-4 grid md:grid-cols-2 gap-4">
                <div class="">
                    <InputLabel
                        type="number"
                        label="Kolumny (desktop) / max: 12"
                        v-model="categoryForm.cols"
                    />
                </div>
                <div class="">
                    <InputLabel
                        type="number"
                        label="Rzędy (desktop)"
                        v-model="categoryForm.rows"
                    />
                </div>
            </div>
            <div class="mt-4" v-if="frozen_news.length > 0">
                <h1 class="mb-2 dark:text-white text-gray-500">Zamrożone newsy</h1>
                 <draggable
                    :list="frozen_news"
                    @start="drag=true"
                    @end="drag=false"
                    tag="transition-group"
                    @change="changePosition"
                    handle=".handle"
                    item-key="id"
                    v-bind="dragOptions"
                    >

                    <template #item="{element}">
                        <div
                        class="border-b relative border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-700 dark:text-white cursor-pointer flex justify-between"
                        >
                            <div class="py-1.5 pl-2 text-sm flex items-center font-bold">
                                {{element.name}} - {{ element.frozen_top }}
                            </div>
                            <div>
                                <div class="py-1.5 text-center flex items-center justify-center mr-2">
                                    <Link :href="route('admin.news.edit', element.news_id)" class="bg-green-600 hover:bg-green-800">
                                        <SvgIcon name="check" width="18" height="18" />
                                        <span class="ml-2 hidden lg:block">Edytuj</span>
                                    </Link>
                                    <div class="ml-4 handle cursor-move transform text-gray-400">
                                        <SvgIcon
                                            name="menu"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>


                 </draggable>

            </div>
            <div class="mt-10" v-if="$props.category && $props.category.main_news">
                <h1 class="mb-2 dark:text-white text-gray-500">Wpis główny</h1>
                <div
                    class="border-b relative border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-700 dark:text-white cursor-pointer flex justify-between hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                    <div class="py-1 pl-2 gap-3 text-sm flex items-center flex-wrap font-bold">
                        <span>{{$props.category.main_news.title}}</span>
                        <span class="text-gray-500 dark:text-gray-300 mt-1 font-normal text-xs">{{ getDate($props.category.main_news.created_at) }}</span>
                    </div>
                    <div>
                        <div class="py-1 text-center flex items-center justify-center mr-2">
                            <Link :href="route('admin.news.edit', $props.category.main_news.id)" class="bg-green-600 hover:bg-green-800">
                                <SvgIcon name="pencil" width="18" height="18" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
             <div class="mt-4" v-if="$props.news && $props.news.data.length">
                 <h1 class="mb-2 dark:text-white text-gray-500">Newsy przypisane do kategorii</h1>
                <ul>
                    <li
                        class="border-b relative border-gray-200  dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-700 dark:text-white cursor-pointer flex justify-between sm:flex-row flex-col hover:bg-gray-100 dark:hover:bg-gray-700"
                        v-for="cat in $props.news.data" :key="cat.id"
                    >
                        <div class="py-1 pl-2 gap-3 text-sm flex items-center flex-wrap font-bold">
                            <span>{{cat.title}}</span> <span class="text-gray-500 dark:text-gray-300 mt-1 font-normal text-xs">{{ getDate(cat.created_at) }}</span>
                        </div>
                        <div class="ml-auto">
                            <div class="py-1 text-center flex items-center gap-3 justify-center mr-2">
                                <Link
                                    @click="ev => {
                                        if(categoryForm.main_news == cat.id) categoryForm.main_news = null;
                                        else categoryForm.main_news = cat.id;
                                    }"
                                    :class="[
                                        cat.id == categoryForm.main_news ?
                                            'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-500 dark:hover:bg-yellow-600' :
                                                'bg-gray-500 hover:bg-gray-600 dark:bg-gray-500 dark:hover:bg-gray-600'
                                    ]"
                                >
                                    Wpis główny
                                </Link>
                                <Link :href="route('admin.news.edit', cat.id)" class="bg-green-600 hover:bg-green-800">
                                    <SvgIcon name="pencil" width="18" height="18" />
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="flex justify-center">
                    <Pagination class="mt-6" :links="$props.news.links" />
                </div>
            </div>
            <div class="mt-6 flex justify-end sticky bottom-0 py-1.5 bg-gray-200 dark:bg-gray-800">
                <Link @click="editCategory()" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="check" width="18" height="18" />
                    <span class="ml-2">Zapisz</span>
            </Link>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import { ref } from '@vue/reactivity';
    import { useForm } from '@inertiajs/inertia-vue3'
    import { nextTick, onMounted } from 'vue'
    import Link from '@/Components/Link'
    import Listbox from '@/Components/Listbox'
    import ImageUpload from '@/Components/ImageUpload'
    import ImageUpload2 from '@/Components/ImageUpload2'
    import MultiListbox from '@/Components/MultiListbox'
    import draggable from 'vuedraggable'
    import { Inertia } from '@inertiajs/inertia'
    import moment from 'moment';
    import Pagination from '@/Components/Pagination'

    import Checkbox from '@/Components/Checkbox'
    import Label from '@/Components/Label'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Listbox,
            ImageUpload,
            ImageUpload2,
            MultiListbox,
            draggable,
            Pagination,
            Checkbox,
            Label
        },
        props: {
            category: [Array, Object],
            news: [Array, Object],
            frozen_news: Array,
            categories: Array,
            news_list_box: [Array, Object],
        },
        data(){
            return{
                drag: false,
            }
        },
        setup(props) {
            const drag = ref(false);
            const dragOptions = ref({
                animation: 200,
                disabled: false,
            });

            function getDate(date){
                return moment(date).format('DD.MM.YYYY, HH:mm')
            }

            const categoryForm = useForm({
                name: props.category.name,
                visible_mobile: props.category.visible_mobile ? true:false,
                visible_desktop: props.category.visible_desktop ? true:false,
                rolled_mobile: props.category.rolled_mobile ? true:false,
                rolled_desktop: props.category.rolled_desktop ? true:false,
                cols: props.category.cols,
                rows: props.category.rows,
                cols_mobile: props.category.cols_mobile,
                rows_mobile: props.category.rows_mobile,
                main_news: props.category.main_news_id !== null ? props.category.main_news_id : 0,
            })

            const editCategory = () => {
                categoryForm.post(route('admin.category.edit', props.category.id), {
                    // only: ['category'],
                });
            }

            const changePosition = (e) => {
                let positions = {}
                props.frozen_news.forEach((el, key) => {
                    positions[el.id] = key+1
                })
                // console.log(positions)
                Inertia.post(route('admin.category.change.positions.frozen_news', props.category.id), {frozen_news: positions})
            }


            return {
                categoryForm,
                editCategory,
                drag,
                dragOptions,
                getDate,
                changePosition,
            }
        }
    }
</script>
