<template>
    <AdminLayout>
        <div class="py-6 px-3 sm:px-6 lg:px-8 ">
            <div class="flex justify-between mb-3 gap-4">
                <Link :href="route('admin.news.add')" class="bg-green-600 hover:bg-green-800">
                    <SvgIcon name="plus" width="18" height="18" />
                    <span class="ml-2">Dodaj</span>
                </Link>

            </div>
                <div class="w-full xl:hidden flex flex-row items-center mb-3">
                    <input
                        class="w-full bg-white border-1 border-gray-400 inner-shadow-sm text-gray-700 rounded"
                        type="text"
                        placeholder="Szukaj po nazwie"
                        v-model="searchForm.string"
                        @change="searchClubs()"
                        />
                        <button class="px-3 py-3 text-white bg-green-600 hover:bg-green-800 rounded ml-2">
                            <SvgIcon name="search" width="18" height="18" />
                        </button>
                </div>
            <div class="w-full table-auto rounded-md overflow-hidden shadow-sm ">
                <div class="bg-gray-700 text-gray-100 uppercase text-sm leading-normal flex flex-row justify-between items-center">
                    <div class="py-3 pl-3">News</div>
                    <div class="w-1/2 hidden xl:block">
                        <input
                        class="w-full bg-gray-800 border-none inner-shadow-sm text-white"
                        type="text"
                        placeholder="Szukaj po nazwie"
                        v-model="searchForm.string"
                        @change="searchClubs()"
                        />
                    </div>
                    <div class="py-3 pr-3">Akcje</div>
                </div>
                <div class="text-gray-600 text-sm font-light">
                    <div
                        v-for="n in news.data"
                        :key="n"
                        :class="[
                            showNewsCategory == n.id ? 'bg-green-50 dark:bg-gray-700 dark:bg-opacity-50' : 'odd:bg-gray-100'
                        ]"
                        class="dark:odd:bg-opacity-70  border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-600 dark:text-white cursor-pointer flex justify-between flex-wrap"
                    >
                    <div class="flex justify-between w-full">
                        <div class="flex items-center justify-between w-full flex-wrap">
                            <div class="pl-3 py-1 col-span-3 flex flex-col lg:flex-row items-left lg:items-center justify-between w-full flex-1 mr-3">
                                <inertia-link
                                    :href="route('news.id', n.slug)"
                                    v-html="n.title"
                                >
                                </inertia-link>
                                <div class="lg:pl-3 py-0 gap-2 flex items-center">
                                    <inertia-link
                                        v-if="n.user"
                                        :href="route('admin.dashboard.user.stats', n.user.id)"
                                        class="text-gray-400 hover:underline hover:text-green-600 py-1 rounded-full flex items-center"
                                    >
                                        <SvgIcon name="user" width="18" height="18" />
                                        <span class="ml-2">{{n.user.name}}</span>
                                    </inertia-link>
                                    <div
                                        class="flex items-center text-gray-400 px-1 py-1 rounded-full"
                                    >
                                        <SvgIcon name="eye" width="18" height="18" />
                                        <span class="ml-2 flex items-center">
                                            {{ n.visited }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-gray-400 border-l gap-2 border-gray-300 px-2 flex flex-col">
                                <span class="text-xs">{{ getDate(n.created_at) }}</span>
                            </div>
                        </div>
                        <div class="py-1 mx-3 text-center flex justify-end items-center">
                            <div>
                                <Link :href="route('admin.news.edit', n.id)" class="flex items-center bg-green-600 hover:bg-green-800">
                                    <SvgIcon name="pencil" width="18" height="18" />
                                    <span class="ml-2 hidden lg:block">Edytuj</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <pagination class="mt-6" :links="news.links" />
        </div>
    </AdminLayout>
</template>

<script>
    import AdminLayout from '@/Layouts/Admin'
    import InputLabel from '@/Components/InputLabel'
    import SvgIcon from '@/Components/SvgIcon'
    import Link from '@/Components/Link'
    import Pagination from '@/Components/Pagination'
    import { Inertia } from '@inertiajs/inertia'
    import { useForm } from '@inertiajs/inertia-vue3'
    import { ref } from '@vue/reactivity'
    import moment from 'moment'

    export default {
        components: {
            AdminLayout,
            InputLabel,
            SvgIcon,
            Link,
            Pagination
        },
        props: {
            news: Object
        },
        setup() {
            const searchForm = useForm({
                string: route().params.search ? route().params.search : null
            });

            const showNewsCategory = ref(null);

            const searchClubs = () => {
                Inertia.get(route('admin.news.list'), { search: searchForm.string }, {preserveState: true})
            }

            function getDate(date){
                return moment(date).format('DD/MM/YYYY HH:mm')
            }
            return {
                searchForm,
                searchClubs,
                getDate,

                showNewsCategory
            }
        }
    }
</script>
